





import { Pessoa } from '@/model/pessoa';
import { Component, Prop, Vue } from 'vue-property-decorator';
import documentoService from '@/api/documento.service';

@Component
export default class TermoAceite extends Vue {

    @Prop() public pessoaProp!: Pessoa;

    public conteudoDocumento: String;

    constructor() {
        super();
        this.conteudoDocumento = "";
        this.carregarTermoAceite();
    }

    async carregarTermoAceite () {
        let retorno = await documentoService.obterTermoAceite();
        if (retorno != null) {
            this.conteudoDocumento = retorno.conteudo;
        }
    }
    
}

