






import { Component, Vue } from 'vue-property-decorator';
import ListarUsuarioAdministrativo from '../usuario-administrativo/listar-usuario-administrativo.vue';
import ExibirUsuarioAdministrativo from '../usuario-administrativo/exibir-usuario-administrativo.vue';
import EditarUsuarioAdministrativo from '../usuario-administrativo/editar-usuario-administrativo.vue';

@Component({
  components: {
    ListarUsuarioAdministrativo,
    EditarUsuarioAdministrativo,
    ExibirUsuarioAdministrativo
  }
})
export default class UsuarioAdministrativoContainer extends Vue {
}
