
export class Avaliacao {
    nota?: Number;
    observacao?: string;
    recursoExiste?: Boolean;
    disponivel?: Boolean;

    constructor (nota?: Number, observacao?: string, recursoExiste?: Boolean, disponivel?: Boolean) {
        this.nota = nota;
        this.observacao = observacao;
        this.recursoExiste = recursoExiste;
        this.disponivel = disponivel;
    }
}