export class Contato {
    id: Number | null;
    tipo: String | null;
    valor: String;

    constructor (id: Number | null, tipo: String | null, valor: String) {
        this.id = id;
        this.tipo = tipo;
        this.valor = valor;
    }
}