





















































































import { Component, Vue, Prop} from 'vue-property-decorator';
import { Message } from '@/model/generic/message';
import { SAC } from '@/model/sac';
import { Pessoa } from '@/model/pessoa';
import ContatoComponent from '../component/contato-component.vue';
import sacService from '@/api/sac.service';
import assuntoService from '@/api/assunto.service';
import { Assunto } from '@/model/assunto';

@Component({
    components: { ContatoComponent }
})
export default class EditarFaleComAGente extends Vue {

    @Prop() public pessoaProp!: Pessoa;
    public sac: SAC;
    public assuntos: Array<Assunto>;

    constructor () {
        super();
        this.sac = new SAC(null, "", null, "");
        this.sac.contatos = Array.of();
        if (this.pessoaProp != null) {
            this.sac.pessoa = this.pessoaProp;
            this.sac.nome = this.pessoaProp.nome;
            this.sac.contatos = this.pessoaProp.contatos;
        }

        this.assuntos = Array.of();
        this.carregaAssuntos();
    }

    async carregaAssuntos() {
        let retorno = await assuntoService.listarAtivos();
        if (retorno != null && retorno.registros != null) {
            this.assuntos = retorno.registros;
        }
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result && this.validaContato()) {
            let retorno = await sacService.salvar(this.sac);
            if (retorno != null) {
                if (this.pessoaProp != null) {
                    this.$router.push({
                        name: 'home'
                    });
                } else {
                    this.$router.push({
                        name: 'login'
                    });
                }
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    validaContato () {
        let contatoPreenchido = this.sac.contatos != null && this.sac.contatos.length != 0;
        if (!contatoPreenchido) {
            this.$store.commit('setMessages', new Message('É obrigatório preencher pelo menos um contato.', 'danger'));
        }
        return contatoPreenchido;
    }

    voltar () {
        if (this.pessoaProp != null) {
            this.$router.push({
                name: 'home'
            });
        } else {
            this.$router.push({
                name: 'login'
            });
        }
    }
}
