export class Municipio {
    id: Number | null;
    nome: String;
    uf: String;
    //regional

    constructor (id: Number | null, nome: String, uf: String) {
        this.id = id;
        this.nome = nome;
        this.uf = uf;
    }
}