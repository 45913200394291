




































































import { SAC } from '@/model/sac';
import { Component, Vue, Prop} from 'vue-property-decorator';
import ContatoComponent from '../component/contato-component.vue';
import HistoricoSacComponent from '../component/historico-sac-component.vue';

@Component({
    components: { ContatoComponent, HistoricoSacComponent }
})
export default class ExibirFaleComAGente extends Vue {

    @Prop() public sacProp!: SAC;

    constructor () {
        super();
    }

    voltar () {
        this.$router.push({
            name: 'fale-com-a-gente-listar'
        });
    }
}
