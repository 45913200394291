var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal-message",
          attrs: {
            id: "modal-message",
            size: "lg",
            "hide-header": "",
            "hide-footer": "",
            centered: ""
          }
        },
        [
          _vm.messages !== null
            ? _c(
                "div",
                {
                  class: _vm.getClass,
                  staticStyle: { "text-align": "center" },
                  attrs: { id: "messages", role: "alert" }
                },
                [
                  _c("i", { class: _vm.getIcon }),
                  _vm._v(" " + _vm._s(_vm.getTitulo) + "\n          "),
                  _vm.getMensagem ? _c("br") : _vm._e(),
                  _vm.getMensagem ? _c("br") : _vm._e(),
                  _c("div", {
                    staticStyle: { "font-weight": "bold" },
                    domProps: { innerHTML: _vm._s(_vm.getMensagem) }
                  }),
                  _vm.messages.errors
                    ? _c("p", { staticClass: "mb-0" })
                    : _vm._e(),
                  _vm._l(_vm.messages.errors, function(e) {
                    return _c("p", { key: e.message }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(e.message) +
                          " " +
                          _vm._s(e.code ? "(" + e.code + ")" : "") +
                          "\n          "
                      )
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    _vm.$bvModal.hide("modal-message")
                    _vm.closeMessage()
                  }
                }
              },
              [_vm._v("OK")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }