







































































import { Component, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'
import formatar from '@/mixins/formatarMixins'
import { Usuario } from '../../model/usuario';
import { PerfilEnum } from '@/model/enum/perfil';
import { SituacaoEnum } from '@/model/enum/situacao';

@Component({
    mixins: [formatar],
    components: { Multiselect },
    props: { usuarioProp: Object }
})
export default class ExibirUsuarioAdministrativo extends Vue {

    private usuario: Usuario;
    private perfis: Array<PerfilEnum>;
    private isDisabled: boolean;
    private situacoes: Array<SituacaoEnum>;

    constructor () {
        super();
        this.isDisabled = true;
        this.usuario = this.$props.usuarioProp;
        this.perfis = Array.of(PerfilEnum.ADMINISTRATIVO, PerfilEnum.GERENCIAL, PerfilEnum.REGIONAL);
        this.situacoes = Array.of(SituacaoEnum.ATIVO, SituacaoEnum.INATIVO);
    }

    voltar () {
        this.$router.push({
            name: 'listar-usuario-administrativo'
        });
    }

}
