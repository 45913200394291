var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c("h2", { staticClass: "app-title" }, [
        _c("i", { staticClass: "fa fa-file-medical" }),
        _vm._v(
          "\n         " +
            _vm._s(
              _vm.isAvaliarLaudo ? "Avaliar Laudo da Pessoa" : "Exibir Pessoa"
            ) +
            "\n    "
        )
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c("dados-pessoa-component", {
                ref: "dadosPessoa",
                attrs: {
                  isRepresentado: _vm.isRepresentado,
                  pessoa: _vm.pessoa,
                  isIncluirDependente: _vm.isIncluirDependente,
                  isExibir: _vm.isExibir
                },
                on: {
                  changePossuiDeficiencia: function($event) {
                    _vm.possuiDeficiencia = $event
                  },
                  changeIsResponsavel: function($event) {
                    _vm.isResponsavel = $event
                  },
                  changeIsRepresentado: function($event) {
                    _vm.isRepresentado = $event
                  }
                }
              }),
              _c("endereco-component", {
                ref: "enderecoComponent",
                attrs: {
                  enderecoProp: _vm.pessoa.endereco,
                  showCollapseProp: true,
                  isExibirProp: _vm.isExibir
                }
              }),
              _c("contato-component", {
                ref: "contatoComponent",
                attrs: {
                  listaContatosProp: _vm.pessoa.contatos,
                  isExibirProp: _vm.isExibir,
                  showCollapseProp: true
                }
              }),
              _c("assuntos-interesse-component", {
                ref: "assuntoComponent",
                attrs: {
                  showCollapseProp: true,
                  isExibirProp: _vm.isExibir,
                  interesseConteudoVinculadoProp:
                    _vm.pessoa.interesseConteudoVinculado
                }
              }),
              _vm.possuiDeficiencia
                ? _c("tipo-deficiencia-component", {
                    ref: "tipoDeficienciaComponent",
                    attrs: {
                      showCollapseProp: true,
                      isExibirProp: _vm.isExibir,
                      tiposDeficienciaProp: _vm.pessoa.tiposDeficiencia
                    }
                  })
                : _vm._e(),
              _vm.possuiDeficiencia
                ? _c("recurso-acessibilidade-component", {
                    ref: "recursoAcessibilidadeComponent",
                    attrs: {
                      showCollapseProp: true,
                      isExibirProp: _vm.isExibir,
                      recursosAcessibilidadeProp:
                        _vm.pessoa.recursosAcessibilidade
                    }
                  })
                : _vm._e(),
              _vm.possuiDeficiencia
                ? _c("beneficio-component", {
                    ref: "beneficioComponent",
                    attrs: {
                      showCollapseProp: true,
                      isExibirProp: _vm.isExibir,
                      beneficiosProp: _vm.pessoa.beneficios
                    }
                  })
                : _vm._e(),
              _c("laudo-pessoa-component", {
                attrs: {
                  isExibirProp: !_vm.isAvaliarLaudo,
                  laudos: _vm.pessoa.laudos
                }
              }),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para consulta de pessoas"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Voltar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }