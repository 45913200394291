


















































































import { TipoDeficiencia } from '@/model/tipo-deficiencia';
import { Component, Prop, Vue } from 'vue-property-decorator';
import tipoDeficienciaService from '@/api/tipo-deficiencia.service';
import { TipoDeficienciaFilter } from '@/model/filter/tipo-deficiencia-filter';

@Component
export default class TipoDeficienciaComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public showCollapseProp!: Boolean;
    @Prop() public tiposDeficienciaProp!: Array<TipoDeficiencia>;

    public isExibir: Boolean = false;
    public showContent: Boolean = false;

    public tiposDeficienciaEspecifica: Array<TipoDeficiencia> = Array.of();
    public tiposDeficienciaGeral: Array<TipoDeficiencia> = Array.of();
    public tiposDeficienciaGeralSelecionadas: Array<TipoDeficiencia> = Array.of();
    public tiposDeficienciasSelecionadas: Array<TipoDeficiencia> = Array.of();

    constructor () {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);
        
        if (this.showCollapseProp != undefined) {
            this.showContent = this.showCollapseProp;
        }
        this.tiposDeficienciasSelecionadas = this.tiposDeficienciaProp;
        this.tiposDeficienciasSelecionadas.forEach(selecionado => {
            if (selecionado.pai) {
                const index = this.tiposDeficienciaGeralSelecionadas.map(g => g.id).indexOf(selecionado.pai.id, 0);
                if (index == -1) {
                    this.tiposDeficienciaGeralSelecionadas.push(selecionado.pai);
                }
            }
        })
        if(this.tiposDeficienciaGeralSelecionadas.length == 0){
            this.tiposDeficienciaGeralSelecionadas = this.tiposDeficienciasSelecionadas
        }
        this.carregaTipoDeficiencia();
    }

    async carregaTipoDeficiencia() {
        let filtro = new TipoDeficienciaFilter("", true)
        filtro.gerais = true;
        filtro.subCategorias = true;
        
        const retorno = await tipoDeficienciaService.listarTiposDeficiencia(filtro, false);
        if (retorno != null){
            this.tiposDeficienciaGeral = retorno.registros;
        }
    }

    atualizaTiposDeficienciaEspecificas() {
        this.tiposDeficienciaEspecifica = Array.of()
        this.tiposDeficienciaGeralSelecionadas.forEach(geral => {
            if (geral.subCategorias) {
                geral.subCategorias.forEach(sub => this.tiposDeficienciaEspecifica.push(sub))
            }
        })
        let remover: Array<TipoDeficiencia> = Array.of();
        this.tiposDeficienciasSelecionadas.forEach(selecionado => {
            const index = this.tiposDeficienciaEspecifica.indexOf(selecionado, 0);
            if (index == -1) {
                remover.push(selecionado)
            }
        })
        remover.forEach(rem => this.removerTipoDeficiencia(rem));
    }

    removerTipoDeficiencia (tip: TipoDeficiencia) {
        if (this.tiposDeficienciasSelecionadas != null) {
            const index = this.tiposDeficienciasSelecionadas.indexOf(tip, 0);
            if (index > -1) {
                this.tiposDeficienciasSelecionadas.splice(index, 1);
            }
        }
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }
}
