






















































































































































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router/router';
import { PerfilEnum } from '@/model/enum/perfil';
import { SituacaoEnum } from '@/model/enum/situacao';
import formatar from '@/mixins/formatarMixins'
import { Usuario } from '../../model/usuario';
import { UsuarioFilter } from '../../model/filter/usuario-filter';
import usuarioService from '@/api/usuario.service';

@Component({
    mixins: [formatar]
})
export default class ListarUsuarioAdministrativo extends Vue {

    private regexDoc: String = '^(\d{11}|[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}|[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/-[0-9]{2})$';
    private filtro: UsuarioFilter;
    private perfis: Array<PerfilEnum>;
    private situacoes: Array<SituacaoEnum>;
    private usuarios: Array<Usuario>;
    private usuarioSelecionado: Usuario = new Usuario(0, "", "", "", true, []);

    //campos da grid
    private campos: Array<Object> = [{
        key: 'nome',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'cpf',
        label: 'CPF',
        sortable: true
      },
      {
        key: 'email',
        label: 'E-mail',
        sortable: true
      },
      {
        key: 'situacao',
        label: 'Situação',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
      {
        key: 'acoes',
        label: 'Exibir',
        tdClass: 'acoes-1'
      }];
    private currentPage: Number = 1;
    private perPage: Number = 5;
    private pesquisando: boolean = false;
    private totalRows: Number = 0;

    constructor () {
        super();
        this.filtro = new UsuarioFilter("", "", true);
        this.filtro.trazerTodos = true;
        this.usuarios = Array<Usuario>();
        this.perfis = Array.of(PerfilEnum.ADMINISTRATIVO, PerfilEnum.GERENCIAL, PerfilEnum.REGIONAL);
        this.situacoes = Array.of(SituacaoEnum.ATIVO, SituacaoEnum.INATIVO);
    }

    mounted (): void {
        this.limpar();
        this.listarUsuarios();
    }

    limpar () {
        this.filtro = new UsuarioFilter("", "", true);
        this.filtro.trazerTodos = true;
        this.usuarios = Array<Usuario>();
    }

    async listarUsuarios () {
        if (this.filtro.perfil != null && this.filtro.perfil.length == 0) {
            this.filtro.perfil = undefined;
        }
        const retorno = await usuarioService.listarUsuarios(this.filtro)
        if (retorno != null) {
            this.usuarios = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
    }

    iniciarIncluir () {
        router.push({
            name: 'editar-usuario-administrativo'
        });
    }

    iniciarExibir (usuario: Usuario) {
        router.push({
            name: 'exibir-usuario-administrativo',
            params: { usuarioProp: usuario as any }
        });
    }

    iniciarAlterar (usuario: Usuario) {
        this.$router.push({
            name: 'editar-usuario-administrativo',
            params: { usuarioProp: usuario as any }
        });
    }

    async excluir (usuario: Usuario) {
        const retorno = await usuarioService.excluir(usuario);
        if (retorno != null) {
            this.listarUsuarios();
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }
}
