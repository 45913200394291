var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fieldset", { staticClass: "blocoFieldset" }, [
        _c("legend", [_vm._v("\n            Temas de Interesse\n        ")]),
        _c(
          "div",
          [
            _c(
              "b-card",
              [
                _c("label", { ref: "tabTemas", attrs: { tabindex: "0" } }, [
                  _vm._v("Tabela de Temas de Interesse")
                ]),
                _c(
                  "b-table",
                  {
                    attrs: {
                      "show-empty": "",
                      stacked: "sm",
                      hover: "hover",
                      striped: "striped",
                      items: _vm.interesseConteudoVinculado,
                      fields: _vm.campos,
                      "current-page": _vm.currentPage,
                      "per-page": _vm.perPage
                    },
                    scopedSlots: _vm._u(
                      [
                        !_vm.isExibirProp
                          ? {
                              key: "cell(acoes)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-content-left"
                                    },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modalExcluirInteresse",
                                              modifiers: {
                                                modalExcluirInteresse: true
                                              }
                                            }
                                          ],
                                          staticClass:
                                            "btn btn-outline-info ml-1",
                                          attrs: {
                                            title:
                                              "Excluir Categoria " +
                                              data.item.interesseCategoria
                                                .descricao +
                                              " Tema " +
                                              data.item.descricao
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.interesseConteudoExcluir =
                                                data.item
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-times"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "empty", colspan: "2", align: "center" },
                        slot: "empty"
                      },
                      [
                        _c("label", { attrs: { tabindex: "0" } }, [
                          _vm._v("Nenhum item informado")
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    ref: "btnIncluirNovoTema",
                    staticClass: "mt-30",
                    attrs: { variant: "primary", size: "md" },
                    on: {
                      click: function($event) {
                        return _vm.iniciarIncluir()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus" }),
                    _vm._v(" Incluir Novo Tema de Interesse\n                ")
                  ]
                )
              ],
              1
            ),
            _vm.showInclusao && !_vm.isExibirProp
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { xl: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "text-label",
                            attrs: {
                              label: "Categoria de Interesse",
                              "label-for": "categoriaInteresse"
                            }
                          },
                          [
                            _c(
                              "b-form-select",
                              {
                                attrs: {
                                  id: "categoriaInteresse",
                                  plain: true,
                                  value: "Selecionar a Categoria de Interesse"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.carregarInteresseConteudo()
                                  }
                                },
                                model: {
                                  value: _vm.categoriaInteresse,
                                  callback: function($$v) {
                                    _vm.categoriaInteresse = $$v
                                  },
                                  expression: "categoriaInteresse"
                                }
                              },
                              [
                                _c("template", { slot: "first" }, [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("Selecione")
                                  ])
                                ]),
                                _vm._l(_vm.categoriasInteresses, function(
                                  m,
                                  i
                                ) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: m } },
                                    [
                                      _vm._v(
                                        _vm._s(m.descricao) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { xl: "5" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "text-label required",
                            attrs: { label: "Tema" }
                          },
                          [
                            _c(
                              "b-form-select",
                              {
                                attrs: {
                                  id: "escolaridade",
                                  value: "Selecionar Tema"
                                },
                                model: {
                                  value: _vm.interesseConteudosSelecionado,
                                  callback: function($$v) {
                                    _vm.interesseConteudosSelecionado = $$v
                                  },
                                  expression: "interesseConteudosSelecionado"
                                }
                              },
                              [
                                _c("template", { slot: "first" }, [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("Selecione o Tema")
                                  ])
                                ]),
                                _vm._l(_vm.interessesConteudos, function(m, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: m } },
                                    [_vm._v(_vm._s(m.descricao))]
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        attrs: {
                          xs: "12",
                          sm: "12",
                          md: "12",
                          lg: "2",
                          xl: "4"
                        }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-30",
                            attrs: { variant: "primary", size: "md" },
                            on: {
                              click: function($event) {
                                return _vm.incluir()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(
                              " Salvar Novo Tema de Interesse\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalExcluirInteresse",
            title: "Confirmação de Remoção",
            "cancel-title": "Cancelar",
            "ok-title": "Excluir",
            modal: ""
          },
          on: {
            ok: function($event) {
              return _vm.excluir(_vm.interesseConteudoExcluir)
            }
          }
        },
        [
          _c(
            "b-row",
            [_c("b-col", [_vm._v("Deseja excluir o Assunto de Interesse?")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }