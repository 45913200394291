var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c("h2", { staticClass: "app-title" }, [
        _c("i", { staticClass: "fas fa-user-tie" }),
        _vm._v(
          " " +
            _vm._s(
              _vm.usuario.id == null || _vm.usuario.id == 0
                ? "Incluir"
                : "Alterar"
            ) +
            " Usuário Administrativo\n    "
        )
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.salvar()
                }
              }
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "6", xl: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "text-label", attrs: { label: "Nome" } },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "nome",
                              "data-vv-name": "nome",
                              placeholder: "Nome",
                              "error-messages": _vm.errors.collect("nome"),
                              state:
                                _vm.errors.has("nome") == false
                                  ? null
                                  : !_vm.errors.has("nome"),
                              autofocus: true,
                              trim: ""
                            },
                            model: {
                              value: _vm.usuario.nome,
                              callback: function($$v) {
                                _vm.$set(_vm.usuario, "nome", $$v)
                              },
                              expression: "usuario.nome"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("nome"),
                                  expression: "errors.has('nome')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.first("nome")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "6", xl: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "E-mail" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "email",
                              "data-vv-name": "email",
                              placeholder: "E-mail",
                              "error-messages": _vm.errors.collect("email"),
                              state:
                                _vm.errors.has("email") == false
                                  ? null
                                  : !_vm.errors.has("email"),
                              trim: ""
                            },
                            model: {
                              value: _vm.usuario.email,
                              callback: function($$v) {
                                _vm.$set(_vm.usuario, "email", $$v)
                              },
                              expression: "usuario.email"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("email"),
                                  expression: "errors.has('email')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.first("email")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "4", lg: "3", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "text-label", attrs: { label: "CPF" } },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, regex: _vm.regexDoc },
                                expression: "{ required: true, regex: regexDoc}"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'"
                              }
                            ],
                            attrs: {
                              id: "cpf",
                              "data-vv-name": "cpf",
                              placeholder: "CPF",
                              "error-messages": _vm.errors.collect("cpf"),
                              state:
                                _vm.errors.has("cpf") == false
                                  ? null
                                  : !_vm.errors.has("cpf"),
                              trim: ""
                            },
                            model: {
                              value: _vm.usuario.cpf,
                              callback: function($$v) {
                                _vm.$set(_vm.usuario, "cpf", $$v)
                              },
                              expression: "usuario.cpf"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cpf"),
                                  expression: "errors.has('cpf')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("CPF inválido.")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "Perfil" }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.perfis,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "select-label": "",
                              "hide-selected": true,
                              "allow-empty": false,
                              "preserve-search": true,
                              placeholder: "Selecione",
                              taggable: true,
                              "preselect-first": false
                            },
                            model: {
                              value: _vm.usuario.perfis,
                              callback: function($$v) {
                                _vm.$set(_vm.usuario, "perfis", $$v)
                              },
                              expression: "usuario.perfis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("perfil"),
                              expression: "errors.has('perfil')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.errors.first("perfil")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.usuario.id != null && _vm.usuario.id != 0
                    ? _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "6",
                            md: "6",
                            lg: "4",
                            xl: "3"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: { label: "Situação" }
                            },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "text-label negrito",
                                  model: {
                                    value: _vm.usuario.ativo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.usuario, "ativo", $$v)
                                    },
                                    expression: "usuario.ativo"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: "true" } },
                                    [_vm._v("Ativo")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: "false" } },
                                    [_vm._v("Inativo")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("situacao"),
                                  expression: "errors.has('situacao')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errors.first("situacao")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        size: "md",
                        title: "Salvar Usuário Administrativo"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Salvar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title:
                          "Voltar para tela de pesquisa de Usuário Administrativo"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Cancelar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }