var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "fieldset",
      { staticClass: "blocoFieldset" },
      [
        _c("legend", [
          _vm._v("\n            Histórico Atendimento SAC\n        ")
        ]),
        _c(
          "b-card",
          [
            _c(
              "b-table",
              {
                attrs: {
                  "show-empty": "",
                  stacked: "sm",
                  hover: "hover",
                  striped: "striped",
                  items: _vm.anotacoes,
                  fields: _vm.campos,
                  "current-page": _vm.currentPage,
                  "per-page": _vm.perPage
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(anotacao)",
                    fn: function(data) {
                      return [_vm._v(_vm._s(data.item.anotacao))]
                    }
                  },
                  {
                    key: "cell(dataHora)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("mascaraDataHora")(data.item.dataHora))
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  {
                    attrs: { slot: "empty", colspan: "2", align: "center" },
                    slot: "empty"
                  },
                  [_vm._v("Sem dados para exibir")]
                )
              ]
            ),
            _vm.anotacoesProp.length > 0
              ? _c(
                  "b-row",
                  { staticClass: "my-1 center-xy" },
                  [
                    _c("b-pagination", {
                      staticClass: "text-label",
                      attrs: {
                        "total-rows": _vm.totalRows,
                        "per-page": _vm.perPage,
                        length: _vm.currentPage
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "VuePagination__count text-center col-md-12"
                      },
                      [
                        _vm._v(
                          "\n                    Mostrando " +
                            _vm._s(
                              _vm.currentPage * _vm.perPage - _vm.perPage + 1
                            ) +
                            " a\n                    " +
                            _vm._s(
                              _vm.currentPage * _vm.perPage > _vm.totalRows
                                ? _vm.totalRows
                                : _vm.currentPage * _vm.perPage
                            ) +
                            "\n                    de " +
                            _vm._s(_vm.totalRows) +
                            " registros\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }