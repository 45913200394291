





















































import pessoaService from '@/api/pessoa-service';
import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator'
import CardButton from '../component/card-button.vue'
import centralseguranca from '@/api/centralseguranca';
import monitoramentoService from '@/api/monitoramento-service';
import {
    Pessoa
} from '@/model/pessoa';
import docsService from '@/api/docs-service';

@Component({
    components: {
        CardButton,
    }
})
export default class Home extends Vue {

    public usuarioCidadao: boolean;
    public showModalPessoa: Boolean = false;
    public pessoa: Pessoa | null;
    public deficienteVisual: Boolean = false;

    constructor() {
        super();
        this.usuarioCidadao = centralseguranca.usuarioLogadoCidadao();
        this.pessoa = null;
        this.carregarPessoa();
    }

    @Watch('deficienteVisual')
    alterarPessoa(valor) {
        if (this.pessoa && this.pessoa.id) {
            this.pessoa.deficienteVisual = valor
            pessoaService.salvarFlagDeficienteVisual(this.pessoa);
        }
    }

    mounted(): void {
        monitoramentoService.healthCheck();
    }

    async carregarPessoa() {
        let pessoa = await pessoaService.obterLogadoCompleto();
        if (pessoa != null) {
            this.pessoa = pessoa;
            this.deficienteVisual = this.pessoa.deficienteVisual
        } else {
            this.deficienteVisual = localStorage.getItem('deficienteVisual') == 'S'
        }
    }

    // Cidadao
    async cadastroPessoaNew() {
        if (this.pessoa === null) {
            await this.carregarPessoa();
        }
        let representado = this.pessoa !== null ? this.pessoa.representado : false;
        let rota = this.deficienteVisual || (this.pessoa && this.pessoa.deficienteVisual) ? 'editar-pessoa-deficiente-visual' : 'editar-pessoa';

        this.$router.push({
            name: rota,
            params: {
                pessoaProp: this.pessoa as any,
                isIncluirDependente: false as any,
                isRepresentado: representado as any
            }
        });
    }

    avaliarEstabelecimento() {
        this.$router.push({
            name: 'listar-avaliar-estabelecimento'
        });
    }
    async faleComAGente() {
        let pessoa = await pessoaService.obterLogadoCompleto();
        this.$router.push({
            name: 'fale-com-a-gente',
            params: {
                pessoaProp: pessoa as any
            }
        });
    }
    async meusEstabelecimentos() {
        this.$router.push({
            name: 'listar-meus-estabelecimentos',
            params: {
                visaoCidadao: true as any
            }
        });
    }

    exibirVideo() {
        if (this.pessoa && this.pessoa.deficienteVisual) {
            docsService.downloadArquivo(4)
                .then(response => {
                    if (response != null) {
                        // @ts-ignore
                        const blob = new Blob([response.data], {
                            type: 'video/mp4'
                        })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'pra-video-tutorial-deficientes-visuais.mp4'
                        link.click()

                        URL.revokeObjectURL(link.href)
                    }
                })
        } else {
            docsService.downloadArquivo(3)
                .then(response => {
                    if (response != null) {
                        // @ts-ignore
                        const blob = new Blob([response.data], {
                            type: 'video/mp4'
                        })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'pra-video-tutorial.mp4'
                        link.click()

                        URL.revokeObjectURL(link.href)
                    }
                })
        }
    }

    portal() {
        window.location.href = 'http://www.pracessivel.pr.gov.br'
    }
}
