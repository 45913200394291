






























































































import { Component, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'
import formatar from '@/mixins/formatarMixins'
import { Usuario } from '../../model/usuario';
import { Message } from '../../model/generic/message';
import { PerfilEnum } from '@/model/enum/perfil';
import { SituacaoEnum } from '@/model/enum/situacao';
import usuarioService from '@/api/usuario.service';

@Component({
    mixins: [formatar],
    components: { Multiselect },
    props: { usuarioProp: Object }
})
export default class EditarUsuarioAdministrativo extends Vue {

    private regexDoc: String = '^(\d{11}|[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}|[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/-[0-9]{2})$';
    private usuario: Usuario;
    private perfis: Array<PerfilEnum>;
    private situacoes: Array<SituacaoEnum>;

    constructor () {
        super();
        if (this.$props.usuarioProp != null) {
            this.usuario = this.$props.usuarioProp;
        } else {
            this.usuario = new Usuario(null, "", "", "", true);
        }
        this.perfis = Array.of(PerfilEnum.ADMINISTRATIVO, PerfilEnum.GERENCIAL, PerfilEnum.REGIONAL);
        this.situacoes = Array.of(SituacaoEnum.ATIVO, SituacaoEnum.INATIVO);
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result) {
            let retorno = await usuarioService.salvar(this.usuario);
            if (retorno != null) {
                this.$router.push({
                    name: 'listar-usuario-administrativo'
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'listar-usuario-administrativo'
        });
    }
}
