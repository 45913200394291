import { Message } from './../model/generic/message';
import store from '@/store/store';
import { SAC } from './../model/sac';
import { PagingList } from '@/model/generic/paging-list';
import { SACFilter } from './../model/filter/sac-filter';
import restUtil from './rest.util.service';

class SACService {

    async obterPorID (id: Number): Promise<SAC|null> {
        let retorno = await restUtil.doGet<SAC>('v1/sac/'+id);

        return retorno != null && retorno.data != null ? retorno.data : null;
    }

    async listarSAC (filter: SACFilter): Promise<PagingList<SAC>|null> {
        try{
            let retorno = await restUtil.doPost<PagingList<SAC>>('v1/sac/search', filter);
            if (retorno != null && retorno.data != null) {
                if (retorno.data.totalRegistros == 0) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

    async salvar (sac: SAC) {
        let retorno;
        if (sac.id == null) {
           retorno = await restUtil.doPost<SAC>('v1/sac', sac);
        } else {
           retorno = await restUtil.doPut<SAC>('v1/sac/'+sac.id, sac);
        }
        return retorno;
    }

}

export default new SACService();