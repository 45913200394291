
















































import { Component, Vue } from 'vue-property-decorator';
import { TipoDeficiencia } from '../../model/tipo-deficiencia';

@Component({
    props: { tipoDefProp: Object }
})
export default class ExibirTipoDeficiencia extends Vue {

    private tipo: TipoDeficiencia;

    private campos: Array<Object> = [
      {
        key: 'descricao',
        label: 'Deficiencia Específica',
        sortable: true
      }];

    constructor(){
        super();
        this.tipo = this.$props.tipoDefProp;
    }

    voltar () {
        this.$router.push({
            name: 'listar-tipo-deficiencia'
        });
    }
}
