<template>
    <div class="fluid">
        <br>
        <h2 class="app-title">
            <i class="fas fa-star"></i> Legislações
        </h2>
        <b-card>
            <b-row>
                <b-col>
                    <b-link aria-label="LINK Estatuto da Pessoa com Deficiência do Estado do Paraná Lei 18419/2015" class="btn btn-outline-info ml-1" 
                        @click="exibirLei('/lei18419')">
                        Estatuto da Pessoa com Deficiência do Estado do Paraná (Lei nº 18419/2015)
                    </b-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <br>
                    <b-link aria-label="Link Lei Brasileira de Inclusão Lei Federal nº 13146/2015" class="btn btn-outline-info ml-1" 
                        @click="exibirLei('/lei13146')">
                        Lei Brasileira de Inclusão (Lei Federal nº 13146/2015)
                    </b-link>
                </b-col>
            </b-row>
        </b-card>
        <div align="right">
            <b-col xl="6">
                <b-button outline size="md" @click="voltar()" variant="secondary"
                    role="button" 
                    aria-label="Voltar para tela inicial">
                    <i class="fas fa-arrow-left" /> Voltar para tela inicial
                </b-button>
            </b-col>
        </div>
    </div>
</template>

<script>

export default {

    methods: {
        exibirLei(lei) {
            this.$router.push({path: lei});
        },

        voltar () {
            this.$router.push({name: 'inicial'});
        }        
    }
}
</script>
