import { Assunto } from '../assunto';
export class SACFilter {
    nome: String;
    assunto: Assunto | null;
    situacao?: String;
    dataInicio?: Date;
    dataFim?: Date;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;

    constructor (nome: String, assunto: Assunto | null, trazerTodos: boolean) {
        this.nome = nome;
        this.assunto = assunto;
        this.trazerTodos = trazerTodos;
        this.campoOrdenacao = "sac.assunto";
        this.ascendente = true;
    }
}