var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "app d-flex flex-row justify-content-center align-items-center loading"
    },
    [
      _c("b-spinner", {
        staticStyle: { width: "3rem", height: "3rem" },
        attrs: { variant: "primary", label: "Carregando a aplicação" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }