import { InteresseConteudo } from './../model/interesse-conteudo';
import { Message } from './../model/generic/message';
import store from '@/store/store';
import restUtil from './rest.util.service';
import { PagingList } from '@/model/generic/paging-list';
import { InteresseCategoria } from '@/model/interesse-categoria';

class AssuntoInteresseService {

    async listarInteresseCategoriaAtivos (): Promise<PagingList<InteresseCategoria>|null> {
        try{
            let retorno = await restUtil.doGet<PagingList<InteresseCategoria>>('v1/assuntoInteresse/interesseCategoria');
            if (retorno != null && retorno.data != null) {
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

    async listarInteresseConteudo (idInteresseCategoria: Number): Promise<PagingList<InteresseConteudo>|null> {
        try{
            let retorno = await restUtil.doGet<PagingList<InteresseConteudo>>('v1/assuntoInteresse/interesseConteudo/'+idInteresseCategoria);
            if (retorno != null && retorno.data != null) {
                if (retorno.data.totalRegistros == 0) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

}

export default new AssuntoInteresseService();