import { PapelEstabelecimentoEnum } from "./enum/papel-estabelecimento";

export class PessoaEstabelecimento {
    id: Number | null;
    cpf: String | null;
    nome: String | null;
    papel: String | null;

    constructor (id: Number | null, cpf: String | null, nome: String, papel?: String | null) {
        this.id = id;
        this.cpf = cpf;
        this.nome = nome;
        if (papel !== undefined) {
            this.papel = papel;
        } else {
            this.papel = PapelEstabelecimentoEnum.EDITOR;
        }
    }
}