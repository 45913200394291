




























































































import { Component, Vue } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { PessoaEstabelecimento } from '@/model/pessoa-estabelecimento';
import { Util } from '@/util/util';

@Component({
    props: {
        listaPessoasProp: Array,
        isExibirProp: Boolean
    },
    mixins: [formatar]
})
export default class PessoaEstabelecimentoComponent extends Vue {

    public pessoa: PessoaEstabelecimento | null;
    public pessoaAlterar: PessoaEstabelecimento | null = null;
    public pessoas: Array<PessoaEstabelecimento>;
    public mascara: String | null = null;
    public isExibir: Boolean = false;

    //campos da grid
    public campos: Array<Object> = [
      {
        key: 'cpf',
        label: 'CPF',
        sortable: true
      },
      {
        key: 'nome',
        label: 'Nome',
        sortable: true
      }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public totalRows: Number = 0;

    constructor () {
        super();
        if (this.$props.isExibirProp != null) {
            this.isExibir = this.$props.isExibirProp;
        }
        if (!this.isExibir) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }

        this.pessoa = null;
        if (this.$props.listaPessoasProp != null) {
            this.pessoas = this.$props.listaPessoasProp;
            this.totalRows = this.pessoas.length;
        } else {
            this.pessoas = Array<PessoaEstabelecimento>();
        }
    }

    iniciarIncluir () {
        this.pessoa = new PessoaEstabelecimento(null, null, "");
    }

    iniciarAlterar (pessoa: PessoaEstabelecimento) {
        this.pessoaAlterar = pessoa;
        this.pessoa = {...pessoa};
    }

    incluir () {
        this.$validator.validateAll().then(res => { 
            if (res && this.validarCPFPessoa()) {
                if (this.pessoa != null) {
                    this.pessoas.push(this.pessoa);
                }
                this.pessoa = null;
            }
        });
    }

    alterar () {
        if (!this.validarCPFPessoa()) {
            return;
        }
        if (this.pessoaAlterar != null && this.pessoa != null){
            this.pessoaAlterar.cpf = this.pessoa.cpf;
            this.pessoaAlterar.nome = this.pessoa.nome;
        }
        this.pessoa = null;
        this.pessoaAlterar = null;
    }

    excluir (pessoa: PessoaEstabelecimento) {
        if (this.pessoas != null) {
            const index = this.pessoas.indexOf(pessoa, 0);
            if (index > -1) {
                this.pessoas.splice(index, 1);
            }
        }
        this.pessoaAlterar = null;
    }
    validarCPFPessoa () : Boolean {
        return this.pessoa !== undefined && this.pessoa !== null  && this.validarCPF(this.pessoa.cpf);
    }
    validarCPF (cpf: String | null) : Boolean {
        if (cpf === null && this.pessoa !== undefined && this.pessoa !== null) {
            cpf = this.pessoa.cpf;
        }
        return Util.validarCPF(cpf)
    }

}
