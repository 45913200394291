var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c("h2", { staticClass: "app-title" }, [
        _c("i", { staticClass: "fas fa-universal-access" }),
        _vm._v(
          " " +
            _vm._s(
              _vm.recurso.id == null || _vm.recurso.id == 0
                ? "Incluir"
                : "Alterar"
            ) +
            " Recurso de Acessibilidade\n    "
        )
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.salvar()
                }
              }
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "12", lg: "6", xl: "6" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Nome", "label-for": "nome" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "nome",
                              title: "Nome",
                              trim: "",
                              required: true,
                              "data-vv-name": "nome",
                              "error-messages": _vm.$validator.errors.collect(
                                "nome"
                              ),
                              state:
                                _vm.$validator.errors.has("nome") == false
                                  ? null
                                  : !_vm.$validator.errors.has("nome")
                            },
                            model: {
                              value: _vm.recurso.nome,
                              callback: function($$v) {
                                _vm.$set(_vm.recurso, "nome", $$v)
                              },
                              expression: "recurso.nome"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has("nome"),
                                  expression: "$validator.errors.has('nome')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$validator.errors.first("nome")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Descrição",
                            "label-for": "descricao"
                          }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "descricao",
                              title: "Descrição",
                              trim: "",
                              required: true,
                              "data-vv-name": "descricao",
                              "error-messages": _vm.$validator.errors.collect(
                                "descricao"
                              ),
                              state:
                                _vm.$validator.errors.has("descricao") == false
                                  ? null
                                  : !_vm.$validator.errors.has("descricao")
                            },
                            model: {
                              value: _vm.recurso.descricao,
                              callback: function($$v) {
                                _vm.$set(_vm.recurso, "descricao", $$v)
                              },
                              expression: "recurso.descricao"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has("descricao"),
                                  expression:
                                    "$validator.errors.has('descricao')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$validator.errors.first("descricao")
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        size: "md",
                        title: "Salvar Recurso de Acessibilidade"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Salvar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title:
                          "Voltar para tela de pesquisa de Recurso de Acessibilidade"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Cancelar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }