var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset",
        { staticClass: "blocoFieldset" },
        [
          _c("legend", [_vm._v("Laudos")]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-table",
                    {
                      attrs: {
                        "show-empty": "",
                        stacked: "sm",
                        hover: "hover",
                        striped: "striped",
                        items: _vm.laudos,
                        fields: _vm.campos,
                        "caption-top": "",
                        caption: "Tabela de Laudos"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(arquivo)",
                            fn: function(data) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: data.item.arquivo,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-file-alt",
                                      staticStyle: { "font-size": "30px" }
                                    })
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(dataAvaliacao)",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("mascaraDataHora")(
                                      data.item.dataAvaliacao
                                    )
                                  )
                                )
                              ]
                            }
                          },
                          !_vm.isExibirProp
                            ? {
                                key: "cell(acoes)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-left"
                                      },
                                      [
                                        _c(
                                          "b-link",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.modalAvaliar",
                                                modifiers: {
                                                  modalAvaliar: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-outline-info ml-1",
                                            attrs: { title: "Avaliar Laudo" },
                                            on: {
                                              click: function($event) {
                                                _vm.laudoSelecionado = data.item
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-file-medical"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: {
                            slot: "empty",
                            colspan: "2",
                            align: "center"
                          },
                          slot: "empty"
                        },
                        [_vm._v("Sem dados para exibir")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalAvaliar",
            title: "Avaliação de Laudo",
            "hide-footer": "",
            modal: ""
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Baixar Laudo"
                      },
                      on: {
                        click: function($event) {
                          return _vm.baixarLaudo(_vm.laudoSelecionado)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-download" }),
                      _vm._v(" Baixar Laudo\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" \n        "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label required",
                      attrs: {
                        label: "Justificativa",
                        "label-for": "justificativaLaudo"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'"
                          }
                        ],
                        attrs: {
                          id: "justificativaLaudo",
                          "data-vv-name": "justificativaLaudo",
                          "error-messages": _vm.$validator.errors.collect(
                            "justificativaLaudo"
                          ),
                          state:
                            _vm.$validator.errors.has("justificativaLaudo") ==
                            false
                              ? null
                              : !_vm.$validator.errors.has(
                                  "justificativaLaudo"
                                ),
                          autofocus: true,
                          trim: ""
                        },
                        model: {
                          value: _vm.justificativaLaudo,
                          callback: function($$v) {
                            _vm.justificativaLaudo = $$v
                          },
                          expression: "justificativaLaudo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { align: "right" } },
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Aprovar Laudo"
                      },
                      on: {
                        click: function($event) {
                          return _vm.aprovarLaudo(_vm.laudoSelecionado)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check" }),
                      _vm._v(" Aprovar Laudo\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        size: "md",
                        title: "Recusar Laudo"
                      },
                      on: {
                        click: function($event) {
                          return _vm.recusarLaudo(_vm.laudoSelecionado)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-times" }),
                      _vm._v(" Recusar Laudo\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }