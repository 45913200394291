






















































































































































































import formatar from '@/mixins/formatarMixins'
import { Estabelecimento } from '@/model/estabelecimento';
import { Component, Vue } from 'vue-property-decorator';
import { RamoAtividadeEnum } from '@/model/enum/ramo-atividade';
import { Option } from '@/model/option';
import EnderecoComponent from '../component/endereco-component.vue';
import ContatoComponent from '../component/contato-component.vue';
import PessoaEstabelecimentoComponent from '../component/pessoa-estabelecimento-component.vue';
import { Usuario } from '@/model/usuario';
import { ArquivoEstabelecimento } from '@/model/arquivo-estabelecimento';
import {saveAs} from 'file-saver';
import estabelecimentoService from '@/api/estabelecimento.service';
import centralseguranca from '@/api/centralseguranca';

@Component({
    mixins: [formatar],
    props: { estabProp: Object, voltarProp: String, visaoCidadao: Boolean },
    components: { EnderecoComponent, ContatoComponent, PessoaEstabelecimentoComponent }
})
export default class ExibirEstabelecimento extends Vue {

    public estabelecimento: Estabelecimento;
    public ramos: Array<Option>;
    public ramoAtividade: Option|undefined;
    public responsavel?: Usuario;
    public usuarioLogadoCidadao: Boolean;
    
    public camposFotos: Array<Object> = [
      {
        key: 'nome',
        label: 'Nome do Arquivo',
        sortable: true
      },
      {
        key: 'miniatura',
        label: "Pré-Visualização",
        sortable: false
      },
      {
        key: 'descricao',
        label: "Descrição da foto para deficientes visuais",
        sortable: false
      },
      {
        key: 'fonte',
        label: "Fonte da Foto",
        sortable: false
      },
      {
        key: 'autor',
        label: "Autor da Foto",
        sortable: false
      },
      {
        key: 'acoes',
        label: 'Ações',
        sortable: false
      },
    ]

    constructor(){
        super();
        this.usuarioLogadoCidadao = centralseguranca.usuarioLogadoCidadao();
        this.estabelecimento = this.$props.estabProp;
        this.estabelecimento.arquivos.forEach( arq => {
            arq.miniatura = '../../assets/loading.gif'
            this.geraMiniatura(arq);
        })
        this.ramos =  Object.entries(RamoAtividadeEnum).flatMap(obj => [new Option(obj, obj[1])]);
        if (this.estabelecimento.ramoAtividade) {
            this.ramoAtividade = this.getRamoOption(this.estabelecimento.ramoAtividade);
        }
        if(this.estabelecimento.horariosAtendimento != undefined){
            this.estabelecimento.horariosAtendimento.forEach(horarioAtendimento => {
                horarioAtendimento.horaInicio = new Date(horarioAtendimento.horaInicio);
                horarioAtendimento.horaFim = new Date(horarioAtendimento.horaFim);
            })
        }
        
        // preenchido somente para alguns perfis
        this.responsavel = this.estabelecimento.usuarioAlteracao;
    }

    getRamoOption(key : String) : Option {
        const enumValue = Object.entries(RamoAtividadeEnum)
            .find(([id, value]) => {if(id == key) return value});

        if (enumValue) {
            return new Option(enumValue, enumValue[1]);
        }
        return new Option(0, "");
    }

    voltar () {
        this.$router.push({
            name: this.$props.voltarProp,
            params: { visaoCidadao: this.$props.visaoCidadao }
        });
    }

    async geraMiniatura (arquivo: ArquivoEstabelecimento) {
        // @ts-ignore
        let conteudo = await estabelecimentoService.downloadArquivo(arquivo);
        if (conteudo != null) {
            var reader = new FileReader();
            // @ts-ignore
            reader.readAsDataURL(conteudo.data); 
            reader.onloadend = function() {
                var base64data = reader.result;
                // @ts-ignore
                arquivo.miniatura = base64data;
            }
        }
    }

    salvarArquivo (arquivo: ArquivoEstabelecimento) {
        if (arquivo.miniatura !== undefined) {
            // @ts-ignore
            saveAs(arquivo.miniatura, arquivo.nome);
        }
    }

    excluirArquivo (arquivo: ArquivoEstabelecimento) {
        estabelecimentoService.excluirArquivo(arquivo);
        if (this.estabelecimento.arquivos != null) {
            const index = this.estabelecimento.arquivos.indexOf(arquivo, 0);
            if (index > -1) {
                this.estabelecimento.arquivos.splice(index, 1);
            }
        }
    }
}
