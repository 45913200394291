import { PerfilEnum } from './enum/perfil';

export class Usuario {
    id: Number|null;
    nome: String;
    email: String;
    cpf: String;
    ativo: boolean;
    origem?: String; 
    perfis?: Array<PerfilEnum>;

    constructor (id: Number|null, nome: String, email: String, 
            cpf: String, ativo: boolean, perfis?: Array<PerfilEnum>) {
        this.id = id;
        this.nome = nome;
        this.email = email;
        this.cpf = cpf;
        this.ativo = ativo;
        this.origem = "PR_ACESSIVEL";
        this.perfis = perfis;
    }

}