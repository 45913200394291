var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-body",
    { staticClass: "submenu" },
    [
      _c("b-card-text", [
        _c(
          "ul",
          _vm._l(_vm.itens, function(item) {
            return _c(
              "li",
              { key: item.name, on: { click: _vm.hideSidebar } },
              [
                _c("router-link", { attrs: { to: item.url } }, [
                  _vm._v(_vm._s(item.name))
                ])
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }