export enum RamoAtividadeEnum {
    ALIMENTOS_BEBIDAS = "Alimentos e Bebidas",
    ARTE_ANTIGUIDADE = "Arte e Antiguidades",
    ARTIGOS_REGLIGIOSOS = "Artigos Religiosos",
    ASSINATURAS_REVISTAS = "Assinaturas e Revistas",
    AUTOMOVEIS_VEICULOS = "Automóveis e Veículos",
    BEBES_CIA = "Bebês e Cia",
    BLU_RAY = "Blu-Ray",
    BRINDES_MATERIAIS_PROMOCIONAIS = "Brindes / Materiais Promocionais",
    BRINQUEDOS_GAMES = "Brinquedos e Games",
    CASA_DECORACAO = "Casa e Decoração",
    CDS = "CDs",
    COLECIONAVEIS = "Colecionáveis",
    COMPRAS_COLETIVAS = "Compras Coletivas",
    CONSTRUCAO_FERRAMENTAS = "Construção e Ferramentas",
    COSMETICOS_PERFUMARIA = "Cosméticos e Perfumaria",
    CURSOS_EDUCACAO = "Cursos e Educação",
    DISCOS_VINIL = "Discos de Vinil",
    DVDS = "DVDs",
    ELETRODOMESTICOS = "Eletrodomésticos",
    ELETRONICOS = "Eletrônicos",
    EMISSORAS_RADIO = "Emissoras de Rádio",
    EMISSORAS_TELEVISAO = "Emissoras de Televisão",
    EMPREGOS = "Empregos",
    EMPRESAS_TELEMARKETING = "Empresas de Telemarketing",
    ESPORTE_LAZER = "Esporte e Lazer",
    FITAS_K7_GRAVADAS = "Fitas K7 Gravadas",
    FLORES_CESTAS_PRESENTES = "Flores, Cestas e Presentes",
    FOTOGRAFIA = "Fotografia",
    HD_DVD = "HD-DVD",
    IGREJAS_TEMPLOS_INST_RELIGIOSAS = "Igrejas / Templos / Instituições Religiosas",
    INDUSTRIA_COMERCIO_NEGOCIOS = "Indústria, Comércio e Negócios",
    INFLAVEIS_PROMOCIONAIS = "Infláveis Promocionais",
    INFORMATICA = "Informática",
    INGRESSOS = "Ingressos",
    INSTRUMENTOS_MUSICAIS = "Instrumentos Musicais",
    JOALHERIA = "Joalheria",
    LAZER = "Lazer",
    LD = "LD",
    LIVROS = "Livros",
    MD = "MD",
    MODA_ACESSORIOS = "Moda e Acessórios",
    MOTEIS = "Motéis",
    MUSICA_DIGITAL = "Música Digital",
    NATAL = "Natal",
    NEGOCIOS_E_OPORTUNIDADES = "Negócios e Oportunidades",
    OUTROS_SERVICOS = "Outros Serviços",
    OUTROS_SERVICOS_DE_AVALIACAO = "Outros Serviços de Avaliação",
    PAPELARIA_ESCRITORIO = "Papelaria e Escritório",
    PASCOA = "Páscoa",
    PET_SHOP = "Pet Shop",
    SAUDE = "Saúde",
    SERVICO_ADVOCATICIOS = "Serviço Advocaticios",
    SERVICO_DISTRIBUICAO_JORNAIS_REVISTAS = "Serviço de Distribuição de Jornais / Revistas",
    SERVICOS_ADMINISTRATIVOS = "Serviços Administrativos",
    SERVICOS_ARTISTICOS = "Serviços Artísticos",
    SERVICOS_ABATEDOUROS_MATADOUROS = "Serviços de Abatedouros / Matadouros",
    SERVICOS_AEROPORTOS = "Serviços de Aeroportos",
    SERVICOS_AGENCIAS = "Serviços de Agências",
    SERVICOS_ALUGUEL_LOCACAO = "Serviços de Aluguel / Locação",
    SERVICOS_ARMAZENAGEM = "Serviços de Armazenagem",
    SERVICOS_ASSESSORIAS = "Serviços de Assessorias",
    SERVICOS_ASSISTENCIA_TECNICA_INSTALACOES  = "Serviços de Assistência Técnica / Instalações ",
    SERVICOS_ASSOCIACOES = "Serviços de Associações",
    SERVICOS_BANCOS_SANGUE = "Serviços de Bancos de Sangue",
    SERVICOS_BIBLIOTECAS = "Serviços de Bibliotecas",
    SERVICOS_CARTORIOS = "Serviços de Cartórios",
    SERVICOS_CASAS_LOTERICAS = "Serviços de Casas Lotéricas",
    SERVICOS_CONFECCOES = "Serviços de Confecções",
    SERVICOS_CONSORCIOS = "Serviços de Consórcios",
    SERVICOS_CONSULTORIAS = "Serviços de Consultorias",
    SERVICOS_COOPERATIVAS = "Serviços de Cooperativas",
    SERVICOS_DESPACHANTE = "Serviços de Despachante",
    SERVICOS_ENGENHARIA = "Serviços de Engenharia",
    SERVICOS_ESTACIONAMENTOS = "Serviços de Estacionamentos",
    SERVICOS_ESTALEIROS = "Serviços de Estaleiros",
    SERVICOS_EXPORTACAO_IMPORTACAO = "Serviços de Exportação / Importação",
    SERVICOS_GEOLOGOS = "Serviços de Geólogos",
    SERVICOS_JOALHEIROS = "Serviços de joalheiros",
    SERVICOS_LEILOEIROS = "Serviços de Leiloeiros",
    SERVICOS_LIMPEZA = "Serviços de limpeza",
    SERVICOS_LOJA_DE_CONVENIENCIA = "Serviços de Loja de Conveniência",
    SERVICOS_MAO_DE_OBRA = "Serviços de Mão de Obra",
    SERVICOS_ORGAO_PUBLICOS = "Serviços de Órgão Públicos",
    SERVICOS_PESQUISAS = "Serviços de Pesquisas",
    SERVICOS_PORTOS = "Serviços de Portos",
    SERVICOS_SAUDE_BEM_ESTAR = "Serviços de Saúde / Bem Estar",
    SERVICOS_SEGURADORAS = "Serviços de Seguradoras",
    SERVICOS_SEGURANCA = "Serviços de Segurança",
    SERVICOS_SINALIZACAO = "Serviços de Sinalização",
    SERVICOS_SINDICATOS_FEDERACOES = "Serviços de Sindicatos / Federações",
    SERVICOS_TRADUCOES = "Serviços de Traduções",
    SERVICOS_TRANSPORTE = "Serviços de Transporte",
    SERVICOS_UTILIDADE_PUBLICA = "Serviços de Utilidade Pública",
    SERVICOS_AGRICULTURA_PECUARIA_PISCICULTURA = "Serviços em Agricultura / Pecuária / Piscicultura",
    SERVICOS_ALIMENTACAO = "Serviços em Alimentação",
    SERVICOS_ARTE = "Serviços em Arte",
    SERVICOS_CINE_FOTO_SOM = "Serviços em Cine / Foto / Som",
    SERVICOS_COMUNICACAO = "Serviços em Comunicação",
    SERVICOS_CONSTRUCAO = "Serviços em Construção",
    SERVICOS_ECOLOGIA_MEIO_AMBIENTE = "Serviços em Ecologia / Meio Ambiente",
    SERVICOS_ELETROELETRONICA_METAL_MECANICA = "Serviços em Eletroeletrônica / Metal Mecânica",
    SERVICOS_FESTAS_EVENTOS = "Serviços em Festas / Eventos",
    SERVICOS_INFORMATICA = "Serviços em Informática",
    SERVICOS_INTERNET = "Serviços em Internet",
    SERVICOS_JOIAS_RELOGIOS_OTICAS = "Serviços em Jóias / Relógios / Óticas",
    SERVICOS_TELEFONIA = "Serviços em Telefonia",
    SERVICOS_VEICULOS = "Serviços em Veículos",
    SERVICOS_ESOTERICOS_MISTICOS = "Serviços Esotéricos / Místicos",
    SERVICOS_FINANCEIROS = "Serviços Financeiros",
    SERVICOS_FUNERARIOS = "Serviços Funerários",
    SERVICOS_GERAIS = "Serviços Gerais",
    SERVICOS_GRAFICOS_EDITORIAIS = "Serviços Gráficos / Editoriais",
    SERVICOS_PARA_ANIMAIS = "Serviços para Animais",
    SERVICOS_PARA_DEFICIENTES = "Serviços para Deficientes",
    SERVICOS_PARA_ESCRITORIOS = "Serviços para Escritórios",
    SERVICOS_PARA_ROUPAS = "Serviços para Roupas",
    SERVICOS_SOCIAS_ASSISTENCIAIS = "Serviços Socias / Assistenciais",
    SEX_SHOP = "Sex Shop",
    SHOPPING_CENTERS = "Shopping Centers",
    TABACARIA = "Tabacaria",
    TARIFAS_BANCARIAS = "Tarifas Bancárias",
    TARIFAS_TELEFONICAS = "Tarifas Telefônicas",
    TELEFONIA = "Telefonia",
    TURISMO = "Turismo"
}