





























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Contato } from '@/model/contato';
import { TipoContatoEnum } from '@/model/enum/tipo-contato';
import { Option } from '@/model/option';

@Component({
    props: {
        listaContatosProp: Array
    }
})
export default class ContatoComponent extends Vue {

    @Prop() public showCollapseProp!: Boolean;
    @Prop() public isExibirProp!: Boolean;

    public currentPage: Number = 1;
    public perPage: Number = 50;
    
    public contato: Contato | null;
    public contatoAlterar: Contato | null = null;
    public contatos: Array<Contato>;
    public tipos: Array<Option>;
    public mascara: String = "X".repeat(100);;
    public tipo: Option|null;

    public showContent: Boolean = true;

    //campos da grid
    public campos: Array<Object> = [
      {
        key: 'tipo',
        label: 'Tipo',
        sortable: false,
        formatter: val => { 
            const enumValue = this.tipos.find(tipo => val == tipo.id[0])
            if (enumValue) {
                return enumValue.name;
            }
            return null;
         }
      },
      {
        key: 'valor',
        label: 'Contato',
        sortable: false
      }];

    constructor () {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);
        this.tipo = null;
        if (!this.isExibirProp) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }

        this.contato = null;
        this.tipos = Object.entries(TipoContatoEnum).flatMap(obj => [new Option(obj, obj[1])]);
        if (this.$props.listaContatosProp != null) {
            this.contatos = this.$props.listaContatosProp;
        } else {
            this.contatos = Array<Contato>();
        }
        this.getMask(null);
    }

    iniciarIncluir () {
        this.tipo = null;
        this.contato = new Contato(null, null, "");
        this.getMask(null);
    }

    iniciarAlterar (contato: Contato) {
        this.contatoAlterar = contato;
        this.contato = {...contato};
        let tipoBusca = this.tipos.find(t => this.contatoAlterar != null &&  t.id[0] == this.contatoAlterar.tipo)

        if (tipoBusca !== undefined) {
            this.tipo = tipoBusca
        }
    }

    incluir () {
        this.$validator.validateAll().then(res => { 
            if (res) {
                if (this.contato != null && this.tipo != undefined) {
                    this.contato.tipo = this.tipo.id[0];
                    this.contatos.push(this.contato);
                }

                this.contato = null;
                
                if (this.$refs.tabContatos instanceof HTMLElement) {
                    this.$refs.tabContatos.focus();
                }
            }
        });
    }

    alterar () {
        if (this.contatoAlterar != null && this.contato != null && this.tipo != undefined){
            this.contatoAlterar.tipo = this.tipo.id[0];
            this.contatoAlterar.valor = this.contato.valor;
        }
        this.contato = null;
        this.contatoAlterar = null;

        if (this.$refs.tabContatos instanceof HTMLElement) {
            this.$refs.tabContatos.focus();
        }
    }

    excluir (contato: Contato) {
        if (this.contatos != null) {
            const index = this.contatos.indexOf(contato, 0);
            if (index > -1) {
                this.contatos.splice(index, 1);
            }
        }
        this.contatoAlterar = null;
    }

    getMask (value: Option|null) {
        this.contato = new Contato(null, null, "");
        if(value != null) {
            if (TipoContatoEnum.CELULAR == value.id[1]) {
                this.mascara = "(##) #####-####";
            } else if (TipoContatoEnum.TELEFONE_FIXO == value.id[1]) {
                this.mascara = "(##) ####-####";
            } else {
                this.mascara = "X".repeat(100);
            }
        } else {
            this.mascara = "X".repeat(100);
        }
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }
}
