var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("fieldset", { staticClass: "blocoFieldset" }, [
      _c("legend", [
        _c(
          "a",
          {
            staticClass: "btn",
            attrs: { role: "button", "aria-expanded": "false" },
            on: {
              click: function($event) {
                _vm.showContent = !_vm.showContent
              }
            }
          },
          [
            _c("i", {
              staticClass: "far fa-caret-square-up",
              class: _vm.showContent ? "collapse.show" : "collapse"
            }),
            _c("i", {
              staticClass: "far fa-caret-square-down",
              class: _vm.showContent ? "collapse" : "collapse.show"
            })
          ]
        ),
        _vm._v("\n            Tipos de Deficiência\n        ")
      ]),
      _c(
        "div",
        { class: _vm.showContent ? "collapse.show" : "collapse" },
        [
          _c(
            "b-row",
            [
              !_vm.isExibirProp
                ? _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Deficiência Geral",
                            "label-for": "deficienciaGeral"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "deficienciaGeral",
                              options: _vm.tiposDeficienciaGeral,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": false,
                              "allow-empty": true,
                              "preselect-first": false,
                              "preserve-search": true,
                              placeholder: "Selecione",
                              "track-by": "id",
                              label: "descricao",
                              "deselect-label": "",
                              "select-label": "",
                              "selected-label": ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.atualizaTiposDeficienciaEspecificas()
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    return [
                                      values.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "multiselect__single"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(values.length) +
                                                  " selecionados\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              615670294
                            ),
                            model: {
                              value: _vm.tiposDeficienciaGeralSelecionadas,
                              callback: function($$v) {
                                _vm.tiposDeficienciaGeralSelecionadas = $$v
                              },
                              expression: "tiposDeficienciaGeralSelecionadas"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isExibirProp
                ? _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Deficiência Específica",
                            "label-for": "deficienciaEspecifica"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "deficienciaEspecifica",
                              options: _vm.tiposDeficienciaEspecifica,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": false,
                              "allow-empty": true,
                              "preselect-first": false,
                              "preserve-search": true,
                              placeholder: "Selecione",
                              "track-by": "id",
                              label: "descricao",
                              "deselect-label": "",
                              "select-label": "",
                              "selected-label": ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit(
                                  "atualizarTiposDeficiencia",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    return [
                                      values.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "multiselect__single"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(values.length) +
                                                  " selecionados\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              615670294
                            ),
                            model: {
                              value: _vm.tiposDeficienciasSelecionadas,
                              callback: function($$v) {
                                _vm.tiposDeficienciasSelecionadas = $$v
                              },
                              expression: "tiposDeficienciasSelecionadas"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isExibirProp
                ? _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "Selecionados" }
                        },
                        _vm._l(_vm.tiposDeficienciasSelecionadas, function(
                          tip
                        ) {
                          return _c(
                            "div",
                            {
                              key: tip.id,
                              staticClass: "multiselect__tags-wrap"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "multiselect__tag" },
                                [
                                  _c("span", [_vm._v(_vm._s(tip.descricao))]),
                                  !_vm.isExibirProp
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: { title: "Remover" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removerTipoDeficiencia(
                                                tip
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "multiselect__tag-icon",
                                            attrs: {
                                              "aria-hidden": "true",
                                              tabindex: "1"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isExibirProp
                ? _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "text-label" },
                        _vm._l(_vm.tiposDeficienciaGeralSelecionadas, function(
                          geral
                        ) {
                          return _c(
                            "div",
                            {
                              key: geral.id,
                              staticClass: "multiselect__tags-wrap"
                            },
                            [
                              _c("span", { staticClass: "negrito" }, [
                                _vm._v(_vm._s(geral.descricao))
                              ]),
                              _vm._l(
                                _vm.tiposDeficienciasSelecionadas,
                                function(tip) {
                                  return _c("div", { key: tip.id }, [
                                    tip.pai != undefined &&
                                    tip.pai.id == geral.id
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(tip.descricao))
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                }
                              )
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }