






























































import { Component, Vue } from 'vue-property-decorator';
import { Beneficio } from '../../model/beneficio';

@Component({
    props: { beneficioProp: Object }
})
export default class ExibirBeneficio extends Vue {
    
    public beneficio: Beneficio;

    constructor(){
        super();
        this.beneficio = this.$props.beneficioProp;
        if (this.beneficio.municipio == null) {
            this.beneficio.municipio = {id: null, nome: '', uf: ''};
        }
    }

    voltar () {
        this.$router.push({
            name: 'listar-beneficio'
        });
    }

    concatenaTiposDeficiencia () {
        let tiposDeficiencia = this.beneficio.tiposDeficiencia;
        let retorno = '';
        if (tiposDeficiencia != null) {
            tiposDeficiencia.forEach(function(tipoDef,i) {
                if (retorno.length !== 0) {
                    retorno += ", ";
                }
                retorno += tipoDef.descricao;
            });
        }
        return retorno;
    }
}
