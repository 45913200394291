<template>
  <button id="user-menu-button" class="dont-print cel-user-account" @click="openMenu" v-click-outside="hide">
    <div class="user-menu-labels">
      <div class="label-username text-right">
        <span class="sr-only">Nome do usuário</span>
        {{ nomeUsuario() }}
      </div>
      <div class="text-right">
        <span class="sr-only">Grupo do usuário</span>
        <span class="label-usergroup">{{ grupoUsuario() }}</span>
      </div>
    </div>
    <div >
      <img
        :src="require('@/assets/images/user-photo.png')"
        alt="Foto do usuário"
        class="user-photo"/>
      <span class="sr-only">Menu do usuário</span>

      <transition name="slide-fade">
        <div class="user-menu" v-if="showMenu">
          <div class="header no-gutters">
            <img
              :src="user.photo"
              alt="Foto do usuário"
              class="user-photo-menu"/>
            <div class="id-container">
              <div class="label-username">{{ nomeUsuario() }}</div>
              <div class="label-usergroup">{{ grupoUsuario() }}</div>
            </div>
          </div>
          <div class="user-menu-body">
            <ul>
              <li>
                <a href="#" @click="perfil">
                  <div class="i-block">
                    <i class="fas fa-id-badge"></i>
                  </div>
                  Perfil
                </a>
              </li>
              <li>
                <a href="#" @click="sair">
                  <div class="i-block">
                    <i class="fas fa-sign-out-alt"></i>
                  </div>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </button>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import store from '@/store/store'
import apiCentralSeguranca from '@/api/centralseguranca'
import { mediaBreakpoint } from '@/api/defs.js'

export default {
  name: 'UserAccount',
  data () {
    return {
      showMenu: false,
      user: {
        name: 'Usuário de Exemplo',
        group: this.grupoUsuario()
      }
    }
  },
  methods: {
    openMenu () {
      if (screen.width <= mediaBreakpoint.sm) {
        this.$store.commit('setSidebar', 'sidebar-contracted')
        this.$store.commit('setUserMenu', true)
      }
      this.showMenu = !this.showMenu
    },
    hide () {
      this.showMenu = false
    },
    sair () {
      this.showMenu = false
      this.$store.commit('limparJwtTokenState')
      window.location.href = apiCentralSeguranca.authorizeUrl + '&force_login=true'
    },
    perfil () {
      window.open(process.env.VUE_APP_PERFIL_URL, '_blank')
    },
    nomeUsuario () {
      if (store.getters.isAuthenticated) {
        return apiCentralSeguranca.decodeJwtToken(this.$store.getters.getJwtToken).nome
      } else {
        return this.user.name
      }
    },
    grupoUsuario () {
      return apiCentralSeguranca.getGrupoAtivo(this.$store.getters.getJwtToken);
    }    
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped></style>
