




































































import { Component, Vue } from 'vue-property-decorator';
import { RepresentanteDeficiente } from '../../model/representante';
import representanteService from '@/api/representante-service';
import pessoaService from '@/api/pessoa-service';
import formatar from '@/mixins/formatarMixins'
import { Message } from '@/model/generic/message';

@Component({
    mixins: [formatar]
})
export default class ListarVinculados extends Vue {


    public vinculados: Array<RepresentanteDeficiente> = Array.of();
    public pessoaSelecionada: RepresentanteDeficiente|null;

    public campos: Array<Object> = [{
        key: 'cpf',
        label: 'CPF',
        sortable: true
      },
      {
        key: 'deficiente.nome',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'grauParentesco.descricao',
        label: 'Grau de Parentesco',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public pesquisando: boolean = false;
    public totalRows: Number = 0;
    
    constructor(){
        super();
        this.pessoaSelecionada = null;
        this.vinculados = Array.of();
        this.carregaVinculados();
    }

    async carregaVinculados() {
        this.pesquisando = true;
        let retorno = await representanteService.listarVinculados();
        if (retorno != null) {
            this.vinculados = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
        this.pesquisando = false;
    }

    iniciarIncluir () {
        this.$router.push({
            name: 'editar-pessoa',
            params: { isRepresentado: true as any, isIncluirDependente: true as any }
        });
    }

    async iniciarAlterar (repDef: RepresentanteDeficiente) {
        if (repDef.deficiente != null && repDef.deficiente.id != null) {
            let retorno = await pessoaService.obterCompleto(repDef.deficiente.id)
            if (retorno != null) {
                this.$router.push({
                    name: 'editar-pessoa',
                    params: {
                        pessoaProp: retorno as any,
                        isRepresentado: retorno.representado as any,
                        isIncluirDependente: false as any
                    }
                });
            }
        }
    }

    async desvincular ( repDef: RepresentanteDeficiente) {
        if (repDef.deficiente != null && repDef.deficiente.id != null) {
            await pessoaService.desvincular(repDef.deficiente.cpf);
            this.$store.commit('setMessages', new Message('Pessoa desvinculada com sucesso', 'success'));
        }
        this.carregaVinculados();
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

}
