






























































































































































































































































import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { Pessoa } from '@/model/pessoa';
import { Endereco } from '@/model/endereco';
import { Message } from '@/model/generic/message';
import pessoaService from '@/api/pessoa-service';
import { Contato } from '@/model/contato';
import moment from 'moment';
import EnderecoComponent from '../component/endereco-component.vue';
import ContatoComponent from '../component/contato-component.vue';
import AssuntosInteresseComponent from '../component/assuntos-interesse-component.vue';
import TipoDeficienciaComponent from '../component/tipo-deficiencia-component.vue';
import RecursoAcessibilidadeComponent from '../component/recurso-acessibilidade-component.vue';
import BeneficioComponent from '../component/beneficio-component.vue';
import DadosPessoaComponent from '../pessoa/dados-pessoa-component.vue';
import TermoAceite from '../termo-aceite/termo-aceite.vue';
import { InteresseConteudo } from '@/model/interesse-conteudo';
import { TipoDeficiencia } from '@/model/tipo-deficiencia';
import { TipoPessoaEnum } from '@/model/enum/tipo-pessoa';
import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import { Beneficio } from '@/model/beneficio';
import { LaudoPessoa } from '@/model/laudo-pessoa';
import { SituacaoLaudoEnum } from '@/model/enum/situacao-laudo';
import { GrauParentesco } from '@/model/grau-parentesco';
import grauParentescoService from '@/api/grau-parentesco.service';
import Messages from '@/views/shared/components/Messages.vue'
import store from '@/store/store';

@Component({
    mixins: [formatar],
    components: {
        DadosPessoaComponent,
        ContatoComponent, EnderecoComponent, AssuntosInteresseComponent,
        TipoDeficienciaComponent, RecursoAcessibilidadeComponent, BeneficioComponent,
        TermoAceite, Messages
    }
})
export default class EditarPessoa extends Vue {

    @Prop() public pessoaProp!: Pessoa;
    @Prop() public isRepresentado!: Boolean; //deve ser removido e carregado através do usuário
    @Prop() public isIncluirDependente!: Boolean;
    @Prop() public isExibir!: Boolean;

    @Provide("parentValidator")
    public parentValidator = this.$validator;

    public pessoa: Pessoa;

    public possuiDeficiencia: Boolean | null = false;
    public isResponsavel: Boolean | null = false;
    public arquivos: Array<File>;
    public ciente: Boolean = false;
    public REPRESENTANTE: TipoPessoaEnum = TipoPessoaEnum.REPRESENTANTE;
    private arquivosEnviando: Array<String>;
    public cpfVincularRepresentante: String = "";

    public grausParentesco: Array<GrauParentesco>;

    public messages: any | null;

    public campos: Array<Object> = [
        {
            key: 'arquivo',
            label: 'Nome do Arquivo',
            sortable: true
        },
        {
            key: 'acoes',
            label: 'Ações',
            sortable: false
        }];
    public extensions: String = 'pdf,jpg,jpeg,bmp,png,doc,docx,xls,xlsx'
    public maxSizeFile: Number = 1024 * 1024 * 5 // 5MB

    constructor () {
        super();
        this.grausParentesco = Array.of<GrauParentesco>();
        if (this.pessoaProp != null) {
            this.pessoa = this.pessoaProp;
            if (this.pessoa.dtNascimento != null) {
                this.pessoa.dtNascimento = moment(this.pessoa.dtNascimento).toDate();
            }
            if (this.pessoa.endereco == null) {
                this.pessoa.endereco = new Endereco(null, "", "", "", null, "", null);
            }
            if (this.pessoa.tipo == TipoPessoaEnum.PCD) {
                this.possuiDeficiencia = true;
            } else if (this.pessoa.tipo == TipoPessoaEnum.REPRESENTANTE) {
                this.isResponsavel = true
            }
        } else {
            let endereco = new Endereco(null, "", "", "", null, "", null);
            this.pessoa = new Pessoa(null, "", "", "", null);
            this.pessoa.endereco = endereco;
            this.pessoa.contatos = Array.of<Contato>();
            this.pessoa.interesseConteudoVinculado = Array.of<InteresseConteudo>();
            this.pessoa.tiposDeficiencia = Array.of<TipoDeficiencia>();
            this.pessoa.recursosAcessibilidade = Array.of<RecursoAcessibilidade>();
            this.pessoa.beneficios = Array.of<Beneficio>();
            this.pessoa.laudos = Array.of<LaudoPessoa>();
            this.pessoa.tempoCadastro = new Date().getTime();
            this.pessoa.deficienteVisual = localStorage.getItem('deficienteVisual') == 'S'
            this.possuiDeficiencia = this.pessoa.deficienteVisual
        }
        
        if (this.pessoa.laudos !== undefined) {
            this.arquivos = this.pessoa.laudos.flatMap(laudo => new File([], laudo.arquivo));
        } else {
            this.arquivos = Array.of();
        }
        this.arquivosEnviando = Array.of();
        this.carregarGrauParentesco();
        this.messages = null;
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result) {
            if (this.isIncluirDependente) {
               this.salvarDependente();
            } else if (this.pessoa.id == null) {
                this.$bvModal.show('modalTermoAceite');
            } else {
                this.ciente = true;
                this.confirmarAceite();
            }
        } else {
            this.abrirFieldsets();
            this.$store.commit('setMessages', new Message('Preencha todos os campos obrigatórios', 'danger'));
            window.scrollTo(0, 0);
        }
    }

    async salvarDependente () {
        this.trataEndereco(this.pessoa);
        await this.aguardaEnvioArquivos();
        this.pessoa.tipo = this.possuiDeficiencia ? TipoPessoaEnum.PCD : TipoPessoaEnum.OUTROS;
        const retorno = await pessoaService.salvar(this.pessoa);
        if (retorno != null){
            this.$router.push({
                name: 'listar-vinculados'
            });
            this.$store.commit('setMessages', new Message('Dependente vinculado com sucesso', 'success'));
        }
    }

    trataEndereco(pessoa: Pessoa) {
        if (pessoa.endereco !== undefined && pessoa.endereco.cep == "") {
            pessoa.endereco = undefined;
        }
    }

    voltar () {
        this.$router.go(-1);
    }    

    abrirFieldsets() {
        this.$emit('openFieldset', true);
    }

    inputFile (newFile: Array<Object>) {
        if (this.pessoa.laudos === undefined) {
            this.pessoa.laudos = Array.of();
        }
        // @ts-ignore
        let lastFile = newFile[newFile.length-1].file;
        if (this.validaInputFile(lastFile)) {
            let novoLaudo = new LaudoPessoa(null, SituacaoLaudoEnum.NAO_AVALIADO, lastFile.name);
            novoLaudo.content = lastFile;
            this.pessoa.laudos.push(novoLaudo);
            this.arquivosEnviando.push(novoLaudo.arquivo);
            pessoaService.enviarArquivo(lastFile, this.pessoa.cpf).then(response => {
                const index = this.arquivosEnviando.indexOf(novoLaudo.arquivo, 0);
                if (index > -1) {
                    this.arquivosEnviando.splice(index, 1);
                }
            });
        }
    }

    validaInputFile(file: File) {
        let fileExtension = file.name.split('.').pop();
        if (!fileExtension || !this.extensions.includes(fileExtension)) {
            this.$store.commit('setMessages', new Message('Tipo de arquivo não permitido', 'warning'));
            return false;
        }
        if (file.size > this.maxSizeFile) {
            this.$store.commit('setMessages', new Message('O arquivo deve possuir no máximo 5MB', 'warning'));
            return false;
        }
        return true;
    }

    removeArquivo (arquivo: LaudoPessoa) {
        if (this.pessoa.laudos != null) {
            const index = this.pessoa.laudos.indexOf(arquivo, 0);
            if (index > -1) {
                this.pessoa.laudos.splice(index, 1);
            }
        }
    }

    async confirmarAceite() {
        if (this.ciente) {
            this.trataEndereco(this.pessoa);
            await this.aguardaEnvioArquivos();
            if (this.isResponsavel) {
                this.salvarRepresentante();
            } else {
                this.salvarPCD();
            }
        }
    }

    async salvarRepresentante () {
        this.pessoa.tipo = this.possuiDeficiencia ? TipoPessoaEnum.PCD : TipoPessoaEnum.REPRESENTANTE;
        const retorno = await pessoaService.salvar(this.pessoa);
        if (retorno != null && !this.pessoa.possuiVinculados){
            this.$router.push({
                name: 'listar-vinculados'
            });
        }
    }

    async salvarPCD () {
        await this.aguardaEnvioArquivos();
        this.pessoa.tipo = this.possuiDeficiencia ? TipoPessoaEnum.PCD : TipoPessoaEnum.OUTROS;

        const retorno = await pessoaService.salvar(this.pessoa);
        if (retorno != null){
            this.$router.push({
                name: 'home'
            });
            this.$store.commit('setMessages', new Message('Dados pessoais salvos com sucesso', 'success'));
        }
    }

    async aguardaEnvioArquivos () {
        if (this.arquivosEnviando.length > 0) {
            await this.delay(1000);
            await this.aguardaEnvioArquivos();
        }
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    pessoasVinculadas () {
        this.$router.push({
            name: 'listar-vinculados'
        });
    }

    async buscarVincularRepresentante () {
        this.pessoa.representante = await pessoaService.obterPorCpf(this.cpfVincularRepresentante);
        this.messages = this.$store.getters.getMessages;
        setTimeout(function(){
            store.commit('setMessages', '')
        }, 5000);
    }

    async vincularRepresentante () {
        this.trataEndereco(this.pessoa);
        this.pessoa.tipo = this.possuiDeficiencia ? TipoPessoaEnum.PCD : TipoPessoaEnum.OUTROS;
        this.pessoa.representado = true;
        await pessoaService.salvar(this.pessoa);
        this.$store.commit('setMessages', new Message('Representante vinculado com sucesso!', 'success'));
        this.isRepresentado = true;
        // @ts-ignore
        this.$refs.dadosPessoa.isRepresentado = this.isRepresentado;
    }

    async carregarGrauParentesco () {
        let retorno = await grauParentescoService.listarTodos();
        if (retorno != null) {
            this.grausParentesco = retorno;
        }
    }

}
