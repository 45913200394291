import { Municipio } from './municipio';
import { TipoDeficiencia } from './tipo-deficiencia';

export class Beneficio {

    id: Number|null;
    descricao: String;
    tiposDeficiencia: Array<TipoDeficiencia>|null;
    tipoBeneficio?: String;
    municipio?: Municipio;
    estado?: String;

    constructor(id: Number|null, descricao: String, tiposDeficiencia: Array<TipoDeficiencia>|null, 
        tipoBeneficio?: String, municipio?: Municipio, estado?: String) {
        this.id = id;
        this.descricao = descricao;
        this.tiposDeficiencia = tiposDeficiencia;
        this.tipoBeneficio = tipoBeneficio;
        this.municipio = municipio;
        this.estado = estado;
    }
}