import { AvaliacaoRecurso } from './avaliacao-recurso';
import { Avaliacao } from './avaliacao';
import { Estabelecimento } from './estabelecimento';

export class AvaliacaoEstabelecimento {
    estabelecimento: Estabelecimento;
    avaliacao: Avaliacao;
    avaliacoesRecursos: Array<AvaliacaoRecurso>;
    dataComparecimento?: Date;

    constructor (estabelecimento: Estabelecimento, dataComparecimento?: Date, nota?: Number, observacao?: string) {
        this.estabelecimento = estabelecimento;
        this.dataComparecimento = dataComparecimento;
        this.avaliacao = new Avaliacao(nota, observacao);
        if (estabelecimento.acessibilidades !== undefined) {
            this.avaliacoesRecursos = estabelecimento.acessibilidades.flatMap(obj => [new AvaliacaoRecurso(obj)]);
        } else {
            this.avaliacoesRecursos = Array.of();
        }
    }
}