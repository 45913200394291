<template>
  <div>
    <b-modal 
        ref="modal-message"
        id="modal-message" 
        size="lg" 
        hide-header
        hide-footer
        centered
        >
        <div id="messages" v-if="messages !== null" :class="getClass" role="alert" style="text-align: center;">
          <i :class="getIcon"></i> {{ getTitulo }}
          <br v-if="getMensagem" />
          <br v-if="getMensagem" />
          <div v-html="getMensagem" style="font-weight: bold;"></div>

          <p class="mb-0" v-if="messages.errors" />

          <p v-for="e in messages.errors" :key="e.message">
            {{ e.message }} {{ e.code ? "(" + e.code + ")" : "" }}
          </p>
        </div>
        <div style="text-align: center;"> 
          <a href="#" @click="$bvModal.hide('modal-message'); closeMessage();">OK</a>
        </div>
    </b-modal>

</div>
</template>
<script>

export default {
  name: 'Messages',
  mounted () {
    this.$bvModal.show('modal-message')
  },
  methods: {
    closeMessage() {
      this.$store.commit('setMessages', '');
    },
  },
  computed: {
    messages: {
      get () {
        window.scrollTo(0, 0);
        const msg = this.$store.getters.getMessages

        if (msg.message) {
          this.$bvModal.show('modal-message')
        }
        return msg
      }
    },
    getTitulo() {
      const msg = this.$store.getters.getMessages

      if (msg.message && msg.message.indexOf('#', msg.message) > -1) {
        return msg.message.substring(0, msg.message.indexOf('#', msg.message))
      } else {
        return msg.message
      }
    },
    getMensagem() {
      const msg = this.$store.getters.getMessages

      if (msg.message && msg.message.indexOf('#', msg.message) > -1) {
        return msg.message.substring(msg.message.indexOf('#', msg.message)+1)
      } else {
        return null
      }
    },
    defineVariant () {
      if (this.messages.variant != null) {
        return this.messages.variant
      } else {
        return 'danger'
      }
    },
    showDismissibleAlert: {
      get () {
        if (this.messages.message) {
          return true
        } else {
          return false
        }
      },
      set () {
        return true
      }
    },
    getClass () {
      var classe
      switch (this.messages.variant) {
        case 'warning':
          classe = 'alert alert-warning'
          break
        case 'danger':
          classe = 'alert alert-danger'
          break
        case 'info':
          classe = 'alert alert-info'
          break
        case 'success':
          classe = 'alert alert-success'
          break
        default:
          classe = 'alert alert-warning'
      }
      return classe
    },
    getIcon () {
      var icon
      switch (this.messages.variant) {
        case 'warning':
          icon = 'fas fa-exclamation-triangle'
          break
        case 'danger':
          icon = 'fas fa-exclamation-circle'
          break
        case 'info':
          icon = 'fas fa-info-circle'
          break
        case 'success':
          icon = 'fas fa-check-circle'
          break
        default:
          icon = 'fas fa-exclamation-circle'
      }
      return icon
    }
  }
}
</script>








