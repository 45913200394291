import { GrauParentesco } from './../model/grau-parentesco';
import { Message } from '../model/generic/message';
import store from '@/store/store';
import restUtil from './rest.util.service';

class AssuntoService {
    async listarTodos (): Promise<Array<GrauParentesco>|null> {
        try{
            let retorno = await restUtil.doGet<Array<GrauParentesco>>('v1/grauParentesco');
            if (retorno != null && retorno.data != null) {
                if (retorno.data.length == 0) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }
}

export default new AssuntoService();