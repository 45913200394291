



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { LaudoPessoa } from '@/model/laudo-pessoa';
import { Message } from '@/model/generic/message';
import {saveAs} from 'file-saver';
import pessoaService from '@/api/pessoa-service';
import { SituacaoLaudoEnum } from '@/model/enum/situacao-laudo';

@Component({
    mixins: [formatar]
})
export default class DadosPessoaComponent extends Vue {
    
    @Prop() public isExibirProp!: Boolean;
    @Prop() public laudos!: Array<LaudoPessoa>;

    private laudoSelecionado?: LaudoPessoa;
    private justificativaLaudo: String | null;
    public campos: Array<Object> = [
      {
        key: 'arquivo',
        label: 'Documento',
        sortable: true
      },
      {
        key: 'situacao',
        label: 'Situação',
        sortable: true
      },
      {
        key: 'usuarioAvaliacao.nome',
        label: 'Usuário Avaliador',
        sortable: true
      },
      {
        key: 'dataAvaliacao',
        label: 'Data de Avaliação',
        sortable: true
      },
      {
        key: 'justificativa',
        label: 'Justificativa',
        sortable: false
      },
      {
        key: 'acoes',
        label: 'Ações',
        sortable: false
      }];

    constructor() {
        super();
        this.justificativaLaudo = null;
        if (this.$props.isExibirProp) {
            this.campos.pop();
        }
    }

    baixarLaudo(laudoPessoa: LaudoPessoa) {
        pessoaService.downloadArquivo(laudoPessoa)
        .then(response => {
            if (response != null) {
                // @ts-ignore
                saveAs(response.data, laudoPessoa.arquivo);
            }
        })
    }

    async aprovarLaudo(laudoPessoa: LaudoPessoa) {
        this.salvarAvaliacaoLaudo(laudoPessoa, SituacaoLaudoEnum.ACEITO);
    }

    async recusarLaudo(laudoPessoa: LaudoPessoa) {
        this.salvarAvaliacaoLaudo(laudoPessoa, SituacaoLaudoEnum.RECUSADO);
    }

    async salvarAvaliacaoLaudo (laudoPessoa: LaudoPessoa, situacao: string) {
        const result = await this.$validator.validateAll();
        if (result) {
            laudoPessoa.origemAvaliacao = "PR_ACESSIVEL";
            if (this.justificativaLaudo != null) {
                laudoPessoa.justificativa = this.justificativaLaudo;
            }
            laudoPessoa.situacao = situacao;
            let laudoResultado = await pessoaService.avaliaLaudo(laudoPessoa);
            if (laudoResultado != null) {
                laudoPessoa.dataAvaliacao = laudoResultado.dataAvaliacao
                laudoPessoa.usuarioAvaliacao = laudoResultado.usuarioAvaliacao
            }
            this.$bvModal.hide('modalAvaliar');
        } else {
            this.$store.commit('setMessages', new Message('Preencha todos os campos obrigatórios', 'danger'));
            window.scrollTo(0, 0);
        }
    }
}
