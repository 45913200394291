var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "fieldset",
      { staticClass: "blocoFieldset" },
      [
        _c("legend", [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { role: "button", "aria-expanded": "false" },
              on: {
                click: function($event) {
                  _vm.showContent = !_vm.showContent
                }
              }
            },
            [
              _c("i", {
                staticClass: "far fa-caret-square-up",
                class: _vm.showContent ? "collapse.show" : "collapse"
              }),
              _c("i", {
                staticClass: "far fa-caret-square-down",
                class: _vm.showContent ? "collapse" : "collapse.show"
              })
            ]
          ),
          _vm._v("\n            Benefícios\n        ")
        ]),
        _c(
          "b-form-group",
          { staticClass: "text-label required", attrs: { label: "" } },
          [
            _c("multiselect", {
              attrs: {
                options: _vm.beneficios,
                multiple: true,
                "close-on-select": false,
                "clear-on-select": false,
                "hide-selected": false,
                "allow-empty": true,
                "preselect-first": false,
                "preserve-search": true,
                placeholder: "Selecione",
                "track-by": "id",
                label: "descricao",
                "deselect-label": "",
                "select-label": "",
                "selected-label": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "selection",
                  fn: function(ref) {
                    var values = ref.values
                    return [
                      values.length
                        ? _c("span", { staticClass: "multiselect__single" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(values.length) +
                                " selecionados\n                                "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.beneficiosSelecionados,
                callback: function($$v) {
                  _vm.beneficiosSelecionados = $$v
                },
                expression: "beneficiosSelecionados"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }