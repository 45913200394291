export class RecursoAcessibilidadeFilter {
    nome: String;
    descricao: String;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;

    constructor(nome: String, descricao: String, trazerTodos: boolean) {
        this.nome = nome;
        this.descricao = descricao;
        this.trazerTodos = trazerTodos;
        this.campoOrdenacao = "descricao";
        this.ascendente = true;
    }
}