import { TipoPessoaEnum } from "../enum/tipo-pessoa";


export class PessoaFilter {
    idadeMinima?: number;
    idadeMaxima?: number;
    tiposPessoa?: Array<TipoPessoaEnum>;
    situacaoCadastralLaudoComCID?: boolean;
    cpf?: String;
    nome?: String;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;
    pagina: Number;
    quantidadePorPagina: Number;

    constructor (idadeMinima?: number, idadeMaxima?: number, tiposPessoa?: Array<TipoPessoaEnum>, situacaoCadastralLaudoComCID?: boolean, cpf?: String, nome?: String) {
        this.idadeMinima = idadeMinima;
        this.idadeMaxima = idadeMaxima;
        this.tiposPessoa = tiposPessoa;
        this.situacaoCadastralLaudoComCID = situacaoCadastralLaudoComCID;
        this.cpf = cpf;
        this.nome = nome;
        this.trazerTodos = false;
        this.campoOrdenacao = "nome";
        this.ascendente = true;
        this.pagina = 1;
        this.quantidadePorPagina = 5;
    }
}