import { Message } from '../model/generic/message';
import store from '@/store/store';
import { PagingList } from '@/model/generic/paging-list';
import restUtil from './rest.util.service';
import { Assunto } from '@/model/assunto';

class AssuntoService {
    async listarAtivos (): Promise<PagingList<Assunto>|null> {
        try{
            let retorno = await restUtil.doGet<PagingList<Assunto>>('v1/assunto/');
            if (retorno != null && retorno.data != null) {
                if (retorno.data.totalRegistros == 0) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }
}

export default new AssuntoService();