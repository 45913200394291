import { PagingList } from '../model/generic/paging-list';
import store from '@/store/store';
import { Usuario } from './../model/usuario';
import { UsuarioFilter } from './../model/filter/usuario-filter';
import restUtil from './rest.util.service';
import { Message } from './../model/generic/message';

class UsuarioService {

  async obterPorCPF (cpf: String): Promise<Usuario|null> {
    try{
      let retorno = await restUtil.doGet<Usuario>('v1/usuario/' + cpf);
      if (retorno != null && retorno.data != null) {
        return retorno.data;
      }
    } catch (error) {
      store.commit('setMessages', (error as any).response.data);
    }
    return null;
  }

  async listarUsuarios (filter: UsuarioFilter): Promise<PagingList<Usuario>|null> {
    try{
      let retorno = await restUtil.doPost<PagingList<Usuario>>('v1/usuario/search', filter);
      if (retorno != null && retorno.data != null) {
        if (retorno.data.totalRegistros == 0) {
          store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
      }
        return retorno.data;
      }
    } catch (error) {
      store.commit('setMessages', (error as any).response.data);
    }
    return null;
  }

  async salvar (usuario: Usuario): Promise<Usuario|null> {
    let retorno;
    if (usuario.id == null) {
      retorno = await restUtil.doPost<Usuario>('v1/usuario', usuario);
    } else {
      usuario.origem = "PR_ACESSIVEL";
      retorno = await restUtil.doPut<Usuario>('v1/usuario/'+usuario.id, usuario);
    }
    return retorno;
  }

  async excluir (usuario: Usuario) {
    const retorno = await restUtil.doDelete('v1/usuario/'+usuario.id);
    if (retorno != null) {
      store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
    }
    return retorno;
  }

}

export default new UsuarioService();