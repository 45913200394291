<template>
    <div>
        <b-card-body class="cartaoConteudo">
            <b-row fluid class="justify-content-center mb-2">
                <div class="center-xy pd-button">
                    <b-button @click="evento()" class="btn-portal">
                        <i :class="icone + ' icon-button'"></i> <br>
                       <span v-html="textoBotao"></span>
                    </b-button>
                </div>
            </b-row>
        </b-card-body>
    </div>
</template>

<script>
export default {
    name: "CardButton",
    props: {
        textoBotao: String,
        icone: String
    },
    methods: {
        evento () {
            this.$emit('evento')
        }
    }
}
</script>
