export class TipoDeficiencia {

    id: Number|null;
    descricao: String;
    cid: String|null;
    pai?: TipoDeficiencia;
    subCategorias?: Array<TipoDeficiencia>;

    constructor(id: Number|null, descricao: String, cid: String|null, pai?: TipoDeficiencia, subCategorias?: Array<TipoDeficiencia>){
        this.id = id;
        this.descricao = descricao;
        this.cid = cid;
        this.pai = pai;
        this.subCategorias = subCategorias;
    }

}