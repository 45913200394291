var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "CPF", "label-for": "cpf" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###.###.###-##",
                            expression: "'###.###.###-##'"
                          }
                        ],
                        attrs: { id: "cpf" },
                        model: {
                          value: _vm.filtro.cpf,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "cpf", $$v)
                          },
                          expression: "filtro.cpf"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "8", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Nome", "label-for": "nome" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: { id: "nome" },
                        model: {
                          value: _vm.filtro.nome,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "nome", $$v)
                          },
                          expression: "filtro.nome"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "4", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        label: "Idade Mínima",
                        "label-for": "idadeMinima"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "idadeMinima",
                          type: "number",
                          min: "0",
                          max: _vm.filtro.idadeMaxima
                        },
                        model: {
                          value: _vm.filtro.idadeMinima,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "idadeMinima", $$v)
                          },
                          expression: "filtro.idadeMinima"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "4", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        label: "Idade Máxima",
                        "label-for": "idadeMaxima"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "idadeMaxima",
                          type: "number",
                          min: _vm.filtro.idadeMinima
                        },
                        model: {
                          value: _vm.filtro.idadeMaxima,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "idadeMaxima", $$v)
                          },
                          expression: "filtro.idadeMaxima"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "4", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Tipo de Pessoa" }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          id: "tiposPessoa",
                          options: _vm.tiposPessoa,
                          multiple: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "hide-selected": false,
                          "allow-empty": true,
                          "preselect-first": false,
                          "preserve-search": true,
                          placeholder: "Selecione",
                          "track-by": "id",
                          label: "name",
                          "deselect-label": "",
                          "select-label": "",
                          "selected-label": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(ref) {
                              var values = ref.values
                              return [
                                values.length
                                  ? _c(
                                      "span",
                                      { staticClass: "multiselect__single" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(values.length) +
                                            " selecionados\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.tiposPessoaSelecionados,
                          callback: function($$v) {
                            _vm.tiposPessoaSelecionados = $$v
                          },
                          expression: "tiposPessoaSelecionados"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "text-label" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            value: true,
                            "data-vv-as": "Situação Cadastral de Laudo com CID",
                            title: "Situação Cadastral de Laudo com CID",
                            "data-vv-name": "situacaoCadastralLaudoComCID",
                            state:
                              _vm.errors.has("situacaoCadastralLaudoComCID") ==
                              false
                                ? null
                                : !_vm.errors.has(
                                    "situacaoCadastralLaudoComCID"
                                  )
                          },
                          model: {
                            value: _vm.filtro.situacaoCadastralLaudoComCID,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.filtro,
                                "situacaoCadastralLaudoComCID",
                                $$v
                              )
                            },
                            expression: "filtro.situacaoCadastralLaudoComCID"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Situação Cadastral de Laudo com CID\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { align: "right" } },
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "6", md: "6", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Pesquisar Pessoa"
                      },
                      on: {
                        click: function($event) {
                          return _vm.pesquisar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-search" }),
                      _vm._v(" Pesquisar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Limpar busca"
                      },
                      on: {
                        click: function($event) {
                          return _vm.limpar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-eraser" }),
                      _vm._v(" Limpar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para tela inicial"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Voltar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("div"),
          _c(
            "b-table",
            {
              attrs: {
                "show-empty": "",
                stacked: "sm",
                hover: "hover",
                striped: "striped",
                items: _vm.pessoas,
                fields: _vm.campos,
                "current-page": 1,
                "per-page": _vm.filtro.quantidadePorPagina,
                busy: _vm.pesquisando,
                "sort-by": _vm.filtro.campoOrdenacao,
                "sort-direction": _vm.filtro.ascendente ? "asc" : "desc",
                "sort-desc": true,
                "caption-top": ""
              },
              on: { "sort-changed": _vm.reordenaPesquisa },
              scopedSlots: _vm._u([
                {
                  key: "table-caption",
                  fn: function() {
                    return [
                      _c("div", { staticStyle: { float: "left" } }, [
                        _vm._v("Tabela de Pessoas")
                      ]),
                      _c(
                        "div",
                        { staticStyle: { color: "red", float: "left" } },
                        [_vm._v("  (limitada para exibir até 1000 pessoas)")]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "cell(cpf)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("mascaraDocumento")(data.item.cpf)) +
                          "\n            "
                      )
                    ]
                  }
                },
                {
                  key: "cell(situacao)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getSituacao(data.item)) +
                          "\n            "
                      )
                    ]
                  }
                },
                {
                  key: "cell(acoes)",
                  fn: function(data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-left" },
                        [
                          data.item.laudos != null &&
                          data.item.laudos.length > 0
                            ? _c(
                                "b-link",
                                {
                                  staticClass: "btn btn-outline-info ml-1",
                                  attrs: { title: "Avaliar Laudo" },
                                  on: {
                                    click: function($event) {
                                      return _vm.iniciarAvaliarLaudo(data.item)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-file-medical" })]
                              )
                            : _vm._e(),
                          _vm._v(" \n                    "),
                          _c(
                            "b-link",
                            {
                              staticClass: "btn btn-outline-info ml-1",
                              attrs: { title: "Exibir Pessoa" },
                              on: {
                                click: function($event) {
                                  return _vm.iniciarExibir(data.item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-eye" })]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "empty", colspan: "2", align: "center" },
                  slot: "empty"
                },
                [_vm._v("Sem dados para exibir")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-center text-danger my-2",
                  attrs: { slot: "table-busy" },
                  slot: "table-busy"
                },
                [
                  _c("b-spinner", { staticClass: "align-middle" }),
                  _c("strong", [_vm._v(" Pesquisando ...")])
                ],
                1
              )
            ]
          ),
          _vm.pessoas.length > 0
            ? _c(
                "b-row",
                { staticClass: "my-1 center-xy" },
                [
                  _c("b-pagination", {
                    staticClass: "text-label",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.filtro.quantidadePorPagina
                    },
                    model: {
                      value: _vm.filtro.pagina,
                      callback: function($$v) {
                        _vm.$set(_vm.filtro, "pagina", $$v)
                      },
                      expression: "filtro.pagina"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "VuePagination__count text-center col-md-12"
                    },
                    [
                      _vm._v(
                        "\n                Mostrando " +
                          _vm._s(
                            _vm.filtro.pagina * _vm.filtro.quantidadePorPagina -
                              _vm.filtro.quantidadePorPagina +
                              1
                          ) +
                          " a\n                " +
                          _vm._s(
                            _vm.filtro.pagina * _vm.filtro.quantidadePorPagina >
                              _vm.totalRows
                              ? _vm.totalRows
                              : _vm.filtro.pagina *
                                  _vm.filtro.quantidadePorPagina
                          ) +
                          "\n                de " +
                          _vm._s(_vm.totalRows) +
                          " registros\n            "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "fas fa-user-friends" }),
      _vm._v(" Pesquisar Pessoas\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }