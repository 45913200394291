import { SituacaoSACEnum } from './enum/situacao-sac';
import { Usuario } from './usuario';
import { SACAnotacao } from './sac-anotacao';
import { Pessoa } from './pessoa';
import { Contato } from './contato';
import { Assunto } from './assunto';

export class SAC {
    id: Number | null;
    nome: String;
    assunto: Assunto | null;
    mensagem: String;
    dataHora?: Date;
    pessoa?: Pessoa;
    contatos?: Array<Contato>;
    anotacao?: String;
    anotacoes?: Array<SACAnotacao>;
    responsavel?: Usuario;
    situacao?: SituacaoSACEnum;

    constructor (id: Number | null, nome: String, assunto: Assunto | null, mensagem: String,
        dataHora?: Date, pessoa?: Pessoa, contatos?: Array<Contato>, anotacoes?: Array<SACAnotacao>) {
        this.id = id;
        this.nome = nome;
        this.assunto = assunto;
        this.mensagem = mensagem;
        this.dataHora = dataHora;
        this.pessoa = pessoa;
        this.contatos = contatos;
        this.anotacoes = anotacoes;
    }

    isNovo() : boolean {
        if (this.situacao == SituacaoSACEnum.NOVO) {
            return true;
        }

        return false;
    }
}