var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isRepresentado
        ? _c(
            "fieldset",
            { staticClass: "blocoFieldset" },
            [
              _c("legend", [_vm._v("Dados Representante")]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: {
                            label: "CPF",
                            "label-for": "cpfRepresentante"
                          }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'"
                              }
                            ],
                            attrs: { id: "cpfRepresentante", disabled: true },
                            model: {
                              value: _vm.cpfRepresentante,
                              callback: function($$v) {
                                _vm.cpfRepresentante = $$v
                              },
                              expression: "cpfRepresentante"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Grau Parentesco",
                            "label-for": "grauParentesco"
                          }
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                id: "grauParentesco",
                                plain: true,
                                required: true,
                                disabled:
                                  _vm.isExibir || !_vm.isIncluirDependente,
                                value: "Selecionar Grau de Parentesco",
                                "data-vv-name": "grauParentesco",
                                "data-vv-as": "Grau de Parentesco",
                                "error-messages": _vm.$validator.errors.collect(
                                  "grauParentesco"
                                ),
                                state:
                                  _vm.$validator.errors.has("grauParentesco") ==
                                  false
                                    ? null
                                    : !_vm.$validator.errors.has(
                                        "grauParentesco"
                                      )
                              },
                              model: {
                                value: _vm.pessoa.grauParentesco,
                                callback: function($$v) {
                                  _vm.$set(_vm.pessoa, "grauParentesco", $$v)
                                },
                                expression: "pessoa.grauParentesco"
                              }
                            },
                            [
                              _c("template", { slot: "first" }, [
                                _c("option", { domProps: { value: null } })
                              ]),
                              _vm._l(_vm.grausParentesco, function(m, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: m } },
                                  [
                                    _vm._v(
                                      _vm._s(m.descricao) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has(
                                    "grauParentesco"
                                  ),
                                  expression:
                                    "$validator.errors.has('grauParentesco')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$validator.errors.first(
                                      "grauParentesco"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", {
                    attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                  }),
                  _c(
                    "b-col",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      !_vm.isExibir &&
                      _vm.pessoa.representante != null &&
                      _vm.pessoa.representante.id != null &&
                      _vm.cpfUserLogado !=
                        _vm.cpfRepresentante
                          .replaceAll(".", "")
                          .replaceAll("-", "")
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.modalDesvincularRepresentante",
                                  modifiers: {
                                    modalDesvincularRepresentante: true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "primary",
                                size: "md",
                                title: "Desvincular do Representante"
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-ban" }),
                              _vm._v(" Desvincular\n                ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: {
                            label: "Nome",
                            "label-for": "nomeRepresentante"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "nomeRepresentante", disabled: true },
                            model: {
                              value: _vm.nomeRepresentante,
                              callback: function($$v) {
                                _vm.nomeRepresentante = $$v
                              },
                              expression: "nomeRepresentante"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "fieldset",
        { staticClass: "blocoFieldset" },
        [
          _c("legend", [_vm._v("Identificação")]),
          _c(
            "b-row",
            [
              (_vm.exibirDadosPessoa = true)
                ? _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "4", xl: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "CPF", "label-for": "cpf" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'"
                              }
                            ],
                            attrs: {
                              id: "cpf",
                              "data-vv-name": "cpf",
                              "error-messages": "CPF Inválido"
                            },
                            model: {
                              value: _vm.pessoa.cpf,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "cpf", $$v)
                              },
                              expression: "pessoa.cpf"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.validarCPF(_vm.pessoa.cpf),
                                  expression: "!validarCPF(pessoa.cpf)"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("CPF Inválido")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "", "label-for": "cpf" }
                        },
                        [
                          _vm._v("**\n                    "),
                          _c(
                            "label",
                            { ref: "cpf", attrs: { tabindex: "0" } },
                            [
                              _c("b", [_vm._v("CPF")]),
                              _vm._v(
                                ": " + _vm._s(_vm.formatCPF(_vm.pessoa.cpf))
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _c(
                "b-col",
                {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "4" }
                },
                [
                  _vm.novoCadastro
                    ? _c("span", { staticStyle: { color: "green" } }, [
                        _vm._v(
                          "\n                    Novo cadastro\n                    "
                        ),
                        _c("i", { staticClass: "fas fa-check" })
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "5", lg: "5", xl: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        label: "RNE (Registro Nacional de Estrangeiro)",
                        "label-for": "rne"
                      }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'"
                          }
                        ],
                        attrs: {
                          id: "rne",
                          "data-vv-name": "rne",
                          disabled: _vm.isExibir,
                          "error-messages": _vm.$validator.errors.collect(
                            "rne"
                          ),
                          state:
                            _vm.$validator.errors.has("rne") == false
                              ? null
                              : !_vm.$validator.errors.has("rne"),
                          trim: ""
                        },
                        model: {
                          value: _vm.pessoa.rne,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "rne", $$v)
                          },
                          expression: "pessoa.rne"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("rne"),
                              expression: "$validator.errors.has('rne')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v(_vm._s(_vm.$validator.errors.first("rne")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              (_vm.exibirDadosPessoa = true)
                ? _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "RG", "label-for": "rg" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##############",
                                expression: "'##############'"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              id: "rg",
                              trim: "",
                              trim: "",
                              "data-vv-name": "rg",
                              "error-messages": _vm.errors.collect("rg"),
                              state:
                                _vm.errors.has("rg") == false
                                  ? null
                                  : !_vm.errors.has("rg")
                            },
                            model: {
                              value: _vm.pessoa.rg,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "rg", $$v)
                              },
                              expression: "pessoa.rg"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("rg"),
                                  expression: "errors.has('rg')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errors.first("rg")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "", "label-for": "rg" }
                        },
                        [
                          _c("label", { ref: "rg", attrs: { tabindex: "0" } }, [
                            _c("b", [_vm._v("RG")]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.pessoa.rg
                                    ? _vm.pessoa.rg
                                    : "conteúdo não preenchido, edição não permitida"
                                )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
              (_vm.exibirDadosPessoa = true)
                ? _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "UF do RG", "label-for": "ufRg" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              id: "ufRg",
                              trim: "",
                              maxlength: "2",
                              trim: "",
                              "data-vv-name": "ufRg",
                              "error-messages": _vm.errors.collect("ufRg"),
                              state:
                                _vm.errors.has("ufRg") == false
                                  ? null
                                  : !_vm.errors.has("ufRg")
                            },
                            model: {
                              value: _vm.pessoa.ufRg,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "ufRg", $$v)
                              },
                              expression: "pessoa.ufRg"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("ufRg"),
                                  expression: "errors.has('ufRg')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errors.first("ufRg")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "", "label-for": "rg" }
                        },
                        [
                          _c("label", { ref: "rg", attrs: { tabindex: "0" } }, [
                            _c("b", [_vm._v("UF do RG")]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.pessoa.ufRg
                                    ? _vm.pessoa.ufRg
                                    : "conteúdo não preenchido, edição não permitida"
                                )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-row",
            [
              (_vm.exibirDadosPessoa = true)
                ? _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Nome", "label-for": "nome" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "nome",
                              trim: "",
                              "data-vv-name": "nome",
                              "error-messages": _vm.errors.collect("nome"),
                              state:
                                _vm.errors.has("nome") == false
                                  ? null
                                  : !_vm.errors.has("nome")
                            },
                            model: {
                              value: _vm.pessoa.nome,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "nome", $$v)
                              },
                              expression: "pessoa.nome"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("nome"),
                                  expression: "errors.has('nome')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errors.first("nome")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: { label: "", "label-for": "nome" }
                        },
                        [
                          _c("label", { ref: "rg", attrs: { tabindex: "0" } }, [
                            _c("b", [_vm._v("Nome")]),
                            _vm._v(": " + _vm._s(_vm.pessoa.nome))
                          ])
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Nome Social", "label-for": "nomeSocial" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: {
                          id: "nomeSocial",
                          disabled: _vm.isExibir,
                          "data-vv-name": "nomeSocial",
                          trim: "",
                          "error-messages": _vm.$validator.errors.collect(
                            "nomeSocial"
                          ),
                          state:
                            _vm.$validator.errors.has("nomeSocial") == false
                              ? null
                              : !_vm.$validator.errors.has("nomeSocial")
                        },
                        model: {
                          value: _vm.pessoa.nomeSocial,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "nomeSocial", $$v)
                          },
                          expression: "pessoa.nomeSocial"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("nomeSocial"),
                              expression: "$validator.errors.has('nomeSocial')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$validator.errors.first("nomeSocial")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "6", lg: "5", xl: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label required",
                      attrs: {
                        label: "Data de Nascimento",
                        "label-for": "dtNascimento"
                      }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          }
                        ],
                        attrs: {
                          id: "dtNascimento",
                          placeholder: "__/__/____",
                          "data-vv-name": "pessoa.dtNascimento",
                          "data-vv-as": "Data de Nascimento",
                          "error-messages": _vm.$validator.errors.collect(
                            "dtNascimento"
                          ),
                          state: _vm.validarDataNascimento()
                        },
                        on: {
                          blur: function($event) {
                            _vm.dataNascimentoBlur = true
                          }
                        },
                        model: {
                          value: _vm.dataNascimentoStr,
                          callback: function($$v) {
                            _vm.dataNascimentoStr = $$v
                          },
                          expression: "dataNascimentoStr"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("dtNascimento"),
                              expression:
                                "$validator.errors.has('dtNascimento')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "Data de Nascimento\n                        inválida."
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", {
                attrs: { xs: "12", sm: "12", md: "12", lg: "1", xl: "1" }
              }),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "6", lg: "5", xl: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Gênero", "label-for": "genero" }
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: {
                            id: "genero",
                            plain: true,
                            required: false,
                            value: "Selecionar Gênero",
                            disabled: _vm.isExibir,
                            "data-vv-name": "genero",
                            "data-vv-as": "Gênero",
                            "error-messages": _vm.$validator.errors.collect(
                              "genero"
                            ),
                            state:
                              _vm.$validator.errors.has("genero") == false
                                ? null
                                : !_vm.$validator.errors.has("genero")
                          },
                          model: {
                            value: _vm.pessoa.sexo,
                            callback: function($$v) {
                              _vm.$set(_vm.pessoa, "sexo", $$v)
                            },
                            expression: "pessoa.sexo"
                          }
                        },
                        [
                          _c("template", { slot: "first" }, [
                            _c("option", { domProps: { value: undefined } })
                          ]),
                          _vm._l(_vm.sexos, function(m, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: m.id[0] } },
                              [_vm._v(_vm._s(m.name))]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("genero"),
                              expression: "$validator.errors.has('genero')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v(_vm._s(_vm.$validator.errors.first("genero")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        { staticClass: "blocoFieldset" },
        [
          _c("legend", [_vm._v("Informações")]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "6", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        label: "Escolaridade",
                        "label-for": "escolaridade"
                      }
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: {
                            id: "escolaridade",
                            plain: true,
                            required: false,
                            value: "Selecionar Escolaridade",
                            "data-vv-name": "escolaridade",
                            "data-vv-as": "Escolaridade",
                            "error-messages": _vm.$validator.errors.collect(
                              "escolaridade"
                            ),
                            disabled: _vm.isExibir,
                            state:
                              _vm.$validator.errors.has("escolaridade") == false
                                ? null
                                : !_vm.$validator.errors.has("escolaridade")
                          },
                          model: {
                            value: _vm.pessoa.escolaridade,
                            callback: function($$v) {
                              _vm.$set(_vm.pessoa, "escolaridade", $$v)
                            },
                            expression: "pessoa.escolaridade"
                          }
                        },
                        [
                          _c("template", { slot: "first" }, [
                            _c("option", { domProps: { value: undefined } })
                          ]),
                          _vm._l(_vm.escolaridades, function(m, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: m.id[0] } },
                              [_vm._v(_vm._s(m.name))]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("escolaridade"),
                              expression:
                                "$validator.errors.has('escolaridade')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$validator.errors.first("escolaridade"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "6", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Renda Média", "label-for": "faixaRenda" }
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: {
                            id: "faixaRenda",
                            plain: true,
                            required: false,
                            value: "Selecionar Renda Média",
                            "data-vv-name": "faixaRenda",
                            "data-vv-as": "Renda Média",
                            "error-messages": _vm.$validator.errors.collect(
                              "faixaRenda"
                            ),
                            disabled: _vm.isExibir,
                            state:
                              _vm.$validator.errors.has("faixaRenda") == false
                                ? null
                                : !_vm.$validator.errors.has("faixaRenda")
                          },
                          model: {
                            value: _vm.pessoa.faixaRenda,
                            callback: function($$v) {
                              _vm.$set(_vm.pessoa, "faixaRenda", $$v)
                            },
                            expression: "pessoa.faixaRenda"
                          }
                        },
                        [
                          _c("template", { slot: "first" }, [
                            _c("option", { domProps: { value: undefined } })
                          ]),
                          _vm._l(_vm.faixasRenda, function(m, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: m.id[0] } },
                              [_vm._v(_vm._s(m.name))]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("faixaRenda"),
                              expression: "$validator.errors.has('faixaRenda')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$validator.errors.first("faixaRenda"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "8", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Filiação 1", "label-for": "filiacao1" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: {
                          id: "filiacao1",
                          disabled: _vm.isExibir,
                          "data-vv-name": "filiacao1",
                          trim: "",
                          "error-messages": _vm.$validator.errors.collect(
                            "filiacao1"
                          ),
                          state:
                            _vm.$validator.errors.has("filiacao1") == false
                              ? null
                              : !_vm.$validator.errors.has("filiacao1")
                        },
                        model: {
                          value: _vm.pessoa.filiacao1,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "filiacao1", $$v)
                          },
                          expression: "pessoa.filiacao1"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("filiacao1"),
                              expression: "$validator.errors.has('filiacao1')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$validator.errors.first("filiacao1")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "8", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Filiação 2", "label-for": "filiacao2" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: {
                          id: "filiacao2",
                          disabled: _vm.isExibir,
                          "data-vv-name": "filiacao2",
                          trim: "",
                          "error-messages": _vm.$validator.errors.collect(
                            "filiacao2"
                          ),
                          state:
                            _vm.$validator.errors.has("filiacao2") == false
                              ? null
                              : !_vm.$validator.errors.has("filiacao2")
                        },
                        model: {
                          value: _vm.pessoa.filiacao2,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "filiacao2", $$v)
                          },
                          expression: "pessoa.filiacao2"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("filiacao2"),
                              expression: "$validator.errors.has('filiacao2')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$validator.errors.first("filiacao2")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "8", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Filiação 3", "label-for": "filiacao3" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: {
                          id: "filiacao3",
                          disabled: _vm.isExibir,
                          "data-vv-name": "filiacao3",
                          trim: "",
                          "error-messages": _vm.$validator.errors.collect(
                            "filiacao3"
                          ),
                          state:
                            _vm.$validator.errors.has("filiacao3") == false
                              ? null
                              : !_vm.$validator.errors.has("filiacao3")
                        },
                        model: {
                          value: _vm.pessoa.filiacao3,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "filiacao3", $$v)
                          },
                          expression: "pessoa.filiacao3"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("filiacao3"),
                              expression: "$validator.errors.has('filiacao3')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$validator.errors.first("filiacao3")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "8", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: { label: "Filiação 4", "label-for": "filiacao4" }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        attrs: {
                          id: "filiacao4",
                          disabled: _vm.isExibir,
                          "data-vv-name": "filiacao4",
                          trim: "",
                          "error-messages": _vm.$validator.errors.collect(
                            "filiacao4"
                          ),
                          state:
                            _vm.$validator.errors.has("filiacao4") == false
                              ? null
                              : !_vm.$validator.errors.has("filiacao4")
                        },
                        model: {
                          value: _vm.pessoa.filiacao4,
                          callback: function($$v) {
                            _vm.$set(_vm.pessoa, "filiacao4", $$v)
                          },
                          expression: "pessoa.filiacao4"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has("filiacao4"),
                              expression: "$validator.errors.has('filiacao4')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$validator.errors.first("filiacao4")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalDesvincularRepresentante",
            title: "Confirmação de Desvinculação",
            "cancel-title": "Não",
            "ok-title": "Sim",
            modal: ""
          },
          on: {
            ok: function($event) {
              return _vm.desvincularRepresentante()
            }
          }
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v("Deseja realmente se desvincular de seu representante?")
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label required",
                      attrs: { label: "Justificativa:" }
                    },
                    [
                      _c("b-form-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'"
                          }
                        ],
                        attrs: {
                          id: "justificativaDesvincular",
                          size: "lg",
                          "data-vv-as": "Justificativa",
                          "data-vv-name": "justificativaDesvincular",
                          state:
                            _vm.$validator.errors.has(
                              "justificativaDesvincular"
                            ) == false
                              ? null
                              : !_vm.$validator.errors.has(
                                  "justificativaDesvincular"
                                )
                        },
                        model: {
                          value: _vm.justificativaDesvincular,
                          callback: function($$v) {
                            _vm.justificativaDesvincular = $$v
                          },
                          expression: "justificativaDesvincular"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has(
                                "justificativaDesvincular"
                              ),
                              expression:
                                "$validator.errors.has('justificativaDesvincular')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$validator.errors.first(
                                  "justificativaDesvincular"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }