

















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { PessoaFilter } from '@/model/filter/pessoa-filter';
import { Pessoa } from '@/model/pessoa';
import { Option } from '@/model/option';
import { TipoPessoaEnum } from '@/model/enum/tipo-pessoa';
import { Util } from '@/util/util';
import pessoaService from '@/api/pessoa-service';

const { entries } = Object;

@Component({
    mixins: [formatar]
})
export default class ListarPessoas extends Vue {
    public pessoas: Array<Pessoa> = Array.of();
    public pessoaSelecionada: Pessoa|null;
    public filtro: PessoaFilter;
    public tiposPessoa: Array<Option>;
    public tiposPessoaSelecionados: Array<Option>;

    public campos: Array<Object> = [{
        key: 'cpf',
        label: 'CPF',
        sortable: true
      },
      {
        key: 'nome',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'tipo',
        label: 'Tipo da Pessoa',
        sortable: true
      },
      {
        key: 'situacao', // mudar para algo do laudo
        label: 'Situação',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      }];
    public pesquisando: boolean = false;
    public totalRows: Number = 0;
    
    constructor(){
        super();
        this.filtro = new PessoaFilter();
        this.tiposPessoa = Util.carregaValoresEnum(entries(TipoPessoaEnum));
        this.tiposPessoaSelecionados = Array.of();
        this.pessoaSelecionada = null;
    }

    async pesquisar() {
        this.pesquisando = true;
        this.filtro.tiposPessoa = Array.of();
        this.tiposPessoaSelecionados.forEach( tp => {
            if (this.filtro != null && this.filtro.tiposPessoa != null){
                this.filtro.tiposPessoa.push(tp.id[0])
            }
        });
        const retorno = await pessoaService.listarPessoas(this.filtro)
        if (retorno != null) {
            this.pessoas = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
        this.pesquisando = false;
    }

    async iniciarAvaliarLaudo (pessoa: Pessoa) {
        this.direcionarPaginaLaudo(pessoa, true)
    }

    async iniciarExibir (pessoa: Pessoa) {
        this.direcionarPaginaLaudo(pessoa, false)
    }

    async direcionarPaginaLaudo(pessoa: Pessoa, modoAvaliacao: Boolean) {
        if (pessoa.id != null) {
            let pessoaCompleto = await pessoaService.obterCompleto(pessoa.id);
            if (pessoaCompleto != null) {
                this.$router.push({
                    name: 'avaliar-laudo-pessoa',
                    params: {
                        pessoaProp: pessoaCompleto as any,
                        isRepresentado: pessoaCompleto.representado as any,
                        isIncluirDependente: false as any,
                        isExibir: true as any,
                        isAvaliarLaudo: modoAvaliacao as any
                    }
                });
            }
        }
    }

    limpar () {
        this.filtro = new PessoaFilter();
        this.tiposPessoaSelecionados = Array.of();
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

    getSituacao(pessoa : Pessoa) : String {
        let situacao = "";
        if (pessoa.laudos != null) {
            let laudoSituacao = new Set(pessoa.laudos.flatMap(l => l.situacao));
            laudoSituacao.forEach(l => {
                if (situacao != "") {
                    situacao += ", ";
                }
                situacao += l
            })
        }
        
        return situacao.length == 0 ? "Sem Laudo" : situacao;
    }

    async reordenaPesquisa(ctx) {
        this.filtro.campoOrdenacao = ctx.sortBy;
        this.filtro.ascendente = !ctx.sortDesc;
        this.pesquisar();
    }

    @Watch("filtro.pagina")
    onPropertyChanged(value: string, oldValue: string) {
        this.filtro.pagina = Number(value);
        this.pesquisar()
    }

}
