import { PerfilEnum } from '../enum/perfil';

export class UsuarioFilter {
    origem: String;
    cpf: String;
    nome: String;
    perfil?: PerfilEnum;
    situacao: boolean;
    trazerTodos: boolean;

    constructor (cpf: String, nome: String, situacao: boolean, perfil?: PerfilEnum) {
        this.origem = "PR_ACESSIVEL";
        this.cpf = cpf;
        this.nome = nome;
        this.situacao = situacao;
        this.perfil = perfil;
        this.trazerTodos = false;
    }

}