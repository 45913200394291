


































































































import sacService from '@/api/sac.service';
import { SituacaoSACEnum } from '@/model/enum/situacao-sac';
import { Message } from '@/model/generic/message';
import { SAC } from '@/model/sac';
import { Usuario } from '@/model/usuario';
import { Component, Vue, Prop} from 'vue-property-decorator';
import ContatoComponent from '../component/contato-component.vue';
import HistoricoSacComponent from '../component/historico-sac-component.vue';

@Component({
    components: { ContatoComponent, HistoricoSacComponent }
})
export default class ResponderFaleComAGente extends Vue {

    @Prop() public sacProp!: SAC;
    @Prop() public responsavelProp!: Usuario;

    public sac!: SAC;

    constructor () {
        super();
        if (this.sacProp != null) {
            this.sac = this.sacProp;
        }
        if (this.responsavelProp != null) {
            this.sac.responsavel = this.responsavelProp;
        }
    }

    async finalizar () {
        const result = await this.$validator.validateAll();
        if (result) {
            this.sac.situacao = SituacaoSACEnum.FINALIZADO;
            let retorno = await sacService.salvar(this.sac);
            if (retorno != null) {
                this.$router.push({
                    name: 'fale-com-a-gente-listar'
                });
                this.$store.commit('setMessages', new Message('Atendimento finalizado com sucesso', 'success'));
            }
        }
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result) {
            this.sac.situacao = SituacaoSACEnum.EM_ANDAMENTO;
            let retorno = await sacService.salvar(this.sac);
            if (retorno != null) {
                this.$router.push({
                    name: 'fale-com-a-gente-listar'
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'fale-com-a-gente-listar'
        });
    }
}
