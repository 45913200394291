import { SituacaoEnum } from './../model/enum/situacao';
import { Documento } from './../model/documento';
import restUtil from './rest.util.service';
import { DocumentoFilter } from '@/model/filter/documento-filter';

class DocumentoService {

    async obterTermoAceite (): Promise<Documento|null> {
      let filter = new DocumentoFilter("TERMO_ACEITE", SituacaoEnum.ATIVO);
      let retorno = await restUtil.doPost<Documento>('v1/documento/search', filter);

      return retorno != null && retorno.data != null ? retorno.data : null;
    }

}

export default new DocumentoService();