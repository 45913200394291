import Vue from 'vue'
import Router, { RouteConfig, Route } from 'vue-router'
import store from '../store/store'
import Login from '../views/login/login.vue'
import Home from '../views/home/Home.vue'

import UsuarioAdministrativoContainer from '../views/usuario-administrativo/usuario-administrativo-container.vue'
import ListarUsuarioAdministrativo from '../views/usuario-administrativo/listar-usuario-administrativo.vue'
import EditarUsuarioAdministrativo from '../views/usuario-administrativo/editar-usuario-administrativo.vue'
import ExibirUsuarioAdministrativo from '../views/usuario-administrativo/exibir-usuario-administrativo.vue'

import RecursoAcessibilidadeContainer from '../views/recurso-acessibilidade/recurso-acessibilidade-container.vue'
import ListarRecursoAcessibilidade from '../views/recurso-acessibilidade/listar-recurso-acessibilidade.vue'
import EditarRecursoAcessibilidade from '../views/recurso-acessibilidade/editar-recurso-acessibilidade.vue'
import ExibirRecursoAcessibilidade from '../views/recurso-acessibilidade/exibir-recurso-acessibilidade.vue'

import BeneficioContainer from '../views/beneficio/beneficio-container.vue'
import ListarBeneficio from '../views/beneficio/listar-beneficio.vue'
import EditarBeneficio from '../views/beneficio/editar-beneficio.vue'
import ExibirBeneficio from '../views/beneficio/exibir-beneficio.vue'

import TipoDeficienciaContainer from '../views/tipo-deficiencia/tipo-deficiencia-container.vue'
import ListarTipoDeficiencia from '../views/tipo-deficiencia/listar-tipo-deficiencia.vue'
import IncluirTipoDeficiencia from '../views/tipo-deficiencia/incluir-tipo-deficiencia.vue'
import EditarTipoDeficiencia from '../views/tipo-deficiencia/editar-tipo-deficiencia.vue'
import ExibirTipoDeficiencia from '../views/tipo-deficiencia/exibir-tipo-deficiencia.vue'

import EstabelecimentoContainer from '../views/estabelecimento/estabelecimento-container.vue'
import ListarEstabelecimento from '../views/estabelecimento/listar-estabelecimento.vue'
import EditarEstabelecimento from '../views/estabelecimento/editar-estabelecimento.vue'
import ExibirEstabelecimento from '../views/estabelecimento/exibir-estabelecimento.vue'

import PessoaContainer from '../views/pessoa/pessoa-container.vue'
import EditarPessoa from '../views/pessoa/editar-pessoa.vue'
import EditarPessoaDeficienteVisual from '../views/pessoa-deficiente-visual/editar-pessoa-deficiente-visual.vue'
import ListarVinculados from '../views/pessoa/listar-vinculados.vue'
import ListarPessoas from '../views/pessoa/listar-pessoas.vue'
import AvaliarLaudoPessoa from '../views/pessoa/avaliar-laudo-pessoa.vue'

import AvaliarEstabelecimentoContainer from '../views/avaliar-estabelecimento/avaliar-estabelecimento-container.vue'
import ListarAvaliarEstabelecimento from '../views/avaliar-estabelecimento/listar-avaliar-estabelecimento.vue'
import AvaliarEstabelecimento from '../views/avaliar-estabelecimento/avaliar-estabelecimento.vue'

import FaleComAGenteContainer from '../views/fale-com-a-gente/fale-com-a-gente-container.vue'
import NovoFaleComAGente from '../views/fale-com-a-gente/novo-fale-com-a-gente.vue'
import ListarFaleComAGente from '../views/fale-com-a-gente/listar-fale-com-a-gente.vue'
import ResponderFaleComAGente from '../views/fale-com-a-gente/responder-fale-com-a-gente.vue'
import ExibirFaleComAGente from '../views/fale-com-a-gente/exibir-fale-com-a-gente.vue'

import ExibirLegislacao from '../views/legislacao/exibir-legislacoes.vue'
import ExibirLei_13146_2015 from '../views/legislacao/exibir-lei-13146-2015.vue'
import ExibirLei_18419_2015 from '../views/legislacao/exibir-lei-18419-2015.vue'

import centralSeguranca from '../api/centralseguranca'
import HistoricoNavegacaoService from '../api/historico-navegacao.service'

// Perfis
import { PerfilCentralSegurancaEnum } from '../model/enum/perfil-central-seguranca';

// error pages
import AccessDenied from '../layout/errors/Deny.vue'
import ErrorPage from '../layout/errors/Error.vue'
import NotFound from '../layout/errors/NotFound.vue'
import { Position } from 'vue-router/types/router'

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'inicial',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      breadcrumb: true,
      label: 'Página Inicial',
      requiresAuth: true
    }
  },

  {
    path: '/usuario-administrativo',
    name: 'usuario-administrativo',
    component: UsuarioAdministrativoContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-usuario-administrativo',
        component: ListarUsuarioAdministrativo,
        meta: {
          breadcrumb: true,
          label: 'Usuário Administrativo / Listar Usuário',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO]
        }
      },
      {
        path: 'editar',
        name: 'editar-usuario-administrativo',
        component: EditarUsuarioAdministrativo,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Usuário Administrativo / Alterar Usuário',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO]
        }
      },
      {
        path: 'exibir',
        name: 'exibir-usuario-administrativo',
        component: ExibirUsuarioAdministrativo,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Usuário Administrativo / Exibir Usuário',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO]
        }
      }
    ]
  },

  {
    path: '/recurso-acessibilidade',
    name: 'recurso-acessibilidade',
    component: RecursoAcessibilidadeContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-recurso-acessibilidade',
        component: ListarRecursoAcessibilidade,
        meta: {
          breadcrumb: true,
          label: 'Recurso Acessibilidade / Listar Recurso Acessibilidade',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL, PerfilCentralSegurancaEnum.REGIONAL]
        }
      },
      {
        path: 'editar',
        name: 'editar-recurso-acessibilidade',
        component: EditarRecursoAcessibilidade,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Recurso Acessibilidade / Alterar Recurso Acessibilidade',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      },
      {
        path: 'exibir',
        name: 'exibir-recurso-acessibilidade',
        component: ExibirRecursoAcessibilidade,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Recurso Acessibilidade / Exibir Recurso Acessibilidade',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      }
    ]
  },

  {
    path: '/beneficio',
    name: 'beneficio',
    component: BeneficioContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-beneficio',
        component: ListarBeneficio,
        meta: {
          breadcrumb: true,
          label: 'Benefício / Listar Benefício',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL, PerfilCentralSegurancaEnum.REGIONAL]
        }
      },
      {
        path: 'editar',
        name: 'editar-beneficio',
        component: EditarBeneficio,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Benefício / Alterar Benefício',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      },
      {
        path: 'exibir',
        name: 'exibir-beneficio',
        component: ExibirBeneficio,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Benefício / Exibir Benefício',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      }
    ]
  },

  {
    path: '/tipo-deficiencia',
    name: 'tipo-deficiencia',
    component: TipoDeficienciaContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-tipo-deficiencia',
        component: ListarTipoDeficiencia,
        meta: {
          breadcrumb: true,
          label: 'Tipo de Deficiência / Listar Tipo de Deficiência',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL, PerfilCentralSegurancaEnum.REGIONAL]
        }
      },
      {
        path: 'incluir',
        name: 'incluir-tipo-deficiencia',
        component: IncluirTipoDeficiencia,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Tipo de Deficiência',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      },
      {
        path: 'editar',
        name: 'editar-tipo-deficiencia',
        component: EditarTipoDeficiencia,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Tipo de Deficiência / Alterar Tipo de Deficiência',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      },
      {
        path: 'exibir',
        name: 'exibir-tipo-deficiencia',
        component: ExibirTipoDeficiencia,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Tipo de Deficiência / Exibir Tipo de Deficiência',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL]
        }
      }
    ]
  },

  {
    path: '/estabelecimento',
    name: 'estabelecimento',
    component: EstabelecimentoContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-estabelecimento',
        component: ListarEstabelecimento,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Estabelecimento / Listar Estabelecimento',
          requiresAuth: true
        }
      },
      {
        path: 'listar/meus',
        name: 'listar-meus-estabelecimentos',
        component: ListarEstabelecimento,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Meus Estabelecimentos',
          requiresAuth: true
        }
      },
      {
        path: 'editar',
        name: 'editar-estabelecimento',
        component: EditarEstabelecimento,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Estabelecimento / Alterar Estabelecimento',
          requiresAuth: true
        }
      },
      {
        path: 'exibir',
        name: 'exibir-estabelecimento',
        component: ExibirEstabelecimento,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Estabelecimento / Exibir Estabelecimento',
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: '/pessoa',
    name: 'pessoa',
    component: PessoaContainer,
    children: [
      {
        path: 'editar',
        name: 'editar-pessoa',
        component: EditarPessoa,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Pessoa / Editar Pessoa',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'editar-deficiente-visual',
        name: 'editar-pessoa-deficiente-visual',
        component: EditarPessoaDeficienteVisual,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Pessoa / Editar Pessoa Deficiente Visual',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'vinculados',
        name: 'listar-vinculados',
        component: ListarVinculados,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Pessoas Vinculadas',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'avaliar-laudo',
        name: 'avaliar-laudo-pessoa',
        component: AvaliarLaudoPessoa,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Avaliar Laudo da Pessoa',
          requiresAuth: true,
          prototipo: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL, PerfilCentralSegurancaEnum.REGIONAL]
        }
      },
      {
        path: 'listar',
        name: 'listar-pessoas',
        component: ListarPessoas,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Consultar Pessoas',
          requiresAuth: true,
          groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
            PerfilCentralSegurancaEnum.GERENCIAL],
          prototipo: true
        }
      }
    ]
  },

  {
    path: '/avaliar-estabelecimento',
    name: 'avaliar-estabelecimento',
    component: AvaliarEstabelecimentoContainer,
    children: [
      {
        path: 'listar',
        name: 'listar-avaliar-estabelecimento',
        component: ListarAvaliarEstabelecimento,
        meta: {
          breadcrumb: true,
          label: 'Avaliar Estabelecimento / Listar Avaliar Estabelecimento',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'avaliar',
        name: 'avaliar-estabelecimento',
        component: AvaliarEstabelecimento,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Avaliar Estabelecimento',
          requiresAuth: true,
          prototipo: true
        }
      }
    ]
  },

  {
    path: '/legislacoes',
    name: 'legislacoes',
    component: ExibirLegislacao,
    meta: {
      label: 'Legislações',
      requiresAuth: false
    }
  },
  {
    path: '/lei13146',
    name: 'Lei_13146_2015',
    component: ExibirLei_13146_2015,
    meta: {
      label: 'Lei 13146/2015',
      requiresAuth: false
    }
  },
  {
    path: '/lei18419',
    name: 'Lei_18419_2015',
    component: ExibirLei_18419_2015,
    meta: {
      label: 'Lei 18419/2015',
      requiresAuth: false
    }
  },

  {
    path: '/fale-com-a-gente',
    name: 'fale-com-a-gente',
    component: FaleComAGenteContainer,
    children: [
      {
        path: 'novo',
        name: 'fale-com-a-gente',
        component: NovoFaleComAGente,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Fale com a gente',
          requiresAuth: false,
          prototipo: true
        }
      },
      {
        path: 'listar',
        name: 'fale-com-a-gente-listar',
        component: ListarFaleComAGente,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Fale com a gente / Listar Fale com a gente',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'responder',
        name: 'fale-com-a-gente-responder',
        component: ResponderFaleComAGente,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Responder SAC',
          requiresAuth: true,
          prototipo: true
        }
      },
      {
        path: 'exibir',
        name: 'fale-com-a-gente-exibir',
        component: ExibirFaleComAGente,
        props: true,
        meta: {
          breadcrumb: true,
          label: 'Exibir SAC',
          requiresAuth: true,
          prototipo: true
        }
      }
    ]
  },

  // error pages
  {
    path: '/accessdenied',
    name: 'AccessDenied',
    component: AccessDenied,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/*',
    name: 'NotFound',
    components: {
      secundario: NotFound,
      default: NotFound
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/error',
    name: 'falhaComunicacao',
    component: ErrorPage,
    meta: {
      requiresAuth: false
    }
  },
]
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // @ts-ignore
  scrollBehavior: (to, from, savedPosition) => ({x: 0, y: 0 } as Position),
  routes: routes
})

router.beforeEach((to: Route, from: Route, next: Function) => {
  store.commit('setMessages', null)

  // protected access
  if (to.meta.requiresAuth) {
    (async () => {
      if (await store.dispatch('authFilter')) {
        if (store.getters.isAuthenticated === true) {
          if (to.path === '/' || to.path === '/home') {
            store.commit('setLayout', 'loginTmpl')
            next('/home')
          }

          // permission groups
          if (verificarPermissao(to, next)) {
            let name = to.name;
            if (name !== undefined && (to.meta.prototipo === undefined || !to.meta.prototipo)) {
              HistoricoNavegacaoService.registrar(name);
            }
          }

        } else {
          store.commit('limparJwtTokenState')
          store.commit('setLayout', 'blank')
          next('/accessdenied')
        }
      }
    })()
  } else {
    if (to.name === 'AccessDenied' || to.name === 'NotFound' || to.name === 'falhaComunicacao') {
      store.commit('setLayout', 'blank')
    } else if (to.name === 'login') {
      store.commit('setLayout', 'loginTmpl')
    } else {
      store.commit('setLayout', 'loginTmpl')
    }
  }

  // public access
  next()
})

function verificarPermissao(to: Route, next: Function) {
  let grupos: Array<String> = to.meta.groups;
  if (grupos && grupos.length > 0) {
    let userData = centralSeguranca.decodeJwtToken(store.getters.getJwtToken);

    if (userData.grupos) {
      for (let i = 0; i < userData.grupos.length; i++) {
        let grupo = userData.grupos[i];
        if (grupos.indexOf(grupo) !== -1) {
          store.commit('setLayout', 'loginTmpl');
          next();
          return true;
        }
      }
      store.commit('setLayout', 'blank');
      next('/accessdenied');
    }
    // Sem grupos para função que exige grupo
    else {
      store.commit('setLayout', 'blank');
      next('/accessdenied');
    }
  }
  return false;
}

export default router
