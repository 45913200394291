















































































































import tipoDeficienciaService from '@/api/tipo-deficiencia.service';
import { Component, Vue } from 'vue-property-decorator';
import { Message } from '../../model/generic/message';
import { TipoDeficiencia } from '../../model/tipo-deficiencia';

@Component({
    props: { tipoDefProp: Object }
})
export default class IncluirTipoDeficiencia extends Vue {

    public tipo: TipoDeficiencia;
    public subCategoria: TipoDeficiencia | null = null;
    public subAlterar: TipoDeficiencia | null = null;
    public tiposDeficienciaGerais: Array<TipoDeficiencia>;
        
    public subSelecionado;

    public campos: Array<Object> = [
      {
        key: 'descricao',
        label: 'Deficiência Específica',
        sortable: true
      },
      {
        key: 'cid',
        label: 'CID',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      }];

    constructor(){
        super();
        if (this.$props.tipoDefProp != null) {
            this.tipo = this.$props.tipoDefProp;
        } else {
            this.tipo = new TipoDeficiencia(null, "", null);
        }
        this.tiposDeficienciaGerais = Array.of();
        this.carregarTiposDeficienciaGerais();
    }

    async carregarTiposDeficienciaGerais () {
        let tiposDeficiencia = await tipoDeficienciaService.listarGerais();
        if (tiposDeficiencia != null) {
            this.tiposDeficienciaGerais = tiposDeficiencia.registros;
        }
    }

    exibirIncluirSubCategoria () {
        this.subCategoria = new TipoDeficiencia(null, "", null);
    }

    exibirAlterarSubCategoria (sub: TipoDeficiencia) {
        this.subAlterar = sub;
        this.subCategoria = {...sub};
    }

    incluirSubCategoria () {
        if (this.tipo.subCategorias == null) {
            this.tipo.subCategorias = Array<TipoDeficiencia>();
        }

        if (this.subCategoria != null){
            this.tipo.subCategorias.push(this.subCategoria);
        }
        this.subCategoria = null;
    }

    alterarSubCategoria () {
        if (this.subAlterar != null && this.subCategoria != null){
            this.subAlterar.descricao = this.subCategoria.descricao;
        }
        this.subCategoria = null;
        this.subAlterar = null;
    }

    excluir (tipoDef: TipoDeficiencia) {
        if (this.tipo.subCategorias != null) {
            const index = this.tipo.subCategorias.indexOf(tipoDef, 0);
            if (index > -1) {
                if (tipoDef.id != null) {
                    tipoDeficienciaService.excluir(tipoDef.id);
                }
                this.tipo.subCategorias.splice(index, 1);
            }
            if (this.tipo.subCategorias.length == 0) {
                this.tipo.subCategorias = undefined;
            }
        }

    }

    async salvar () {
        let result = await this.$validator.validateAll();
        if (this.tipo.subCategorias == null) {
            result = false;
            this.$store.commit('setMessages', new Message('É obrigatório inserir uma Deficiêcia Específica', 'danger'));
        }
        if (result) {
            let retorno = await tipoDeficienciaService.salvar(this.tipo);
            if (retorno != null) {
                this.$router.push({
                    name: 'listar-tipo-deficiencia'
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'listar-tipo-deficiencia'
        });
    }
}
