
















































































































import { Component, Vue } from 'vue-property-decorator';
import { Message } from '../../model/generic/message';
import { Beneficio } from '../../model/beneficio';
import { Option } from '@/model/option';
import beneficioService from '@/api/beneficio.service';
import { Municipio } from '@/model/municipio';
import enderecoService from '@/api/endereco.service';
import { PagingList } from '@/model/generic/paging-list';
import { TipoDeficiencia } from '@/model/tipo-deficiencia';
import tipoDeficienciaService from '@/api/tipo-deficiencia.service';
import { TipoDeficienciaFilter } from '@/model/filter/tipo-deficiencia-filter';

@Component({
    props: { beneficioProp: Object }
})
export default class EditarBeneficio extends Vue {
    
    public beneficio: Beneficio;
    public categoriaSelecionada: Option|null;
    public tiposDeficiencia: Array<TipoDeficiencia>;
    public tipos: Array<String>;
    public estados: PagingList<String>|null = null;
    public municipios: Array<Municipio> = Array.of();

    constructor(){
        super();
        this.tiposDeficiencia = Array.of();
        this.categoriaSelecionada = null
        if (this.$props.beneficioProp != null) {
            this.beneficio = this.$props.beneficioProp;
            if (this.beneficio.municipio != null) {
                this.carregaMunicipios();
                this.beneficio.estado = this.beneficio.municipio.uf
            }
        } else {
            this.beneficio = new Beneficio(null, "", null);
        }

        /** OS XX */
        this.tipos = Array.of("Municipal", "Estadual", "Federal");
        this.carregaEstados();
        this.listarTiposDeficiencia();
    }

    async listarTiposDeficiencia () {
        let tiposDeficiencia = await tipoDeficienciaService.listarGerais();
        if(tiposDeficiencia != null) {
            this.tiposDeficiencia = tiposDeficiencia.registros;
        }
    }

    async carregaEstados() {
        this.estados = await enderecoService.listarUFs();
        this.carregaMunicipios();
    }

    async carregaMunicipios() {
        if (this.beneficio.estado != undefined) {
            let retorno = await enderecoService.listarMunicipios(this.beneficio.estado);
            if (retorno != null && retorno.registros != null) {
                this.municipios = retorno.registros;
            }
        } else {
            this.municipios = Array.of();
        }
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result) {
            let retorno = await beneficioService.salvar(this.beneficio);
            if (retorno != null) {
                this.$router.push({
                    name: 'listar-beneficio'
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    limparMunicipio () {
        if (this.beneficio.tipoBeneficio != "Municipal") {
            if (this.beneficio.tipoBeneficio == '') {
                this.beneficio.tipoBeneficio = undefined;
            }
            this.beneficio.municipio = undefined;
        }
        if (this.beneficio.tipoBeneficio != "Federal") {
            if (this.beneficio.estado == undefined) {
                this.beneficio.estado = "PR";
                this.carregaMunicipios();
            }
        } else {
            this.beneficio.estado = undefined;
        }
    }

    voltar () {
        this.$router.push({
            name: 'listar-beneficio'
        });
    }
}
