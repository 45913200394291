






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Beneficio } from '@/model/beneficio';
import { BeneficioFilter } from '@/model/filter/beneficio-filter';
import beneficioService from '@/api/beneficio.service';
import { TipoDeficiencia } from '@/model/tipo-deficiencia';

@Component
export default class BeneficioComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public beneficiosProp!: Array<Beneficio>; // beneficios da pessoa
    @Prop() public tiposDeficiencia!: Array<TipoDeficiencia>;

    public showInclusao: Boolean = false;
    
    // lista de beneficios - filtrado pelo tipo de deficiencia
    public beneficios: Array<Beneficio> = Array.of();
    //beneficios da pessoa que foram selecionadas e/ou pre carregadas
    public beneficiosSelecionados: Array<Beneficio> = Array.of();
    
    public beneficioSelecionado: Beneficio | null = null;
    public beneficioExcluir: Beneficio | null = null;

    //campos da grid
    public campos: Array<Object> = [
        {
            key: 'descricao',
            label: 'Beneficio',
            sortable: false
        }];

    public currentPage: Number = 1;
    public perPage: Number = 50;
    public totalRows: Number = 0;

    constructor () {
        super();
        if (!this.isExibirProp) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }
        this.beneficiosSelecionados = this.beneficiosProp;
        this.carregaBeneficios();
    }
    
    @Watch('tiposDeficiencia')
    async carregarRescursosLogado() {
        this.carregaBeneficios();
    }

    public async carregaBeneficios() {
        this.beneficios = Array.of();

        let listaDesficienciasGerais: Number[] = [];

        this.tiposDeficiencia.forEach(td => {
            if (td.pai && td.pai.id) {
                listaDesficienciasGerais.push(td.pai.id);
            }
        });

        listaDesficienciasGerais = listaDesficienciasGerais.filter((valor, index, self) => {
            return self.indexOf(valor) === index;
        });

        let tiposDefGerais: Array<TipoDeficiencia> = [];
        listaDesficienciasGerais.forEach(dgId => {
            tiposDefGerais.push(new TipoDeficiencia(dgId, "", null));
        })

        let filter = new BeneficioFilter("", true, true, tiposDefGerais);

        // if (tiposDefGerais.length == 0) {
        //     if (this.$props.enderecoProp != null && this.enderecoProp.municipio != null && this.enderecoProp.municipio.id != null) {
        //         filter.municipio = this.enderecoProp.municipio
        //     }
        // }
        
        const retorno = await beneficioService.listarBeneficios(filter, false);
        if (retorno != null){
            this.beneficios = retorno.registros;

            this.beneficios = this.beneficios.filter(
                selec => this.beneficiosSelecionados.filter(ben => ben.id === selec.id).length == 0)
        }
    }

    iniciarIncluir () {
        this.showInclusao = true;
    }

    incluir () : void {
        if (this.beneficios == null) {
            this.beneficios = Array.of<Beneficio>();
        }

        if (this.beneficioSelecionado != null) {
            this.beneficiosSelecionados.push(this.beneficioSelecionado);

            this.$emit('atualizarBeneficio', this.beneficiosSelecionados);
            this.showInclusao = false;
            
            const index = this.beneficios.indexOf(this.beneficioSelecionado, 0);
            if (index > -1) {
                this.beneficios.splice(index, 1);
            }
            
            this.beneficioSelecionado = null;

            if (this.$refs.tabBeneficios instanceof HTMLElement) {
                this.$refs.tabBeneficios.focus();
            }
        }
    }

    async excluir (ace: Beneficio) {
        if (this.beneficiosSelecionados != null) {
            const index = this.beneficiosSelecionados.indexOf(ace, 0);

            if (index > -1) {
                this.beneficios.push(ace);

                this.beneficios.sort((a, b) => {
                    const nomeA = a.descricao.toUpperCase();
                    const nomeB = b.descricao.toUpperCase();

                    if (nomeA < nomeB) {
                        return -1;
                    }
                    if (nomeA > nomeB) {
                        return 1;
                    }
                    return 0;
                });

                this.beneficiosSelecionados.splice(index, 1);
            }
            await this.$emit('atualizarBeneficio', this.beneficiosSelecionados);

            if (this.$refs.tabBeneficios instanceof HTMLElement) {
                this.$refs.tabBeneficios.focus();
            }
        }
    }
}
