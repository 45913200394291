






import { Component, Vue } from 'vue-property-decorator';
import ListarBeneficio from './listar-beneficio.vue';
import EditarBeneficio from './editar-beneficio.vue';
import ExibirBeneficio from './exibir-beneficio.vue';

@Component({
    components: {
        ListarBeneficio,
        EditarBeneficio,
        ExibirBeneficio
    }
})
export default class BeneficioContainer extends Vue {
}
