






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import { RecursoAcessibilidadeFilter } from '@/model/filter/recurso-acessibilidade-filter';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';

@Component
export default class RecursoAcessibilidadeComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public tiposRecursoProp!: Array<RecursoAcessibilidade>;

    public showInclusao: Boolean = false;
    
    public tiposRecursosAcessibilidade: Array<RecursoAcessibilidade> = Array.of();
    public tipoRecursoSelecionado: RecursoAcessibilidade | null = null;

    public recursoAcessibilidadeExcluir: RecursoAcessibilidade | null = null;

    public tiposRecurso: Array<RecursoAcessibilidade> = Array.of();

    //campos da grid
    public campos: Array<Object> = [
        {
            key: 'nome',
            label: 'Recursos de Acessibilidade',
            sortable: false
        }];

    public currentPage: Number = 1;
    public perPage: Number = 50;
    public totalRows: Number = 0;

    constructor () {
        super();
        if (!this.isExibirProp) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }
        
        this.tiposRecursosAcessibilidade = Array.of();

        this.carregarTiposRecurso ();
        this.tiposRecurso = this.tiposRecursoProp ? this.tiposRecursoProp : Array.of();
    }

    iniciarIncluir () {
        this.showInclusao = true;
    }

    async carregarTiposRecurso () {
        const retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(new RecursoAcessibilidadeFilter("", "", true), false);
        if (retorno != null){
            this.tiposRecursosAcessibilidade = retorno.registros; 
        }
    }

    incluir () : void {
        if (this.tiposRecurso == null) {
            this.tiposRecurso = Array.of<RecursoAcessibilidade>();
        }

        if (this.tipoRecursoSelecionado != null) {
            this.tiposRecurso.push(this.tipoRecursoSelecionado);
            this.$emit('atualizarRecursoAcessibilidade', this.tiposRecurso);
            this.showInclusao = false;
            
            const index = this.tiposRecursosAcessibilidade.indexOf(this.tipoRecursoSelecionado, 0);
            if (index > -1) {
                this.tiposRecursosAcessibilidade.splice(index, 1);
            }
            
            this.tipoRecursoSelecionado = null;

            if (this.$refs.tabRecursos instanceof HTMLElement) {
                this.$refs.tabRecursos.focus();
            }
        }
    }

    async excluir (ace: RecursoAcessibilidade) {
        if (this.tiposRecurso != null) {
            const index = this.tiposRecurso.indexOf(ace, 0);
            if (index > -1) {
                this.tiposRecursosAcessibilidade.push(ace);

                this.tiposRecursosAcessibilidade.sort((a, b) => {
                    const nomeA = a.nome.toUpperCase();
                    const nomeB = b.nome.toUpperCase();

                    if (nomeA < nomeB) {
                        return -1;
                    }
                    if (nomeA > nomeB) {
                        return 1;
                    }
                    return 0;
                });

                this.tiposRecurso.splice(index, 1);
            }
            await this.$emit('atualizarRecursoAcessibilidade', this.tiposRecurso);

            if (this.$refs.tabRecursos instanceof HTMLElement) {
                this.$refs.tabRecursos.focus();
            }
        }
    }
}
