var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-wrapper" }, [
    _c("nav", { attrs: { id: "sidebar" } }, [
      _c(
        "div",
        { staticClass: "sidebar-content" },
        [
          _c("SidebarSearch", { attrs: { maxlength: 30 } }),
          _c("div", { staticClass: "sidebar-menu mt-2" }, [
            _c(
              "div",
              { attrs: { role: "tablist" } },
              _vm._l(_vm.itens, function(item) {
                return _c("Item", { key: item.name, attrs: { menu: item } })
              }),
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }