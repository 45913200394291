import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router'
import store from './store/store'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR'
import VueI18n from 'vue-i18n'
import VueMask from 'v-mask'
import PortalVue from 'portal-vue'
import BackToTop from 'vue-backtotop'
import Multiselect from 'vue-multiselect'
import Timeselector from 'vue-timeselector';
import vSelect from 'vue-select'
import FileUpload from 'vue-upload-component'

Vue.use(VueMask)
Vue.use(PortalVue)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'ptBR'
})

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  i18n,
  dictionary: {
    ptBR
  }
})

Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(require('vue-shortkey'))

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.component('multiselect', Multiselect)
Vue.component('timeselector', Timeselector)
Vue.component('v-select', vSelect)
Vue.component('file-upload', FileUpload)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
