































import { SACAnotacao } from '@/model/sac-anotacao';
import { Component, Prop, Vue } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins';

@Component({
    mixins: [formatar]
})
export default class HistoricoSACComponent extends Vue {

    @Prop() public anotacoesProp!: Array<SACAnotacao>;
    public anotacoes: Array<SACAnotacao>;

    //campos da grid
    public campos: Array<Object> = [
      {
        key: 'anotacao',
        label: 'Anotação',
        sortable: false
      },
      {
        key: 'dataHora',
        label: 'Data/Hora',
        sortable: true
      }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public totalRows: Number = 0;

    constructor () {
        super();
        this.totalRows = this.anotacoesProp.length;
        this.anotacoes = this.anotacoesProp;
    }

}

