var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c("h2", { staticClass: "app-title" }, [
        _c("i", { staticClass: "fas fa-clipboard-list" }),
        _vm._v(
          " " +
            _vm._s(
              _vm.beneficio.id == null || _vm.beneficio.id == 0
                ? "Incluir"
                : "Alterar"
            ) +
            " Benefício\n    "
        )
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.salvar()
                }
              }
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "6", xl: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Descrição",
                            "label-for": "descricao"
                          }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "descricao",
                              trim: "",
                              "data-vv-name": "descricao",
                              "error-messages": _vm.errors.collect("descricao"),
                              state:
                                _vm.errors.has("descricao") == false
                                  ? null
                                  : !_vm.errors.has("descricao")
                            },
                            model: {
                              value: _vm.beneficio.descricao,
                              callback: function($$v) {
                                _vm.$set(_vm.beneficio, "descricao", $$v)
                              },
                              expression: "beneficio.descricao"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("descricao"),
                                  expression: "errors.has('descricao')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.first("descricao")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "5", lg: "4", xl: "4" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: {
                            label: "Tipo de Benefício",
                            "label-for": "tipoBeneficio"
                          }
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                id: "tipo",
                                plain: true,
                                value: "Selecionar Tipo de Benefício",
                                "data-vv-name": "tipo",
                                "data-vv-as": "Tipo Benefício",
                                "error-messages": _vm.errors.collect("tipo"),
                                state:
                                  _vm.errors.has("tipo") == false
                                    ? null
                                    : !_vm.errors.has("tipo")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.limparMunicipio()
                                }
                              },
                              model: {
                                value: _vm.beneficio.tipoBeneficio,
                                callback: function($$v) {
                                  _vm.$set(_vm.beneficio, "tipoBeneficio", $$v)
                                },
                                expression: "beneficio.tipoBeneficio"
                              }
                            },
                            _vm._l(_vm.tipos, function(m, i) {
                              return _c(
                                "option",
                                { key: i, domProps: { value: m } },
                                [_vm._v(_vm._s(m))]
                              )
                            }),
                            0
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("tipo"),
                                  expression: "errors.has('tipo')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.first("tipo")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.estados !== null
                    ? _c(
                        "b-col",
                        {
                          attrs: { xs: "6", sm: "6", md: "1", lg: "2", xl: "2" }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: { label: "Estado", "label-for": "estado" }
                            },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  id: "estado",
                                  options: _vm.estados,
                                  disabled:
                                    _vm.beneficio.tipoBeneficio != "Estadual" &&
                                    _vm.beneficio.tipoBeneficio != "Municipal",
                                  "data-vv-name": "estado",
                                  "data-vv-as": "Estado",
                                  "error-messages": _vm.errors.collect(
                                    "Estado"
                                  ),
                                  state:
                                    _vm.errors.has("estado") == false
                                      ? null
                                      : !_vm.errors.has("estado")
                                },
                                on: { input: _vm.carregaMunicipios },
                                model: {
                                  value: _vm.beneficio.estado,
                                  callback: function($$v) {
                                    _vm.$set(_vm.beneficio, "estado", $$v)
                                  },
                                  expression: "beneficio.estado"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("estado"),
                                      expression: "errors.has('estado')"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.errors.first("estado")) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { xs: "6", sm: "6", md: "6", lg: "6", xl: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: {
                            label: "Município",
                            "label-for": "municipio"
                          }
                        },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              id: "municipio",
                              options: _vm.municipios,
                              disabled:
                                _vm.beneficio.tipoBeneficio != "Municipal",
                              label: "nome",
                              "data-vv-name": "municipio",
                              "data-vv-as": "Município",
                              "error-messages": _vm.errors.collect("municipio"),
                              state:
                                _vm.errors.has("municipio") == false
                                  ? null
                                  : !_vm.errors.has("municipio")
                            },
                            model: {
                              value: _vm.beneficio.municipio,
                              callback: function($$v) {
                                _vm.$set(_vm.beneficio, "municipio", $$v)
                              },
                              expression: "beneficio.municipio"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("municipio"),
                                  expression: "errors.has('municipio')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.first("municipio")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label",
                          attrs: {
                            label: "Tipo de Deficiência",
                            "label-for": "tipoDeficiencia"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.tiposDeficiencia,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": false,
                              "allow-empty": true,
                              "preselect-first": false,
                              "preserve-search": true,
                              placeholder: "Selecione",
                              "track-by": "id",
                              label: "descricao",
                              "deselect-label": "",
                              "select-label": "",
                              "selected-label": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var values = ref.values
                                  return [
                                    values.length
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "multiselect__single"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(values.length) +
                                                " selecionados\n                                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.beneficio.tiposDeficiencia,
                              callback: function($$v) {
                                _vm.$set(_vm.beneficio, "tiposDeficiencia", $$v)
                              },
                              expression: "beneficio.tiposDeficiencia"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("tipoDeficiencia"),
                              expression: "errors.has('tipoDeficiencia')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.errors.first("tipoDeficiencia")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-row"),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        size: "md",
                        title: "Salvar Benefício"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Salvar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para tela de pesquisa de Benefício"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Cancelar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }