import { SituacaoEnum } from './../enum/situacao';
export class DocumentoFilter {

    tipo: String;
    situacao: SituacaoEnum;

    constructor(tipo: String, situacao: SituacaoEnum) {
        this.tipo = tipo;
        this.situacao = situacao;
    }
}