<template>
	<div class="fluid">
		<br />
		<h2 class="app-title">
			<b-form-group class="text-label">
				<label ref="cpf" tabindex="0">
					<legend>Lei Brasileira de Inclusão (Lei Federal nº 13146/2015)</legend>
				</label>
			</b-form-group>
		</h2>
		<b-card>
			<b-row>
                <b-col>
				<b-form-group class="text-label"><label tabindex="0">A PRESIDENTA DA REPÚBLICA Faço saber que o Congresso Nacional decreta e eu sanciono a seguinte Lei:</label></b-form-group>

                <b-form-group class="text-label"><label tabindex="0">LIVRO I</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">PARTE GERAL</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">TÍTULO I</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">DISPOSIÇÕES PRELIMINARES</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">CAPÍTULO I</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">ISPOSIÇÕES GERAIS</label></b-form-group>

                <b-form-group class="text-label"><label tabindex="0">Art. 1º	É instituída a Lei Brasileira de Inclusão da Pessoa com Deficiência
				(Estatuto da Pessoa com Deficiência), destinada a assegurar e a
				promover, em condições de igualdade, o exercício dos direitos e das
				liberdades fundamentais por pessoa com deficiência, visando à sua
				inclusão social e cidadania.</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Parágrafo único. Esta Lei tem como base a
				Convenção sobre os Direitos das Pessoas com Deficiência e seu Protocolo
				Facultativo, ratificados pelo Congresso Nacional por meio do Decreto
				Legislativo nº 186, de 9 de julho de 2008 , em conformidade com o
				procedimento previsto no § 3º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 5º da Constituição da República
				Federativa do Brasil , em vigor para o Brasil, no plano jurídico
				externo, desde 31 de agosto de 2008, e promulgados pelo Decreto nº
				6.949, de 25 de agosto de 2009 , data de início de sua vigência no plano
				interno. </label></b-form-group>
                
                <b-form-group class="text-label"><label tabindex="0">Art. 2º Considera-se pessoa com deficiência aquela que tem
				impedimento de longo prazo de natureza física, mental, intelectual ou
				sensorial, o qual, em interação com uma ou mais barreiras, pode obstruir
				sua participação plena e efetiva na sociedade em igualdade de condições
				com as demais pessoas.</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">§ 1º A avaliação da deficiência, quando
				necessária, será biopsicossocial, realizada por equipe multiprofissional
				e interdisciplinar e considerará: (Vigência) (Vide Decreto nº 11.063, de
				2022)</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">I - os impedimentos nas funções e nas estruturas do corpo;</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">II - os fatores socioambientais, psicológicos e pessoais;</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">III - a limitação no
				desempenho de atividades; e</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">IV - a restrição de participação.</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">§ 2º O
				Poder Executivo criará instrumentos para avaliação da deficiência. (Vide
				Lei nº 13.846, de 2019) (Vide Lei nº 14.126, de 2021)</label></b-form-group>

                

                <b-form-group class="text-label"><label tabindex="0">Art. 2º-A. É
				instituído o cordão de fita com desenhos de girassóis como símbolo
				nacional de identificação de pessoas com deficiências ocultas. (Incluído
				pela Lei nº 14.624, de 2023) § 1º O uso do símbolo de que trata o caput
				deste artigo é opcional, e sua ausência não prejudica o exercício de
				direitos e garantias previstos em lei. (Incluído pela Lei nº 14.624, de
				2023) § 2º A utilização do símbolo de que trata o caput deste artigo não
				dispensa a apresentação de documento comprobatório da deficiência, caso
				seja solicitado pelo atendente ou pela autoridade competente. (Incluído
				pela Lei nº 14.624, de 2023) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º Para fins de aplicação desta Lei,
				consideram-se:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - acessibilidade: possibilidade e condição de alcance
				para utilização, com segurança e autonomia, de espaços, mobiliários,
				equipamentos urbanos, edificações, transportes, informação e
				comunicação, inclusive seus sistemas e tecnologias, bem como de outros
				serviços e instalações abertos ao público, de uso público ou privados de
				uso coletivo, tanto na zona urbana como na rural, por pessoa com
				deficiência ou com mobilidade reduzida;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - desenho universal:
				concepção de produtos, ambientes, programas e serviços a serem usados
				por todas as pessoas, sem necessidade de adaptação ou de projeto
				específico, incluindo os recursos de tecnologia assistiva; III -
				tecnologia assistiva ou ajuda técnica: produtos, equipamentos,
				dispositivos, recursos, metodologias, estratégias, práticas e serviços
				que objetivem promover a funcionalidade, relacionada à atividade e à
				participação da pessoa com deficiência ou com mobilidade reduzida,
				visando à sua autonomia, independência, qualidade de vida e inclusão
				social;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - barreiras: qualquer entrave, obstáculo, atitude ou
				comportamento que limite ou impeça a participação social da pessoa, bem
				como o gozo, a fruição e o exercício de seus direitos à acessibilidade,
				à liberdade de movimento e de expressão, à comunicação, ao acesso à
				informação, à compreensão, à circulação com segurança, entre outros,
				classificadas em: a) barreiras urbanísticas: as existentes nas vias e
				nos espaços públicos e privados abertos ao público ou de uso coletivo;
				b) barreiras arquitetônicas: as existentes nos edifícios públicos e
				privados; c) barreiras nos transportes: as existentes nos sistemas e
				meios de transportes; d) barreiras nas comunicações e na informação:
				qualquer entrave, obstáculo, atitude ou comportamento que dificulte ou
				impossibilite a expressão ou o recebimento de mensagens e de informações
				por intermédio de sistemas de comunicação e de tecnologia da informação;
				e) barreiras atitudinais: atitudes ou comportamentos que impeçam ou
				prejudiquem a participação social da pessoa com deficiência em igualdade
				de condições e oportunidades com as demais pessoas; f) barreiras
				tecnológicas: as que dificultam ou impedem o acesso da pessoa com
				deficiência às tecnologias;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - comunicação: forma de interação dos
				cidadãos que abrange, entre outras opções, as línguas, inclusive a
				Língua Brasileira de Sinais (Libras), a visualização de textos, o
				Braille, o sistema de sinalização ou de comunicação tátil, os caracteres
				ampliados, os dispositivos multimídia, assim como a linguagem simples,
				escrita e oral, os sistemas auditivos e os meios de voz digitalizados e
				os modos, meios e formatos aumentativos e alternativos de comunicação,
				incluindo as tecnologias da informação e das comunicações; VI -
				adaptações razoáveis: adaptações, modificações e ajustes necessários e
				adequados que não acarretem ônus desproporcional e indevido, quando
				requeridos em cada caso, a fim de assegurar que a pessoa com deficiência
				possa gozar ou exercer, em igualdade de condições e oportunidades com as
				demais pessoas, todos os direitos e liberdades fundamentais; VII -
				elemento de urbanização: quaisquer componentes de obras de urbanização,
				tais como os referentes a pavimentação, saneamento, encanamento para
				esgotos, distribuição de energia elétrica e de gás, iluminação pública,
				serviços de comunicação, abastecimento e distribuição de água,
				paisagismo e os que materializam as indicações do planejamento
				urbanístico;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VIII - mobiliário urbano: conjunto de objetos existentes
				nas vias e nos espaços públicos, superpostos ou adicionados aos
				elementos de urbanização ou de edificação, de forma que sua modificação
				ou seu traslado não provoque alterações substanciais nesses elementos,
				tais como semáforos, postes de sinalização e similares, terminais e
				pontos de acesso coletivo às telecomunicações, fontes de água, lixeiras,
				toldos, marquises, bancos, quiosques e quaisquer outros de natureza
				análoga;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IX - pessoa com mobilidade reduzida: aquela que tenha, por
				qualquer motivo, dificuldade de movimentação, permanente ou temporária,
				gerando redução efetiva da mobilidade, da flexibilidade, da coordenação
				motora ou da percepção, incluindo idoso, gestante, lactante, pessoa com
				criança de colo e obeso;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> X - residências inclusivas: unidades de oferta
				do Serviço de Acolhimento do Sistema Único de Assistência Social (Suas)
				localizadas em áreas residenciais da comunidade, com estruturas
				adequadas, que possam contar com apoio psicossocial para o atendimento
				das necessidades da pessoa acolhida, destinadas a jovens e adultos com
				deficiência, em situação de dependência, que não dispõem de condições de
				autossustentabilidade e com vínculos familiares fragilizados ou
				rompidos;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XI - moradia para a vida independente da pessoa com
				deficiência: moradia com estruturas adequadas capazes de proporcionar
				serviços de apoio coletivos e individualizados que respeitem e ampliem o
				grau de autonomia de jovens e adultos com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XII - atendente
				pessoal: pessoa, membro ou não da família, que, com ou sem remuneração,
				assiste ou presta cuidados básicos e essenciais à pessoa com deficiência
				no exercício de suas atividades diárias, excluídas as técnicas ou os
				procedimentos identificados com profissões legalmente estabelecidas;
				XIII - profissional de apoio escolar: pessoa que exerce atividades de
				alimentação, higiene e locomoção do estudante com deficiência e atua em
				todas as atividades escolares nas quais se fizer necessária, em todos os
				níveis e modalidades de ensino, em instituições públicas e privadas,
				excluídas as técnicas ou os procedimentos identificados com profissões
				legalmente estabelecidas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XIV - acompanhante: aquele que acompanha a
				pessoa com deficiência, podendo ou não desempenhar as funções de
				atendente pessoal. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">CAPÍTULO II</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">DA IGUALDADE E DA NÃO DISCRIMINAÇÃO</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				4º Toda pessoa com deficiência tem direito à igualdade de oportunidades
				com as demais pessoas e não sofrerá nenhuma espécie de discriminação. §
				1º Considera-se discriminação em razão da deficiência toda forma de
				distinção, restrição ou exclusão, por ação ou omissão, que tenha o
				propósito ou o efeito de prejudicar, impedir ou anular o reconhecimento
				ou o exercício dos direitos e das liberdades fundamentais de pessoa com
				deficiência, incluindo a recusa de adaptações razoáveis e de
				fornecimento de tecnologias assistivas. § 2º A pessoa com deficiência
				não está obrigada à fruição de benefícios decorrentes de ação
				afirmativa. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 5º A pessoa com deficiência será protegida de toda
				forma de negligência, discriminação, exploração, violência, tortura,
				crueldade, opressão e tratamento desumano ou degradante. Parágrafo
				único. Para os fins da proteção mencionada no caput deste artigo, são
				considerados especialmente vulneráveis a criança, o adolescente, a
				mulher e o idoso, com deficiência. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 6º A deficiência não afeta a
				plena capacidade civil da pessoa, inclusive para:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - casar-se e
				constituir união estável;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - exercer direitos sexuais e reprodutivos;
				III - exercer o direito de decidir sobre o número de filhos e de ter
				acesso a informações adequadas sobre reprodução e planejamento familiar;
				IV - conservar sua fertilidade, sendo vedada a esterilização
				compulsória;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - exercer o direito à família e à convivência familiar e
				comunitária; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - exercer o direito à guarda, à tutela, à curatela e à
				adoção, como adotante ou adotando, em igualdade de oportunidades com as
				demais pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 7º É dever de todos comunicar à autoridade
				competente qualquer forma de ameaça ou de violação aos direitos da
				pessoa com deficiência. Parágrafo único. Se, no exercício de suas
				funções, os juízes e os tribunais tiverem conhecimento de fatos que
				caracterizem as violações previstas nesta Lei, devem remeter peças ao
				Ministério Público para as providências cabíveis. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 8º É dever do
				Estado, da sociedade e da família assegurar à pessoa com deficiência,
				com prioridade, a efetivação dos direitos referentes à vida, à saúde, à
				sexualidade, à paternidade e à maternidade, à alimentação, à habitação,
				à educação, à profissionalização, ao trabalho, à previdência social, à
				habilitação e à reabilitação, ao transporte, à acessibilidade, à
				cultura, ao desporto, ao turismo, ao lazer, à informação, à comunicação,
				aos avanços científicos e tecnológicos, à dignidade, ao respeito, à
				liberdade, à convivência familiar e comunitária, entre outros
				decorrentes da Constituição Federal, da Convenção sobre os Direitos das
				Pessoas com Deficiência e seu Protocolo Facultativo e das leis e de
				outras normas que garantam seu bem-estar pessoal, social e econômico.
				Seção Única Do Atendimento Prioritário </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 9º A pessoa com deficiência
				tem direito a receber atendimento prioritário, sobretudo com a
				finalidade de:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - proteção e socorro em quaisquer circunstâncias; II -
				atendimento em todas as instituições e serviços de atendimento ao
				público;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - disponibilização de recursos, tanto humanos quanto
				tecnológicos, que garantam atendimento em igualdade de condições com as
				demais pessoas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - disponibilização de pontos de parada, estações e
				terminais acessíveis de transporte coletivo de passageiros e garantia de
				segurança no embarque e no desembarque;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - acesso a informações e
				disponibilização de recursos de comunicação acessíveis;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - recebimento
				de restituição de imposto de renda;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - tramitação processual e
				procedimentos judiciais e administrativos em que for parte ou
				interessada, em todos os atos e diligências. § 1º Os direitos previstos
				neste artigo são extensivos ao acompanhante da pessoa com deficiência ou
				ao seu atendente pessoal, exceto quanto ao disposto nos incisos VI e VII
				deste artigo. § 2º Nos serviços de emergência públicos e privados, a
				prioridade conferida por esta Lei é condicionada aos protocolos de
				atendimento médico.     </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">TÍTULO II</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">DOS DIREITOS FUNDAMENTAIS</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">CAPÍTULO I</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">DO DIREITO À VIDA</label></b-form-group>


                <b-form-group class="text-label"><label tabindex="0">Art. 10. Compete ao poder público garantir a dignidade da
				pessoa com deficiência ao longo de toda a vida. Parágrafo único. Em
				situações de risco, emergência ou estado de calamidade pública, a pessoa
				com deficiência será considerada vulnerável, devendo o poder público
				adotar medidas para sua proteção e segurança. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 11. A pessoa com
				deficiência não poderá ser obrigada a se submeter a intervenção clínica
				ou cirúrgica, a tratamento ou a institucionalização forçada. Parágrafo
				único. O consentimento da pessoa com deficiência em situação de curatela
				poderá ser suprido, na forma da lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 12. O consentimento prévio,
				livre e esclarecido da pessoa com deficiência é indispensável para a
				realização de tratamento, procedimento, hospitalização e pesquisa
				científica. § 1º Em caso de pessoa com deficiência em situação de
				curatela, deve ser assegurada sua participação, no maior grau possível,
				para a obtenção de consentimento. § 2º A pesquisa científica envolvendo
				pessoa com deficiência em situação de tutela ou de curatela deve ser
				realizada, em caráter excepcional, apenas quando houver indícios de
				benefício direto para sua saúde ou para a saúde de outras pessoas com
				deficiência e desde que não haja outra opção de pesquisa de eficácia
				comparável com participantes não tutelados ou curatelados. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 13. A
				pessoa com deficiência somente será atendida sem seu consentimento
				prévio, livre e esclarecido em casos de risco de morte e de emergência
				em saúde, resguardado seu superior interesse e adotadas as salvaguardas
				legais cabíveis. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO II</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO DIREITO À HABILITAÇÃO E À REABILITAÇÃO
				</label></b-form-group>
                
                <b-form-group class="text-label"><label tabindex="0">Art. 14. O processo de habilitação e de reabilitação é um direito da
				pessoa com deficiência. Parágrafo único. O processo de habilitação e de
				reabilitação tem por objetivo o desenvolvimento de potencialidades,
				talentos, habilidades e aptidões físicas, cognitivas, sensoriais,
				psicossociais, atitudinais, profissionais e artísticas que contribuam
				para a conquista da autonomia da pessoa com deficiência e de sua
				participação social em igualdade de condições e oportunidades com as
				demais pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 15. O processo mencionado no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 14 desta Lei
				baseia-se em avaliação multidisciplinar das necessidades, habilidades e
				potencialidades de cada pessoa, observadas as seguintes diretrizes: I -
				diagnóstico e intervenção precoces;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - adoção de medidas para
				compensar perda ou limitação funcional, buscando o desenvolvimento de
				aptidões;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - atuação permanente, integrada e articulada de políticas
				públicas que possibilitem a plena participação social da pessoa com
				deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - oferta de rede de serviços articulados, com atuação
				intersetorial, nos diferentes níveis de complexidade, para atender às
				necessidades específicas da pessoa com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - prestação de
				serviços próximo ao domicílio da pessoa com deficiência, inclusive na
				zona rural, respeitadas a organização das Redes de Atenção à Saúde (RAS)
				nos territórios locais e as normas do Sistema Único de Saúde (SUS). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				16. Nos programas e serviços de habilitação e de reabilitação para a
				pessoa com deficiência, são garantidos:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - organização, serviços,
				métodos, técnicas e recursos para atender às características de cada
				pessoa com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - acessibilidade em todos os ambientes e
				serviços;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - tecnologia assistiva, tecnologia de reabilitação,
				materiais e equipamentos adequados e apoio técnico profissional, de
				acordo com as especificidades de cada pessoa com deficiência; IV -
				capacitação continuada de todos os profissionais que participem dos
				programas e serviços. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 17. Os serviços do SUS e do Suas deverão
				promover ações articuladas para garantir à pessoa com deficiência e sua
				família a aquisição de informações, orientações e formas de acesso às
				políticas públicas disponíveis, com a finalidade de propiciar sua plena
				participação social. Parágrafo único. Os serviços de que trata o caput
				deste artigo podem fornecer informações e orientações nas áreas de
				saúde, de educação, de cultura, de esporte, de lazer, de transporte, de
				previdência social, de assistência social, de habitação, de trabalho, de
				empreendedorismo, de acesso ao crédito, de promoção, proteção e defesa
				de direitos e nas demais áreas que possibilitem à pessoa com deficiência
				exercer sua cidadania. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO III</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO DIREITO À SAÚDE </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 18. É
				assegurada atenção integral à saúde da pessoa com deficiência em todos
				os níveis de complexidade, por intermédio do SUS, garantido acesso
				universal e igualitário. § 1º É assegurada a participação da pessoa com
				deficiência na elaboração das políticas de saúde a ela destinadas. § 2º
				É assegurado atendimento segundo normas éticas e técnicas, que
				regulamentarão a atuação dos profissionais de saúde e contemplarão
				aspectos relacionados aos direitos e às especificidades da pessoa com
				deficiência, incluindo temas como sua dignidade e autonomia. § 3º Aos
				profissionais que prestam assistência à pessoa com deficiência,
				especialmente em serviços de habilitação e de reabilitação, deve ser
				garantida capacitação inicial e continuada. § 4º As ações e os serviços
				de saúde pública destinados à pessoa com deficiência devem assegurar: I
				- diagnóstico e intervenção precoces, realizados por equipe
				multidisciplinar;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - serviços de habilitação e de reabilitação sempre
				que necessários, para qualquer tipo de deficiência, inclusive para a
				manutenção da melhor condição de saúde e qualidade de vida; III -
				atendimento domiciliar multidisciplinar, tratamento ambulatorial e
				internação;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - campanhas de vacinação;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - atendimento psicológico,
				inclusive para seus familiares e atendentes pessoais;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - respeito à
				especificidade, à identidade de gênero e à orientação sexual da pessoa
				com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - atenção sexual e reprodutiva, incluindo o direito
				à fertilização assistida;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VIII - informação adequada e acessível à
				pessoa com deficiência e a seus familiares sobre sua condição de saúde;
				IX - serviços projetados para prevenir a ocorrência e o desenvolvimento
				de deficiências e agravos adicionais;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> X - promoção de estratégias de
				capacitação permanente das equipes que atuam no SUS, em todos os níveis
				de atenção, no atendimento à pessoa com deficiência, bem como orientação
				a seus atendentes pessoais;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XI - oferta de órteses, próteses, meios
				auxiliares de locomoção, medicamentos, insumos e fórmulas nutricionais,
				conforme as normas vigentes do Ministério da Saúde. § 5º As diretrizes
				deste artigo aplicam-se também às instituições privadas que participem
				de forma complementar do SUS ou que recebam recursos públicos para sua
				manutenção. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 19. Compete ao SUS desenvolver ações destinadas à
				prevenção de deficiências por causas evitáveis, inclusive por meio de: I
				- acompanhamento da gravidez, do parto e do puerpério, com garantia de
				parto humanizado e seguro;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - promoção de práticas alimentares
				adequadas e saudáveis, vigilância alimentar e nutricional, prevenção e
				cuidado integral dos agravos relacionados à alimentação e nutrição da
				mulher e da criança;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - aprimoramento e expansão dos programas de
				imunização e de triagem neonatal;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - identificação e controle da
				gestante de alto risco.</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - aprimoramento do atendimento neonatal, com a
				oferta de ações e serviços de prevenção de danos cerebrais e sequelas
				neurológicas em recém-nascidos, inclusive por telessaúde. (Incluído pela
				Lei nº 14.510, de 2022) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 20. As operadoras de planos e seguros
				privados de saúde são obrigadas a garantir à pessoa com deficiência, no
				mínimo, todos os serviços e produtos ofertados aos demais clientes. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				21. Quando esgotados os meios de atenção à saúde da pessoa com
				deficiência no local de residência, será prestado atendimento fora de
				domicílio, para fins de diagnóstico e de tratamento, garantidos o
				transporte e a acomodação da pessoa com deficiência e de seu
				acompanhante. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 22. À pessoa com deficiência internada ou em
				observação é assegurado o direito a acompanhante ou a atendente pessoal,
				devendo o órgão ou a instituição de saúde proporcionar condições
				adequadas para sua permanência em tempo integral. § 1º Na
				impossibilidade de permanência do acompanhante ou do atendente pessoal
				junto à pessoa com deficiência, cabe ao profissional de saúde
				responsável pelo tratamento justificá-la por escrito. § 2º Na ocorrência
				da impossibilidade prevista no § 1º deste artigo, o órgão ou a
				instituição de saúde deve adotar as providências cabíveis para suprir a
				ausência do acompanhante ou do atendente pessoal. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 23. São vedadas
				todas as formas de discriminação contra a pessoa com deficiência,
				inclusive por meio de cobrança de valores diferenciados por planos e
				seguros privados de saúde, em razão de sua condição. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 24. É
				assegurado à pessoa com deficiência o acesso aos serviços de saúde,
				tanto públicos como privados, e às informações prestadas e recebidas,
				por meio de recursos de tecnologia assistiva e de todas as formas de
				comunicação previstas no inciso V do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 3º desta Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 25. Os
				espaços dos serviços de saúde, tanto públicos quanto privados, devem
				assegurar o acesso da pessoa com deficiência, em conformidade com a
				legislação em vigor, mediante a remoção de barreiras, por meio de
				projetos arquitetônico, de ambientação de interior e de comunicação que
				atendam às especificidades das pessoas com deficiência física,
				sensorial, intelectual e mental. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 26. Os casos de suspeita ou de
				confirmação de violência praticada contra a pessoa com deficiência serão
				objeto de notificação compulsória pelos serviços de saúde públicos e
				privados à autoridade policial e ao Ministério Público, além dos
				Conselhos dos Direitos da Pessoa com Deficiência. Parágrafo único. Para
				os efeitos desta Lei, considera-se violência contra a pessoa com
				deficiência qualquer ação ou omissão, praticada em local público ou
				privado, que lhe cause morte ou dano ou sofrimento físico ou
				psicológico. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO IV</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO DIREITO À EDUCAÇÃO </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 27. A educação
				constitui direito da pessoa com deficiência, assegurados sistema
				educacional inclusivo em todos os níveis e aprendizado ao longo de toda
				a vida, de forma a alcançar o máximo desenvolvimento possível de seus
				talentos e habilidades físicas, sensoriais, intelectuais e sociais,
				segundo suas características, interesses e necessidades de aprendizagem.
				Parágrafo único. É dever do Estado, da família, da comunidade escolar e
				da sociedade assegurar educação de qualidade à pessoa com deficiência,
				colocando-a a salvo de toda forma de violência, negligência e
				discriminação. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 28. Incumbe ao poder público assegurar, criar,
				desenvolver, implementar, incentivar, acompanhar e avaliar:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - sistema
				educacional inclusivo em todos os níveis e modalidades, bem como o
				aprendizado ao longo de toda a vida;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - aprimoramento dos sistemas
				educacionais, visando a garantir condições de acesso, permanência,
				participação e aprendizagem, por meio da oferta de serviços e de
				recursos de acessibilidade que eliminem as barreiras e promovam a
				inclusão plena;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - projeto pedagógico que institucionalize o
				atendimento educacional especializado, assim como os demais serviços e
				adaptações razoáveis, para atender às características dos estudantes com
				deficiência e garantir o seu pleno acesso ao currículo em condições de
				igualdade, promovendo a conquista e o exercício de sua autonomia; IV -
				oferta de educação bilíngue, em Libras como primeira língua e na
				modalidade escrita da língua portuguesa como segunda língua, em escolas
				e classes bilíngues e em escolas inclusivas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - adoção de medidas
				individualizadas e coletivas em ambientes que maximizem o
				desenvolvimento acadêmico e social dos estudantes com deficiência,
				favorecendo o acesso, a permanência, a participação e a aprendizagem em
				instituições de ensino;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - pesquisas voltadas para o desenvolvimento
				de novos métodos e técnicas pedagógicas, de materiais didáticos, de
				equipamentos e de recursos de tecnologia assistiva;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - planejamento
				de estudo de caso, de elaboração de plano de atendimento educacional
				especializado, de organização de recursos e serviços de acessibilidade e
				de disponibilização e usabilidade pedagógica de recursos de tecnologia
				assistiva;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VIII - participação dos estudantes com deficiência e de suas
				famílias nas diversas instâncias de atuação da comunidade escolar; IX -
				adoção de medidas de apoio que favoreçam o desenvolvimento dos aspectos
				linguísticos, culturais, vocacionais e profissionais, levando-se em
				conta o talento, a criatividade, as habilidades e os interesses do
				estudante com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> X - adoção de práticas pedagógicas inclusivas
				pelos programas de formação inicial e continuada de professores e oferta
				de formação continuada para o atendimento educacional especializado; XI
				- formação e disponibilização de professores para o atendimento
				educacional especializado, de tradutores e intérpretes da Libras, de
				guias intérpretes e de profissionais de apoio;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XII - oferta de ensino da
				Libras, do Sistema Braille e de uso de recursos de tecnologia assistiva,
				de forma a ampliar habilidades funcionais dos estudantes, promovendo sua
				autonomia e participação;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XIII - acesso à educação superior e à educação
				profissional e tecnológica em igualdade de oportunidades e condições com
				as demais pessoas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XIV - inclusão em conteúdos curriculares, em cursos
				de nível superior e de educação profissional técnica e tecnológica, de
				temas relacionados à pessoa com deficiência nos respectivos campos de
				conhecimento;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XV - acesso da pessoa com deficiência, em igualdade de
				condições, a jogos e a atividades recreativas, esportivas e de lazer, no
				sistema escolar;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XVI - acessibilidade para todos os estudantes,
				trabalhadores da educação e demais integrantes da comunidade escolar às
				edificações, aos ambientes e às atividades concernentes a todas as
				modalidades, etapas e níveis de ensino;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XVII - oferta de profissionais
				de apoio escolar;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> XVIII - articulação intersetorial na implementação de
				políticas públicas. § 1º Às instituições privadas, de qualquer nível e
				modalidade de ensino, aplica-se obrigatoriamente o disposto nos incisos
				I, II, III, V, VII, VIII, IX, X, XI, XII, XIII, XIV, XV, XVI, XVII e
				XVIII do caput deste artigo, sendo vedada a cobrança de valores
				adicionais de qualquer natureza em suas mensalidades, anuidades e
				matrículas no cumprimento dessas determinações. § 2º Na disponibilização
				de tradutores e intérpretes da Libras a que se refere o inciso XI do
				caput deste artigo, deve-se observar o seguinte:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - os tradutores e
				intérpretes da Libras atuantes na educação básica devem, no mínimo,
				possuir ensino médio completo e certificado de proficiência na Libras;
				(Vigência)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - os tradutores e intérpretes da Libras, quando
				direcionados à tarefa de interpretar nas salas de aula dos cursos de
				graduação e pós-graduação, devem possuir nível superior, com
				habilitação, prioritariamente, em Tradução e Interpretação em Libras.
				(Vigência) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 29. (VETADO). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 30. Nos processos seletivos para
				ingresso e permanência nos cursos oferecidos pelas instituições de
				ensino superior e de educação profissional e tecnológica, públicas e
				privadas, devem ser adotadas as seguintes medidas:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - atendimento
				preferencial à pessoa com deficiência nas dependências das Instituições
				de Ensino Superior (IES) e nos serviços;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - disponibilização de
				formulário de inscrição de exames com campos específicos para que o
				candidato com deficiência informe os recursos de acessibilidade e de
				tecnologia assistiva necessários para sua participação; III -
				disponibilização de provas em formatos acessíveis para atendimento às
				necessidades específicas do candidato com deficiência; IV -
				disponibilização de recursos de acessibilidade e de tecnologia assistiva
				adequados, previamente solicitados e escolhidos pelo candidato com
				deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - dilação de tempo, conforme demanda apresentada pelo
				candidato com deficiência, tanto na realização de exame para seleção
				quanto nas atividades acadêmicas, mediante prévia solicitação e
				comprovação da necessidade;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - adoção de critérios de avaliação das
				provas escritas, discursivas ou de redação que considerem a
				singularidade linguística da pessoa com deficiência, no domínio da
				modalidade escrita da língua portuguesa;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - tradução completa do
				edital e de suas retificações em Libras. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO V</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO DIREITO À MORADIA
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 31. A pessoa com deficiência tem direito à moradia digna, no seio
				da família natural ou substituta, com seu cônjuge ou companheiro ou
				desacompanhada, ou em moradia para a vida independente da pessoa com
				deficiência, ou, ainda, em residência inclusiva. § 1º O poder público
				adotará programas e ações estratégicas para apoiar a criação e a
				manutenção de moradia para a vida independente da pessoa com
				deficiência. § 2º A proteção integral na modalidade de residência
				inclusiva será prestada no âmbito do Suas à pessoa com deficiência em
				situação de dependência que não disponha de condições de
				autossustentabilidade, com vínculos familiares fragilizados ou rompidos.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 32. Nos programas habitacionais, públicos ou subsidiados com
				recursos públicos, a pessoa com deficiência ou o seu responsável goza de
				prioridade na aquisição de imóvel para moradia própria, observado o
				seguinte:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - reserva de, no mínimo, 3% (três por cento) das unidades
				habitacionais para pessoa com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - (VETADO);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - em caso
				de edificação multifamiliar, garantia de acessibilidade nas áreas de uso
				comum e nas unidades habitacionais no piso térreo e de acessibilidade ou
				de adaptação razoável nos demais pisos;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - disponibilização de
				equipamentos urbanos comunitários acessíveis;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - elaboração de
				especificações técnicas no projeto que permitam a instalação de
				elevadores. § 1º O direito à prioridade, previsto no caput deste artigo,
				será reconhecido à pessoa com deficiência beneficiária apenas uma vez. §
				2º Nos programas habitacionais públicos, os critérios de financiamento
				devem ser compatíveis com os rendimentos da pessoa com deficiência ou de
				sua família. § 3º Caso não haja pessoa com deficiência interessada nas
				unidades habitacionais reservadas por força do disposto no inciso I do
				caput deste artigo, as unidades não utilizadas serão disponibilizadas às
				demais pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 33. Ao poder público compete:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - adotar as
				providências necessárias para o cumprimento do disposto nos arts. 31 e
				32 desta Lei; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - divulgar, para os agentes interessados e
				beneficiários, a política habitacional prevista nas legislações federal,
				estaduais, distrital e municipais, com ênfase nos dispositivos sobre
				acessibilidade. CAPÍTULO VI DO DIREITO AO TRABALHO Seção I Disposições
				Gerais </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 34. A pessoa com deficiência tem direito ao trabalho de sua
				livre escolha e aceitação, em ambiente acessível e inclusivo, em
				igualdade de oportunidades com as demais pessoas. § 1º As pessoas
				jurídicas de direito público, privado ou de qualquer natureza são
				obrigadas a garantir ambientes de trabalho acessíveis e inclusivos. § 2º
				A pessoa com deficiência tem direito, em igualdade de oportunidades com
				as demais pessoas, a condições justas e favoráveis de trabalho,
				incluindo igual remuneração por trabalho de igual valor. § 3º É vedada
				restrição ao trabalho da pessoa com deficiência e qualquer discriminação
				em razão de sua condição, inclusive nas etapas de recrutamento, seleção,
				contratação, admissão, exames admissional e periódico, permanência no
				emprego, ascensão profissional e reabilitação profissional, bem como
				exigência de aptidão plena. § 4º A pessoa com deficiência tem direito à
				participação e ao acesso a cursos, treinamentos, educação continuada,
				planos de carreira, promoções, bonificações e incentivos profissionais
				oferecidos pelo empregador, em igualdade de oportunidades com os demais
				empregados. § 5º É garantida aos trabalhadores com deficiência
				acessibilidade em cursos de formação e de capacitação. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 35. É
				finalidade primordial das políticas públicas de trabalho e emprego
				promover e garantir condições de acesso e de permanência da pessoa com
				deficiência no campo de trabalho. Parágrafo único. Os programas de
				estímulo ao empreendedorismo e ao trabalho autônomo, incluídos o
				cooperativismo e o associativismo, devem prever a participação da pessoa
				com deficiência e a disponibilização de linhas de crédito, quando
				necessárias. Seção II Da Habilitação Profissional e Reabilitação
				Profissional </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 36. O poder público deve implementar serviços e
				programas completos de habilitação profissional e de reabilitação
				profissional para que a pessoa com deficiência possa ingressar,
				continuar ou retornar ao campo do trabalho, respeitados sua livre
				escolha, sua vocação e seu interesse. § 1º Equipe multidisciplinar
				indicará, com base em critérios previstos no § 1º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 2º desta Lei,
				programa de habilitação ou de reabilitação que possibilite à pessoa com
				deficiência restaurar sua capacidade e habilidade profissional ou
				adquirir novas capacidades e habilidades de trabalho. § 2º A habilitação
				profissional corresponde ao processo destinado a propiciar à pessoa com
				deficiência aquisição de conhecimentos, habilidades e aptidões para
				exercício de profissão ou de ocupação, permitindo nível suficiente de
				desenvolvimento profissional para ingresso no campo de trabalho. § 3º Os
				serviços de habilitação profissional, de reabilitação profissional e de
				educação profissional devem ser dotados de recursos necessários para
				atender a toda pessoa com deficiência, independentemente de sua
				característica específica, a fim de que ela possa ser capacitada para
				trabalho que lhe seja adequado e ter perspectivas de obtê-lo, de
				conservá-lo e de nele progredir. § 4º Os serviços de habilitação
				profissional, de reabilitação profissional e de educação profissional
				deverão ser oferecidos em ambientes acessíveis e inclusivos. § 5º A
				habilitação profissional e a reabilitação profissional devem ocorrer
				articuladas com as redes públicas e privadas, especialmente de saúde, de
				ensino e de assistência social, em todos os níveis e modalidades, em
				entidades de formação profissional ou diretamente com o empregador. § 6º
				A habilitação profissional pode ocorrer em empresas por meio de prévia
				formalização do contrato de emprego da pessoa com deficiência, que será
				considerada para o cumprimento da reserva de vagas prevista em lei,
				desde que por tempo determinado e concomitante com a inclusão
				profissional na empresa, observado o disposto em regulamento. § 7º A
				habilitação profissional e a reabilitação profissional atenderão à
				pessoa com deficiência. Seção III Da Inclusão da Pessoa com Deficiência
				no Trabalho </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 37. Constitui modo de inclusão da pessoa com
				deficiência no trabalho a colocação competitiva, em igualdade de
				oportunidades com as demais pessoas, nos termos da legislação
				trabalhista e previdenciária, na qual devem ser atendidas as regras de
				acessibilidade, o fornecimento de recursos de tecnologia assistiva e a
				adaptação razoável no ambiente de trabalho. Parágrafo único. A colocação
				competitiva da pessoa com deficiência pode ocorrer por meio de trabalho
				com apoio, observadas as seguintes diretrizes:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - prioridade no
				atendimento à pessoa com deficiência com maior dificuldade de inserção
				no campo de trabalho;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - provisão de suportes individualizados que
				atendam a necessidades específicas da pessoa com deficiência, inclusive
				a disponibilização de recursos de tecnologia assistiva, de agente
				facilitador e de apoio no ambiente de trabalho;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - respeito ao perfil
				vocacional e ao interesse da pessoa com deficiência apoiada;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - oferta
				de aconselhamento e de apoio aos empregadores, com vistas à definição de
				estratégias de inclusão e de superação de barreiras, inclusive
				atitudinais;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - realização de avaliações periódicas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - articulação
				intersetorial das políticas públicas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - possibilidade de
				participação de organizações da sociedade civil. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 38. A entidade
				contratada para a realização de processo seletivo público ou privado
				para cargo, função ou emprego está obrigada à observância do disposto
				nesta Lei e em outras normas de acessibilidade vigentes. CAPÍTULO VII DO
				DIREITO À ASSISTÊNCIA SOCIAL </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 39. Os serviços, os programas, os
				projetos e os benefícios no âmbito da política pública de assistência
				social à pessoa com deficiência e sua família têm como objetivo a
				garantia da segurança de renda, da acolhida, da habilitação e da
				reabilitação, do desenvolvimento da autonomia e da convivência familiar
				e comunitária, para a promoção do acesso a direitos e da plena
				participação social. § 1º A assistência social à pessoa com deficiência,
				nos termos do caput deste artigo, deve envolver conjunto articulado de
				serviços do âmbito da Proteção Social Básica e da Proteção Social
				Especial, ofertados pelo Suas, para a garantia de seguranças
				fundamentais no enfrentamento de situações de vulnerabilidade e de
				risco, por fragilização de vínculos e ameaça ou violação de direitos. §
				2º Os serviços socioassistenciais destinados à pessoa com deficiência em
				situação de dependência deverão contar com cuidadores sociais para
				prestar-lhe cuidados básicos e instrumentais. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 40. É assegurado à
				pessoa com deficiência que não possua meios para prover sua subsistência
				nem de tê-la provida por sua família o benefício mensal de 1 (um)
				salário-mínimo, nos termos da Lei nº 8.742, de 7 de dezembro de 1993 .
				CAPÍTULO VIII DO DIREITO À PREVIDÊNCIA SOCIAL </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 41. A pessoa com
				deficiência segurada do Regime Geral de Previdência Social (RGPS) tem
				direito à aposentadoria nos termos da Lei Complementar nº 142, de 8 de
				maio de 2013 . CAPÍTULO IX DO DIREITO À CULTURA, AO ESPORTE, AO TURISMO
				E AO LAZER </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 42. A pessoa com deficiência tem direito à cultura, ao
				esporte, ao turismo e ao lazer em igualdade de oportunidades com as
				demais pessoas, sendo-lhe garantido o acesso:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - a bens culturais em
				formato acessível;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - a programas de televisão, cinema, teatro e
				outras atividades culturais e desportivas em formato acessível; e III -
				a monumentos e locais de importância cultural e a espaços que ofereçam
				serviços ou eventos culturais e esportivos. § 1º É vedada a recusa de
				oferta de obra intelectual em formato acessível à pessoa com
				deficiência, sob qualquer argumento, inclusive sob a alegação de
				proteção dos direitos de propriedade intelectual. § 2º O poder público
				deve adotar soluções destinadas à eliminação, à redução ou à superação
				de barreiras para a promoção do acesso a todo patrimônio cultural,
				observadas as normas de acessibilidade, ambientais e de proteção do
				patrimônio histórico e artístico nacional. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 43. O poder público deve
				promover a participação da pessoa com deficiência em atividades
				artísticas, intelectuais, culturais, esportivas e recreativas, com
				vistas ao seu protagonismo, devendo:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - incentivar a provisão de
				instrução, de treinamento e de recursos adequados, em igualdade de
				oportunidades com as demais pessoas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - assegurar acessibilidade nos
				locais de eventos e nos serviços prestados por pessoa ou entidade
				envolvida na organização das atividades de que trata este artigo; e III
				- assegurar a participação da pessoa com deficiência em jogos e
				atividades recreativas, esportivas, de lazer, culturais e artísticas,
				inclusive no sistema escolar, em igualdade de condições com as demais
				pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 44. Nos teatros, cinemas, auditórios, estádios, ginásios
				de esporte, locais de espetáculos e de conferências e similares, serão
				reservados espaços livres e assentos para a pessoa com deficiência, de
				acordo com a capacidade de lotação da edificação, observado o disposto
				em regulamento. § 1º Os espaços e assentos a que se refere este artigo
				devem ser distribuídos pelo recinto em locais diversos, de boa
				visibilidade, em todos os setores, próximos aos corredores, devidamente
				sinalizados, evitando-se áreas segregadas de público e obstrução das
				saídas, em conformidade com as normas de acessibilidade. § 2º No caso de
				não haver comprovada procura pelos assentos reservados, esses podem,
				excepcionalmente, ser ocupados por pessoas sem deficiência ou que não
				tenham mobilidade reduzida, observado o disposto em regulamento. § 3º Os
				espaços e assentos a que se refere este artigo devem situar-se em locais
				que garantam a acomodação de, no mínimo, 1 (um) acompanhante da pessoa
				com deficiência ou com mobilidade reduzida, resguardado o direito de se
				acomodar proximamente a grupo familiar e comunitário. § 4º Nos locais
				referidos no caput deste artigo, deve haver, obrigatoriamente, rotas de
				fuga e saídas de emergência acessíveis, conforme padrões das normas de
				acessibilidade, a fim de permitir a saída segura da pessoa com
				deficiência ou com mobilidade reduzida, em caso de emergência. § 5º
				Todos os espaços das edificações previstas no caput deste artigo devem
				atender às normas de acessibilidade em vigor. § 6º As salas de cinema
				devem oferecer, em todas as sessões, recursos de acessibilidade para a
				pessoa com deficiência. (Vigência) § 7º O valor do ingresso da pessoa
				com deficiência não poderá ser superior ao valor cobrado das demais
				pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 45. Os hotéis, pousadas e similares devem ser construídos
				observando-se os princípios do desenho universal, além de adotar todos
				os meios de acessibilidade, conforme legislação em vigor. (Vigência)
				(Reglamento) § 1º Os estabelecimentos já existentes deverão
				disponibilizar, pelo menos, 10% (dez por cento) de seus dormitórios
				acessíveis, garantida, no mínimo, 1 (uma) unidade acessível. § 2º Os
				dormitórios mencionados no § 1º deste artigo deverão ser localizados em
				rotas acessíveis. CAPÍTULO X DO DIREITO AO TRANSPORTE E À MOBILIDADE
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 46. O direito ao transporte e à mobilidade da pessoa com
				deficiência ou com mobilidade reduzida será assegurado em igualdade de
				oportunidades com as demais pessoas, por meio de identificação e de
				eliminação de todos os obstáculos e barreiras ao seu acesso. § 1º Para
				fins de acessibilidade aos serviços de transporte coletivo terrestre,
				aquaviário e aéreo, em todas as jurisdições, consideram-se como
				integrantes desses serviços os veículos, os terminais, as estações, os
				pontos de parada, o sistema viário e a prestação do serviço. § 2º São
				sujeitas ao cumprimento das disposições desta Lei, sempre que houver
				interação com a matéria nela regulada, a outorga, a concessão, a
				permissão, a autorização, a renovação ou a habilitação de linhas e de
				serviços de transporte coletivo. § 3º Para colocação do símbolo
				internacional de acesso nos veículos, as empresas de transporte coletivo
				de passageiros dependem da certificação de acessibilidade emitida pelo
				gestor público responsável pela prestação do serviço. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 47. Em todas
				as áreas de estacionamento aberto ao público, de uso público ou privado
				de uso coletivo e em vias públicas, devem ser reservadas vagas próximas
				aos acessos de circulação de pedestres, devidamente sinalizadas, para
				veículos que transportem pessoa com deficiência com comprometimento de
				mobilidade, desde que devidamente identificados. § 1º As vagas a que se
				refere o caput deste artigo devem equivaler a 2% (dois por cento) do
				total, garantida, no mínimo, 1 (uma) vaga devidamente sinalizada e com
				as especificações de desenho e traçado de acordo com as normas técnicas
				vigentes de acessibilidade. § 2º Os veículos estacionados nas vagas
				reservadas devem exibir, em local de ampla visibilidade, a credencial de
				beneficiário, a ser confeccionada e fornecida pelos órgãos de trânsito,
				que disciplinarão suas características e condições de uso. § 3º A
				utilização indevida das vagas de que trata este artigo sujeita os
				infratores às sanções previstas no inciso XVII do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 181 da Lei nº
				9.503, de 23 de setembro de 1997 (Código de Trânsito Brasileiro) . § 3º
				A utilização indevida das vagas de que trata este artigo sujeita os
				infratores às sanções previstas no inciso XX do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 181 da Lei nº
				9.503, de 23 de setembro de 1997 (Código de Trânsito Brasileiro) .
				(Redação dada pela Lei nº 13.281, de 2016) (Vigência) § 4º A credencial
				a que se refere o § 2º deste artigo é vinculada à pessoa com deficiência
				que possui comprometimento de mobilidade e é válida em todo o território
				nacional. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 48. Os veículos de transporte coletivo terrestre,
				aquaviário e aéreo, as instalações, as estações, os portos e os
				terminais em operação no País devem ser acessíveis, de forma a garantir
				o seu uso por todas as pessoas. § 1º Os veículos e as estruturas de que
				trata o caput deste artigo devem dispor de sistema de comunicação
				acessível que disponibilize informações sobre todos os pontos do
				itinerário. § 2º São asseguradas à pessoa com deficiência prioridade e
				segurança nos procedimentos de embarque e de desembarque nos veículos de
				transporte coletivo, de acordo com as normas técnicas. § 3º Para
				colocação do símbolo internacional de acesso nos veículos, as empresas
				de transporte coletivo de passageiros dependem da certificação de
				acessibilidade emitida pelo gestor público responsável pela prestação do
				serviço. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 49. As empresas de transporte de fretamento e de turismo,
				na renovação de suas frotas, são obrigadas ao cumprimento do disposto
				nos arts. 46 e 48 desta Lei. (Vigência) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 50. O poder público
				incentivará a fabricação de veículos acessíveis e a sua utilização como
				táxis e vans , de forma a garantir o seu uso por todas as pessoas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				51. As frotas de empresas de táxi devem reservar 10% (dez por cento) de
				seus veículos acessíveis à pessoa com deficiência. (Vide Decreto nº
				9.762, de 2019) (Vigência) § 1º É proibida a cobrança diferenciada de
				tarifas ou de valores adicionais pelo serviço de táxi prestado à pessoa
				com deficiência. § 2º O poder público é autorizado a instituir
				incentivos fiscais com vistas a possibilitar a acessibilidade dos
				veículos a que se refere o caput deste artigo. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 52. As locadoras de
				veículos são obrigadas a oferecer 1 (um) veículo adaptado para uso de
				pessoa com deficiência, a cada conjunto de 20 (vinte) veículos de sua
				frota. (Vide Decreto nº 9.762, de 2019) (Vigência) Parágrafo único. O
				veículo adaptado deverá ter, no mínimo, câmbio automático, direção
				hidráulica, vidros elétricos e comandos manuais de freio e de embreagem.
				</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título III </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DA ACESSIBILIDADE CAPÍTULO I DISPOSIÇÕES GERAIS </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 53. A
				acessibilidade é direito que garante à pessoa com deficiência ou com
				mobilidade reduzida viver de forma independente e exercer seus direitos
				de cidadania e de participação social. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 54. São sujeitas ao
				cumprimento das disposições desta Lei e de outras normas relativas à
				acessibilidade, sempre que houver interação com a matéria nela regulada:
				I - a aprovação de projeto arquitetônico e urbanístico ou de comunicação
				e informação, a fabricação de veículos de transporte coletivo, a
				prestação do respectivo serviço e a execução de qualquer tipo de obra,
				quando tenham destinação pública ou coletiva;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - a outorga ou a
				renovação de concessão, permissão, autorização ou habilitação de
				qualquer natureza;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - a aprovação de financiamento de projeto com
				utilização de recursos públicos, por meio de renúncia ou de incentivo
				fiscal, contrato, convênio ou instrumento congênere; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - a concessão
				de aval da União para obtenção de empréstimo e de financiamento
				internacionais por entes públicos ou privados. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 55. A concepção e a
				implantação de projetos que tratem do meio físico, de transporte, de
				informação e comunicação, inclusive de sistemas e tecnologias da
				informação e comunicação, e de outros serviços, equipamentos e
				instalações abertos ao público, de uso público ou privado de uso
				coletivo, tanto na zona urbana como na rural, devem atender aos
				princípios do desenho universal, tendo como referência as normas de
				acessibilidade. § 1º O desenho universal será sempre tomado como regra
				de caráter geral. § 2º Nas hipóteses em que comprovadamente o desenho
				universal não possa ser empreendido, deve ser adotada adaptação
				razoável. § 3º Caberá ao poder público promover a inclusão de conteúdos
				temáticos referentes ao desenho universal nas diretrizes curriculares da
				educação profissional e tecnológica e do ensino superior e na formação
				das carreiras de Estado. § 4º Os programas, os projetos e as linhas de
				pesquisa a serem desenvolvidos com o apoio de organismos públicos de
				auxílio à pesquisa e de agências de fomento deverão incluir temas
				voltados para o desenho universal. § 5º Desde a etapa de concepção, as
				políticas públicas deverão considerar a adoção do desenho universal.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 56. A construção, a reforma, a ampliação ou a mudança de uso de
				edificações abertas ao público, de uso público ou privadas de uso
				coletivo deverão ser executadas de modo a serem acessíveis. § 1º As
				entidades de fiscalização profissional das atividades de Engenharia, de
				Arquitetura e correlatas, ao anotarem a responsabilidade técnica de
				projetos, devem exigir a responsabilidade profissional declarada de
				atendimento às regras de acessibilidade previstas em legislação e em
				normas técnicas pertinentes. § 2º Para a aprovação, o licenciamento ou a
				emissão de certificado de projeto executivo arquitetônico, urbanístico e
				de instalações e equipamentos temporários ou permanentes e para o
				licenciamento ou a emissão de certificado de conclusão de obra ou de
				serviço, deve ser atestado o atendimento às regras de acessibilidade. §
				3º O poder público, após certificar a acessibilidade de edificação ou de
				serviço, determinará a colocação, em espaços ou em locais de ampla
				visibilidade, do símbolo internacional de acesso, na forma prevista em
				legislação e em normas técnicas correlatas. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 57. As edificações
				públicas e privadas de uso coletivo já existentes devem garantir
				acessibilidade à pessoa com deficiência em todas as suas dependências e
				serviços, tendo como referência as normas de acessibilidade vigentes.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 58. O projeto e a construção de edificação de uso privado
				multifamiliar devem atender aos preceitos de acessibilidade, na forma
				regulamentar. (Regulamento) § 1º As construtoras e incorporadoras
				responsáveis pelo projeto e pela construção das edificações a que se
				refere o caput deste artigo devem assegurar percentual mínimo de suas
				unidades internamente acessíveis, na forma regulamentar. § 2º É vedada a
				cobrança de valores adicionais para a aquisição de unidades internamente
				acessíveis a que se refere o § 1º deste artigo. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 59. Em qualquer
				intervenção nas vias e nos espaços públicos, o poder público e as
				empresas concessionárias responsáveis pela execução das obras e dos
				serviços devem garantir, de forma segura, a fluidez do trânsito e a
				livre circulação e acessibilidade das pessoas, durante e após sua
				execução. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 60. Orientam-se, no que couber, pelas regras de
				acessibilidade previstas em legislação e em normas técnicas, observado o
				disposto na Lei nº 10.098, de 19 de dezembro de 2000 , nº 10.257, de 10
				de julho de 2001 , e nº 12.587, de 3 de janeiro de 2012 :</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - os planos
				diretores municipais, os planos diretores de transporte e trânsito, os
				planos de mobilidade urbana e os planos de preservação de sítios
				históricos elaborados ou atualizados a partir da publicação desta Lei;
				II - os códigos de obras, os códigos de postura, as leis de uso e
				ocupação do solo e as leis do sistema viário;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - os estudos prévios
				de impacto de vizinhança;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - as atividades de fiscalização e a
				imposição de sanções; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - a legislação referente à prevenção contra
				incêndio e pânico. § 1º A concessão e a renovação de alvará de
				funcionamento para qualquer atividade são condicionadas à observação e à
				certificação das regras de acessibilidade. § 2º A emissão de carta de
				habite-se ou de habilitação equivalente e sua renovação, quando esta
				tiver sido emitida anteriormente às exigências de acessibilidade, é
				condicionada à observação e à certificação das regras de acessibilidade.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 61. A formulação, a implementação e a manutenção das ações de
				acessibilidade atenderão às seguintes premissas básicas:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - eleição de
				prioridades, elaboração de cronograma e reserva de recursos para
				implementação das ações; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - planejamento contínuo e articulado entre
				os setores envolvidos. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 62. É assegurado à pessoa com deficiência,
				mediante solicitação, o recebimento de contas, boletos, recibos,
				extratos e cobranças de tributos em formato acessível. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO II</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO
				ACESSO À INFORMAÇÃO E À COMUNICAÇÃO </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 63. É obrigatória a
				acessibilidade nos sítios da internet mantidos por empresas com sede ou
				representação comercial no País ou por órgãos de governo, para uso da
				pessoa com deficiência, garantindo-lhe acesso às informações
				disponíveis, conforme as melhores práticas e diretrizes de
				acessibilidade adotadas internacionalmente. § 1º Os sítios devem conter
				símbolo de acessibilidade em destaque. § 2º Telecentros comunitários que
				receberem recursos públicos federais para seu custeio ou sua instalação
				e lan houses devem possuir equipamentos e instalações acessíveis. § 3º
				Os telecentros e as lan houses de que trata o § 2º deste artigo devem
				garantir, no mínimo, 10% (dez por cento) de seus computadores com
				recursos de acessibilidade para pessoa com deficiência visual, sendo
				assegurado pelo menos 1 (um) equipamento, quando o resultado percentual
				for inferior a 1 (um). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 64. A acessibilidade nos sítios da internet
				de que trata o </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 63 desta Lei deve ser observada para obtenção do
				financiamento de que trata o inciso III do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 54 desta Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 65.
				As empresas prestadoras de serviços de telecomunicações deverão garantir
				pleno acesso à pessoa com deficiência, conforme regulamentação
				específica. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 66. Cabe ao poder público incentivar a oferta de
				aparelhos de telefonia fixa e móvel celular com acessibilidade que,
				entre outras tecnologias assistivas, possuam possibilidade de indicação
				e de ampliação sonoras de todas as operações e funções disponíveis. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				67. Os serviços de radiodifusão de sons e imagens devem permitir o uso
				dos seguintes recursos, entre outros:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - subtitulação por meio de
				legenda oculta;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - janela com intérprete da Libras; III -
				audiodescrição. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 68. O poder público deve adotar mecanismos de
				incentivo à produção, à edição, à difusão, à distribuição e à
				comercialização de livros em formatos acessíveis, inclusive em
				publicações da administração pública ou financiadas com recursos
				públicos, com vistas a garantir à pessoa com deficiência o direito de
				acesso à leitura, à informação e à comunicação. § 1º Nos editais de
				compras de livros, inclusive para o abastecimento ou a atualização de
				acervos de bibliotecas em todos os níveis e modalidades de educação e de
				bibliotecas públicas, o poder público deverá adotar cláusulas de
				impedimento à participação de editoras que não ofertem sua produção
				também em formatos acessíveis. § 2º Consideram-se formatos acessíveis os
				arquivos digitais que possam ser reconhecidos e acessados por softwares
				leitores de telas ou outras tecnologias assistivas que vierem a
				substituí-los, permitindo leitura com voz sintetizada, ampliação de
				caracteres, diferentes contrastes e impressão em Braille. § 3º O poder
				público deve estimular e apoiar a adaptação e a produção de artigos
				científicos em formato acessível, inclusive em Libras. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 69. O poder
				público deve assegurar a disponibilidade de informações corretas e
				claras sobre os diferentes produtos e serviços ofertados, por quaisquer
				meios de comunicação empregados, inclusive em ambiente virtual, contendo
				a especificação correta de quantidade, qualidade, características,
				composição e preço, bem como sobre os eventuais riscos à saúde e à
				segurança do consumidor com deficiência, em caso de sua utilização,
				aplicando-se, no que couber, os arts. 30 a 41 da Lei nº 8.078, de 11 de
				setembro de 1990 . § 1º Os canais de comercialização virtual e os
				anúncios publicitários veiculados na imprensa escrita, na internet, no
				rádio, na televisão e nos demais veículos de comunicação abertos ou por
				assinatura devem disponibilizar, conforme a compatibilidade do meio, os
				recursos de acessibilidade de que trata o </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 67 desta Lei, a expensas
				do fornecedor do produto ou do serviço, sem prejuízo da observância do
				disposto nos arts. 36 a 38 da Lei nº 8.078, de 11 de setembro de 1990 .
				§ 2º Os fornecedores devem disponibilizar, mediante solicitação,
				exemplares de bulas, prospectos, textos ou qualquer outro tipo de
				material de divulgação em formato acessível. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 70. As instituições
				promotoras de congressos, seminários, oficinas e demais eventos de
				natureza científico-cultural devem oferecer à pessoa com deficiência, no
				mínimo, os recursos de tecnologia assistiva previstos no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 67 desta
				Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 71. Os congressos, os seminários, as oficinas e os demais
				eventos de natureza científico-cultural promovidos ou financiados pelo
				poder público devem garantir as condições de acessibilidade e os
				recursos de tecnologia assistiva. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 72. Os programas, as linhas de
				pesquisa e os projetos a serem desenvolvidos com o apoio de agências de
				financiamento e de órgãos e entidades integrantes da administração
				pública que atuem no auxílio à pesquisa devem contemplar temas voltados
				à tecnologia assistiva. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 73. Caberá ao poder público, diretamente ou
				em parceria com organizações da sociedade civil, promover a capacitação
				de tradutores e intérpretes da Libras, de guias intérpretes e de
				profissionais habilitados em Braille, audiodescrição, estenotipia e
				legendagem. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO III</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DA TECNOLOGIA ASSISTIVA </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 74. É garantido à
				pessoa com deficiência acesso a produtos, recursos, estratégias,
				práticas, processos, métodos e serviços de tecnologia assistiva que
				maximizem sua autonomia, mobilidade pessoal e qualidade de vida. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				75. O poder público desenvolverá plano específico de medidas, a ser
				renovado em cada período de 4 (quatro) anos, com a finalidade de:
				(Regulamento)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - facilitar o acesso a crédito especializado, inclusive
				com oferta de linhas de crédito subsidiadas, específicas para aquisição
				de tecnologia assistiva;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - agilizar, simplificar e priorizar
				procedimentos de importação de tecnologia assistiva, especialmente as
				questões atinentes a procedimentos alfandegários e sanitários; III -
				criar mecanismos de fomento à pesquisa e à produção nacional de
				tecnologia assistiva, inclusive por meio de concessão de linhas de
				crédito subsidiado e de parcerias com institutos de pesquisa oficiais;
				IV - eliminar ou reduzir a tributação da cadeia produtiva e de
				importação de tecnologia assistiva;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - facilitar e agilizar o processo
				de inclusão de novos recursos de tecnologia assistiva no rol de produtos
				distribuídos no âmbito do SUS e por outros órgãos governamentais.
				Parágrafo único. Para fazer cumprir o disposto neste artigo, os
				procedimentos constantes do plano específico de medidas deverão ser
				avaliados, pelo menos, a cada 2 (dois) anos. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO IV</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO DIREITO À
				PARTICIPAÇÃO NA VIDA PÚBLICA E POLÍTICA </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 76. O poder público deve
				garantir à pessoa com deficiência todos os direitos políticos e a
				oportunidade de exercê-los em igualdade de condições com as demais
				pessoas. § 1º À pessoa com deficiência será assegurado o direito de
				votar e de ser votada, inclusive por meio das seguintes ações: I -
				garantia de que os procedimentos, as instalações, os materiais e os
				equipamentos para votação sejam apropriados, acessíveis a todas as
				pessoas e de fácil compreensão e uso, sendo vedada a instalação de
				seções eleitorais exclusivas para a pessoa com deficiência; II -
				incentivo à pessoa com deficiência a candidatar-se e a desempenhar
				quaisquer funções públicas em todos os níveis de governo, inclusive por
				meio do uso de novas tecnologias assistivas, quando apropriado; III -
				garantia de que os pronunciamentos oficiais, a propaganda eleitoral
				obrigatória e os debates transmitidos pelas emissoras de televisão
				possuam, pelo menos, os recursos elencados no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 67 desta Lei; IV -
				garantia do livre exercício do direito ao voto e, para tanto, sempre que
				necessário e a seu pedido, permissão para que a pessoa com deficiência
				seja auxiliada na votação por pessoa de sua escolha. § 2º O poder
				público promoverá a participação da pessoa com deficiência, inclusive
				quando institucionalizada, na condução das questões públicas, sem
				discriminação e em igualdade de oportunidades, observado o seguinte: I -
				participação em organizações não governamentais relacionadas à vida
				pública e à política do País e em atividades e administração de partidos
				políticos;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - formação de organizações para representar a pessoa com
				deficiência em todos os níveis;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - participação da pessoa com
				deficiência em organizações que a representem. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título IV </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DA CIÊNCIA E
				TECNOLOGIA </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 77. O poder público deve fomentar o desenvolvimento
				científico, a pesquisa e a inovação e a capacitação tecnológicas,
				voltados à melhoria da qualidade de vida e ao trabalho da pessoa com
				deficiência e sua inclusão social. § 1º O fomento pelo poder público
				deve priorizar a geração de conhecimentos e técnicas que visem à
				prevenção e ao tratamento de deficiências e ao desenvolvimento de
				tecnologias assistiva e social. § 2º A acessibilidade e as tecnologias
				assistiva e social devem ser fomentadas mediante a criação de cursos de
				pós-graduação, a formação de recursos humanos e a inclusão do tema nas
				diretrizes de áreas do conhecimento. § 3º Deve ser fomentada a
				capacitação tecnológica de instituições públicas e privadas para o
				desenvolvimento de tecnologias assistiva e social que sejam voltadas
				para melhoria da funcionalidade e da participação social da pessoa com
				deficiência. § 4º As medidas previstas neste artigo devem ser
				reavaliadas periodicamente pelo poder público, com vistas ao seu
				aperfeiçoamento. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 78. Devem ser estimulados a pesquisa, o
				desenvolvimento, a inovação e a difusão de tecnologias voltadas para
				ampliar o acesso da pessoa com deficiência às tecnologias da informação
				e comunicação e às tecnologias sociais. Parágrafo único. Serão
				estimulados, em especial:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - o emprego de tecnologias da informação e
				comunicação como instrumento de superação de limitações funcionais e de
				barreiras à comunicação, à informação, à educação e ao entretenimento da
				pessoa com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - a adoção de soluções e a difusão de normas
				que visem a ampliar a acessibilidade da pessoa com deficiência à
				computação e aos sítios da internet, em especial aos serviços de governo
				eletrônico. LIVRO II PARTE ESPECIAL TÍTULO I DO ACESSO À JUSTIÇA
				CAPÍTULO I DISPOSIÇÕES GERAIS </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 79. O poder público deve assegurar o
				acesso da pessoa com deficiência à justiça, em igualdade de
				oportunidades com as demais pessoas, garantindo, sempre que requeridos,
				adaptações e recursos de tecnologia assistiva. § 1º A fim de garantir a
				atuação da pessoa com deficiência em todo o processo judicial, o poder
				público deve capacitar os membros e os servidores que atuam no Poder
				Judiciário, no Ministério Público, na Defensoria Pública, nos órgãos de
				segurança pública e no sistema penitenciário quanto aos direitos da
				pessoa com deficiência. § 2º Devem ser assegurados à pessoa com
				deficiência submetida a medida restritiva de liberdade todos os direitos
				e garantias a que fazem jus os apenados sem deficiência, garantida a
				acessibilidade. § 3º A Defensoria Pública e o Ministério Público tomarão
				as medidas necessárias à garantia dos direitos previstos nesta Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				80. Devem ser oferecidos todos os recursos de tecnologia assistiva
				disponíveis para que a pessoa com deficiência tenha garantido o acesso à
				justiça, sempre que figure em um dos polos da ação ou atue como
				testemunha, partícipe da lide posta em juízo, advogado, defensor
				público, magistrado ou membro do Ministério Público. Parágrafo único. A
				pessoa com deficiência tem garantido o acesso ao conteúdo de todos os
				atos processuais de seu interesse, inclusive no exercício da advocacia.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 81. Os direitos da pessoa com deficiência serão garantidos por
				ocasião da aplicação de sanções penais. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 82. (VETADO). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 83. Os
				serviços notariais e de registro não podem negar ou criar óbices ou
				condições diferenciadas à prestação de seus serviços em razão de
				deficiência do solicitante, devendo reconhecer sua capacidade legal
				plena, garantida a acessibilidade. Parágrafo único. O descumprimento do
				disposto no caput deste artigo constitui discriminação em razão de
				deficiência. </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
CAPÍTULO II</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DO RECONHECIMENTO IGUAL PERANTE A LEI </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 84.
				A pessoa com deficiência tem assegurado o direito ao exercício de sua
				capacidade legal em igualdade de condições com as demais pessoas. § 1º
				Quando necessário, a pessoa com deficiência será submetida à curatela,
				conforme a lei. § 2º É facultado à pessoa com deficiência a adoção de
				processo de tomada de decisão apoiada. § 3º A definição de curatela de
				pessoa com deficiência constitui medida protetiva extraordinária,
				proporcional às necessidades e às circunstâncias de cada caso, e durará
				o menor tempo possível. § 4º Os curadores são obrigados a prestar,
				anualmente, contas de sua administração ao juiz, apresentando o balanço
				do respectivo ano. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 85. A curatela afetará tão somente os atos
				relacionados aos direitos de natureza patrimonial e negocial. § 1º A
				definição da curatela não alcança o direito ao próprio corpo, à
				sexualidade, ao matrimônio, à privacidade, à educação, à saúde, ao
				trabalho e ao voto. § 2º A curatela constitui medida extraordinária,
				devendo constar da sentença as razões e motivações de sua definição,
				preservados os interesses do curatelado. § 3º No caso de pessoa em
				situação de institucionalização, ao nomear curador, o juiz deve dar
				preferência a pessoa que tenha vínculo de natureza familiar, afetiva ou
				comunitária com o curatelado. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 86. Para emissão de documentos
				oficiais, não será exigida a situação de curatela da pessoa com
				deficiência. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 87. Em casos de relevância e urgência e a fim de
				proteger os interesses da pessoa com deficiência em situação de
				curatela, será lícito ao juiz, ouvido o Ministério Público, de oficio ou
				a requerimento do interessado, nomear, desde logo, curador provisório, o
				qual estará sujeito, no que couber, às disposições do Código de Processo
				Civil . </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título II </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DOS CRIMES E DAS INFRAÇÕES ADMINISTRATIVAS </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 88.
				Praticar, induzir ou incitar discriminação de pessoa em razão de sua
				deficiência: Pena - reclusão, de 1 (um) a 3 (três) anos, e multa. § 1º
				Aumenta-se a pena em 1/3 (um terço) se a vítima encontrar-se sob cuidado
				e responsabilidade do agente. § 2º Se qualquer dos crimes previstos no
				caput deste artigo é cometido por intermédio de meios de comunicação
				social ou de publicação de qualquer natureza: Pena - reclusão, de 2
				(dois) a 5 (cinco) anos, e multa. § 3º Na hipótese do § 2º deste artigo,
				o juiz poderá determinar, ouvido o Ministério Público ou a pedido deste,
				ainda antes do inquérito policial, sob pena de desobediência: I -
				recolhimento ou busca e apreensão dos exemplares do material
				discriminatório;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - interdição das respectivas mensagens ou páginas de
				informação na internet. § 4º Na hipótese do § 2º deste artigo, constitui
				efeito da condenação, após o trânsito em julgado da decisão, a
				destruição do material apreendido. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 89. Apropriar-se de ou desviar
				bens, proventos, pensão, benefícios, remuneração ou qualquer outro
				rendimento de pessoa com deficiência: Pena - reclusão, de 1 (um) a 4
				(quatro) anos, e multa. Parágrafo único. Aumenta-se a pena em 1/3 (um
				terço) se o crime é cometido:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - por tutor, curador, síndico,
				liquidatário, inventariante, testamenteiro ou depositário judicial; ou
				II - por aquele que se apropriou em razão de ofício ou de profissão.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 90. Abandonar pessoa com deficiência em hospitais, casas de saúde,
				entidades de abrigamento ou congêneres: Pena - reclusão, de 6 (seis)
				meses a 3 (três) anos, e multa. Parágrafo único. Na mesma pena incorre
				quem não prover as necessidades básicas de pessoa com deficiência quando
				obrigado por lei ou mandado. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 91. Reter ou utilizar cartão
				magnético, qualquer meio eletrônico ou documento de pessoa com
				deficiência destinados ao recebimento de benefícios, proventos, pensões
				ou remuneração ou à realização de operações financeiras, com o fim de
				obter vantagem indevida para si ou para outrem: Pena - detenção, de 6
				(seis) meses a 2 (dois) anos, e multa. Parágrafo único. Aumenta-se a
				pena em 1/3 (um terço) se o crime é cometido por tutor ou curador.
				</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título III </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">DISPOSIÇÕES FINAIS E TRANSITÓRIAS </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 92. É criado o
				Cadastro Nacional de Inclusão da Pessoa com Deficiência
				(Cadastro-Inclusão), registro público eletrônico com a finalidade de
				coletar, processar, sistematizar e disseminar informações
				georreferenciadas que permitam a identificação e a caracterização
				socioeconômica da pessoa com deficiência, bem como das barreiras que
				impedem a realização de seus direitos. § 1º O Cadastro-Inclusão será
				administrado pelo Poder Executivo federal e constituído por base de
				dados, instrumentos, procedimentos e sistemas eletrônicos. § 2º Os dados
				constituintes do Cadastro-Inclusão serão obtidos pela integração dos
				sistemas de informação e da base de dados de todas as políticas públicas
				relacionadas aos direitos da pessoa com deficiência, bem como por
				informações coletadas, inclusive em censos nacionais e nas demais
				pesquisas realizadas no País, de acordo com os parâmetros estabelecidos
				pela Convenção sobre os Direitos das Pessoas com Deficiência e seu
				Protocolo Facultativo. § 3º Para coleta, transmissão e sistematização de
				dados, é facultada a celebração de convênios, acordos, termos de
				parceria ou contratos com instituições públicas e privadas, observados
				os requisitos e procedimentos previstos em legislação específica. § 4º
				Para assegurar a confidencialidade, a privacidade e as liberdades
				fundamentais da pessoa com deficiência e os princípios éticos que regem
				a utilização de informações, devem ser observadas as salvaguardas
				estabelecidas em lei. § 5º Os dados do Cadastro-Inclusão somente poderão
				ser utilizados para as seguintes finalidades:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - formulação, gestão,
				monitoramento e avaliação das políticas públicas para a pessoa com
				deficiência e para identificar as barreiras que impedem a realização de
				seus direitos;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - realização de estudos e pesquisas. § 6º As
				informações a que se refere este artigo devem ser disseminadas em
				formatos acessíveis. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 93. Na realização de inspeções e de auditorias
				pelos órgãos de controle interno e externo, deve ser observado o
				cumprimento da legislação relativa à pessoa com deficiência e das normas
				de acessibilidade vigentes. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 94. Terá direito a auxílio-inclusão,
				nos termos da lei, a pessoa com deficiência moderada ou grave que: I -
				receba o benefício de prestação continuada previsto no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 20 da Lei nº
				8.742, de 7 de dezembro de 1993 , e que passe a exercer atividade
				remunerada que a enquadre como segurado obrigatório do RGPS;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - tenha
				recebido, nos últimos 5 (cinco) anos, o benefício de prestação
				continuada previsto no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 20 da Lei nº 8.742, de 7 de dezembro de 1993
				, e que exerça atividade remunerada que a enquadre como segurado
				obrigatório do RGPS. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 95. É vedado exigir o comparecimento de pessoa
				com deficiência perante os órgãos públicos quando seu deslocamento, em
				razão de sua limitação funcional e de condições de acessibilidade,
				imponha-lhe ônus desproporcional e indevido, hipótese na qual serão
				observados os seguintes procedimentos:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - quando for de interesse do
				poder público, o agente promoverá o contato necessário com a pessoa com
				deficiência em sua residência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - quando for de interesse da pessoa
				com deficiência, ela apresentará solicitação de atendimento domiciliar
				ou fará representar-se por procurador constituído para essa finalidade.
				Parágrafo único. É assegurado à pessoa com deficiência atendimento
				domiciliar pela perícia médica e social do Instituto Nacional do Seguro
				Social (INSS), pelo serviço público de saúde ou pelo serviço privado de
				saúde, contratado ou conveniado, que integre o SUS e pelas entidades da
				rede socioassistencial integrantes do Suas, quando seu deslocamento, em
				razão de sua limitação funcional e de condições de acessibilidade,
				imponha-lhe ônus desproporcional e indevido. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 96. O § 6º -A do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				135 da Lei nº 4.737, de 15 de julho de 1965 (Código Eleitoral) , passa a
				vigorar com a seguinte redação: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 135.
				.................................................................
				........................................................................................
				§ 6º -A. Os Tribunais Regionais Eleitorais deverão, a cada eleição,
				expedir instruções aos Juízes Eleitorais para orientá-los na escolha dos
				locais de votação, de maneira a garantir acessibilidade para o eleitor
				com deficiência ou com mobilidade reduzida, inclusive em seu entorno e
				nos sistemas de transporte que lhe dão acesso.
				....................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 97. A Consolidação das Leis do Trabalho (CLT), aprovada pelo
				Decreto-Lei nº 5.452, de 1º de maio de 1943 , passa a vigorar com as
				seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 428.
				..................................................................
				...........................................................................................
				§ 6º Para os fins do contrato de aprendizagem, a comprovação da
				escolaridade de aprendiz com deficiência deve considerar, sobretudo, as
				habilidades e competências relacionadas com a profissionalização.
				...........................................................................................
				§ 8º Para o aprendiz com deficiência com 18 (dezoito) anos ou mais, a
				validade do contrato de aprendizagem pressupõe anotação na CTPS e
				matrícula e frequência em programa de aprendizagem desenvolvido sob
				orientação de entidade qualificada em formação técnico-profissional
				metódica.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 433.
				..................................................................
				...........................................................................................
				I - desempenho insuficiente ou inadaptação do aprendiz, salvo para o
				aprendiz com deficiência quando desprovido de recursos de
				acessibilidade, de tecnologias assistivas e de apoio necessário ao
				desempenho de suas atividades;
				..................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 98. A Lei nº 7.853, de 24 de outubro de 1989 , passa a vigorar
				com as seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º As medidas judiciais destinadas à
				proteção de interesses coletivos, difusos, individuais homogêneos e
				individuais indisponíveis da pessoa com deficiência poderão ser
				propostas pelo Ministério Público, pela Defensoria Pública, pela União,
				pelos Estados, pelos Municípios, pelo Distrito Federal, por associação
				constituída há mais de 1 (um) ano, nos termos da lei civil, por
				autarquia, por empresa pública e por fundação ou sociedade de economia
				mista que inclua, entre suas finalidades institucionais, a proteção dos
				interesses e a promoção de direitos da pessoa com deficiência.
				.................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 8º Constitui crime punível com reclusão de 2 (dois) a 5
				(cinco) anos e multa:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - recusar, cobrar valores adicionais, suspender,
				procrastinar, cancelar ou fazer cessar inscrição de aluno em
				estabelecimento de ensino de qualquer curso ou grau, público ou privado,
				em razão de sua deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - obstar inscrição em concurso público
				ou acesso de alguém a qualquer cargo ou emprego público, em razão de sua
				deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - negar ou obstar emprego, trabalho ou promoção à
				pessoa em razão de sua deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - recusar, retardar ou dificultar
				internação ou deixar de prestar assistência médico-hospitalar e
				ambulatorial à pessoa com deficiência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - deixar de cumprir, retardar
				ou frustrar execução de ordem judicial expedida na ação civil a que
				alude esta Lei;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - recusar, retardar ou omitir dados técnicos
				indispensáveis à propositura da ação civil pública objeto desta Lei,
				quando requisitados. § 1º Se o crime for praticado contra pessoa com
				deficiência menor de 18 (dezoito) anos, a pena é agravada em 1/3 (um
				terço). § 2º A pena pela adoção deliberada de critérios subjetivos para
				indeferimento de inscrição, de aprovação e de cumprimento de estágio
				probatório em concursos públicos não exclui a responsabilidade
				patrimonial pessoal do administrador público pelos danos causados. § 3º
				Incorre nas mesmas penas quem impede ou dificulta o ingresso de pessoa
				com deficiência em planos privados de assistência à saúde, inclusive com
				cobrança de valores diferenciados. § 4º Se o crime for praticado em
				atendimento de urgência e emergência, a pena é agravada em 1/3 (um
				terço).” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 99. O </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 20 da Lei nº 8.036, de 11 de maio de 1990
				, passa a vigorar acrescido do seguinte inciso XVIII: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 20.
				......................................................................
				..............................................................................................
				XVIII - quando o trabalhador com deficiência, por prescrição, necessite
				adquirir órtese ou prótese para promoção de acessibilidade e de inclusão
				social.
				..................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 100. A Lei nº 8.078, de 11 de setembro de 1990 (Código de
				Defesa do Consumidor) , passa a vigorar com as seguintes alterações:
				“</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 6º
				.......................................................................
				............................................................................................
				Parágrafo único. A informação de que trata o inciso III do caput deste
				artigo deve ser acessível à pessoa com deficiência, observado o disposto
				em regulamento.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 43.
				......................................................................
				............................................................................................
				§ 6º Todas as informações de que trata o caput deste artigo devem ser
				disponibilizadas em formatos acessíveis, inclusive para a pessoa com
				deficiência, mediante solicitação do consumidor.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 101. A Lei
				nº 8.213, de 24 de julho de 1991 , passa a vigorar com as seguintes
				alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 16.
				...................................................................... I
				- o cônjuge, a companheira, o companheiro e o filho não emancipado, de
				qualquer condição, menor de 21 (vinte e um) anos ou inválido ou que
				tenha deficiência intelectual ou mental ou deficiência grave;
				............................................................................................
				III - o irmão não emancipado, de qualquer condição, menor de 21 (vinte e
				um) anos ou inválido ou que tenha deficiência intelectual ou mental ou
				deficiência grave;
				.................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 77.
				.....................................................................
				............................................................................................
				§ 2º
				..............................................................................
				............................................................................................
				II - para o filho, a pessoa a ele equiparada ou o irmão, de ambos os
				sexos, pela emancipação ou ao completar 21 (vinte e um) anos de idade,
				salvo se for inválido ou tiver deficiência intelectual ou mental ou
				deficiência grave;
				...................................................................................
				§ 4º (VETADO).
				...................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 93. (VETADO):</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - (VETADO);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - (VETADO);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - (VETADO); IV
				- (VETADO);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - (VETADO). § 1º A dispensa de pessoa com deficiência ou
				de beneficiário reabilitado da Previdência Social ao final de contrato
				por prazo determinado de mais de 90 (noventa) dias e a dispensa
				imotivada em contrato por prazo indeterminado somente poderão ocorrer
				após a contratação de outro trabalhador com deficiência ou beneficiário
				reabilitado da Previdência Social. § 2º Ao Ministério do Trabalho e
				Emprego incumbe estabelecer a sistemática de fiscalização, bem como
				gerar dados e estatísticas sobre o total de empregados e as vagas
				preenchidas por pessoas com deficiência e por beneficiários reabilitados
				da Previdência Social, fornecendo-os, quando solicitados, aos
				sindicatos, às entidades representativas dos empregados ou aos cidadãos
				interessados. § 3º Para a reserva de cargos será considerada somente a
				contratação direta de pessoa com deficiência, excluído o aprendiz com
				deficiência de que trata a Consolidação das Leis do Trabalho (CLT),
				aprovada pelo Decreto-Lei nº 5.452, de 1º de maio de 1943. § 4º
				(VETADO).” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 110-A. No ato de requerimento de benefícios
				operacionalizados pelo INSS, não será exigida apresentação de termo de
				curatela de titular ou de beneficiário com deficiência, observados os
				procedimentos a serem estabelecidos em regulamento.” </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 102. O </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 2º
				da Lei nº 8.313, de 23 de dezembro de 1991 , passa a vigorar acrescido
				do seguinte § 3º : “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 2º
				.........................................................................
				.............................................................................................
				§ 3º Os incentivos criados por esta Lei somente serão concedidos a
				projetos culturais que forem disponibilizados, sempre que tecnicamente
				possível, também em formato acessível à pessoa com deficiência,
				observado o disposto em regulamento.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 103. O </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 11 da Lei nº
				8.429, de 2 de junho de 1992 , passa a vigorar acrescido do seguinte
				inciso IX: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 11.
				.....................................................................
				............................................................................................
				IX - deixar de cumprir a exigência de requisitos de acessibilidade
				previstos na legislação.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 104. A Lei nº 8.666, de 21 de junho
				de 1993 , passa a vigorar com as seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º
				.....................................................................
				..........................................................................................
				§ 2º
				...........................................................................
				..........................................................................................
				V - produzidos ou prestados por empresas que comprovem cumprimento de
				reserva de cargos prevista em lei para pessoa com deficiência ou para
				reabilitado da Previdência Social e que atendam às regras de
				acessibilidade previstas na legislação.
				...........................................................................................
				§ 5º Nos processos de licitação, poderá ser estabelecida margem de
				preferência para:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - produtos manufaturados e para serviços nacionais
				que atendam a normas técnicas brasileiras; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - bens e serviços
				produzidos ou prestados por empresas que comprovem cumprimento de
				reserva de cargos prevista em lei para pessoa com deficiência ou para
				reabilitado da Previdência Social e que atendam às regras de
				acessibilidade previstas na legislação.
				...................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 66-A. As empresas enquadradas no inciso V do § 2º e no inciso
				II do § 5º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 3º desta Lei deverão cumprir, durante todo o período
				de execução do contrato, a reserva de cargos prevista em lei para pessoa
				com deficiência ou para reabilitado da Previdência Social, bem como as
				regras de acessibilidade previstas na legislação. Parágrafo único. Cabe
				à administração fiscalizar o cumprimento dos requisitos de
				acessibilidade nos serviços e nos ambientes de trabalho.” </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 105. O
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 20 da Lei nº 8.742, de 7 de dezembro de 1993 , passa a vigorar com
				as seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 20.
				......................................................................
				.............................................................................................
				§ 2º Para efeito de concessão do benefício de prestação continuada,
				considera-se pessoa com deficiência aquela que tem impedimento de longo
				prazo de natureza física, mental, intelectual ou sensorial, o qual, em
				interação com uma ou mais barreiras, pode obstruir sua participação
				plena e efetiva na sociedade em igualdade de condições com as demais
				pessoas.
				............................................................................................
				§ 9º Os rendimentos decorrentes de estágio supervisionado e de
				aprendizagem não serão computados para os fins de cálculo da renda
				familiar per capita a que se refere o § 3º deste artigo.
				.............................................................................................
				§ 11. Para concessão do benefício de que trata o caput deste artigo,
				poderão ser utilizados outros elementos probatórios da condição de
				miserabilidade do grupo familiar e da situação de vulnerabilidade,
				conforme regulamento.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 106. (VETADO). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 107. A Lei nº
				9.029, de 13 de abril de 1995 , passa a vigorar com as seguintes
				alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1º É proibida a adoção de qualquer prática
				discriminatória e limitativa para efeito de acesso à relação de
				trabalho, ou de sua manutenção, por motivo de sexo, origem, raça, cor,
				estado civil, situação familiar, deficiência, reabilitação profissional,
				idade, entre outros, ressalvadas, nesse caso, as hipóteses de proteção à
				criança e ao adolescente previstas no inciso XXXIII do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 7º da
				Constituição Federal. ” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º Sem prejuízo do prescrito no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				2º desta Lei e nos dispositivos legais que tipificam os crimes
				resultantes de preconceito de etnia, raça, cor ou deficiência, as
				infrações ao disposto nesta Lei são passíveis das seguintes cominações:
				..................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 4º
				........................................................................
				I - a reintegração com ressarcimento integral de todo o período de
				afastamento, mediante pagamento das remunerações devidas, corrigidas
				monetariamente e acrescidas de juros legais;
				....................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 108. O </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 35 da Lei nº 9.250, de 26 de dezembro de 1995 ,
				passa a vigorar acrescido do seguinte § 5º : “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 35.
				......................................................................
				.............................................................................................
				§ 5º Sem prejuízo do disposto no inciso IX do parágrafo único do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 3º
				da Lei nº 10.741, de 1º de outubro de 2003 , a pessoa com deficiência,
				ou o contribuinte que tenha dependente nessa condição, tem preferência
				na restituição referida no inciso III do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 4º e na alínea “c” do
				inciso II do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 8º .” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 109. A Lei nº 9.503, de 23 de setembro
				de 1997 (Código de Trânsito Brasileiro) , passa a vigorar com as
				seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 2º
				........................................................... Parágrafo
				único. Para os efeitos deste Código, são consideradas vias terrestres as
				praias abertas à circulação pública, as vias internas pertencentes aos
				condomínios constituídos por unidades autônomas e as vias e áreas de
				estacionamento de estabelecimentos privados de uso coletivo.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				86-A. As vagas de estacionamento regulamentado de que trata o inciso
				XVII do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 181 desta Lei deverão ser sinalizadas com as respectivas
				placas indicativas de destinação e com placas informando os dados sobre
				a infração por estacionamento indevido.” “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 147-A. Ao candidato com
				deficiência auditiva é assegurada acessibilidade de comunicação,
				mediante emprego de tecnologias assistivas ou de ajudas técnicas em
				todas as etapas do processo de habilitação. § 1º O material didático
				audiovisual utilizado em aulas teóricas dos cursos que precedem os
				exames previstos no </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 147 desta Lei deve ser acessível, por meio de
				subtitulação com legenda oculta associada à tradução simultânea em
				Libras. § 2º É assegurado também ao candidato com deficiência auditiva
				requerer, no ato de sua inscrição, os serviços de intérprete da Libras,
				para acompanhamento em aulas práticas e teóricas.” “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 154. (VETADO).”
				“</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 181.
				...................................................................
				..........................................................................................
				XVII -
				.........................................................................
				Infração - grave;
				.................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 110. O inciso VI e o § 1º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 56 da Lei nº 9.615, de 24 de
				março de 1998 , passam a vigorar com a seguinte redação: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 56.
				....................................................................
				...........................................................................................
				VI - 2,7% (dois inteiros e sete décimos por cento) da arrecadação bruta
				dos concursos de prognósticos e loterias federais e similares cuja
				realização estiver sujeita a autorização federal, deduzindo-se esse
				valor do montante destinado aos prêmios;
				.............................................................................................
				§ 1º Do total de recursos financeiros resultantes do percentual de que
				trata o inciso VI do caput , 62,96% (sessenta e dois inteiros e noventa
				e seis centésimos por cento) serão destinados ao Comitê Olímpico
				Brasileiro (COB) e 37,04% (trinta e sete inteiros e quatro centésimos
				por cento) ao Comitê Paralímpico Brasileiro (CPB), devendo ser
				observado, em ambos os casos, o conjunto de normas aplicáveis à
				celebração de convênios pela União.
				..................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 111. O </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1º da Lei nº 10.048, de 8 de novembro de 2000 ,
				passa a vigorar com a seguinte redação: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1º As pessoas com
				deficiência, os idosos com idade igual ou superior a 60 (sessenta) anos,
				as gestantes, as lactantes, as pessoas com crianças de colo e os obesos
				terão atendimento prioritário, nos termos desta Lei.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 112. A
				Lei nº 10.098, de 19 de dezembro de 2000 , passa a vigorar com as
				seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 2º
				.......................................................................
				I - acessibilidade: possibilidade e condição de alcance para utilização,
				com segurança e autonomia, de espaços, mobiliários, equipamentos
				urbanos, edificações, transportes, informação e comunicação, inclusive
				seus sistemas e tecnologias, bem como de outros serviços e instalações
				abertos ao público, de uso público ou privados de uso coletivo, tanto na
				zona urbana como na rural, por pessoa com deficiência ou com mobilidade
				reduzida;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - barreiras: qualquer entrave, obstáculo, atitude ou
				comportamento que limite ou impeça a participação social da pessoa, bem
				como o gozo, a fruição e o exercício de seus direitos à acessibilidade,
				à liberdade de movimento e de expressão, à comunicação, ao acesso à
				informação, à compreensão, à circulação com segurança, entre outros,
				classificadas em: a) barreiras urbanísticas: as existentes nas vias e
				nos espaços públicos e privados abertos ao público ou de uso coletivo;
				b) barreiras arquitetônicas: as existentes nos edifícios públicos e
				privados; c) barreiras nos transportes: as existentes nos sistemas e
				meios de transportes; d) barreiras nas comunicações e na informação:
				qualquer entrave, obstáculo, atitude ou comportamento que dificulte ou
				impossibilite a expressão ou o recebimento de mensagens e de informações
				por intermédio de sistemas de comunicação e de tecnologia da informação;
				III - pessoa com deficiência: aquela que tem impedimento de longo prazo
				de natureza física, mental, intelectual ou sensorial, o qual, em
				interação com uma ou mais barreiras, pode obstruir sua participação
				plena e efetiva na sociedade em igualdade de condições com as demais
				pessoas;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - pessoa com mobilidade reduzida: aquela que tenha, por
				qualquer motivo, dificuldade de movimentação, permanente ou temporária,
				gerando redução efetiva da mobilidade, da flexibilidade, da coordenação
				motora ou da percepção, incluindo idoso, gestante, lactante, pessoa com
				criança de colo e obeso;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - acompanhante: aquele que acompanha a pessoa
				com deficiência, podendo ou não desempenhar as funções de atendente
				pessoal;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - elemento de urbanização: quaisquer componentes de obras de
				urbanização, tais como os referentes a pavimentação, saneamento,
				encanamento para esgotos, distribuição de energia elétrica e de gás,
				iluminação pública, serviços de comunicação, abastecimento e
				distribuição de água, paisagismo e os que materializam as indicações do
				planejamento urbanístico;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - mobiliário urbano: conjunto de objetos
				existentes nas vias e nos espaços públicos, superpostos ou adicionados
				aos elementos de urbanização ou de edificação, de forma que sua
				modificação ou seu traslado não provoque alterações substanciais nesses
				elementos, tais como semáforos, postes de sinalização e similares,
				terminais e pontos de acesso coletivo às telecomunicações, fontes de
				água, lixeiras, toldos, marquises, bancos, quiosques e quaisquer outros
				de natureza análoga;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VIII - tecnologia assistiva ou ajuda técnica:
				produtos, equipamentos, dispositivos, recursos, metodologias,
				estratégias, práticas e serviços que objetivem promover a
				funcionalidade, relacionada à atividade e à participação da pessoa com
				deficiência ou com mobilidade reduzida, visando à sua autonomia,
				independência, qualidade de vida e inclusão social;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IX - comunicação:
				forma de interação dos cidadãos que abrange, entre outras opções, as
				línguas, inclusive a Língua Brasileira de Sinais (Libras), a
				visualização de textos, o Braille, o sistema de sinalização ou de
				comunicação tátil, os caracteres ampliados, os dispositivos multimídia,
				assim como a linguagem simples, escrita e oral, os sistemas auditivos e
				os meios de voz digitalizados e os modos, meios e formatos aumentativos
				e alternativos de comunicação, incluindo as tecnologias da informação e
				das comunicações;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> X - desenho universal: concepção de produtos,
				ambientes, programas e serviços a serem usados por todas as pessoas, sem
				necessidade de adaptação ou de projeto específico, incluindo os recursos
				de tecnologia assistiva.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º O planejamento e a urbanização
				das vias públicas, dos parques e dos demais espaços de uso público
				deverão ser concebidos e executados de forma a torná-los acessíveis para
				todas as pessoas, inclusive para aquelas com deficiência ou com
				mobilidade reduzida. Parágrafo único. O passeio público, elemento
				obrigatório de urbanização e parte da via pública, normalmente segregado
				e em nível diferente, destina-se somente à circulação de pedestres e,
				quando possível, à implantação de mobiliário urbano e de vegetação.”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 9º
				........................................................................
				Parágrafo único. Os semáforos para pedestres instalados em vias públicas
				de grande circulação, ou que deem acesso aos serviços de reabilitação,
				devem obrigatoriamente estar equipados com mecanismo que emita sinal
				sonoro suave para orientação do pedestre.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 10-A. A instalação
				de qualquer mobiliário urbano em área de circulação comum para pedestre
				que ofereça risco de acidente à pessoa com deficiência deverá ser
				indicada mediante sinalização tátil de alerta no piso, de acordo com as
				normas técnicas pertinentes.” “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 12-A. Os centros comerciais e os
				estabelecimentos congêneres devem fornecer carros e cadeiras de rodas,
				motorizados ou não, para o atendimento da pessoa com deficiência ou com
				mobilidade reduzida.” </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 113. A Lei nº 10.257, de 10 de julho de 2001
				(Estatuto da Cidade) , passa a vigorar com as seguintes alterações:
				“</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º
				......................................................................
				............................................................................................
				III - promover, por iniciativa própria e em conjunto com os Estados, o
				Distrito Federal e os Municípios, programas de construção de moradias e
				melhoria das condições habitacionais, de saneamento básico, das
				calçadas, dos passeios públicos, do mobiliário urbano e dos demais
				espaços de uso público;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - instituir diretrizes para desenvolvimento
				urbano, inclusive habitação, saneamento básico, transporte e mobilidade
				urbana, que incluam regras de acessibilidade aos locais de uso público;
				.................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 41.
				....................................................................
				...........................................................................................
				§ 3º As cidades de que trata o caput deste artigo devem elaborar plano
				de rotas acessíveis, compatível com o plano diretor no qual está
				inserido, que disponha sobre os passeios públicos a serem implantados ou
				reformados pelo poder público, com vistas a garantir acessibilidade da
				pessoa com deficiência ou com mobilidade reduzida a todas as rotas e
				vias existentes, inclusive as que concentrem os focos geradores de maior
				circulação de pedestres, como os órgãos públicos e os locais de
				prestação de serviços públicos e privados de saúde, educação,
				assistência social, esporte, cultura, correios e telégrafos, bancos,
				entre outros, sempre que possível de maneira integrada com os sistemas
				de transporte coletivo de passageiros.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 114. A Lei nº 10.406,
				de 10 de janeiro de 2002 (Código Civil) , passa a vigorar com as
				seguintes alterações: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 3º São absolutamente incapazes de exercer
				pessoalmente os atos da vida civil os menores de 16 (dezesseis) anos. I
				- (Revogado);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - (Revogado);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - (Revogado).” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 4º São
				incapazes, relativamente a certos atos ou à maneira de os exercer:
				.....................................................................................
				II - os ébrios habituais e os viciados em tóxico;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - aqueles que, por
				causa transitória ou permanente, não puderem exprimir sua vontade;
				.............................................................................................
				Parágrafo único . A capacidade dos indígenas será regulada por
				legislação especial.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 228.
				.....................................................................
				.............................................................................................
				II - (Revogado);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - (Revogado);
				.............................................................................................
				§ 1º
				..............................................................................
				§ 2º A pessoa com deficiência poderá testemunhar em igualdade de
				condições com as demais pessoas, sendo-lhe assegurados todos os recursos
				de tecnologia assistiva.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.518 . Até a celebração do
				casamento podem os pais ou tutores revogar a autorização.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				1.548.
				................................................................... I -
				(Revogado);
				....................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.550.
				..................................................................
				.............................................................................................
				§ 1º
				..............................................................................
				§ 2º A pessoa com deficiência mental ou intelectual em idade núbia
				poderá contrair matrimônio, expressando sua vontade diretamente ou por
				meio de seu responsável ou curador.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.557.
				................................................................
				............................................................................................
				III - a ignorância, anterior ao casamento, de defeito físico
				irremediável que não caracterize deficiência ou de moléstia grave e
				transmissível, por contágio ou por herança, capaz de pôr em risco a
				saúde do outro cônjuge ou de sua descendência;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - (Revogado).” (NR)
				“</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.767.
				.................................................................. I -
				aqueles que, por causa transitória ou permanente, não puderem exprimir
				sua vontade;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - (Revogado);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - os ébrios habituais e os viciados em
				tóxico;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - (Revogado);
				....................................................................................”
				(NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.768. O processo que define os termos da curatela deve ser
				promovido:
				.............................................................................................
				IV - pela própria pessoa.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.769 . O Ministério Público
				somente promoverá o processo que define os termos da curatela:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - nos
				casos de deficiência mental ou intelectual;
				............................................................................................
				III - se, existindo, forem menores ou incapazes as pessoas mencionadas
				no inciso II.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.771. Antes de se pronunciar acerca dos
				termos da curatela, o juiz, que deverá ser assistido por equipe
				multidisciplinar, entrevistará pessoalmente o interditando.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				1.772. O juiz determinará, segundo as potencialidades da pessoa, os
				limites da curatela, circunscritos às restrições constantes do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				1.782, e indicará curador. Parágrafo único. Para a escolha do curador, o
				juiz levará em conta a vontade e as preferências do interditando, a
				ausência de conflito de interesses e de influência indevida, a
				proporcionalidade e a adequação às circunstâncias da pessoa.” (NR) “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				1.775-A . Na nomeação de curador para a pessoa com deficiência, o juiz
				poderá estabelecer curatela compartilhada a mais de uma pessoa.” “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				1.777. As pessoas referidas no inciso I do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1.767 receberão todo o
				apoio necessário para ter preservado o direito à convivência familiar e
				comunitária, sendo evitado o seu recolhimento em estabelecimento que os
				afaste desse convívio.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 115. O </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título IV </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">do Livro IV da Parte
				Especial da Lei nº 10.406, de 10 de janeiro de 2002 (Código Civil) ,
				passa a vigorar com a seguinte redação: “</label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título IV </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">Da Tutela, da
				Curatela e da Tomada de Decisão Apoiada” </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 116. O </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">
Título IV </label></b-form-group>
<b-form-group class="text-label"><label tabindex="0">do Livro
				IV da Parte Especial da Lei nº 10.406, de 10 de janeiro de 2002 (Código
				Civil) , passa a vigorar acrescido do seguinte Capítulo III: “CAPÍTULO
				III Da Tomada de Decisão Apoiada </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1.783-A. A tomada de decisão
				apoiada é o processo pelo qual a pessoa com deficiência elege pelo menos
				2 (duas) pessoas idôneas, com as quais mantenha vínculos e que gozem de
				sua confiança, para prestar-lhe apoio na tomada de decisão sobre atos da
				vida civil, fornecendo-lhes os elementos e informações necessários para
				que possa exercer sua capacidade. § 1º Para formular pedido de tomada de
				decisão apoiada, a pessoa com deficiência e os apoiadores devem
				apresentar termo em que constem os limites do apoio a ser oferecido e os
				compromissos dos apoiadores, inclusive o prazo de vigência do acordo e o
				respeito à vontade, aos direitos e aos interesses da pessoa que devem
				apoiar. § 2º O pedido de tomada de decisão apoiada será requerido pela
				pessoa a ser apoiada, com indicação expressa das pessoas aptas a
				prestarem o apoio previsto no caput deste artigo. § 3º Antes de se
				pronunciar sobre o pedido de tomada de decisão apoiada, o juiz,
				assistido por equipe multidisciplinar, após oitiva do Ministério
				Público, ouvirá pessoalmente o requerente e as pessoas que lhe prestarão
				apoio. § 4º A decisão tomada por pessoa apoiada terá validade e efeitos
				sobre terceiros, sem restrições, desde que esteja inserida nos limites
				do apoio acordado. § 5º Terceiro com quem a pessoa apoiada mantenha
				relação negocial pode solicitar que os apoiadores contra-assinem o
				contrato ou acordo, especificando, por escrito, sua função em relação ao
				apoiado. § 6º Em caso de negócio jurídico que possa trazer risco ou
				prejuízo relevante, havendo divergência de opiniões entre a pessoa
				apoiada e um dos apoiadores, deverá o juiz, ouvido o Ministério Público,
				decidir sobre a questão. § 7º Se o apoiador agir com negligência,
				exercer pressão indevida ou não adimplir as obrigações assumidas, poderá
				a pessoa apoiada ou qualquer pessoa apresentar denúncia ao Ministério
				Público ou ao juiz. § 8º Se procedente a denúncia, o juiz destituirá o
				apoiador e nomeará, ouvida a pessoa apoiada e se for de seu interesse,
				outra pessoa para prestação de apoio. § 9º A pessoa apoiada pode, a
				qualquer tempo, solicitar o término de acordo firmado em processo de
				tomada de decisão apoiada. § 10. O apoiador pode solicitar ao juiz a
				exclusão de sua participação do processo de tomada de decisão apoiada,
				sendo seu desligamento condicionado à manifestação do juiz sobre a
				matéria. § 11. Aplicam-se à tomada de decisão apoiada, no que couber, as
				disposições referentes à prestação de contas na curatela.” </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 117. O
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1º da Lei nº 11.126, de 27 de junho de 2005 , passa a vigorar com a
				seguinte redação: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 1º É assegurado à pessoa com deficiência visual
				acompanhada de cão-guia o direito de ingressar e de permanecer com o
				animal em todos os meios de transporte e em estabelecimentos abertos ao
				público, de uso público e privados de uso coletivo, desde que observadas
				as condições impostas por esta Lei.
				.............................................................................................
				§ 2º O disposto no caput deste artigo aplica-se a todas as modalidades e
				jurisdições do serviço de transporte coletivo de passageiros, inclusive
				em esfera internacional com origem no território brasileiro.” (NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				118. O inciso IV do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 46 da Lei nº 11.904, de 14 de janeiro de 2009 ,
				passa a vigorar acrescido da seguinte alínea “k”: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 46.
				......................................................................
				...........................................................................................
				IV -
				..............................................................................
				...........................................................................................
				k) de acessibilidade a todas as pessoas.
				.................................................................................”
				(NR) </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 119. A Lei nº 12.587, de 3 de janeiro de 2012 , passa a
				vigorar acrescida do seguinte </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 12-B: “</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 12-B. Na outorga de
				exploração de serviço de táxi, reservar-se-ão 10% (dez por cento) das
				vagas para condutores com deficiência. § 1º Para concorrer às vagas
				reservadas na forma do caput deste artigo, o condutor com deficiência
				deverá observar os seguintes requisitos quanto ao veículo utilizado: I -
				ser de sua propriedade e por ele conduzido; e</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - estar adaptado às
				suas necessidades, nos termos da legislação vigente. § 2º No caso de não
				preenchimento das vagas na forma estabelecida no caput deste artigo, as
				remanescentes devem ser disponibilizadas para os demais concorrentes.”
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 120. Cabe aos órgãos competentes, em cada esfera de governo, a
				elaboração de relatórios circunstanciados sobre o cumprimento dos prazos
				estabelecidos por força das Leis nº 10.048, de 8 de novembro de 2000 , e
				nº 10.098, de 19 de dezembro de 2000 , bem como o seu encaminhamento ao
				Ministério Público e aos órgãos de regulação para adoção das
				providências cabíveis. Parágrafo único. Os relatórios a que se refere o
				caput deste artigo deverão ser apresentados no prazo de 1 (um) ano a
				contar da entrada em vigor desta Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 121. Os direitos, os prazos e
				as obrigações previstos nesta Lei não excluem os já estabelecidos em
				outras legislações, inclusive em pactos, tratados, convenções e
				declarações internacionais aprovados e promulgados pelo Congresso
				Nacional, e devem ser aplicados em conformidade com as demais normas
				internas e acordos internacionais vinculantes sobre a matéria. Parágrafo
				único. Prevalecerá a norma mais benéfica à pessoa com deficiência. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art.
				122. Regulamento disporá sobre a adequação do disposto nesta Lei ao
				tratamento diferenciado, simplificado e favorecido a ser dispensado às
				microempresas e às empresas de pequeno porte, previsto no § 3º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				1º da Lei Complementar nº 123, de 14 de dezembro de 2006 . </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 123.
				Revogam-se os seguintes dispositivos: (Vigência)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - o inciso II do § 2º
				do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1º da Lei nº 9.008, de 21 de março de 1995 ;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - os incisos I,
				II e III do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 3º da Lei nº 10.406, de 10 de janeiro de 2002 (Código
				Civil);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - os incisos II e III do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 228 da Lei nº 10.406, de 10 de
				janeiro de 2002 (Código Civil);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - o inciso I do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1.548 da Lei nº
				10.406, de 10 de janeiro de 2002 (Código Civil);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> V - o inciso IV do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				1.557 da Lei nº 10.406, de 10 de janeiro de 2002 (Código Civil);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VI - os
				incisos II e IV do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 1.767 da Lei nº 10.406, de 10 de janeiro de 2002
				(Código Civil);</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> VII - os arts. 1.776 e 1.780 da Lei nº 10.406, de 10 de
				janeiro de 2002 (Código Civil). </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 124. O § 1º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 2º desta Lei
				deverá entrar em vigor em até 2 (dois) anos, contados da entrada em
				vigor desta Lei. </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 125. Devem ser observados os prazos a seguir
				discriminados, a partir da entrada em vigor desta Lei, para o
				cumprimento dos seguintes dispositivos:</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> I - incisos I e II do § 2º do
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 28 , 48 (quarenta e oito) meses;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - § 6º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 44 , 48
				(quarenta e oito) meses;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - § 6º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 44, 60 (sessenta) meses;
				(Redação dada pela Medida Provisória nº 917, de 2019)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - § 6º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art.
				44, 60 (sessenta) meses; (Redação dada pela Lei nº 14.009, de 2020) II -
				§ 6º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 44, 84 (oitenta e quatro) meses; (Redação dada pela Medida
				Provisória nº 1.025, de 2020)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> II - § 6º do </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 44, 84 (oitenta e
				quatro) meses; (Redação dada pela Lei nº 14.159, de 2021)</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> III - </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 45
				, 24 (vinte e quatro) meses;</label></b-form-group> 
                <b-form-group class="text-label"><label tabindex="0"> IV - </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">art. 49 , 48 (quarenta e oito) meses.
				</label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 126. Prorroga-se até 31 de dezembro de 2021 a vigência da Lei nº
				8.989, de 24 de fevereiro de 1995 . </label></b-form-group>
                <b-form-group class="text-label"><label tabindex="0">Art. 127. Esta Lei entra em vigor
				após decorridos 180 (cento e oitenta) dias de sua publicação oficial .</label></b-form-group>
				<b-form-group class="text-label"><label tabindex="0">Brasília, 6 de julho de 2015; 194º da Independência e 127º da República.</label></b-form-group>
				<b-form-group class="text-label"><label tabindex="0">DILMA ROUSSEF <br>
				Marivaldo de Castro Pereira <br>
				Joaquim Vieira Ferreira Levy<br>
				Renato Janine Ribeiro <br>
				Armando Monteiro <br>
				Nelson Barbosa <br>
				Gilberto Kassab<br>
				Luis Inácio Lucena Adams <br>
				Gilberto José Spier Vargas <br>
				Guilherme Afif Domingos</label></b-form-group>
				<b-form-group class="text-label"><label tabindex="0">Este texto não substitui o publicado no DOU de 7.7.2015 *</label></b-form-group>
            </b-col>
			</b-row>
		</b-card>
		<div align="right">
			<b-col xl="6">
				<b-button
					outline
					size="md"
					@click="voltar()"
					variant="secondary"
					title="Voltar para tela inicial"
				>
					<i class="fas fa-arrow-left" /> Voltar
				</b-button>
			</b-col>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		voltar() {
			this.$router.push({ name: "legislacoes" });
		},
	},
};
</script>
