






import { Component, Vue } from 'vue-property-decorator';
import ListarAvaliarEstabelecimento from './listar-avaliar-estabelecimento.vue';

@Component({
    components: {
        ListarAvaliarEstabelecimento,
    }
})
export default class AvaliarEstabelecimentoContainer extends Vue {
}
