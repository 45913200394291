import { PapelEstabelecimentoEnum } from "./enum/papel-estabelecimento";

export class ArquivoEstabelecimento {
    id: Number | null;
    nome: String | null;
    conteudo: File | null;;
    data: Date;
    miniatura?: String;
    descricao?: String;
    fonte?: String;
    autor?: String;

    constructor (id: Number | null, nome: String, conteudo: File | null, descricao: String, fonte: String, autor: String) {
        this.id = id;
        this.nome = nome;
        this.conteudo = conteudo;
        this.data = new Date();
        this.descricao = descricao;
        this.fonte = fonte;
        this.autor = autor;
    }
}