var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c(
                "fieldset",
                { staticClass: "blocoFieldset" },
                [
                  _c("legend", [
                    _vm._v("\n                    SAC\n                ")
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "5",
                            xl: "5"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Situação",
                                "label-for": "situacao"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "situacao", disabled: "" },
                                model: {
                                  value: _vm.sacProp.situacao,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sacProp, "situacao", $$v)
                                  },
                                  expression: "sacProp.situacao"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: { label: "Nome", "label-for": "nome" }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "nome", disabled: "" },
                                model: {
                                  value: _vm.sacProp.nome,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sacProp, "nome", $$v)
                                  },
                                  expression: "sacProp.nome"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Assunto",
                                "label-for": "assunto"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "assunto", disabled: "" },
                                model: {
                                  value: _vm.sacProp.assunto.descricao,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.sacProp.assunto,
                                      "descricao",
                                      $$v
                                    )
                                  },
                                  expression: "sacProp.assunto.descricao"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.sacProp.usuarioResponsavel !== undefined
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                                xl: "12"
                              }
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "text-label",
                                  attrs: {
                                    label: "Responsável",
                                    "label-for": "responsavel"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "responsavel", disabled: "" },
                                    model: {
                                      value:
                                        _vm.sacProp.usuarioResponsavel.nome,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sacProp.usuarioResponsavel,
                                          "nome",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sacProp.usuarioResponsavel.nome"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Mensagem:",
                                "label-for": "mensagem"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "mensagem",
                                  size: "lg",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.sacProp.mensagem,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sacProp, "mensagem", $$v)
                                  },
                                  expression: "sacProp.mensagem"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c("contato-component", {
                            attrs: {
                              listaContatosProp: _vm.sacProp.contatos,
                              isExibirProp: true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.sacProp.anotacoes != null
                ? _c("historico-sac-component", {
                    attrs: { anotacoesProp: _vm.sacProp.anotacoes }
                  })
                : _vm._e(),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para tela de listagem de SAC"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Voltar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "far fa-comments" }),
      _vm._v(" Exibir SAC\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }