import { Message } from '../model/generic/message';
import axios, { AxiosResponse } from 'axios'
import store from '@/store/store'

let instanceAxios = axios.create()
const backURL = process.env.VUE_APP_ROOT_API + "api/"

// interceptor on request
instanceAxios.interceptors.request.use(function (request) {
  if (request !== undefined && request.headers !== undefined) {
    request.headers.post = corrigeHeader(request.headers.post)
    request.headers.get = corrigeHeader(request.headers.get)
    request.headers.put = corrigeHeader(request.headers.put)
    request.headers.delete = corrigeHeader(request.headers.delete)
  }
  return request
},
  function (error) {
    return Promise.reject(error)
  })

// interceptor on response
instanceAxios.interceptors.response.use(function (response) {
  return response
},
  function (error) {
    if (error === 'Error: Network Error' || error.response === undefined) {
      var networkError = {
        response: {
          data: {
            message: 'Falha na comunicação!'
          }
        }
      }
      return Promise.reject(networkError)
    }

    if (error.response !== undefined) {
      let message;
      if(error.response.data !== undefined && error.response.data.mensagem !== undefined){
        message = error.response.data.mensagem;
      } else {
        switch (error.response.status) {
          case 400:
            message = '400 - Requisição inválida.';
            break;
          case 401:
            message = '401 - Acesso não autorizado.';
            break;
          case 403:
            message = '403 - Sua sessão foi desconectada, realize o login novamente.';
            break;
          case 404:
            message = '404 - Algum recurso necessário para execução desta funcionalidade não foi encontrado.';
            break;
          default:
            message = 'Ocorreu um erro inesperado.'
            break;
        }
      }

      error = {
          response: {
            data: {
              status: error.response.status,
              message: message
            }
          }
        }
    }
    
    return Promise.reject(error)
  })

function corrigeHeader(header) {
  header['consumerId'] = process.env.VUE_APP_NEOCEP_API_KEY
  return header
}

class RestUtilCepService {

  doGetCEP(url: string, ignorarMensagem?: Boolean): Promise<AxiosResponse | null> {
    return instanceAxios.get(`${process.env.VUE_APP_NEOCEP_API}${url}`)
      .then(resp => { 
        // console.log('retorno CEP: ', resp.data);
        return resp.data
       })
      .catch(err => {
        if (ignorarMensagem == undefined || ignorarMensagem == false) {
          store.commit('setMessages', new Message(err.response.data.message, 'danger'));
        }
        return null;
      });
  }
}

export default new RestUtilCepService();
