











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TipoDeficiencia } from '@/model/tipo-deficiencia';
import { TipoDeficienciaFilter } from '@/model/filter/tipo-deficiencia-filter';
import tipoDeficienciaService from '@/api/tipo-deficiencia.service';

@Component
export default class TipoDeficienciaComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public tiposDeficienciaVinculadoProp!: Array<TipoDeficiencia>;

    public showInclusao: Boolean = false;
    
    public tiposDeficienciaGeral: Array<TipoDeficiencia> = Array.of();
    public tiposDeficienciaEspecifica: Array<TipoDeficiencia>;
        
    public tipoDeficienciaGeralSelecionada: TipoDeficiencia | null = null;
    public tipoDeficienciaEspecificaSelecionada: TipoDeficiencia | null = null;

    public tipoDeficienciaExcluir: TipoDeficiencia | null = null;

    public tiposDeficienciaVinculado: Array<TipoDeficiencia> = Array.of();

    //campos da grid
    public campos: Array<Object> = [
        {
            key: 'pai.descricao',
            label: 'Deficiência Geral',
            sortable: false
        },
        {
            key: 'descricao',
            label: 'Deficiência Específica',
            sortable: false
        }];

    public currentPage: Number = 1;
    public perPage: Number = 50;
    public totalRows: Number = 0;

    constructor () {
        super();
        if (!this.isExibirProp) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }
        
        this.tiposDeficienciaGeral = Array.of();
        this.tiposDeficienciaEspecifica = Array.of();

        this.carregarDeficienciaGeral ();
        this.tiposDeficienciaVinculado = this.tiposDeficienciaVinculadoProp;
    }

    iniciarIncluir () {
        this.showInclusao = true;
    }

    async carregarDeficienciaGeral () {
        let filtro = new TipoDeficienciaFilter("", true)
        filtro.gerais = true;
        filtro.subCategorias = true;
        
        const retorno = await tipoDeficienciaService.listarTiposDeficiencia(filtro, false);
        if (retorno != null){
            this.tiposDeficienciaGeral = retorno.registros;
        }
    }

    async carregarDeficienciaEspecifica () {
        this.tiposDeficienciaEspecifica = Array.of()

        this.tiposDeficienciaGeral.forEach(geral => {
            if (geral.subCategorias) {
                geral.subCategorias.forEach(sub => {
                    if (this.tipoDeficienciaGeralSelecionada && sub.pai && sub.pai.id === this.tipoDeficienciaGeralSelecionada.id)
                        this.tiposDeficienciaEspecifica.push(sub)
                })
            }
        })

        let remover: Array<TipoDeficiencia> = Array.of();
        this.tiposDeficienciaVinculado.forEach(tipoVinculado => {
            const index = this.tiposDeficienciaEspecifica.indexOf(tipoVinculado, 0);
            if (index > -1) {
                remover.push(tipoVinculado)
            }
        })
        remover.forEach(rem => this.removerTipoDeficiencia(rem));
    }

    removerTipoDeficiencia (tip: TipoDeficiencia) {
        if (this.tiposDeficienciaVinculado != null) {
            const index = this.tiposDeficienciaEspecifica.indexOf(tip, 0);
            if (index > -1) {
                this.tiposDeficienciaEspecifica.splice(index, 1);
            }
        }
    }

    incluir () : void {
        if (this.tiposDeficienciaVinculado == null) {
            this.tiposDeficienciaVinculado = Array.of<TipoDeficiencia>();
        }

        if (this.tipoDeficienciaEspecificaSelecionada != null) {
            this.tiposDeficienciaVinculado.push(this.tipoDeficienciaEspecificaSelecionada);
            this.$emit('atualizarTiposDeficiencia', this.tiposDeficienciaVinculado);
            this.tipoDeficienciaEspecificaSelecionada = null;
            this.carregarDeficienciaEspecifica();
            this.showInclusao = false;

            if (this.$refs.tabDeficiencia instanceof HTMLElement) {
                this.$refs.tabDeficiencia.focus();
            }
        }
    }

    async excluir (tipoDeficienciaExcluir: TipoDeficiencia) {
        const index = this.tiposDeficienciaVinculado.indexOf(tipoDeficienciaExcluir, 0);
        if (index > -1) {
            this.tiposDeficienciaVinculado.splice(index, 1);
            await this.carregarDeficienciaEspecifica();
            this.$emit('atualizarTiposDeficiencia', this.tiposDeficienciaVinculado);
        }

        if (this.$refs.tabDeficiencia instanceof HTMLElement) {
            this.$refs.tabDeficiencia.focus();
        }
    }
}
