import restUtil from './rest.util.service';

class DocsService {

    async downloadArquivo (idArquivo): Promise<Object|null> {
        return restUtil.download<Object>('v1/pessoa/docs/'+idArquivo);
    }

}

export default new DocsService();