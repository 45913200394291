
import moment from 'moment'

export default {

  filters: {
    mascaraCpf (valor) {
      const arr = valor.split('')
      arr.splice(3, 0, '.')
      arr.splice(7, 0, '.')
      arr.splice(11, 0, '-')
      return arr.join('')
    },
    mascaraDocumento (documentId) {
      if (documentId != null) {
        documentId = typeof documentId !== 'string' ? documentId.toString() : documentId
        if (documentId.length === 14) {
          documentId = documentId.padStart(14, '0')
          documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        } else {
          documentId = documentId.padStart(11, '0')
          documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        }
      }
      return documentId
    },
    mascaraDataHora (data) {
      return data == null ? 'Indeterminado' : moment(data).format('DD/MM/YYYY HH:mm')
    },
    mascaraData (data) {
      return data == null ? 'Indeterminado' : moment(data).format('DD/MM/YYYY')
    }
  },
  methods: {
    formatarData (data) {
      return moment(data).format('DD/MM/YYYY')
    },
    formatarDataHora (data) {
      return data == null ? 'Indeterminado' : moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    mascaraDocumento (documentId) {
      if (documentId != null) {
        documentId = typeof documentId !== 'string' ? documentId.toString() : documentId
        if (documentId.length === 14) {
          documentId = documentId.padStart(14, '0')
          documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        } else {
          documentId = documentId.padStart(11, '0')
          documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        }
      }
      return documentId
    }
  }
}
