

































































































































import { Component, Inject, Vue, Prop } from 'vue-property-decorator';
import { Endereco } from '@/model/endereco';
import enderecoService from '@/api/endereco.service';
import { Validator } from 'vee-validate';
import { PagingList } from '@/model/generic/paging-list';
import { Municipio } from '@/model/municipio';

@Component
export default class EnderecoComponent extends Vue {

    @Inject() parentValidator!: Validator;
    @Prop() public showCollapseProp!: Boolean;
    @Prop() public isExibirProp!: Boolean;
    @Prop() public enderecoProp!: Endereco;

    public cepEncontrado: Boolean;
    public showContent: Boolean = true;

    public estados: PagingList<String> | null;
    public municipios: Municipio [] | null;
    public estado: String | null = null;
    

    constructor () {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);
        if (this.parentValidator != null) {
            this.$validator = this.parentValidator;
        }
        this.estados = null;
        this.municipios = null;
        this.carregaEstados();
        this.estado = this.enderecoProp.municipio != null ? this.enderecoProp.municipio.uf : null;
        this.cepEncontrado = this.estado != null
    }

    async carregaEstados() {
        this.estado = null;
        this.estados = await enderecoService.listarUFs();
        this.carregaMunicipios();
    }

    async carregaMunicipios() {
        if (this.estado != null) {
            let retorno = await enderecoService.listarMunicipios(this.estado);
            if (retorno != null && retorno.registros != null) {
                this.municipios = retorno.registros;
            }
        } else {
            this.municipios = null
        }
    }

    async buscarEndereco () {
        await this.$validator.validate("cep").then(res => { 
            if ("80000-000" != this.enderecoProp.cep && res) {
                enderecoService.obterPorCep(this.enderecoProp.cep)
                .then(retorno => {
                    if(retorno != null) {
                        this.enderecoProp.bairro = retorno.bairro;
                        this.enderecoProp.logradouro = retorno.logradouro;
                        this.enderecoProp.numero = "";
                        this.enderecoProp.complemento = "";
                        this.enderecoProp.municipio = retorno.municipio;
                        this.$emit('changeMunicipio');
                        this.cepEncontrado = true
                    } else {
                        this.enderecoProp.bairro = '';
                        this.enderecoProp.logradouro = '';
                        this.enderecoProp.municipio = null;
                        this.$emit('changeMunicipio');
                        this.cepEncontrado = false
                        this.estado = null;
                        this.carregaMunicipios()
                    }
                    this.$validator.reset()
                });
            }
        });
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }

}
