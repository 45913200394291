import store from '@/store/store';
import { Message } from './../model/generic/message';
import restUtil from './rest.util.service';
import { Pessoa } from '@/model/pessoa';
import { LaudoPessoa } from '@/model/laudo-pessoa';
import { PessoaFilter } from '@/model/filter/pessoa-filter';
import { PagingList } from '@/model/generic/paging-list';

class PessoaService {

    async obter (): Promise<Pessoa | null> {
        let retorno = await restUtil.doGetWithoutMessage<Pessoa>('v1/pessoa');
        if (retorno != null && retorno.data != null) {
          return retorno.data;
        }
        return null;
    }

    async obterPorCpf (cpf: String, ignorarMensagem?: Boolean): Promise<Pessoa | null> {
        let retorno = await restUtil.doGet<Pessoa>('v1/pessoa/cpf/' + cpf, ignorarMensagem);
        if (retorno != null && retorno.data != null) {
          return retorno.data;
        }
        return null;
    }

    async obterDadosCentralPorCpf (cpf: String, ignorarMensagem?: Boolean): Promise<Pessoa|null> {
        try{
            let retorno = await restUtil.doGet<Pessoa>('v1/centralseguranca/cpf/' + cpf, ignorarMensagem);
            if (retorno != null && retorno.data != null) {
              return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

    async listarPessoas (filter: PessoaFilter): Promise<PagingList<Pessoa>|null> {
        try{
            let retorno = await restUtil.doPost<PagingList<Pessoa>>('v1/pessoa/search', filter);
            if (retorno != null && retorno.data != null) {
                if (retorno.data.totalRegistros == 0) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

    async obterCompleto (idPessoa: Number): Promise<Pessoa | null> {
        let retorno = await restUtil.doGet<Pessoa>('v1/pessoa/'+idPessoa);
        if (retorno != null && retorno.data != null) {
          return retorno.data;
        }
        return null;
    }
 
    async obterLogadoCompleto (): Promise<Pessoa | null> {
       let retorno = await this.obter();
       if (retorno == null || retorno.id == null) {
          return null;
       }
       return await this.obterCompleto(retorno.id);
    }

    async salvar (pessoa: Pessoa) {
        let retorno;
        if (pessoa.id == null){
            retorno = await restUtil.doPost<Pessoa>('v1/pessoa', pessoa);
        } else {
            pessoa.origem = "PR_ACESSIVEL";
            retorno = await restUtil.doPut<Pessoa>('v1/pessoa/'+pessoa.id, pessoa);
        }
        return retorno;
    }

    async salvarFlagDeficienteVisual (pessoa: Pessoa) {
        return await restUtil.doPut<Pessoa>('v1/pessoa/'+pessoa.id+'/flagDeficienteVisual', pessoa);
    }

    enviarArquivo (arquivo: File, cpf: String): Promise<Object|null> {
        let body = new FormData;
        body.append("file", arquivo)
        body.append("name", arquivo.name)

        return restUtil.doPost<Object>(`v1/pessoa/files/${cpf}`, body);
    }

    async downloadArquivo (arquivo: LaudoPessoa): Promise<Object|null> {
        return restUtil.download<Object>('v1/pessoa/files/'+arquivo.id);
    }

    async avaliaLaudo (laudo: LaudoPessoa): Promise<LaudoPessoa|null>{
        let retorno = await restUtil.doPut<LaudoPessoa>('v1/pessoa/laudo/'+laudo.id, laudo);
        if (retorno != null) {
            return retorno.data
        }
        return null;
    }

    async desvincular (cpf: String, observacao?: String) {
        await restUtil.doPut<void>('v1/pessoa/desvincular', { cpf: cpf, observacao: observacao });
    }

    async excluir (id: Number) {
      const retorno = await restUtil.doDelete('v1/pessoa/'+id);
      if (retorno != null) {
        store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
      }
      return retorno;
 }

}

export default new PessoaService();