






import { Component, Vue } from 'vue-property-decorator';
import ListarTipoDeficiencia from './listar-tipo-deficiencia.vue';
import IncluirTipoDeficiencia from './incluir-tipo-deficiencia.vue';
import EditarTipoDeficiencia from './editar-tipo-deficiencia.vue';
import ExibirTipoDeficiencia from './exibir-tipo-deficiencia.vue';

@Component({
    components: {
        ListarTipoDeficiencia,
        IncluirTipoDeficiencia,
        EditarTipoDeficiencia,
        ExibirTipoDeficiencia
    }
})
export default class TipoDeficienciaContainer extends Vue {
}
