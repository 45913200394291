export enum EscolaridadeEnum {
    ANALFABETO = "Analfabeto",
    FUNDAMENTAL_INCOMPLETO = "Ensino Fundamental incompleto",
    FUNDAMENTAL_COMPLETO = "Ensino Fundamental",
    MEDIO_INCOMPLETO = "Ensino Médio incompleto",
    MEDIO_COMPLETO = "Ensino Médio",
    GRADUACAO_INCOMPLETA = "Graduação incompleta",
    GRADUACAO_COMPLETA = "Graduação",
    POSGRADUACAO_INCOMPLETA = "Pós-graduação incompleta",
    POSGRADUACAO_COMPLETA = "Pós-graduação",
    MESTRADO_INCOMPLETO = "Mestrado incompleto",
    MESTRADO_COMPLETO = "Mestrado",
    DOUTORADO_INCOMPLETO =  "Doutorado incompleto",
    DOUTORADO_COMPLETO = "Doutorado"
}