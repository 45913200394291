export class RecursoAcessibilidade {
    id: Number|null;
    nome: String;
    descricao: String;

    constructor(id: Number|null, nome: String, descricao: String) {
        this.id = id;
        this.nome = nome;
        this.descricao = descricao;
    }
}