import { Municipio } from './../municipio';
import { TipoDeficiencia } from '../tipo-deficiencia';

export class BeneficioFilter {
    descricao: String;
    tiposDeficiencia?: Array<TipoDeficiencia>|null;
    tipoBeneficio?: String;
    estado?: String;
    municipio?: Municipio;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;
    cadastroPessoa: boolean;

    constructor(descricao: String, trazerTodos: boolean, cadastroPessoa?: boolean, 
                tiposDeficiencia?: Array<TipoDeficiencia>|null, tipoBeneficio?: String, municipio?: Municipio) {

        this.descricao = descricao;
        this.trazerTodos = trazerTodos;
        this.tiposDeficiencia = tiposDeficiencia;
        this.tipoBeneficio = tipoBeneficio;
        if (municipio !== undefined) {
            this.municipio = municipio;
            this.estado = municipio.uf;
        } else {
            this.municipio = new Municipio(null, "", "PR");
            this.estado = undefined
        }
        this.campoOrdenacao = "ben.descricao";
        this.ascendente = true;
        this.cadastroPessoa = cadastroPessoa !== undefined ? cadastroPessoa : false;
    }
}