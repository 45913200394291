var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-1", attrs: { "no-body": "" } },
    [
      _vm.menu.children
        ? [
            _c(
              "b-card-header",
              { attrs: { "header-tag": "header", role: "tab" } },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: "accordion-" + _vm.menu.name,
                        expression: "'accordion-' + menu.name"
                      }
                    ],
                    staticClass: "has-children",
                    attrs: { block: "", variant: "link" }
                  },
                  [
                    _c("div", { staticClass: "menu-icon" }, [
                      _c("i", { class: _vm.menu.icon })
                    ]),
                    _vm.menu.children
                      ? _c("span", { staticClass: "menu-label" }, [
                          _vm._v(_vm._s(_vm.menu.name))
                        ])
                      : _vm._e(),
                    _vm.menu.badge
                      ? _c(
                          "b-badge",
                          {
                            attrs: { pill: "", variant: _vm.menu.badge.variant }
                          },
                          [_vm._v(_vm._s(_vm.menu.badge.descricao))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm.menu.children
              ? _c(
                  "b-collapse",
                  {
                    attrs: {
                      id: _vm.accordion,
                      accordion: _vm.menu.name,
                      role: "tabpanel"
                    }
                  },
                  [_c("SubItem", { attrs: { itens: _vm.menu.children } })],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      !_vm.menu.children
        ? [
            _c(
              "b-card-header",
              { attrs: { "header-tag": "header", role: "tab" } },
              [
                _c(
                  "b-button",
                  { attrs: { block: "", variant: "link" } },
                  [
                    _c("div", { staticClass: "menu-icon" }, [
                      _c("i", { class: _vm.menu.icon })
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "menu-label",
                        attrs: { to: _vm.menu.url, tag: "span" }
                      },
                      [_vm._v(_vm._s(_vm.menu.name))]
                    ),
                    _vm.menu.badge
                      ? _c(
                          "b-badge",
                          {
                            attrs: { pill: "", variant: _vm.menu.badge.variant }
                          },
                          [_vm._v(_vm._s(_vm.menu.badge.descricao))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }