













































































































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router/router';
import { RecursoAcessibilidadeFilter } from '../../model/filter/recurso-acessibilidade-filter';
import { RecursoAcessibilidade } from '../../model/recurso-acessibilidade';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';

@Component
export default class ListarRecursoAcessibilidade extends Vue {

    private filtro: RecursoAcessibilidadeFilter;
    private recursos: Array<RecursoAcessibilidade>;
    private recursoSelecionado: RecursoAcessibilidade = new RecursoAcessibilidade(null, "","");

    private campos: Array<Object> = [
      {
        key: 'nome',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'descricao',
        label: 'Descrição',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
      {
        key: 'acoes',
        label: 'Exibir',
        tdClass: 'acoes-1'
      }];
    private currentPage: Number = 1;
    private perPage: Number = 5;
    private pesquisando: boolean = false;
    private totalRows: Number = 0;
    
    constructor(){
        super();
        this.filtro = new RecursoAcessibilidadeFilter("", "", true);
        this.recursos = Array<RecursoAcessibilidade>();
    }

    mounted (): void {
        this.limpar();
        this.pesquisar();
    }

    limpar () {
        this.filtro = new RecursoAcessibilidadeFilter("", "", true);
        this.recursos = Array<RecursoAcessibilidade>();
    }

    async pesquisar () {
        const retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(this.filtro, false);
        if (retorno != null) {
           this.recursos = retorno.registros;
           this.totalRows = retorno.totalRegistros;
        }
    }

    iniciarIncluir () {
        router.push({
            name: 'editar-recurso-acessibilidade'
        });
    }

    iniciarExibir (rec: RecursoAcessibilidade) {
        router.push({
            name: 'exibir-recurso-acessibilidade',
            params: { recursoProp: rec as any }
        });
    }

    iniciarAlterar (rec: RecursoAcessibilidade) {
        this.$router.push({
            name: 'editar-recurso-acessibilidade',
            params: { recursoProp: rec as any }
        });
    }

    async excluir (rec: RecursoAcessibilidade) {
        if (rec.id != null) {
            const retorno = await recursoAcessibilidadeService.excluir(rec.id);
            if (retorno != null) {
                this.pesquisar();
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

}
