<template>
  <header class="portal-header">
    <b-row align-v="center" align-h="center" class="hide-sm">
      <b-col sm="12" xl="8">
        <b-row id="main-header" align-v="center">
          <div id="logo-sistema"></div>
          <b-col>
            <div class="portal-title"></div>
          </b-col>
          <UserAccount v-if="showUser()" />
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center" class="menu-bg" v-if="showUser()">
      <PortalMenu></PortalMenu>
    </b-row>
  </header>
</template>

<script>
import UserAccount from './components/UserAccount.vue'
import { application } from '../application.js'
import centralSeguranca from '../api/centralseguranca'
import PortalMenu from '../layout/components/PortalMenu.vue'

export default {
  components: { PortalMenu, UserAccount },
  data () {
    return {
      AppInfo: application,
      contracted: false,
    }
  },

  methods: {
    showUser () {
      const jwtDecoded = centralSeguranca.decodeJwtToken(this.$store.getters.getJwtToken);
      if (jwtDecoded != null) {
        return true;
      }
      return false;
    },
    nomeUsuario () {
      const jwtDecoded = centralSeguranca.decodeJwtToken(this.$store.getters.getJwtToken);
      if (jwtDecoded != null) {
        return jwtDecoded.nome;
      }
      return '';
    },
  }
}
</script>

<style scoped>
  .bar-acessibilidade {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
</style>
