import { Avaliacao } from './avaliacao';
import { RecursoAcessibilidade } from './recurso-acessibilidade';

export class AvaliacaoRecurso {
    recursoAcessibilidade: RecursoAcessibilidade;
    avaliacao: Avaliacao;

    constructor (recursoAcessibilidade: RecursoAcessibilidade, nota?: Number, observacao?: string) {
        this.recursoAcessibilidade = recursoAcessibilidade;
        this.avaliacao = new Avaliacao(nota, observacao);
    }
}