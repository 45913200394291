





























































































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router/router';
import { TipoDeficienciaFilter } from '../../model/filter/tipo-deficiencia-filter';
import { TipoDeficiencia } from '../../model/tipo-deficiencia';
import tipoDeficienciaService from '@/api/tipo-deficiencia.service';

@Component
export default class ListarTipoDeficiencia extends Vue {

    public filtro: TipoDeficienciaFilter;
    public tipos: Array<TipoDeficiencia>;
    public tipoSelecionado: TipoDeficiencia = new TipoDeficiencia(null, "", null);

    public campos: Array<Object> = [
      {
        key: 'pai.descricao',
        label: 'Deficiência Geral',
        sortable: true,
        formatter: (value, key, item) => {
            return value == null ? '-' : value
        }
      },
      {
        key: 'descricao',
        label: 'Deficiência Específica',
        sortable: true
      },
      {
        key: 'cid',
        label: 'CID',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
      {
        key: 'acoes',
        label: 'Exibir',
        tdClass: 'acoes-1'
      }];
    private currentPage: Number = 1;
    private perPage: Number = 5;
    private pesquisando: boolean = false;
    private totalRows: Number = 0;

    constructor(){
        super();
        this.filtro = new TipoDeficienciaFilter("", true);
        this.tipos = Array<TipoDeficiencia>();
    }

    mounted (): void {
        this.limpar();
        this.pesquisar();
    }

    limpar () {
        this.filtro = new TipoDeficienciaFilter("", true);
        this.tipos = Array<TipoDeficiencia>();
    }

    async pesquisar () {
        const retorno = await tipoDeficienciaService.listarTiposDeficiencia(this.filtro, true);
        if (retorno != null) {
           this.tipos = retorno.registros;
           this.totalRows = retorno.totalRegistros;
        }
    }

    iniciarIncluir () {
        router.push({
            name: 'incluir-tipo-deficiencia'
        });
    }

    async iniciarExibir (tipoDef: TipoDeficiencia) {
        if (tipoDef != null) {
            this.$router.push({
                name: 'exibir-tipo-deficiencia',
                params: { tipoDefProp: tipoDef as any }
            });
        }
    }

    async iniciarAlterar (tipoDef: TipoDeficiencia) {
        if (tipoDef != null) {
            this.$router.push({
                name: 'editar-tipo-deficiencia',
                params: { tipoDefProp: tipoDef as any }
            });
        }
    }

    async excluir (tipoDef: TipoDeficiencia) {
        if (tipoDef.id != null) {
            const retorno = await tipoDeficienciaService.excluir(tipoDef.id);
            if (retorno != null) {
                this.pesquisar();
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

}
