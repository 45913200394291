var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { attrs: { id: "portal-footer" } },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", xl: "8" } },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "3", xl: "2" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.pr.gov.br",
                            target: "_blank"
                          }
                        },
                        [_c("div", { attrs: { id: "footer-logo-parana" } })]
                      )
                    ]
                  ),
                  _c("b-col", [
                    _c("p", [
                      _vm.isHomologacao()
                        ? _c("span", { staticClass: "tituloHomologacao" }, [
                            _vm._v(
                              "Esta aplicação requer o uso do navegador Mozilla Firefox para homologação"
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("p", { attrs: { id: "footer-content" } }, [
                      _c("span", { staticClass: "footer-title" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "https://www.desenvolvimentosocial.pr.gov.br/"
                            }
                          },
                          [
                            _vm._v(
                              "SECRETARIA DE DESENVOLVIMENTO SOCIAL E FAMÍLIA"
                            )
                          ]
                        )
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n            Palácio das Araucárias - Rua Jacy Loureiro de Campos, s/n - Centro Cívico\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "map-link",
                          attrs: {
                            title: "Link para localização no Google Maps",
                            href: "https://goo.gl/maps/kTvL25fgij9Smvg687",
                            target: "_blank"
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-map-marker-alt" }),
                          _vm._v(" MAPA")
                        ]
                      ),
                      _c("br"),
                      _vm._v("\n            (41) 3235-8000"),
                      _c("br"),
                      _vm._v(
                        "\n            Horário de Atendimento: das 8h30 às 12h e das 13h30 às 18h\n          "
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "3", xl: "2" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "http://www.celepar.pr.gov.br",
                            target: "_blank"
                          }
                        },
                        [_c("div", { attrs: { id: "footer-logo-celepar" } })]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }