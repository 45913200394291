var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portal-layout", class: _vm.getTheme, attrs: { id: "app" } },
    [
      _c("AccessBar"),
      _c("AppHeader"),
      _c(
        "div",
        { class: _vm.sidebar, attrs: { id: "content-wrapper" } },
        [_c("AppNav"), _c("AppMain"), _c("AppFooter")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }