import { HistoricoNavegacao } from './../model/historico-navegacao';
import restUtil from './rest.util.service';

class HistoricoNavegacaoService {

  registrar (telaAcessada: string | null) {
    restUtil.doPost<HistoricoNavegacao>('v1/historiconavegacao', new HistoricoNavegacao(telaAcessada));
  }

}

export default new HistoricoNavegacaoService();