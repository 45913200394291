export class HorarioAtendimento {
    id: Number|null;
    dia: Number|null;
    horaInicio: Date;
    horaFim: Date;

    constructor(id: Number|null, dia: Number|null, horaInicio: Date, horaFim: Date) {
        this.id = id;
        this.dia = dia;
        this.horaInicio = horaInicio;
        this.horaFim = horaFim;
    }
}