import { Option } from './../model/option';
export class Util {

    static toDateISO (dateStr: String) : Date {
        return new Date(dateStr.split('/').reverse().join('-') + ' 00:00:00');
    }

    static carregaValoresEnum (values: [string, String][]) : Option[] {
        return values.flatMap(obj => [new Option(obj, obj[1])]);
    }

    static validarCPF(inputCPF: String | null) : Boolean {
        if (inputCPF == undefined || inputCPF == null) {
            return false;
        }
        inputCPF = inputCPF.split(".").join("").split("-").join("");
        let soma = 0;
        let i;
        let resto;

        for (i = 1; i <= 9; i++) {
            soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) {
            resto = 0;
        }
        if (resto != parseInt(inputCPF.substring(9, 10))) {
            return false;
        }

        soma = 0;
        for (i = 1; i <= 10; i++) {
            soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) {
            resto = 0;
        }
        if (resto != parseInt(inputCPF.substring(10, 11))) {
            return false;
        }
        return true;
    }

}