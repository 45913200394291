



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { InteresseCategoria } from '@/model/interesse-categoria';
import { InteresseConteudo } from '@/model/interesse-conteudo';
import assuntoInteresseService from '@/api/assunto-interesse-service';

@Component
export default class AssuntosInteresseComponent extends Vue {

    //@Prop() public deficienteProp!: Deficiente;
    @Prop() public isExibirProp!: Boolean;
    @Prop() public showCollapseProp!: Boolean;
    @Prop() public interesseConteudoVinculadoProp!: Array<InteresseConteudo>;

    public showContent: Boolean = true;
    public categoriasInteresses: Array<InteresseCategoria>;
    public categoriaInteresse: InteresseCategoria | null = null;
    public interessesConteudos: Array<InteresseConteudo>;
    public interessesConteudosSelecionados: Array<InteresseConteudo> = Array.of();
    public interesseConteudoVinculado: Array<InteresseConteudo>;
    
    public interesseConteudoExcluir: InteresseConteudo | null = null;

    //campos da grid
    public campos: Array<Object> = [
        {
            key: 'interesseCategoria.descricao',
            label: 'Interesse Categoria',
            sortable: true
        },
        {
            key: 'descricao',
            label: 'Descrição',
            sortable: true
        }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public totalRows: Number = 0;

    constructor () {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);

        if (!this.isExibirProp) {
            this.campos.push({key: 'acoes', label: 'Ações', tdClass: 'acoes-1', thClass: 'acoes-1'})
        }
        
        this.categoriasInteresses = Array.of();
        this.interessesConteudos = Array.of();

        this.carregarInteresseCategoria ();
        this.interesseConteudoVinculado = this.interesseConteudoVinculadoProp;
    }

    async carregarInteresseCategoria () {
        let retorno = await assuntoInteresseService.listarInteresseCategoriaAtivos();
        if (retorno != null && retorno.registros != null) {
            this.categoriasInteresses = retorno.registros;
        }
    }

    async carregarInteresseConteudo () {
        this.interessesConteudos = Array.of();
        if (this.categoriaInteresse != null)  {
            let retorno = await assuntoInteresseService.listarInteresseConteudo(this.categoriaInteresse.id);
            if (retorno != null && retorno.registros != null) {
                this.popularRegistrosInteresseConteudo(retorno.registros);
            }
        }
    }

    popularRegistrosInteresseConteudo (registros : Array<InteresseConteudo>) : void {
        registros.forEach(ic => {
            if (!this.interesseConteudoExistInSelecteds(ic)) {
                this.interessesConteudos.push(ic);
            }
        });
    }

    interesseConteudoExistInSelecteds (ic : InteresseConteudo) : boolean {
        let retorno = false
        if (this.interesseConteudoVinculado != null) {
            this.interesseConteudoVinculado.forEach(icv => {
                if (icv.id == ic.id) {
                    retorno = true;
                    return;
                }
            });    
        }

        return retorno;
    }


    incluir () : void {
        if (this.interesseConteudoVinculado == null) {
            this.interesseConteudoVinculado = Array.of<InteresseConteudo>();
        }

        this.interessesConteudosSelecionados.forEach( ic => {
            this.interesseConteudoVinculado.push(ic);
        });

        this.$emit('atualizarInteresseConteudo', this.interesseConteudoVinculado);
        this.interessesConteudosSelecionados = Array.of();
        this.carregarInteresseConteudo();
    }

    excluir (interesseConteudoExcluir: InteresseConteudo) : void {
        const index = this.interesseConteudoVinculado.indexOf(interesseConteudoExcluir, 0);
        if (index > -1) {
            this.interesseConteudoVinculado.splice(index, 1);
            this.carregarInteresseConteudo();
        }
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }
    
}
