<template>
  <div>
    <component v-bind:is="layout"></component>
    <VLibras />
  </div>
</template>

<script>
import Admin from './layout/Admin.vue'
import Loading from './layout/Loading.vue'
import Blank from './layout/Blank.vue'
import LoginTmpl from './layout/LoginTmpl.vue'
import { VLibras } from '@vue-a11y/vlibras'

export default {
  name: 'App',
  computed: {
    layout () {
      return this.$store.getters.getLayout
    }
  },
  components: {
    'admin': Admin,
    'loading': Loading,
    'blank': Blank,
    'loginTmpl': LoginTmpl,
    VLibras
  }
}
</script>
<style lang='scss'>
@import "@/assets/scss/style.scss";
@import '~vue-select/dist/vue-select.css';
</style>
