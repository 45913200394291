














































import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { Pessoa } from '@/model/pessoa';
import { Endereco } from '@/model/endereco';
import { Municipio } from '@/model/municipio';
import { Contato } from '@/model/contato';
import moment from 'moment';
import EnderecoComponent from '../component/endereco-component.vue';
import ContatoComponent from '../component/contato-component.vue';
import AssuntosInteresseComponent from '../component/assuntos-interesse-component.vue';
import TipoDeficienciaComponent from '../component/tipo-deficiencia-component.vue';
import RecursoAcessibilidadeComponent from '../component/recurso-acessibilidade-component.vue';
import BeneficioComponent from '../component/beneficio-component.vue';
import DadosPessoaComponent from './dados-pessoa-component.vue';
import LaudoPessoaComponent from './laudo-pessoa-component.vue';

@Component({
    mixins: [formatar],
    components: {
        DadosPessoaComponent,
        ContatoComponent, EnderecoComponent, AssuntosInteresseComponent,
        TipoDeficienciaComponent, RecursoAcessibilidadeComponent, BeneficioComponent, LaudoPessoaComponent
    }
})
export default class AvaliarLaudoPessoa extends Vue {

    @Prop() public pessoaProp!: Pessoa;
    @Prop() public isRepresentado!: Boolean; //deve ser removido e carregado através do usuário
    @Prop() public isIncluirDependente!: Boolean;
    @Prop() public isExibir!: Boolean;
    @Prop() public isAvaliarLaudo!: Boolean;

    @Provide("parentValidator")
    public parentValidator = this.$validator;

    public pessoa: Pessoa;

    public possuiDeficiencia: Boolean | null = false;
    public isResponsavel: Boolean | null = false;

    constructor () {
        super();
        if (this.pessoaProp != null) {
            this.pessoa = this.pessoaProp;
            this.possuiDeficiencia = true;
            this.isResponsavel = true;
            if (this.pessoa.dtNascimento != null) {
                this.pessoa.dtNascimento = moment(this.pessoa.dtNascimento).toDate();
            }
            if (this.pessoa.endereco == null) {
                this.pessoa.endereco = new Endereco(null, "", "", "", null, "", new Municipio(null, "", ""));
            }
        } else {
            let endereco = new Endereco(null, "", "", "", null, "", new Municipio(null, "", ""));
            this.pessoa = new Pessoa(null, "", "", "", "");
            this.pessoa.endereco = endereco;
            this.pessoa.contatos = Array.of<Contato>();
        }
        if (this.pessoa.interesseConteudoVinculado === null) {
            this.pessoa.interesseConteudoVinculado = Array.of()
        }

    }

    voltar () {
        this.$router.go(-1);
    }

}
