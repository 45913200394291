var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-stretch" },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-center" },
        [
          _c("b-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "clearfix" },
              [
                _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                  _vm._v("404")
                ]),
                _c("h4", { staticClass: "pt-3" }, [_vm._v("Oops!")]),
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v("A página que você acessou não pode ser encontrada.")
                ]),
                _c(
                  "b-button",
                  { attrs: { variant: "info" }, on: { click: _vm.goHome } },
                  [_vm._v("Página inicial")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }