import { GrauParentesco } from './grau-parentesco';
import { Beneficio } from './beneficio';
import { RecursoAcessibilidade } from './recurso-acessibilidade';
import { TipoPessoaEnum } from './enum/tipo-pessoa';
import { TipoDeficiencia } from './tipo-deficiencia';
import { InteresseConteudo } from './interesse-conteudo';
import { Contato } from "./contato";
import { Endereco } from "./endereco";
import { EscolaridadeEnum } from "./enum/escolaridade";
import { FaixaRendaEnum } from "./enum/faixa-renda";
import { SexoEnum } from "./enum/sexo";
import { LaudoPessoa } from "./laudo-pessoa";

export class Pessoa {
    id: Number|null;
    nome: String;
    nomeSocial?: String;
    rne?: String;
    cpf: String;
    rg: String;
    ufRg: String | null;
    dtNascimento?: Date;
    sexo?: SexoEnum;
    escolaridade?: EscolaridadeEnum;
    faixaRenda?: FaixaRendaEnum;
    endereco?: Endereco;
    filiacao1?: String;
    filiacao2?: String;
    filiacao3?: String;
    filiacao4?: String;
    tipo?: TipoPessoaEnum;
    tempoCadastro!: Number;

    representado!: boolean;
    representante!: Pessoa | null;
    possuiVinculados: boolean;
    grauParentesco!: GrauParentesco|null;

    origem: String;
    contatos?: Array<Contato>;
    laudos?: Array<LaudoPessoa>;
    interesseConteudoVinculado: Array<InteresseConteudo>;
    tiposDeficiencia?: Array<TipoDeficiencia>;
    recursosAcessibilidade?: Array<RecursoAcessibilidade>;
    beneficios?: Array<Beneficio>;

    deficienteVisual!: boolean;

    constructor (id: Number|null, nome: String, cpf: String, rg: String, ufRg: String | null, rne?:String,
            dtNascimento?: Date, nomeSocial?: String, endereco?: Endereco, origem?: String,
            sexo?: SexoEnum, escolaridade?: EscolaridadeEnum,
            faixaRenda?: FaixaRendaEnum, contatos?: Array<Contato>, tipo?: TipoPessoaEnum) {
        this.id = id;
        this.nome = nome;
        this.cpf = cpf;
        this.rg = rg;
        this.ufRg = ufRg;
        this.rne = rne;
        this.nomeSocial = nomeSocial;
        this.dtNascimento = dtNascimento;
        this.endereco = endereco;
        this.origem = origem != null ? origem : "PR_ACESSIVEL";
        this.sexo = sexo;
        this.escolaridade = escolaridade;
        this.faixaRenda = faixaRenda;
        this.contatos = contatos;
        this.tipo = tipo;

        this.interesseConteudoVinculado = Array.of();
        this.tiposDeficiencia = Array.of();
        this.possuiVinculados = false;
    }
}