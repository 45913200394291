var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-main" },
    [
      _c(
        "main",
        { attrs: { id: "main-content" } },
        [
          _vm.messages ? _c("div", [_c("Messages")], 1) : _vm._e(),
          _c("router-view")
        ],
        1
      ),
      _c("back-to-top", { attrs: { bottom: "0", right: "0" } }, [
        _c(
          "button",
          { staticClass: "btn btn-info btn-to-top", attrs: { type: "button" } },
          [_c("i", { staticClass: "fa fa-chevron-up" })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }