import { PagingList } from '../model/generic/paging-list';
import store from '@/store/store';
import { BeneficioFilter } from './../model/filter/beneficio-filter';
import { Beneficio } from './../model/beneficio';
import restUtil from './rest.util.service';
import { Message } from './../model/generic/message';

class BeneficioService {

  async listarBeneficios (filter: BeneficioFilter, exibirMensagem: Boolean): Promise<PagingList<Beneficio>|null> {
    try{
      let retorno = await restUtil.doPost<PagingList<Beneficio>>('v1/beneficio/search', filter);
      if (retorno != null && retorno.data != null) {
        if (retorno.data.totalRegistros == 0 && exibirMensagem) {
          store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
      }
        return retorno.data;
      }
    } catch (error) {
      store.commit('setMessages', (error as any).response.data);
    }
    return null;
  }

  async salvar (beneficio: Beneficio) {
    let retorno;
    if (beneficio.id == null) {
      retorno = await restUtil.doPost<Beneficio>('v1/beneficio', beneficio);
    } else {
      retorno = await restUtil.doPut<Beneficio>('v1/beneficio/'+beneficio.id, beneficio);
    }
    return retorno;
  }

  async excluir (beneficio: Beneficio) {
    const retorno = await restUtil.doDelete('v1/beneficio/'+beneficio.id);
    if (retorno != null) {
      store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
    }
    return retorno;
  }

}

export default new BeneficioService();