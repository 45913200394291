<template>
  <footer id="portal-footer">
    <b-row align-h="center">
      <b-col sm="12" xl="8">
        <b-row align-h="between">
          <b-col sm="12" md="4" lg="3" xl="2">
            <a href="https://www.pr.gov.br" target="_blank">
              <div id="footer-logo-parana"></div>
            </a>
          </b-col>
          <!-- <b-col sm="12" md="4" lg="6" xl="8"> -->
          <b-col>
            <p>
              <span v-if="isHomologacao()" class="tituloHomologacao">Esta aplicação requer o uso do navegador Mozilla Firefox para homologação</span>
            </p>
            <p id="footer-content">
              <span class="footer-title"><a target="_blank" href="https://www.desenvolvimentosocial.pr.gov.br/">SECRETARIA DE DESENVOLVIMENTO SOCIAL E FAMÍLIA</a></span><br />
              Palácio das Araucárias - Rua Jacy Loureiro de Campos, s/n - Centro Cívico
              <a
                class="map-link"
                title="Link para localização no Google Maps"
                href="https://goo.gl/maps/kTvL25fgij9Smvg687"
                target="_blank"
                ><i class="fas fa-map-marker-alt"></i> MAPA</a
              ><br />
              (41) 3235-8000<br />
              Horário de Atendimento: das 8h30 às 12h e das 13h30 às 18h
            </p>
          </b-col>
          <b-col sm="12" md="4" lg="3" xl="2">
            <a href="http://www.celepar.pr.gov.br" target="_blank">
              <div id="footer-logo-celepar"></div>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
import { Vue } from 'vue-property-decorator'
export default class AppFooter extends Vue {
    isHomologacao () {
      return process.env.NODE_ENV == 'homolog'
    }
}
</script>

<style lang="scss">
.tituloHomologacao {
  color: yellow;
  font-size: 1.2em;
}
</style>
