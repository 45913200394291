<template>
	<div class="fluid">
		<br />
		<h2 class="app-title">
			<b-form-group class="text-label">
				<label ref="cpf" tabindex="0">
					<legend>Estatuto da Pessoa com Deficiência do Estado do Paraná (Lei nº 18419/2015)</legend>
				</label>
			</b-form-group>
		</h2>
		<b-card>
			<b-row>
				<b-col>
					<b-form-group class="text-label"><label tabindex="0">
						A Assembleia Legislativa do Estado do Paraná decretou e eu sanciono a seguinte Lei:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						TÍTULO I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DISPOSIÇÕES PRELIMINARES
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 1º Institui o Estatuto da Pessoa com Deficiência do Estado do
						Paraná, destinado a estabelecer orientações normativas que objetivam
						assegurar, promover e proteger o exercício pleno e em condições de
						equidade de todos os direitos humanos e fundamentais das pessoas com
						deficiência, visando à sua inclusão social e cidadania plena,
						efetiva e participativa.
						<strong
							>(Redação do artigo dada pela Lei Nº 20059 DE 18/12/2019).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						<strong
							>(Redação do caput dada pela Lei Nº 21214 DE 29/08/2022):</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 2º É dever dos órgãos e entidades do Poder Público do Estado do
						Paraná, da sociedade, da comunidade e da família assegurar com
						prioridade, às pessoas com deficiência, o pleno exercício dos
						direitos, dentre outros decorrentes da Constituição da República
						Federativa do Brasil, da Constituição do Estado do Paraná e demais
						leis esparsas, que propiciam o bem-estar pessoal, social e
						econômico, referentes:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - à vida;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - à saúde;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - à sexualidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - à paternidade e maternidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - à alimentação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - à educação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - à profissionalização;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - ao trabalho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - à habilitação e reabilitação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - à segurança;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - à previdência social;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - à assistência social;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - ao transporte;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIV - à cultura;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XV - ao desporto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVI - ao turismo;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVII - ao lazer;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVIII - à informação e comunicação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIX - à acessibilidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XX - aos avanços científicos e tecnológicos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXI - à dignidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXII - ao respeito;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXIII - à liberdade e à convivência familiar e comunitária
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O Poder Executivo do Estado do Paraná compromete-se
						a tomar as medidas necessárias, tanto quanto permitir os recursos
						disponíveis, e quando necessário no âmbito da cooperação
						internacional, nacional, estadual e municipal, a fim de assegurar
						progressivamente o pleno exercício desses direitos, sem prejuízo das
						obrigações contidas na presente Lei que forem imediatamente
						aplicáveis, de acordo com a Convenção sobre os Direitos das Pessoas
						com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 3º São consideradas pessoas com deficiência aquelas que têm
						impedimentos de longo prazo de natureza física, mental, intelectual
						ou sensorial, os quais, em interação com diversas barreiras, podem
						obstruir sua participação plena e efetiva na sociedade em equidade
						de condições com as demais pessoas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para os fins deste Estatuto as pessoas com
						neurofibromatose e com fissura palatina e labiopalatina, estas
						quando não totalmente recuperadas, têm os mesmos direitos das
						pessoas com deficiência.
						<strong
							>(Parágrafo acrescentado pela Lei Nº 20059 DE 18/12/2019).</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 4º Para fins de aplicação da presente Lei, considera-se:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - apoio especial: a orientação, a supervisão, as ajudas técnicas,
						entre outros elementos que auxiliem ou permitam compensar uma ou
						mais limitações motoras, sensoriais, mentais ou intelectuais da
						pessoa com deficiência, favorecendo a sua autonomia e sua
						independência, de forma a contribuir com sua inclusão social, bem
						como beneficiar processo de habilitação e reabilitação ou qualidade
						de vida;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - ajuda técnica: qualquer elemento que facilite a autonomia
						pessoal ou possibilite o acesso e o uso de meio físico, visando à
						melhoria da funcionalidade e qualidade de vida da pessoa com
						deficiência, como produtos, instrumentos, equipamentos ou tecnologia
						adaptados ou especialmente projetados, incluindo-se órteses e
						próteses, equipamentos e elementos necessários à terapia e à
						reabilitação da pessoa com deficiência, elementos de cuidado e de
						higiene pessoal de uso diário necessários para facilitar a autonomia
						e a segurança da pessoa com deficiência, bolsas coletoras para
						pessoas ostomizadas, material para cateterismo vesical,<br />
						bloqueadores, protetores, filtros e demais preparados antissolares
						para terapias, cão-guia, leitores, ledores, entre outros;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - procedimentos especiais: meios utilizados para auxiliar à
						pessoa que, devido ao seu grau de deficiência, exige condições
						peculiares para o desenvolvimento de atividades, como jornada de
						trabalho variável, horário flexível, entre outros.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 5º São princípios fundamentais da Política Pública Estadual
						para Promoção dos Direitos e Inclusão da Pessoa com Deficiência:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - o respeito à dignidade inerente, à autonomia individual,
						incluindo a liberdade de fazer suas próprias escolhas, e à
						independência das pessoas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - a não discriminação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - a inclusão e participação plena e efetiva na sociedade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - o respeito pela diferença e aceitação da deficiência como parte
						da diversidade e da condição humana;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - a igualdade de oportunidades;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - a acessibilidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - a igualdade entre homens e mulheres;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - o respeito pela capacidade em desenvolvimento das crianças e
						adolescentes com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 6º A Política Pública Estadual para Promoção dos Direitos e
						Inclusão da Pessoa com Deficiência, em consonância com a Política
						Nacional para Integração da Pessoa com Deficiência, nortear-se-á
						pelos seguintes objetivos:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - desenvolvimento de ação conjunta entre o Estado e a Sociedade
						Civil de modo a assegurar a plena inclusão da pessoa com deficiência
						no contexto socioeconômico e cultural;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - estabelecimento de mecanismos e instrumentos legais e
						operacionais que assegurem às pessoas com deficiência o pleno
						exercício de seus direitos básicos que decorrem da Constituição
						Federal, Constituição do Estado Paraná e demais leis esparsas,
						propiciando seu bem-estar pessoal, social e econômico;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - respeito às pessoas com deficiência, que devem receber
						equidade de oportunidades na sociedade por reconhecimento dos
						direitos que lhes são assegurados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 7º A Política Pública Estadual para Promoção dos Direitos e
						Inclusão da Pessoa com Deficiência, em consonância com a Política
						Nacional para Integração da Pessoa com Deficiência e com o Programa
						Nacional de Saúde da Pessoa com Deficiência, obedecerá às seguintes
						diretrizes:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - promoção da qualidade de vida das pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - assistência integral à saúde da pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - prevenção de deficiências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - ampliação e fortalecimento dos mecanismos de informação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - organização e funcionamento dos serviços de atenção às pessoas
						com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - capacitação de recursos humanos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - estabelecimento de mecanismos que acelerem e favoreçam a
						inclusão social da pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - adoção de estratégias de articulação com órgãos e entidades
						públicos e privados e com organismos nacionais e estrangeiros para a
						implantação destas políticas públicas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - inclusão da pessoa com deficiência, respeitando-se as suas
						peculiaridades, em todas as iniciativas governamentais relacionadas
						à educação, à saúde, ao trabalho, à edificação pública, à seguridade
						social, ao transporte, à habitação, à cultura, ao esporte e ao
						lazer;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - viabilização da participação das pessoas com deficiência em
						todas as fases de elaboração e implementação dessas políticas, por
						intermédio de suas entidades representativas e outros fóruns;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - ampliação das alternativas de inserção econômica das pessoas
						com deficiência, proporcionando qualificação profissional e
						incorporação no mercado de trabalho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - garantia do efetivo atendimento das necessidades das pessoas
						com deficiência, inclusive nos atendimentos realizados em serviços
						públicos com a disponibilização de suporte em Língua Brasileira de
						Sinais (Libras), quando possível presencial, ou por meio telemático,
						conforme § 2º do art. 111 desta Lei;
						<strong
							>(Redação do inciso dada pela Lei Nº 21214 DE 29/08/2022).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						XIII - articulação com entidades governamentais e não governamentais
						em nível federal, estadual e municipal, visando garantir a
						efetividade dos programas de prevenção, de atendimento especializado
						e de inclusão social das pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						TÍTULO II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DIREITOS FUNDAMENTAIS
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO ATENDIMENTO PRIORITÁRIO
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 8º Todos os órgãos públicos da administração direta, indireta e
						autarquias, agências e postos bancários, estabelecimentos de crédito
						financeiro, e instituições similares, estabelecimentos ou espaços
						culturais, estabelecimentos comerciais, bem como estabelecimentos ou
						espaços esportivos, devidamente instalados no Estado do Paraná,
						ficam obrigados a dar atendimento prioritário e especial às pessoas
						com deficiência, clientes ou não clientes, que, por sua vez, ficam
						desobrigadas, a qualquer tempo, de aguardar a vez em filas, mesmo
						aquelas externas de aguardo ao horário de abertura e início de
						expediente, quando também terão preferência, sempre e em todas as
						circunstâncias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Entende-se por precedência de atendimento aquele prestado à
						pessoa com deficiência antes de qualquer outra depois de concluído o
						atendimento que estiver em andamento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Nos serviços de emergência dos estabelecimentos públicos,
						conveniados e privados de atendimento à saúde, a primazia conferida
						por esta Lei fica condicionada à avaliação médica em face da
						gravidade dos casos a serem atendidos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Cabe ao Estado do Paraná e aos municípios, no âmbito de suas
						competências, criar os mecanismos necessários para a efetiva
						implantação e controle do atendimento prioritário referido nesta
						Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 9º As entidades previstas no art. 8º desta Lei deverão afixar,
						em locais visíveis ao público, placas e cartazes informativos
						contendo as previsões legais que conferem a prioridade de
						atendimento às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 10. A garantia de prioridade estabelecida nesta Lei compreende,
						dentre outras medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - a primazia de receber proteção e socorro em quaisquer
						circunstâncias;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - a precedência de atendimento nos serviços públicos ou de
						relevância pública, junto aos órgãos públicos e privados prestadores
						de serviços à população;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - a prioridade no embarque no sistema de transporte coletivo;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - a preferência na formulação e na execução das políticas
						públicas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - a destinação privilegiada de recursos públicos nas áreas
						relacionadas às pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - a priorização do atendimento da pessoa com deficiência por sua
						própria família, em detrimento de abrigo ou entidade de longa
						permanência, exceto quando não possuam ou careçam de condições de
						manutenção da própria sobrevivência ou estejam em situação de risco
						ou violação de direitos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - a capacitação e formação continuada de recursos humanos para o
						atendimento das pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - o estabelecimento de mecanismos que favoreçam a divulgação de
						informações de caráter educativo sobre aspectos ligados às
						deficiências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - a garantia de acesso à rede de serviços de políticas públicas
						setoriais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 11. O descumprimento do disposto neste Capítulo sujeitará os
						responsáveis pela infração ao pagamento de multa correspondente a 35
						(trinta e cinco) UPF/PR - Unidades Padrão Fiscal do Paraná, não os
						desobrigando de seu posterior cumprimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Em caso de reincidência, depois de decorrido o
						prazo de trinta dias, contados a partir da aplicação da primeira
						multa, o valor da multa a que se refere o caput deste artigo será
						dobrado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA SAÚDE
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Disposições Gerais
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 12. O direito aos serviços de saúde compreende:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - atenção integral à saúde, universal e gratuita, por meio do
						Sistema Único de Saúde - SUS, assegurado atendimento personalizado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - transporte, sempre que indispensável à viabilização da atenção
						integral à saúde;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - atenção integral à saúde respeitada a classificação de risco,
						viabilizando acomodações acessíveis de acordo com a legislação em
						vigor;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - fornecimento de medicamentos e materiais, inclusive os de uso
						contínuo, necessários para o tratamento e realização de
						procedimentos específicos para cada tipo de deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Fica garantida a gratuidade de todos os serviços de saúde
						referidos nesta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º É assegurado o direito à presença de acompanhante junto à
						pessoa com deficiência, durante os períodos de atendimento e de
						internação, devendo o órgão de saúde proporcionar as condições
						adequadas para a sua permanência em tempo integral, salvo
						entendimento contrário, devidamente justificado, de profissional da
						saúde.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 13. Os órgãos e entidades da administração pública estadual e
						municipal direta e indireta deverão assegurar às pessoas com
						deficiência o pleno exercício de seu direito à saúde e à
						maternidade, e de outros que, decorrentes da Constituição da
						Republica Federativa do Brasil, da Constituição do Estado do Paraná
						e das demais leis esparsas, propiciem seu bem-estar pessoal, social
						e econômico.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 14. Para o fim estabelecido no art. 13 desta Lei, os órgãos e
						entidades da Administração Estadual Direta e Indireta dispensarão,
						no âmbito de sua competência e finalidade, bem como respeitando a
						classificação de risco,<br />
						tratamento prioritário e adequado, tendente a viabilizar, sem
						prejuízo de outras, as seguintes medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - promoção de ações preventivas, como às referentes ao
						planejamento familiar, ao aconselhamento genético, ao acompanhamento
						da gravidez, do parto e do puerpério, à nutrição da mulher e da
						criança, à identificação e ao controle da gestante e do feto de alto
						risco ou com deficiência, à imunização, às doenças do metabolismo e
						seu diagnóstico, ao encaminhamento de outras doenças causadoras de
						deficiência, bem como de outras doenças crônico degenerativas e de
						outras potencialmente incapacitantes, para o serviço de saúde
						especializado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - desenvolvimento de programas especiais de prevenção de trauma
						de qualquer natureza e o desenvolvimento de programa para tratamento
						adequado às suas vítimas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - criação e estruturação de rede de serviços regionalizados,
						descentralizados e hierarquizados em crescentes níveis de
						complexidade, voltados ao atendimento à saúde e à reabilitação da
						pessoa com deficiência, articulada com outras políticas setoriais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - garantia de acessibilidade da pessoa com deficiência aos
						estabelecimentos de saúde públicos e privados e de seu adequado
						tratamento sob normas técnicas e padrões de conduta apropriados;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - garantia de atenção domiciliar de saúde à pessoa com
						deficiência, quando indicado por profissional da saúde;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - desenvolvimento de programas de saúde voltados às pessoas com
						deficiência, os quais deverão contar com a participação de pessoas
						com deficiência na análise das propostas formuladas pelo Poder
						Executivo;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - reconhecimento do papel estratégico da atuação das Unidades
						Básicas de Saúde e das equipes de saúde da família na disseminação
						das práticas e estratégias de promoção, prevenção e reabilitação
						baseada na comunidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 15. Para os efeitos deste Capítulo, prevenção compreende as
						ações e medidas orientadas a evitar as causas das deficiências que
						possam ocasionar incapacidade e as destinadas a evitar sua
						progressão ou derivação em outras incapacidades.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 16. É assegurado, no âmbito público e privado, o acesso
						igualitário às ações e aos serviços de promoção, prevenção e
						assistência à saúde das pessoas com deficiência, bem como sua
						habilitação e reabilitação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Toda pessoa que apresente deficiência devidamente
						diagnosticada, qualquer que seja sua natureza, agente causal, grau
						de severidade ou prejuízo de sua saúde, terá direito aos
						procedimentos necessários de habilitação e reabilitação realizados
						por profissional de saúde, durante o período que for pertinente
						assegurar estes cuidados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º É parte integrante dos processos de habilitação e reabilitação
						o tratamento e o apoio das equipes de saúde nos diversos níveis de
						atenção, inclusive psicológica, e durante todas as fases do processo
						habilitador e reabilitador, bem como o suprimento dos medicamentos e
						das ajudas técnicas e tecnologias assistivas necessárias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Quando esgotados os meios de atenção à saúde da pessoa com
						deficiência em sua localidade de residência, será prestado
						atendimento fora de domicílio, para fins de diagnóstico e
						atendimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 17. O Poder Público fomentará ações, programas e projetos para
						avaliação, pesquisa e diagnósticos com a finalidade de assegurar
						atendimento adequado para as pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 18. Incluem-se na assistência integral à saúde, reabilitação e
						habilitação da pessoa com deficiência, a concessão de órteses,
						próteses, bolsas coletoras, materiais auxiliares, inclusive os de
						uso contínuo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 19. O Poder Público poderá oferecer no contraturno escolar
						atendimento terapêutico de manutenção e multiprofissional aos alunos
						com deficiência, por intermédio da oferta de serviços especializados
						públicos ou conveniados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 20. Os programas e as linhas de pesquisa a serem desenvolvidos
						com o apoio de organismos públicos e privados de auxílio à pesquisa
						e de agências de financiamento deverão contemplar temas voltados
						para ajudas técnicas, cura, tratamento e prevenção de deficiências
						ou que contribuam para impedir ou minimizar o seu agravamento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 21. O desenvolvimento científico e tecnológico voltado para a
						produção de ajudas técnicas dar-se-á a partir da instituição de
						parcerias com universidades e centros de pesquisa para a produção
						nacional de componentes e equipamentos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 22. Deverá o Poder Público instituir campanhas destinadas à
						prevenção de doenças, deficiências e fatores de risco causadores de
						deficiências, inclusive acidentes, veiculadas por todos os meios de
						comunicação, assegurada sua acessibilidade, por intermédio de janela
						com tradução simultânea para a Língua Brasileira de Sinais - Libras,
						áudio descrição, legenda, dentre outros.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Prevenção e do Tratamento
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 23. O atendimento ao recém-nascido na sala de parto e nas
						Unidades Intermediárias consiste em sua assistência por profissional
						capacitado, médico, preferencialmente neonatologista ou pediatra, ou
						profissional de enfermagem, preferencialmente enfermeiro obstetra ou
						neonatal, desde o período imediatamente anterior ao parto, até que o
						recém-nascido seja avaliado e entregue aos cuidados da mãe, da
						unidade intermediária ou, se necessário, da Unidade de Tratamento
						Intensivo - UTI neonatal.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 24. É obrigatória a realização gratuita do Exame de Triagem
						Neonatal - &quot;Teste do Pezinho&quot; em todos os recém-nascidos
						em hospitais da rede pública e privada, nos termos da Lei nº 8.627,
						de 9 de dezembro de 1987.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 25. É obrigatória a realização gratuita do Exame de Emissões
						Otoacústicas Evocadas - &quot;Teste da Orelhinha&quot; em todos os
						recém-nascidos em hospitais da rede pública e privada, nos termos da
						Lei nº 14.588, de 22 de dezembro de 2004.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 26. É obrigatória a realização gratuita do Exame de Diagnóstico
						Clínico de Catarata Congênita - &quot;Teste do Olhinho&quot; em
						todos os recém-nascidos em hospitais da rede pública e privada, nos
						termos Lei nº 14.601, de 28 de dezembro de 2004.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 27. É obrigatória a realização gratuita de testes de acuidade
						visual e auditiva em todos os estudantes da rede pública estadual de
						ensino.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os alunos que apresentarem distúrbios de acuidade visual ou
						auditiva serão obrigatoriamente encaminhados para exames
						oftalmológicos ou otorrinolaringológicos, respectivamente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O Poder Executivo regulamentará a realização dos testes de
						acuidade visual e auditiva, inclusive sua periodicidade, no prazo de
						noventa dias, contados a partir da data da publicação desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 28. Caberá ao Poder Executivo do Estado do Paraná o
						desenvolvimento e a implantação de sistema próprio para o registro
						dos casos de nascimento e diagnóstico de pessoa com deficiência,
						assim como os casos de deficiência adquirida por acidente ou
						moléstia detectada, em estabelecimento hospitalar ou ambulatorial,
						público ou privado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º As informações para o registro dos nascimentos com detecção de
						anomalia congênita poderão ser obtidas a partir dos dados do Sistema
						de Informações sobre Nascidos Vivos - Sinasc.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As informações para o registro dos casos de deficiências
						causadas por moléstia ou acidente, identificadas posteriormente ao
						preenchimento da Declaração de Nascido Vivo, serão obtidas a partir
						dos dados do Sistema de Informações de Atenção Básica.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Quando necessário, o Poder Executivo do Estado do Paraná criará
						os meios para captação de dados e alimentação no sistema previsto no
						caput deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º As informações integrantes do sistema previsto no caput deste
						artigo serão de caráter sigiloso e utilizadas com a finalidade de
						estabelecer índices estatísticos e mapeamento do Estado do Paraná a
						fim de subsidiar as políticas públicas voltadas às pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 5º O Poder Executivo regulamentará a criação e a forma de
						notificação do sistema previsto no caput deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 29. Os hospitais e as maternidades situados no Estado do Paraná
						prestarão assistência integral às parturientes e aos bebês em
						situação de risco que necessitem de tratamento continuado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os hospitais e as maternidades situados no Estado do Paraná,
						além da assistência integral prevista no caput deste artigo,
						prestarão informações por escrito à parturiente, ou a quem a
						represente, sobre os cuidados a serem tomados com o recém-nascido
						por conta de sua deficiência ou patologia, bem como no fornecimento
						de listagem das instituições, públicas e privadas, especializadas na
						assistência às pessoas com deficiência ou patologia específica.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Os hospitais e maternidades públicos e conveniados situados no
						Estado do Paraná prestarão assistência psicológica aos pais, aos
						responsáveis legais e às crianças, quando constatadas deficiências
						ou patologias nos atendimentos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO DIREITO À HABITAÇÃO
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 30. As habitações de interesse social ofertadas pelo Estado do
						Paraná deverão respeitar os padrões do desenho universal,
						possibilitando o pleno uso por parte de pessoas com e sem
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os órgãos da administração direta ou indireta do estado, as
						fundações ou instituições financeiras instituídas e mantidas pelo
						Estado do Paraná, ou da qual ele faça parte como acionista
						majoritário, quando efetuarem venda de casa própria, farão constar
						se o interessado na aquisição ou qualquer de seus moradores é pessoa
						com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A entrega dos imóveis objetos da inscrição dar-se-á, sempre que
						possível, de forma adaptada e preferencial aos inscritos, na forma
						do § 1º<br />
						deste artigo, permitindo-se a escolha das unidades que melhor se
						prestem à moradia destes em cada lote ofertado, respeitada a ordem
						prévia da inscrição geral.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Deverão ser destinadas no mínimo 5% (cinco por cento) das
						unidades habitacionais para pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA EDUCAÇÃO
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Disposições Gerais
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 31. Assegura o sistema educacional inclusivo em todos os
						níveis, bem como o aprendizado ao longo de toda a vida, como meio de
						efetivar o direito das pessoas com deficiência à educação sem
						discriminação e com base na igualdade de oportunidades.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<strong
							>(Redação do artigo dada pela Lei Nº 19603 DE 19/07/2018):</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 32. É dever do Estado, da família, da comunidade escolar e da
						sociedade assegurar educação de qualidade às pessoas com
						deficiência, colocando-as a salvo de toda a forma de negligência,
						discriminação, violência, crueldade e opressão escolar.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Assegura ao aluno com deficiência, à sua família ou ao seu
						representante legal, o direito de opção pela frequência nas escolas
						da rede comum de ensino ou nas escolas de educação básica na
						modalidade de educação especial, observadas as especificidades
						devidamente detectadas por avaliação multiprofissional, devendo
						haver o serviço de apoio educacional complementar.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Assegura aos alunos com síndrome de down o direito de matrícula
						simultânea nas escolas da rede regular de ensino e nas escolas que
						prestem atendimento educacional especial.
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Art. 33. O Governo do Estado do Paraná deverá promover cursos,
						grupos de estudos e capacitação dos professores da rede pública de
						ensino, a fim de que estejam aptos a lecionar para alunos com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 34. Os órgãos e as entidades da administração pública estadual
						direta e indireta responsáveis pelo sistema de educação do Estado do
						Paraná dispensarão tratamento prioritário e adequado aos assuntos
						objeto desta Seção, viabilizando, sem prejuízo de outras, as
						seguintes medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - inclusão, no sistema educacional, da educação especial como
						modalidade de educação escolar que permeia transversalmente todos os
						níveis e as modalidades de ensino;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - inserção, no sistema educacional, das escolas ou instituições
						especializadas, públicas ou privadas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - oferta, obrigatória e gratuita, da educação especial em
						estabelecimentos públicos ou conveniados de ensino;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - oferta obrigatória dos serviços de educação especial ao aluno
						com deficiência que esteja internado por prazo igual ou superior a
						um mês em unidades hospitalares e congêneres;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - acesso de aluno com deficiência aos benefícios conferidos aos
						demais alunos, inclusive material escolar, transporte adaptado e
						adequado e merenda escolar.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Entende-se por educação especial, para os efeitos desta Seção,
						a modalidade de educação escolar oferecida preferencialmente na rede
						comum de ensino para educandos com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A educação especial deve constituir processos flexíveis,
						dinâmicos e individualizados, contando com equipe multidisciplinar
						especializada que deverá adotar orientações adequadas a cada caso.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º A educação do aluno com deficiência iniciar-se-á na educação
						infantil, a partir de zero de idade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º Quando da construção e reforma de estabelecimentos de ensino
						deverão ser atendidas as normas técnicas de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 35. Os serviços de educação especial serão ofertados nas
						instituições de ensino públicas e privadas do sistema de educação
						geral, de forma transitória ou permanente, mediante programas de
						apoio ao aluno que esteja incluído no sistema comum de ensino, ou
						nas escolas de educação básica na modalidade especial,
						exclusivamente quando a educação das escolas da rede comum de ensino
						não puder satisfazer as necessidades educativas do aluno ou quando
						necessário ao seu bem-estar.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 36. Assegura à pessoa com deficiência a prioridade de vaga em
						escola pública, inclusive nos centros de educação infantil,
						preferencialmente naquela com localização mais próxima à sua
						residência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Considera-se estabelecimento mais próximo da residência da
						pessoa com deficiência aquele cuja distância da residência seja
						menor ou que seja mais fácil seu acesso por meio de transporte
						coletivo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Havendo mais de um estabelecimento de ensino considerado
						próximo à residência do aluno com deficiência, este terá o direito
						de optar por qualquer das instituições de ensino.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Para a obtenção da prioridade de que trata o caput deste
						artigo, as pessoas com deficiência deverão apresentar junto à
						instituição de ensino comprovante de residência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º No caso de preferência por instituição de ensino que não seja a
						considerada mais próxima de sua residência, o aluno com deficiência
						deverá apresentar justificativa circunstanciada, que será apreciada
						pela instituição de ensino escolhida, sendo a decisão da escola
						passível de recurso administrativo ao órgão responsável pela
						administração da escola.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 37. A escola deverá incluir, regularmente, o aluno com
						deficiência matriculado em atividades esportivas proporcionando sua
						participação em atividades físicas, jogos e competições desportivas
						ou paradesportivas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 38. Todas as instituições que ofertam educação básica e
						superior deverão implementar medidas para assegurar aos alunos
						surdos ou com deficiência auditiva o acesso à comunicação, à
						informação e à educação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Paragrafo único. Os professores deverão ter acesso à literatura e às
						informações sobre a especificidade linguística do aluno surdo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 39. A programação visual dos cursos de nível médio e superior,
						preferencialmente os de formação de professores, na modalidade de
						educação à distância, deve dispor de sistemas de acesso à
						informação, nos termos do Decreto Federal nº 5.296, de 02 de
						dezembro de 2004.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 40. A Língua Brasileira de Sinais - Libras deverá ser inserida
						como disciplina curricular obrigatória nos cursos de formação de
						professores para o exercício do magistério, em nível médio e
						superior, e nos cursos de Fonoaudiologia, Pedagogia e Educação
						Especial.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Nos demais cursos de nível superior e de ensino
						profissionalizante, a Libras será incluída como disciplina
						curricular optativa nos termos do § 2º do art. 3º do Decreto Federal
						nº 5.626, de 22 de dezembro de 2005.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 41. A Secretaria de Estado responsável pela política pública da
						educação atenderá às necessidades dos alunos com deficiência
						visual<br />
						matriculados nas escolas estaduais para a impressão em braile ou em
						fonte ampliada dos livros, apostilas e outros materiais pedagógicos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os autores estão autorizados a fornecer à
						Secretaria de Estado responsável pela política pública da educação
						cópia do texto integral das obras mencionadas no caput deste artigo
						em meio digital.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 42. O Poder Executivo do Estado do Paraná assegurará a
						disponibilização de ledor capacitado para aplicação de prova a fim
						de não prejudicar a avaliação do desempenho da pessoa com
						deficiência visual, mediante criação e manutenção de cadastro de
						ledores.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Educação Básica
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 43. As instituições de ensino de educação básica, em qualquer
						nível ou modalidade de ensino, devem assegurar o atendimento
						educacional aos alunos com deficiência, prevendo e provendo a oferta
						de serviço e apoio especializados para o processo ensino
						aprendizagem desses alunos, tais como:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - escolas e classes de educação bilíngue, abertas a alunos surdos
						e ouvintes, com professores bilíngues e professores surdos, na
						educação infantil e nos anos iniciais do ensino fundamental, bem
						como com a presença de tradutores e intérpretes da Língua Brasileira
						de Sinais - LIBRAS;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - escolas bilíngues ou escolas comuns da rede comum de ensino,
						abertas a alunos surdos e ouvintes, para os anos finais do ensino
						fundamental, ensino médio ou educação profissional, com docentes das
						diferentes áreas do conhecimento, cientes da singularidade
						linguística dos alunos surdos e de suas especificidades, bem como
						com a presença de tradutores e intérpretes da Língua Brasileira de
						Sinais - Libras.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os alunos com deficiência têm direito à escolarização em um
						turno diferenciado ao do atendimento educacional especializado para
						o desenvolvimento de complementação curricular, com utilização de
						equipamentos e tecnologias de informação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º São denominadas escolas ou classes de educação bilíngue aquelas
						em que a Língua Brasileira de Sinais - Libras L1 e a Língua
						Portuguesa L2 sejam, respectivamente, a primeira e a segunda língua
						de instrução, e que ambas sejam utilizadas no desenvolvimento de
						todo o processo educativo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º As mudanças decorrentes da implementação dos incisos I e II
						deste artigo implicam na formalização da opção ou preferência pela
						educação bilíngue, a critério dos pais e dos próprios alunos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Do Ensino Superior
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 44. As instituições de ensino superior deverão oferecer
						adaptação das provas e o apoio necessário ao aluno com deficiência,
						inclusive tempo adicional para realização das provas e critérios
						diferenciados de avaliação, conforme as características da
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º As disposições deste artigo aplicam-se, também, ao sistema
						geral do processo seletivo para o ingresso em cursos universitários
						de instituições de ensino superior, conforme legislação vigente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A Secretaria de Estado responsável pela política pública da
						ciência e tecnologia e ensino superior, no âmbito da sua competência
						e em conformidade com a legislação vigente, expedirá instruções para
						que os<br />
						programas de educação superior incluam nos seus currículos
						conteúdos, itens, ou disciplinas relacionados às pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Educação Profissional
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 45. Todo o aluno com deficiência matriculado ou egresso do
						ensino fundamental ou médio terá acesso à educação profissional, a
						fim de obter habilitação profissional que lhe proporcione
						oportunidade de acesso ao mercado de trabalho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A educação profissional para as pessoas com deficiência será
						oferecida nos níveis básico, médio, técnico e tecnológico em escolas
						da rede comum de ensino, em instituições especializadas e nos
						ambientes de trabalho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As instituições especializadas que ministram educação
						profissional deverão, obrigatoriamente, oferecer cursos
						profissionalizantes de nível básico à pessoa com deficiência,
						condicionando a matrícula à sua capacidade de aproveitamento e não
						ao seu nível de escolaridade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Entende-se por habilitação profissional o processo destinado a
						propiciar às pessoas com deficiência, em nível formal e
						sistematizado, a aquisição de conhecimentos e habilidades
						especificamente associados à determinada profissão.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 46. As escolas e instituições de educação profissional
						oferecerão serviços de apoio especializado para atender às
						especificidades das pessoas com deficiência, tais como:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - adaptação dos recursos instrucionais: material pedagógico,
						equipamento e currículo;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - capacitação dos recursos humanos: professores, instrutores e
						profissionais especializados;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - adequação dos recursos físicos: eliminação de barreiras
						arquitetônicas, ambientais e de comunicação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 47. O Governo do Estado do Paraná, por meio da Secretaria de
						Estado responsável pela política pública da educação, da Secretaria
						de Estado responsável pela política pública da administração e da
						previdência, da Secretaria de Estado responsável pela política
						pública da ciência, tecnologia e ensino superior, pela Secretaria de
						Estado responsável pela política pública do trabalho, emprego e
						economia solidária e demais órgãos da administração direta e
						indireta do Estado do Paraná desenvolverão projetos recomendando a
						criação e implantação de programas de cursos técnicos e
						profissionalizantes direcionados às pessoas com deficiência,
						possibilitando sua inclusão no mercado de trabalho de modo a atender
						as suas especificidades.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Do Estágio e do Aprendiz
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 48. É permitida a admissão de pessoa com deficiência na
						condição de aprendiz ou estagiário por órgãos da administração
						direta e indireta, sob forma de contrato de aprendizagem ou de
						estágio.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As condições de aprendizagem ou de estágio serão
						definidas em regulamento próprio a ser definido pela Secretaria de
						Estado responsável pela política pública da administração e da
						previdência, observada a legislação federal específica.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 49. A duração do estágio, exercido na mesma parte concedente,
						poderá exceder dois anos quando se tratar de estagiário com
						deficiência, desde que em áreas de atuação diversas, assegurando,
						desta forma, o aprendizado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO TRABALHO
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Disposições Gerais
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 50. Consideram-se pessoas com deficiência, para os fins deste
						Capítulo, aquelas que se enquadram nos critérios especificados no
						Decreto Federal nº 3.298, de 20 de dezembro de 1999, na Lei Estadual
						nº 16.945, de 18 de novembro de 2011, na Lei Federal nº 12.764, de
						27 de dezembro de 2012 e demais normas que venham a ampliar o rol de
						critérios para pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 51. Os órgãos e entidades da administração pública estadual
						direta e indireta deverão assegurar às pessoas com deficiência o
						pleno exercício de seu direito ao trabalho e de outros que,
						decorrentes da Constituição Federal, da Constituição do Estado do
						Paraná e das demais leis esparsas, propiciem seu bem-estar pessoal,
						social e econômico.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 52. Para o fim estabelecido no art. 51 desta Lei, os órgãos e
						entidades da administração estadual direta e indireta dispensarão,
						no âmbito de sua competência e finalidade, atendimento prioritário e
						adequado, tendente a viabilizar, sem prejuízo de outras, as
						seguintes medidas na área da formação profissional e do trabalho:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - apoio governamental à formação profissional e à garantia de
						acesso aos serviços oferecidos, inclusive aos cursos regulares
						voltados à formação profissional;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - empenho do poder público quanto ao incentivo para a criação,
						manutenção e ampliação de empregos destinados às pessoas com
						deficiência, conforme previsto no art. 35 do Decreto Federal nº
						3.298, de 20 de dezembro de 1999;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - promoção de ações eficazes que propiciem a inserção, nos
						setores públicos e privados, de pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 53. O Poder Executivo do Estado do Paraná deverá criar no prazo
						de 24 (vinte e quatro) meses programa de incentivo para a
						contratação de pessoas com deficiência nas empresas não abrangidas
						pelo art. 93 da Lei Federal nº 8.213, de 24 de julho de 1991.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Reserva de Cargos e Empregos
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 54. Assegura à pessoa com deficiência o direito de se inscrever
						em concurso público, processos seletivos ou quaisquer outros
						procedimentos de recrutamento de mão de obra para provimento em
						igualdade de condições com os demais candidatos de cargo ou emprego
						público.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º O candidato com deficiência, em razão da necessária igualdade
						de condições, concorrerá a todas as vagas, sendo reservado, no
						mínimo, o percentual de 5% (cinco por cento) em face da
						classificação obtida.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Caso a aplicação do percentual de que trata o § 1º deste artigo
						resultar em número fracionado, este deverá ser elevado até o
						primeiro número inteiro subsequente, respeitando o percentual máximo
						de 20% (vinte por cento) das vagas oferecidas no certame.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º É assegurada a gratuidade de inscrição em concurso público à
						pessoa com deficiência comprovadamente carente, desde que apresente
						comprovante atualizado de inscrição no Cadastro Único - CadÚnico
						para programas sociais do Governo Federal, nos termos do Decreto
						Federal nº 6.135, de 26 de junho de 2007, emitido pelo Gestor do
						Programa do seu município de residência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º A reserva do percentual adotado será distribuída
						proporcionalmente pelas vagas em disputa.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 55. Não se aplica o disposto no art. 54 desta Lei aos casos de
						provimento de cargo ou emprego público integrante de carreira que
						exija aptidão plena do candidato, auferida em parecer emitido por
						equipe multiprofissional.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O exame de higidez física ou avaliação médica não
						poderá excluir o candidato em razão de sua deficiência, exceto nos
						casos em que se exija aptidão plena do candidato em razão da função
						a ser desempenhada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 56. Os editais de concursos públicos e testes seletivos deverão
						ser disponibilizados em formato acessível às pessoas com deficiência
						visual nos portais e sítios eletrônicos da administração pública na
						rede mundial de computadores.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O Poder Público terá o prazo de até doze meses,
						contados da data de publicação da presente Lei, para atender ao
						disposto no caput deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 57. Os editais de concursos públicos deverão conter:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - o número de vagas existentes, bem como o total correspondente à
						reserva destinada à pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - as atribuições e tarefas essenciais dos cargos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - previsão de adaptação das provas do curso de formação e do
						estágio probatório, conforme a deficiência do candidato;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - previsão do conteúdo das provas para aferir as habilidades do
						candidato, quando se tratarem de funções que dispensam conhecimentos
						técnicos e comprovação de escolaridade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - exigência de apresentação, pelo candidato com deficiência, de
						laudo médico atestando a espécie e o grau ou o nível de deficiência,
						com expressa referência ao código correspondente da Classificação
						Internacional de Funcionalidade, Incapacidade e Saúde - CIF, bem
						como a provável causa da deficiência, após a realização da prova de
						conhecimentos, mediante convocação específica para este fim, sendo
						assegurada a alteração de sua inscrição para as vagas de livre
						concorrência nos casos em que o laudo médico não se enquadrar nos
						critérios legais para definição de pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 58. É vedado à autoridade competente obstar a inscrição de
						pessoa com deficiência em concurso público para ingresso em carreira
						da administração pública estadual direta e indireta.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º No ato da inscrição, o candidato com deficiência, que necessite
						de atendimento diferenciado nos dias do concurso, deverá requerê-lo,
						no prazo determinado em edital, indicando as condições diferenciadas
						de que necessita para a realização das provas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O candidato com deficiência que necessitar de tempo adicional
						para a realização das provas deverá requerê-lo no prazo estabelecido
						no edital do concurso.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 59. A pessoa com deficiência, resguardadas as condições
						especiais previstas nesta Lei, participará de concurso em equidade
						de condições com os demais candidatos no que concerne:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - ao conteúdo das provas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - à avaliação e aos critérios de aprovação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - ao horário e local de aplicação das provas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - à nota mínima exigida para todos os demais candidatos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A igualdade de condições a que se refere o caput deste artigo
						também compreende:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - adaptação de provas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - apoio necessário, previamente solicitado pelo candidato com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - avaliação de provas discursivas ou de redação por uma comissão
						composta por ao menos um profissional com formação específica na
						área da deficiência que acarreta especificidades na escrita da
						língua.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Considera-se adaptação de provas todos os meios utilizados para
						permitir a realização da prova pelo candidato com deficiência, assim
						compreendendo, entre outros:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - a disponibilidade da prova em braile e, quando solicitado, o
						serviço do ledor apto, ou outros meios existentes, nos casos de
						candidato com deficiência visual;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - a disponibilidade de intérprete, quando solicitado, nos casos
						de candidato surdo ou com deficiência auditiva;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - tempo adicional para a realização das provas, inclusive para
						preenchimento do cartão-resposta, quando for o caso, e se
						necessário, conforme as características da deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 60. A publicação do resultado final do concurso será feita em
						duas listas, contendo a primeira a pontuação de todos os candidatos,
						inclusive daqueles com deficiência, e a segunda somente a pontuação
						destes últimos, de acordo com a ordem classificatória.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A nomeação dos candidatos com deficiência aprovados
						far-se-á concomitantemente com os dos demais candidatos aprovados,
						observada a ordem de classificação das listas de que trata o caput
						deste artigo, convocando-se as pessoas com deficiência para
						nomeação, atendendo-se ao percentual estabelecido em edital, de modo
						a entrecruzarem-se as listas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 61. O órgão responsável pela realização do concurso terá a
						assistência de equipe multiprofissional composta por três
						profissionais capacitados e atuantes nas áreas de deficiência em
						questão, sendo um deles médico e dois profissionais integrantes da
						carreira almejada pelo candidato.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A equipe multiprofissional emitirá parecer observando:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - as informações prestadas pelo candidato no ato da inscrição,
						inclusive as constantes do laudo médico;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - a natureza das atribuições e tarefas essenciais do cargo ou da
						função a desempenhar;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - a viabilidade das condições de acessibilidade e as adequações
						do ambiente de trabalho na execução das tarefas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - a possibilidade de uso, pelo candidato, de equipamentos ou
						meios que habitualmente utilize;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - a CIF e outros padrões reconhecidos nacional e
						internacionalmente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A equipe multiprofissional avaliará a compatibilidade entre as
						atribuições dos cargos e a deficiência do candidato apenas durante o
						estágio probatório.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 62. A avaliação do servidor ou empregado com deficiência,
						durante ou após o período de estágio probatório, deverá considerar
						as condições oferecidas pelo órgão para o efetivo desempenho de suas
						atribuições.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Redução da Jornada de Trabalho
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 63. Assegura ao funcionário ocupante de cargo público ou
						militar, que seja pai ou mãe, filho ou filha, cônjuge, companheiro
						ou companheira, tutor ou tutora, curador ou curadora ou que detenha
						a guarda judicial da pessoa com deficiência congênita ou adquirida,
						de qualquer idade, a redução da carga horária semanal de seu cargo,
						sem prejuízo de remuneração, nos termos desta Seção.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A redução de carga horária, de que trata o caput deste artigo,
						destina-se ao acompanhamento do dependente no seu processo de
						habilitação ou reabilitação ou às suas necessidades básicas diárias,
						podendo ser consecutivo, intercalado, alternado ou escalonado,
						conforme necessidade ou programa do atendimento pertinente, mediante
						requerimento formulado à Secretaria de Estado responsável pela
						política pública da administração e da previdência instruído com a
						indicação da necessidade da jornada a ser reduzida.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A dispensa ocorrerá para cargo de quarenta horas semanais e
						jornada de oito horas diárias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Havendo acumulação legal de dois cargos na esfera do Poder
						Executivo Estadual, de vinte horas semanais cada um e jornada de
						quatro horas diárias cada um, a dispensa será no cargo de menor
						valor ou daquele que for mais conveniente para o atendimento à
						pessoa deficiente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º A dispensa deverá observar o acúmulo máximo de quarenta horas
						semanais e jornada de oito horas diárias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 5º Ao servidor alcançado pela dispensa concedida por esta Lei é
						vedada a ocu pação de qualquer atividade de natureza trabalhista,
						remunerada ou não, enquanto perdurar a dispensa, seja em qualquer
						horário ou qualquer região geográfica.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 6º Caberá à Secretaria de Estado responsável pela política pública
						da administração e da previdência manifestar-se sobre o requerimento
						de redução da carga horária em até trinta dias contados da data do
						protocolo do requerimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 7º A redução prevista no caput deste artigo será regulamentada
						pela Secretaria de Estado responsável pela política pública da
						administração e da previdência em até noventa dias contados da data
						da publicação da presente Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Habilitação e Reabilitação Profissional
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 64. A pessoa com deficiência, beneficiária ou não do Regime de
						Previdência Social, tem direito às prestações de habilitação e
						reabilitação profissional para capacitar-se a obter trabalho,
						conservá-lo e progredir profissionalmente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 65. O direito à habilitação e reabilitação compreende:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - o provimento de ações terapêuticas em favor da pessoa com
						deficiência, visando habilitá-la ou reabilitá-la, sempre que
						possível, eliminando ou minorando os efeitos da deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - a concessão de financiamento para a aquisição de equipamentos
						de uso pessoal que permitam a correção, diminuição e eliminação de
						barreiras, por meio de programas próprios do Estado e Municípios.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O financiamento de que trata o inciso II deste
						artigo será concedido pelo Poder Executivo, por meio de instituição
						financeira, mediante as seguintes condições:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - comprovação do uso exclusivamente pessoal dos equipamentos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - caráter clínico médico para fisioterapia ou terapêutico
						ocupacional dos equipamentos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - comprometimento inferior a 10% (dez por cento) da renda mensal
						familiar no pagamento das parcelas e taxa de juros não superior a
						12% (doze por cento) ao ano.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 66. Entende-se por habilitação e reabilitação profissional o
						processo orientado a possibilitar que a pessoa com deficiência, a
						partir da identificação de suas potencialidades laborativas, adquira
						o nível suficiente de desenvolvimento profissional para ingresso e
						reingresso no mercado de trabalho e participe da vida comunitária.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os serviços de habilitação e reabilitação profissional deverão
						estar dotados dos recursos necessários para atender todas as pessoas
						com deficiência, independentemente da causa de sua deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As pessoas com deficiência atendidas pelos serviços de
						habilitação e reabilitação deverão ser preparadas para o mercado de
						trabalho, a fim de exercer uma função adequada às suas
						especificidades, assegurando a possibilidade de obter, conservar e
						progredir na carreira.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 67. A orientação profissional será prestada pelos
						correspondentes serviços de habilitação e reabilitação profissional,
						tendo em conta as potencialidades das pessoas com deficiência,
						identificadas com base em relatório de equipe multiprofissional, que
						deverá considerar:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - educação escolar efetivamente recebida e por receber;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - expectativas de promoção social;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - possibilidades de emprego existentes em cada caso;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - motivações, atitudes e preferências profissionais; e
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - necessidades do mercado de trabalho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 68. Os órgãos e as entidades da administração pública estadual
						direta e indireta, responsáveis pela formação de recursos humanos,
						devem dispensar ao assunto objeto desta Seção tratamento prioritário
						e adequado, viabilizando, sem prejuízo de outras, as seguintes
						medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - formação e qualificação de profissionais da educação para a
						educação especial e inclusiva especializados na habilitação e
						reabilitação, bem como de instrutores e professores para a formação
						profissional;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - formação e qualificação profissional, nas diversas áreas de
						conhecimento e de recursos humanos que atendam às demandas da pessoa
						com deficiência; e
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - incentivo e apoio à pesquisa e ao desenvolvimento tecnológico
						em todas as áreas do conhecimento relacionadas à pessoa com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO VI
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						ASSISTÊNCIA SOCIAL
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 69. A garantia do atendimento para a pessoa com deficiência
						estará de acordo com o estabelecido na Lei Federal nº 8.742, de 7 de
						dezembro de 1993, em especial em seu art. 2º, que estabelece os
						objetivos da Política Nacional da Assistência Social.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 70. O poder público garantirá os direitos socioassistenciais
						para as pessoas com deficiência, previstos na Política Nacional da
						Assistência Social - PNAS em cumprimento às normativas do Sistema
						Único de Assistência Social - SUAS, na execução dos programas,
						projetos, serviços e benefícios assistenciais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. É garantido à pessoa com deficiência o Benefício de
						Prestação Continuada - BPC/LOAS, nos termos da legislação federal
						vigente, assegurado que as equipes da Assistência Social deverão
						prestar o atendimento prioritário às pessoas com deficiência e suas
						famílias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 71. Compete ao Estado o monitoramento e a avaliação da Política
						de Assistência Social, bem como assessorar os municípios para o
						desenvolvimento dos programas, projetos, serviços e benefícios
						assistenciais à pessoa com deficiência, cabendo a manifestação do
						Conselho Estadual de Assistência Social - CEAS no que tange às suas
						atribuições legais, inclusive no que diz respeito ao controle
						social.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 72. Para o cumprimento no disposto neste Capítulo, o Governo do
						Estado poderá manter parcerias com entidades civis de direito
						privado sem fins lucrativos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 73. A execução dos atendimentos voltados para a pessoa com
						deficiência atenderá aos princípios previstos na Tipificação de
						Serviços do Sistema Único de Assistência Social e demais legislações
						e normativas vigentes, pertinentes à organização destes
						atendimentos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Proteção Social Básica
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 74. Assegura às pessoas com deficiência o acesso à Proteção
						Social Básica - PSB, que tem como objetivo prevenir situações de
						risco, por meio da execução de programas, projetos, benefícios e
						serviços que promovam o desenvolvimento de potencialidades,
						aquisições e fortalecimento de vínculos familiares e comunitários.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 75. Caberá ao poder público por meio da PSB viabilizar o
						atendimento na unidade pública municipal - Centro de Referência de
						Assistência Social - CRAS, para a execução dos programas, projetos,
						benefícios e serviços de assistência social, dentre eles o Serviço
						de Proteção e Atendimento Integral à família - PAIF e demais
						serviços voltados à pessoa com deficiência, monitorando e avaliando
						os serviços prestados na rede socioassistencial do município.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Proteção Social Especial
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 76. Assegura à pessoa com deficiência o acesso à Proteção
						Social Especial - PSE, que tem por objetivo desenvolver programas,
						projetos e serviços especializados às famílias e aos indivíduos que
						se encontram em situação de risco pessoal e social, por violação de
						direito a exemplo das situações de abandono, negligência e maus
						tratos físicos, psíquicos, abuso sexual, deficiência, situação de
						dependência, entre outras.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Média Complexidade
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 77. Caberá ao poder público, por meio da Proteção Social
						Especial de Média Complexidade, viabilizar o atendimento na unidade
						pública - Centro Especializado de Referência de Assistência Social -
						CREAS, para a execução dos programas, projetos, benefícios e
						serviços de assistência social de média complexidade, dentre eles, o
						Serviço de Proteção e Atendimento Especializado às Famílias e aos
						Indivíduos - PAEFI, e demais serviços voltados às pessoas com
						deficiência e suas famílias com grau de dependência, e que tiverem
						suas limitações agravadas por violações de direitos, que elevam a
						desvalorização da potencialidade e da capacidade da pessoa, dentre
						outras que agravam a dependência e comprometem o desenvolvimento da
						autonomia.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Alta Complexidade
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 78. O Poder Público fica incumbido de assegurar às pessoas com
						deficiência com vínculos fragilizados ou rompidos, o acolhimento de
						acordo com as especificidades, a fim de garantir a proteção
						integral, por meio das modalidades previstas no SUAS.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO VII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO DIREITO AO TRANSPORTE - &quot;PASSE LIVRE&quot;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 79. Assegura o transporte gratuito às pessoas com deficiência
						em linhas de transporte intermunicipal e em linhas de ônibus que
						compõem as redes integradas de transporte coletivo das regiões
						metropolitanas, mediante apresentação de comprovação do Passe Livre.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 80. A concessão de transporte gratuito previsto no art. 79
						desta Lei estende-se às pessoas com as seguintes patologias
						crônicas, desde que em tratamento continuado, fora do município de
						sua residência:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - insuficiência renal crônica;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - doença de Crohn;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - câncer;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - transtornos mentais graves;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - HIV, mesmo que com carga viral indetectável por adesão efetiva
						ao tratamento;
						<strong
							>(Redação do inciso dada pela Lei Nº 21259 DE 07/11/2022).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						VI - mucoviscosidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - hemofilia;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - esclerose múltipla.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - transtorno do espectro autista.<strong>
							(Inciso acrescentado pela Lei Nº 20021 DE 13/11/2019).</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 81. As empresas que exploram, através de concessão ou permissão
						do Estado, o transporte coletivo intermunicipal no estado do Paraná,
						ficam obrigadas a adaptar os veículos de suas respectivas frotas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Entende-se por adaptação todas as alterações
						previstas na legislação federal ou estadual vigentes.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 82. Os beneficiários da isenção tarifária de que trata este
						Capítulo deverão promover a reserva da passagem com antecedência
						mínima de três horas do embarque, nos casos de linhas de transporte
						coletivo intermunicipal.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 83. A empresa transportadora que recusar ou dificultar a
						utilização do passe livre, a qualquer pretexto, sofrerá as sanções
						previstas na legislação estadual vigente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 84. As empresas que exploram o transporte coletivo
						intermunicipal deverão comunicar aos estabelecimentos comerciais
						onde são efetuadas as paradas para as refeições que passarão a
						operar com ônibus adaptados para o transporte de pessoas com
						deficiência, a fim de que esses<br />
						estabelecimentos contem com banheiros e demais instalações adaptadas
						para receber esses usuários nos termos desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os estabelecimentos comerciais de que trata este
						artigo que não atenderem ao pedido de adaptação deverão ser
						substituídos por outros que apresentem condições de receber usuários
						com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 85. Somente poderão se beneficiar desta isenção usuários do
						transporte coletivo cuja renda bruta familiar per capita não seja
						superior a dois salários mínimos estadual do Grupo I.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 86. A isenção de tarifa à pessoa com deficiência, mediante
						expedição de carteira específica, será concedida pelo setor
						designado pela Secretaria de Estado responsável pelas políticas
						públicas para as pessoas com deficiência, após análise e conferência
						dos seguintes documentos comprobatórios:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - requerimento de concessão do passe livre em formulário
						específico, contendo declaração de carência de recursos financeiros
						pelo interessado, procurador ou representante legal, juntando
						comprovante de rendimentos do requerente e das pessoas com as quais
						reside, dirigido à Secretaria de Estado responsável pelas políticas
						públicas para as pessoas com deficiência, conforme modelo a ser
						disponibilizado pela mesma Secretaria;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - laudo médico de avaliação fornecido por profissional habilitado
						no Sistema Único de Saúde - SUS, da Secretaria de Estado responsável
						pela política pública da saúde, ou da Secretaria de Saúde do
						município de domicílio com identificação do paciente, o qual deverá
						conter informações sobre a deficiência, sobre necessidade de
						acompanhante, se a deficiência é permanente ou necessita de nova
						avaliação, bem como a data da reavaliação, entre outras informações
						conforme modelo definido pela Resolução nº 246, de 7 de abril de
						2010 da Secretaria de Estado responsável pela política pública da
						saúde ou pelo modelo que venha a substituí-la;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - ficha cadastral do requerente conforme modelo emitido pelo
						Governo do Estado do Paraná, por intermédio da Secretaria de Estado
						responsável pela política pública da pessoa com deficiência, a ser
						preenchida junto ao Conselho Estadual dos Direitos da Pessoa com
						Deficiência do Paraná - COEDE/PR;
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						IV - uma foto 3X4 recente, sem rasuras ou danificações, viabilizando
						a identificação imediata do requerente;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - uma fotocópia legível da Carteira de Identidade (RG);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - uma fotocópia legível do Cadastro de Pessoa Física (CPF);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - uma fotocópia do comprovante de residência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Nos casos em que houver a prescrição médica da necessidade de
						acompanhante, deverá ser indicado no requerimento de concessão do
						benefício o nome de até três pessoas maiores de dezoito anos,
						anexando a este, fotocópia do RG legível destas pessoas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O Governo do Estado do Paraná, por intermédio da Secretária de
						Estado da Saúde, emitirá nota técnica disponibilizando novo modelo
						de laudo médico atendendo às disposições desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Na hipótese do interessado não ser alfabetizado ou estar
						impossibilitado de assinar, será admitida a impressão digital na
						presença do funcionário do órgão autorizador que fará a
						identificação, ou a assinatura a rogo, na presença de duas
						testemunhas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º A falsa declaração ou comprovação de renda mensal sujeitará o
						infrator às penas da lei, bem como à perda do benefício.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 87. Nos casos de deficiência permanente, fica dispensada a
						apresentação de laudo médico na renovação da concessão do passe
						livre, devendo apresentar novamente os demais documentos exigidos no
						art. 86 desta Lei.
						<strong
							>(Redação do artigo dada pela Lei Nº 18453 DE 14/04/2015).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Art. 88. Os procedimentos administrativos para requerimento e
						concessão do benefício do passe livre serão regulamentados pelo
						Poder Executivo do Estado do Paraná, no prazo de noventa dias pela
						Secretaria de Estado responsável pela política pública voltada à
						pessoa com deficiência e pela Secretaria de Estado responsável pelo
						transporte.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Enquanto não estiver em vigor a regulamentação do
						procedimento administrativo necessário à concessão do passe livre,
						previsto no caput deste artigo, deverá ser utilizado o procedimento
						vigente até a publicação desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 89. O requerimento do passe livre será indeferido nos casos de:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - documentação incorreta ou incompleta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - renda bruta per capta superior a dois salários mínimos estadual
						do Grupo I;
						<strong
							>(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						III - tratamento realizado no município de sua residência, quando se
						tratar de pessoa com doença crônica.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os requerimentos indeferidos serão restituídos ao requerente,
						via correio, mediante ofício especificando o motivo do
						indeferimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Sanado o motivo do indeferimento, este poderá ser reenviado ao
						setor responsável da Secretaria de Estado responsável pelas
						políticas públicas para as pessoas com deficiência para nova
						análise.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 90. O requerente que tiver o benefício do passe livre
						indeferido poderá requerer a revisão da decisão pelo Conselho
						Estadual dos Direitos da Pessoa com Deficiência - COEDE/PR.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 91. A carteira do passe livre concedida às pessoas
						beneficiárias desta Lei terá validade de quatro anos, exceto nos
						casos em que houver indicação em laudo médico sobre necessidade de
						nova avaliação em prazo inferior.
						<strong
							>(Redação do artigo dada pela Lei Nº 21259 DE 07/11/2022).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Art. 92. A carteira do passe livre concedida à pessoa com doença
						crônica terá validade de dois anos, exceto nos casos em que houver
						indicação em laudo médico sobre a necessidade de nova avaliação em
						prazo inferior a dois anos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 93. Na carteira concedida ao beneficiário deverão constar os
						seguintes dados:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - nome e dados de identificação do beneficiário;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - foto do beneficiário;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<span class="revogado"
							><strong
								>(Revogado pela Lei Nº 21259 DE 07/11/2022):</strong
							></span
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<span class="revogado">
							III - indicação da deficiência ou doença crônica apontada no laudo
							médico;</span
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - a necessidade ou não de acompanhante;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - os dados de identificação dos acompanhantes indicados;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - data de expedição da carteira;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - data de validade da carteira.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 94. Somente terá direito à isenção tarifária de que trata este
						Capítulo o acompanhante que possuir nome e dados pessoais descritos
						na carteira do passe livre do beneficiário, restringindo-se a um
						acompanhante por viagem.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 95. A verificação pelas empresas concessionárias ou
						permissionárias da necessidade de acompanhante para o beneficiário
						será constatada mediante a conferência da inscrição na carteira
						concedida ao beneficiário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Quando solicitado pelas empresas concessionárias ou
						permissionárias de transporte, o acompanhante deverá apresentar
						documento de identificação com foto e as indicações de acompanhantes
						constantes na carteira concedida ao beneficiário, podendo esta
						solicitação ser realizada tanto no momento da aquisição da passagem
						quanto no embarque do ônibus.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 96. O beneficiário perderá o direito ao passe livre nos casos
						de:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - emissão de falsa declaração ou falsa comprovação de renda mensal
						no momento do pedido do benefício;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - uso do benefício para fins diversos dos estabelecidos nesta
						Lei;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - existência de membros da família com renda superior a dois
						salários mínimos estadual do Grupo I no momento da renovação do
						passe livre concedido (aumento da renda familiar posterior à
						concessão do benefício).
						<strong
							>(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Art. 97. As Secretarias de Saúde do Estado e dos municípios deverão
						dar ampla divulgação dos locais para avaliação, e os Conselhos
						Municipais e entidades, a que se refere este Capítulo, deverão
						também divulgar os locais para expedição das carteiras e
						procedimentos adotados para tal fim.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 98. As empresas concessionárias ou permissionárias deverão
						emitir o bilhete de passagem no ato da apresentação da carteira de
						passe livre e documento de identificação com foto.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Na emissão do bilhete de passagem para o transporte não poderão
						ser cobradas taxas referentes ao uso de balsas ou ferry-boats, taxas
						de embarque ou de pedágio.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As empresas prestadoras dos serviços deverão reservar, até três
						horas antes do embarque, no mínimo dois assentos em cada viagem,
						preferencialmente na primeira fila de poltronas para conferir
						acessibilidade às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Na hipótese de nenhum beneficiário demonstrar interesse em
						viajar dentro do prazo previsto no § 2º deste artigo, as empresas
						prestadoras dos serviços poderão colocar à venda os bilhetes de
						referidos assentos reservados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º Os funcionários das empresas transportadoras deverão auxiliar
						no embarque e desembarque dos beneficiários, tanto nos terminais das
						linhas como nos pontos de parada e apoio ao longo do itinerário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 5º As empresas transportadoras providenciarão a capacitação
						permanente de seu quadro funcional para prestar o atendimento
						adequado aos beneficiários.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 6º Os equipamentos indispensáveis à locomoção e à vida da pessoa
						com deficiência serão transportados de forma adequada, acessível e
						gratuitamente pela empresa, além de sua bagagem.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 7º No embarque deverá o beneficiário apresentar a carteira de
						isenção acompanhada de documento de identificação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 99. Compete ao Departamento de Estradas de Rodagem do Estado do
						Paraná - DER/PR e à Coordenação da Região Metropolitana de Curitiba
						- COMEC a fiscalização da utilização do benefício.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 100. As adaptações a serem feitas nos veículos das frotas das
						empresas concessionárias ou permissionárias do transporte rodoviário
						coletivo intermunicipal de passageiros serão definidas pela
						Secretaria de Estado responsável pela política pública de
						infraestrutura e logística, de acordo com as normas técnicas de
						acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO VIII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA CULTURA, DO DESPORTO, DO TURISMO, DO LAZER E DA COMUNICAÇÃO
						SOCIAL
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 101. Os órgãos e as entidades da administração pública estadual
						direta e indireta responsáveis pela cultura, pelo desporto, pelo
						turismo, pelo lazer e pela comunicação social, dispensarão
						tratamento prioritário e adequado aos assuntos objeto deste
						Capítulo, com vista a viabilizar, sem prejuízo de outras, as
						seguintes medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - promoção do acesso da pessoa com deficiência aos meios de
						comunicação social:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						a) acesso às informações através das legendas, áudio descrição e
						interpretação em Libras, em conformidade com a Lei nº 12.095, de 11
						de março de 1998;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						b) desenvolvimento de programas e trabalhos nos meios de
						comunicação, visando ao esclarecimento das necessidades das pessoas
						com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						c) implantação de programas de impressão em braile ou fonte ampliada
						nos meios de comunicação escrita;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						d) criação de programa de informação pública pautando temáticas
						relacionadas às áreas das deficiências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - acesso das pessoas com deficiência a museus, arquivos,
						bibliotecas e afins;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - incentivos para o exercício de atividades culturais, mediante:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						a) participação da pessoa com deficiência em concursos de prêmios no
						campo das artes e das letras;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						b) promoção de concursos culturais no campo das artes e das letras
						que estimulem o potencial da pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						c) exposições, publicações e representações artísticas de pessoa com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						d) incentivo à produção cultural para as pessoas com deficiência nas
						áreas de música, artes cênicas, audiovisual, literatura, artes
						visuais, folclore, artesanato, dentre outras manifestações
						culturais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						e) cursos e oficinas culturais acessíveis às pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - prática desportiva e paradesportiva nos seguintes moldes:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						a) prática desportiva e paradesportiva formal e não formal como
						direito de cada um;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						b) meios que facilitem o exercício de atividades desportivas e
						paradesportivas entre as pessoas com deficiência e suas entidades
						representativas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						c) acessibilidade às instalações desportivas dos estabelecimentos de
						ensino, desde a educação infantil até o nível superior;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						d) inclusão de atividades desportivas e paradesportivas nos
						estabelecimentos de ensino, desde a educação infantil até o nível
						superior;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - incentivo ao turismo para as pessoas com deficiência, através
						das ações:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						a) publicação e o uso de guias de turismo com informações acessíveis
						às pessoas com deficiência e às características próprias de cada
						área específica de deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						b) ampliação do turismo às pessoas com deficiência, mediante a
						oferta de instalações hoteleiras acessíveis e de serviços adaptados
						de transporte.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - incentivo e criação de ações e iniciativas de lazer inclusivas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<br />
						Art. 102. Os órgãos e as entidades da administração pública estadual
						direta e indireta, promotores ou financiadores de atividades
						desportivas e de lazer devem concorrer técnica e financeiramente
						para obtenção dos objetivos deste Capítulo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Serão prioritariamente apoiadas as manifestações
						desportivas e paradesportivas de rendimento e a educacional,
						compreendendo as atividades de:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - desenvolvimento de recursos humanos especializados em cada uma
						das áreas de deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - promoção de competições desportivas internacionais, nacionais,
						estaduais e municipais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - pesquisa científica, desenvolvimento tecnológico, documentação
						e informação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - construção, ampliação, recuperação e adaptação de instalações
						desportivas, paradesportivas e de lazer.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 103. Os próprios eventos esportivos estaduais terão, em seu
						calendário, datas reservadas para a realização de eventos para as
						pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 104. O Estado promoverá a realização dos eventos de que trata o
						art. 103 desta Lei, admitida a participação de entidades não
						governamentais na sua promoção.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para a elaboração da programação dos eventos serão
						ouvidas as pessoas com deficiência e as instituições que desenvolvem
						ações para as pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 105. Os programas de cultura, de desporto, de paradesporto, de
						turismo e de lazer do estado deverão atender às pessoas com
						deficiência, prevendo ações inclusivas, assegurada a acessibilidade
						dos programas e a busca da igualdade de oportunidades.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º O Poder Público instituirá programas de incentivo fiscal às
						pessoas físicas e jurídicas que apoiarem financeiramente os eventos
						e as práticas desportiva, cultural, de turismo e de lazer das
						pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As pessoas físicas e jurídicas que recebem recursos públicos ou
						incentivos para programas, projetos e ações nas áreas de cultura,
						desporto, turismo e lazer deverão garantir a inclusão de pessoas com
						deficiência, proporcionando local reservado e transporte específico.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Em caso de evento de calendário oficial do Estado do Paraná, as
						empresas de transporte intermunicipal deverão assegurar a
						disponibilidade de transporte acessível à pessoa com deficiência,
						desde que comunicadas com antecedência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 106. Informações essenciais sobre produtos e serviços nas áreas
						de cultura, desporto, paradesporto, turismo e lazer deverão ter
						versões acessíveis às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 107. O Poder Público colocará à disposição pela rede mundial de
						computadores, arquivos com o conteúdo de livros:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - de domínio público, conforme disposto na legislação em vigor;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - autorizados pelos detentores dos respectivos direitos autorais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - adquiridos pelo Poder Público para distribuição gratuita no
						âmbito de programas criados com este propósito.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os arquivos digitais aos quais se referem o caput deste artigo
						deverão ser conversíveis em áudio, em sistema braile ou outro
						sistema de leitura digital.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Os arquivos serão colocados à disposição de bibliotecas
						públicas, de entidades de educação de pessoas com deficiência, de
						usuários com deficiência e das instituições de ensino públicas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 108. O Poder Executivo do Estado do Paraná garantirá a inclusão
						das pessoas com deficiência, inclusive crianças, mediante instalação
						de equipamentos especialmente desenvolvidos para o lazer e a
						recreação, nas praças e parques estaduais assegurando o acesso até
						esses equipamentos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O Poder Executivo do Estado do Paraná priorizará as
						praças e parques que possibilitem acesso e atendimento do maior
						número de pessoas com deficiência na instalação dos equipamentos
						referidos no caput deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 109. O Poder Público apoiará preferencialmente os congressos,
						seminários, oficinas e demais eventos científicos culturais que
						ofereçam, mediante solicitação, apoio às pessoas com deficiência
						auditiva, visual e surdez, tais como tradutores e intérpretes de
						Libras, ledores, guias intérpretes, ou tecnologias de informação e
						comunicação, tais como a transcrição eletrônica simultânea e a
						audiodescrição.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 110. Ficam os hotéis, restaurantes e similares, que possuam
						cardápios como meios informativos de seus produtos aos clientes,
						obrigados a produzir e dispor de exemplares em braile e fonte
						ampliada para atendimento às pessoas com deficiência visual.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para efeitos desta Lei, considera-se cardápio como
						sendo o encarte portfólio informativo do rol de produtos e serviços
						oferecidos habitualmente aos consumidores clientes dos
						estabelecimentos comerciais referidos no caput deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO IX
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA ACESSIBILIDADE
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Disposições Preliminares
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 111. A acessibilidade é condição de alcance para a utilização,
						com segurança e autonomia, total ou assistida, dos espaços,
						mobiliários e equipamentos urbanos, das edificações de uso público,
						coletivo e uso privado, dos transportes e dos dispositivos, dos
						sistemas e dos meios de comunicação e informação, por pessoa com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A acessibilidade para as pessoas com deficiência será garantida
						mediante supressão de barreiras e de obstáculos nas vias e espaços
						públicos, no mobiliário urbano, na construção e reforma de edifícios
						e nos meios de transporte e de comunicação, através das seguintes
						medidas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - elaboração de planos de acessibilidade como parte integrante dos
						planos diretores e dos planos de transporte urbano integrado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - planejamento e urbanização de espaços de uso público, inclusive
						vias, parques e praças, de forma a torná-los acessíveis para as
						pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - atendimento prioritário e diferenciado às pessoas com
						deficiência, prestado pelos órgãos da administração pública, bem
						como pelas empresas e instituições privadas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - construção, ampliação, reforma e adequação das edificações de
						uso público, uso coletivo e uso privado, inclusive os equipamentos
						esportivos e de lazer, na forma desta Lei e demais normas em vigor,
						de forma a que se tornem acessíveis para as pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - atendimento aos princípios do desenho universal na concepção e
						implantação de projetos arquitetônicos, urbanísticos e de
						comunicação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - reserva de espaços e lugares específicos para pessoas com
						deficiência, considerando suas especificidades, em teatros, cinemas,
						auditórios, salas de conferência, museus, bibliotecas e ambientes de
						natureza similar;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - reserva de vagas específicas, devidamente sinalizadas, para
						veículos que transportem pessoas com deficiência, em garagens e
						estacionamentos nas edificações e demais espaços urbanos de uso
						público e coletivo;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - concepção, organização, implantação e adequação dos veículos
						e da infraestrutura de todos os sistemas de transporte coletivo,
						público ou privado, aos requisitos de acessibilidade estabelecidos
						na legislação e nas demais normas de acessibilidade em vigor;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - implantação de sinalização ambiental, visual e tátil para
						orientação de pessoas com deficiência nas edificações de uso
						público, uso coletivo e uso privado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - adoção de medidas, nas políticas e programas habitacionais de
						interesse social, que assegurem a acessibilidade das pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - utilização de instrumentos e técnicas adequadas que tornem
						acessíveis os sistemas de comunicação e sinalização às pessoas com
						deficiência no sentido de lhes assegurar o acesso à informação, à
						comunicação e aos demais direitos fundamentais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - pessoal capacitado para prestar atendimento às pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - disponibilidade de área especial para embarque e desembarque
						de pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIV - divulgação, em lugar visível, do direito de atendimento
						prioritário das pessoas com deficiência e existência de local de
						atendimento específico e acessível.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O direito ao tratamento diferenciado que deverá ser prestado à
						pessoa com deficiência, dentre outras medidas, compreende:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - mobiliário de recepção e atendimento obrigatoriamente adaptados
						à altura e à condição física de pessoas em cadeira de rodas,
						conforme estabelecido nas normas técnicas de acessibilidade em
						vigor;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - serviços de atendimento para pessoas com deficiência auditiva e
						surdos prestados por intérpretes ou pessoas capacitadas em Libras e
						no trato com aquelas que assim não se comuniquem, bem como para
						pessoas surdo-cegas, prestados por guias intérpretes ou pessoas
						capacitadas neste tipo de atendimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - implementação de mecanismos que assegurem a acessibilidade das
						pessoas com deficiência visual nos portais e sítios eletrônicos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - admissão de entrada e permanência de cão-guia junto de pessoa
						com deficiência ou de treinador nas edificações de uso público, uso
						coletivo, mesmo que de propriedade privada, ou de uso privado,
						mediante apresentação da carteira de vacina atualizada do animal;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - a existência de pelo menos um telefone de atendimento adaptado
						para comunicação de pessoas com deficiência auditiva e surdos pelos
						órgãos da<br />
						administração pública direta indireta e fundacional, empresas
						prestadoras de serviços públicos, instituições financeiras, bem como
						nas demais edificações de uso público e de uso coletivo, mesmo que
						de propriedade privada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Consideram-se edificações de uso público aquelas administradas
						por entidades da Administração Pública, Direta e Indireta, ou por
						empresas prestadoras de serviços públicos e destinadas ao público em
						geral.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º Consideram-se edificações de uso coletivo aquelas destinadas às
						atividades de natureza habitacional, comercial, hoteleira, cultural,
						esportiva, financeira, turística, recreativa, social, religiosa,
						educacional, industrial e de saúde, inclusive as edificações de
						prestação de serviços de atividades da mesma natureza, mesmo que de
						propriedade privada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 5º Consideram-se edificações de uso privado aquelas destinadas à
						habitação, que podem ser classificadas como unifamiliar ou
						multifamiliar.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 6º Considera-se desenho universal a concepção de espaços,
						artefatos e produtos que visam a atender simultaneamente todas as
						pessoas, com diferentes características antropométricas e
						sensoriais, de forma autônoma, segura e confortável, constituindo-se
						nos elementos ou soluções que compõem a acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 112. Para os fins deste Capítulo são estabelecidas as seguintes
						definições:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - barreiras: qualquer entrave ou obstáculo que limite, dificulte
						ou impeça o acesso, a liberdade de movimento, a circulação segura
						das pessoas, a comunicação ou o acesso à informação, classificadas
						em:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						a) barreiras urbanísticas: aquelas existentes nas vias públicas e
						nos espaços de uso público;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						b) barreiras nas edificações: as existentes no entorno e interior
						das edificações de uso público e coletivo e no entorno e áreas
						internas de uso comum nas edificações de uso privado multifamiliar;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						c) barreiras nos transportes: aquelas existentes nos meios de
						transportes;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						d) barreiras nas comunicações e informações: qualquer entrave ou
						obstáculo que dificulte ou impossibilite a expressão ou o
						recebimento de mensagens por intermédio dos dispositivos, dos meios
						ou dos sistemas de comunicação, sejam ou não de massa; bem como
						aqueles que dificultem ou impossibilitem o acesso à informação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - elemento da urbanização: qualquer componente das obras de
						urbanização, tais como os referentes à pavimentação, ao calçamento,
						ao saneamento, aos encanamentos para esgotos, à distribuição de
						energia elétrica, à iluminação pública, ao abastecimento e à
						distribuição de água, ao paisagismo e os que materializam as
						indicações do planejamento urbanístico;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - mobiliário urbano: o conjunto de objetos existentes nas vias e
						espaços públicos, superpostos ou adicionados aos elementos da
						urbanização ou da edificação, de forma que sua modificação ou
						traslado não provoque alterações substanciais nestes elementos, tais
						como semáforos, postes de sinalização e similares, cabines
						telefônicas, fontes públicas, lixeiras, toldos, marquises, quiosques
						e quaisquer outros de natureza análoga.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Elementos de Urbanização
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 113. O planejamento e a urbanização das vias públicas, dos
						parques e dos demais espaços de uso público deverão ser concebidos e
						executados de forma a torná-los acessíveis para as pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 114. As vias públicas, os parques e os demais espaços de uso
						público existentes, assim como as respectivas instalações de
						serviços e mobiliários urbanos deverão ser adaptados, obedecendo-se
						ordem de prioridade que vise à maior eficiência das modificações, no
						sentido de promover a plena acessibilidade às pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 115. Os parques de diversões, públicos e privados, devem
						adaptar, no mínimo, 5% (cinco por cento) de cada brinquedo e de seus
						equipamentos e identificá-los para possibilitar sua utilização por
						pessoas com deficiência, tanto quanto tecnicamente possível.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 116. O projeto e o traçado dos elementos de urbanização,
						públicos e privados, de uso comunitário, nestes compreendidos os
						itinerários e as passagens de pedestres, os percursos de entrada e
						de saída de veículos, as escadas e rampas, deverão observar os
						parâmetros estabelecidos pelas normas técnicas de acessibilidade em
						vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 117. Os parques, praças, jardins e espaços livres públicos
						deverão dispor, pelo menos, de duas instalações sanitárias
						acessíveis, sendo uma masculina e uma feminina, em conformidade com
						as normas técnicas de acessibilidade em vigor, incluindo
						identificação e rota acessível.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 118. Em todas as áreas de estacionamento de veículos,
						localizadas em vias ou em espaços públicos, deverão ser reservadas
						vagas próximas dos acessos de circulação de pedestres, devidamente
						sinalizadas, para veículos que transportem pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As vagas às quais se refere o caput deste artigo
						deverão ser em número equivalente a 2% (dois por cento) do total,
						garantida, no mínimo, uma vaga, devidamente sinalizada e com as
						especificações técnicas de desenho e traçado de acordo com as normas
						técnicas em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 119. Os estacionamentos, públicos e privados, e os fornecedores
						de serviços de manobra e guarda de veículos em geral estão obrigados
						a conceder aos veículos automotores utilizados por pessoas com
						deficiência, período mínimo de gratuidade do pagamento de tarifa
						equivalente ao dobro daquele concedido aos demais veículos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os estabelecimentos que não dispuserem de período mínimo de
						gratuidade do pagamento de tarifa deverão conceder o período de
						trinta minutos para a pessoa com deficiência que usufruiu do serviço
						de estacionamento, assegurando seu deslocamento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O detalhamento técnico do disposto no caput deste artigo é
						definido em regulamento, nos termos da Lei Federal nº 10.098, de 19
						de dezembro de 2000.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 120. Os sinais de tráfego, semáforos, postes de iluminação ou
						quaisquer outros elementos verticais de sinalização que devam ser
						instalados em itinerário ou espaço de acesso para pedestres deverão
						ser dispostos de forma a não dificultar ou impedir a circulação, e
						de modo que possam ser utilizados com a máxima comodidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 121. Os semáforos para pedestres instalados nas vias públicas
						deverão estar equipados com mecanismo que emita sinal sonoro suave,
						intermitente e sem estridência ou com mecanismo alternativo, que
						sirva de guia ou orientação para a travessia de pessoas com
						deficiência visual.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A adequação dos semáforos conforme previsto neste
						artigo será efetuada conforme disponibilidade orçamentária, de forma
						gradativa, sendo, para tanto, considerados prioritários os locais
						próximos às instituições<br />
						voltadas às pessoas com deficiência, periculosidade dos cruzamentos
						e a intensidade de tráfego de veículos automotores.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 122. Os elementos do mobiliário urbano deverão ser projetados e
						instalados em locais que permitam serem utilizados pelas pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 123. A infração às disposições desta Seção acarretará ao
						responsável as seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequar no prazo de quinze
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - multa de 680 (seiscentos e oitenta) UPF/PR no caso da não
						adequação no prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de 1360 (mil trezentos e sessenta) UPF/PR, em caso de
						reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade a Estabelecimentos
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Adequação dos Guichês
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 124. Os terminais rodoviários, estações de transporte, cinemas,
						teatros, casa de shows, agências bancárias, correios, lotéricas ou
						órgãos públicos e estabelecimentos de acesso coletivo ou todo e
						qualquer outro estabelecimento que utilize guichês de atendimento no
						Estado do Paraná deverão manter ao menos um de seus guichês adequado
						à altura e condizentes às necessidades das pessoas com deficiência
						que utilizam cadeiras de rodas, para que tenham um melhor contato
						visual e de comunicação com o funcionário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 125. O descumprimento do disposto nesta Subseção sujeitará os
						responsáveis pela infração ao pagamento de multa, correspondente a
						35 (trinta e cinco) UPF/PR, não os desobrigando de seu posterior
						cumprimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Em caso de reincidência, após decorrido o prazo de
						trinta dias, contados a partir da aplicação da primeira multa, o
						valor da multa a que se refere o caput deste artigo será dobrado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Do Acesso aos Elevadores
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 126. Veda qualquer forma de discriminação à pessoa com
						deficiência no acesso aos elevadores de todos os edifícios públicos
						ou particulares, comerciais, industriais e residenciais
						multifamiliares existentes no Estado do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os responsáveis legais pela administração dos
						edifícios citados no caput deste artigo ficam autorizados a
						regulamentar o acesso a esses imóveis, assim como a circulação
						dentro deles e a utilização de suas áreas de uso comum e abertas ao
						uso público, por meio de regras gerais e impessoais não
						discriminatórias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 127. Todos os elevadores deverão possuir botoeiras internas e
						externas com informações em braile, sistema de áudio informando o
						andar e o sentido de deslocamento e piso tátil de alerta, de acordo
						com as normas técnicas em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 128. Estabelece que, para maior conforto, segurança e igualdade
						entre os usuários, o elevador social é o meio usual de transporte de
						pessoas com deficiência que utilizem as dependências dos edifícios,
						independentemente<br />
						do motivo pelo qual o fazem, desde que não estejam deslocando
						cargas, para as quais podem ser utilizados os elevadores especiais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 129. Para conferir efetividade e o conhecimento das disposições
						da presente Subseção, fica determinada a obrigatoriedade da
						colocação de avisos no interior dos edifícios.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os avisos de que tratam o caput deste artigo devem
						configurar-se em forma de cartaz, placa ou plaqueta com os seguintes
						dizeres: &quot;É vedada qualquer forma de discriminação em virtude
						de raça, sexo, cor, origem, condição social, idade, deficiência ou
						doença não contagiosa por contato social no acesso aos elevadores
						deste edifício&quot;.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Fica o responsável pelo edifício, administrador ou síndico,
						conforme for o caso, obrigado a colocar na entrada do edifício e de
						forma bem visível o aviso de que trata este artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 130. Os responsáveis pelo descumprimento do disposto nesta
						Subseção ficarão sujeitos às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequar no prazo de trinta
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - multa de 35 (trinta e cinco) UPF/PR no caso da não adequação no
						prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de setenta UPF/PR, em caso de reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Disponibilidade de Cadeiras de Rodas
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 131. É obrigatória, no âmbito do Estado do Paraná, a
						disponibilização de cadeiras de ao menos duas cadeiras de rodas,
						dentro das normas técnicas e de segurança, em todos os
						estabelecimentos públicos ou privados de uso coletivo, inclusive nas
						agências bancárias.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 132. Todos os estabelecimentos públicos ou privados de uso
						coletivo, inclusive as agências bancárias, deverão assegurar o
						atendimento das pessoas com deficiência física, em locais de fácil
						acesso à utilização das cadeiras de rodas, bem como dar publicidade
						à existência dessa facilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 133. O descumprimento do disposto nesta Subseção sujeitará os
						responsáveis pela infração ao pagamento de multa, correspondente a
						35 (trinta e cinco) UPF/PR - Unidade Padrão Fiscal do Paraná, não os
						desobrigando de seu posterior cumprimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Em caso de reincidência, após decorrido o prazo de
						trinta dias contados a partir da aplicação da primeira multa, o
						valor da multa a que se refere o caput deste artigo será dobrado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Assentos nas Áreas de Embarque e Desembarque
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 134. Será assegurada às pessoas com deficiência a participação
						no percentual de 10% (dez por cento) dos assentos reservados nas
						áreas de embarque e desembarque dos terminais rodoviários e
						rodoferroviários localizados no Estado, nos termos da Lei nº 16.397,
						de 10 de fevereiro de 2010.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 135. Os assentos de que trata o art. 134 desta Lei terão
						identificação específica que informe a sua destinação preferencial.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Estabelecimentos Públicos ou de Uso Coletivo
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 136. A construção, ampliação ou reforma de edifícios públicos
						ou privados destinados ao uso coletivo deverão ser executadas de
						modo que sejam ou se tornem acessíveis às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para os fins do disposto neste artigo, na
						construção, ampliação ou reforma de edifícios públicos ou privados
						destinados ao uso coletivo deverão ser observados, pelo menos, os
						seguintes requisitos de acessibilidade:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - nas áreas externas ou internas da edificação, destinadas à
						garagem e ao estacionamento de uso público, deverão ser reservadas
						5% (cinco por cento) das vagas próximas dos acessos de circulação de
						pedestres, devidamente sinalizadas e demarcadas, conforme legislação
						e normas técnicas em vigor, para veículos que transportem pessoas
						com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - um dos acessos ao interior da edificação deverá estar livre de
						barreiras arquitetônicas e de obstáculos que impeçam ou dificultem a
						acessibilidade de pessoa com deficiência, sendo preferencialmente o
						principal nos casos de ampliação ou reforma e obrigatoriamente o
						principal nos casos de nova construção;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - deverá ser elencada rota acessível em percursos que comuniquem
						horizontal e verticalmente todas as dependências e serviços do
						edifício, entre si e com o exterior, onde se devem cumprir os
						requisitos de acessibilidade de que trata esta Seção;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - os edifícios deverão dispor, pelo menos, de duas instalações
						sanitárias acessíveis por pavimento, sendo uma masculina e uma
						feminina;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 137. Os órgãos da administração direta, indireta, autarquias,
						empresas de economia mista e entidades privadas que prestem
						atendimento diretamente ao público ficam obrigados a implementar
						modificações físicas inclusive nas áreas destinadas ao atendimento
						público, assim como soluções técnicas nos equipamentos de
						autoatendimento, com vistas à acessibilidade e uso por pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para o efetivo cumprimento do disposto no caput
						deste artigo, entende-se como:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - modificações físicas: as adequações necessárias nas áreas
						destinadas ao atendimento ao público para a eliminação de qualquer
						entrave ou obstáculo que limite e impeça o acesso de pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - soluções técnicas: as alterações necessárias nos equipamentos e
						programas para o uso, sem restrição, das pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 138. No caso das edificações públicas já existentes, deverá ser
						observado o prazo previsto na legislação vigente para o órgão
						responsável apresentar a relação de todas as edificações existentes
						sob sua responsabilidade, indicando as que atendem e as que não
						atendem as especificações de acessibilidade e um plano de obras para
						a execução das adequações necessárias, contendo estimativa de
						custos, indicação de previsão no Plano Plurianual - PPA e na Lei
						Orçamentária Anual - LOA e programa de execução de obras.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Das Edificações de Uso Público
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 139. A construção, ampliação, reforma ou adequação de edifícios
						públicos do Estado do Paraná, incluindo os de administração
						indireta, deve atender aos preceitos da acessibilidade na
						interligação de todas as partes de<br />
						uso comum ou abertas ao público, conforme os padrões das normas
						técnicas de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 140. Os desníveis das áreas de circulação interna ou externa
						serão transpostos por meio de rampa em conformidade com as normas
						técnicas vigentes ou equipamento eletromecânico de deslocamento
						vertical, quando não for possível outro acesso mais cômodo para
						pessoas com deficiência físico motora.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. No caso das edificações já existentes e que ainda
						não atendam às especificações de acessibilidade, deverá ser
						observado o prazo definido na legislação vigente para garantir a
						acessibilidade de pessoas com deficiência, especialmente com
						deficiência físico motora.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 141. A instalação de novos elevadores ou sua adaptação, quando
						haja obrigatoriedade da presença de elevadores, deve atender ao
						disposto no art. 126 desta Lei, bem como aos padrões das normas
						técnicas de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º No caso da instalação de elevadores novos ou de troca dos já
						existentes, pelo menos um deles terá cabine que permita acesso e
						movimentação cômoda de pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Os edifícios a serem construídos com mais de um pavimento, além
						do pavimento de acesso, deverão dispor de especificações técnicas e
						de projeto que facilitem a instalação de equipamentos
						eletromecânicos de deslocamento vertical para uso das pessoas com
						deficiência, especialmente com deficiência físico motora.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º As especificações técnicas, sob responsabilidade do autor do
						projeto e do responsável técnico, as quais se referem o § 2º deste
						artigo devem atender:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - à indicação em planta aprovada pelo poder municipal do local
						reservado para a instalação do equipamento eletromecânico,
						devidamente assinada pelo autor do projeto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - à indicação da opção pelo tipo de equipamento, como elevador,
						esteira, plataforma ou similar;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - à indicação das dimensões internas e demais aspectos da cabine
						do equipamento a ser instalado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - às demais especificações em nota na própria planta, tais como a
						existência e as medidas de botoeira, espelho, informação de voz, bem
						como a garantia de responsabilidade técnica de que a estrutura da
						edificação suporta a implantação do equipamento escolhido.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 142. A construção, ampliação, reforma ou adequação deve dispor
						de sanitários acessíveis destinados ao uso por pessoa com
						deficiência, sendo distribuídos na razão de, no mínimo, uma cabine
						para cada sexo em cada pavimento da edificação, com entrada
						independente dos sanitários coletivos, obedecendo às normas técnicas
						de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As edificações já existentes deverão garantir pelo
						menos um banheiro acessível por pavimento, com entrada independente,
						distribuindo-se seus equipamentos e acessórios de modo que possam
						ser utilizados pelas pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 143. O Sistema Penal do Estado do Paraná deverá possuir
						instalações e celas aptas a receber e abrigar as pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Das Edificações de Uso Coletivo
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Estabelecimentos Financeiros
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 144. As instituições financeiras e bancárias que prestem
						atendimento diretamente ao público ficam obrigadas a implementar
						modificações físicas nas áreas destinadas ao atendimento público,
						assim como soluções técnicas nos equipamentos de autoatendimento,
						com vistas à acessibilidade e ao uso de pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para o efetivo cumprimento do disposto no caput
						deste artigo, entende-se como:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - modificações físicas: as adequações necessárias nas áreas
						destinadas ao atendimento ao público para a eliminação de qualquer
						entrave ou obstáculo que limite e impeça o acesso de pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - soluções técnicas: as alterações necessárias nos equipamentos e
						programas para o uso sem restrição das pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 145. Os estabelecimentos financeiros com agências no Estado do
						Paraná ficam obrigados a possuir instalações sanitárias separadas
						por sexo e compatíveis com a pessoa com deficiência, para uso de
						seus clientes, conforme normas técnicas de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os estabelecimentos financeiros referidos no caput
						deste artigo compreendem os bancos, caixas econômicas, sociedades de
						crédito, associações de poupança e suas agências, subagências e
						seções, bem como as conveniadas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 146. Os sanitários devidamente compatíveis com a pessoa com
						deficiência física deverão estar disponíveis nos mesmos horários de
						funcionamento dos estabelecimentos financeiros.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 147. Todos os estabelecimentos financeiros, nas dependências
						destinadas para atendimento ao público, deverão possuir bebedouros,
						observando-se as normas de acessibilidade para a pessoa com
						deficiência física, sendo disponibilizados copos descartáveis aos
						clientes.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 148. É obrigatória a instalação de caixas de autoatendimento e
						guichês de atendimento acessíveis, assegurando sua vinculação às
						rotas acessíveis.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 149. É obrigatória a disponibilização de caixas de
						autoatendimento em sistema braile e áudio para pessoa com
						deficiência visual ou cega em todas as agências bancárias do Estado
						do Paraná, bem como em todo e qualquer tipo de rede bancária.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As instruções para usuário com deficiência visual
						deverão ser feitas por meio de dispositivo de áudio, mediante
						utilização de fones de ouvido e teclado mecânico.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 150. O acesso do deficiente visual ao caixa eletrônico de que
						trata o art. 148 desta Lei deverá ser através de piso tátil, de
						acordo com as normas técnicas de acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 151. Os estabelecimentos bancários que infringirem o disposto
						nesta subseção, ficarão sujeitos às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequar no prazo de quinze
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - multa de 865 (oitocentas e sessenta e cinco) UPF/PR no caso da
						não adequação no prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de 1730 (mil setecentos e trinta) UPF/PR, em caso de
						reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Hotéis, Motéis e Similares
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 152. Os hotéis, motéis e estabelecimentos similares
						estabelecidos no Estado do Paraná ficam obrigados a adaptar suas
						instalações a fim de garantir o acesso da pessoa com deficiência,
						reservando-lhes 5% (cinco por cento) de seus quartos ou
						apartamentos, em qualquer número de unidades, sendo no mínimo uma
						unidade adaptada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º As adaptações de que tratam o caput deste artigo serão
						definidas em conformidade com o disposto nas normas técnicas de
						acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Os estabelecimentos localizados em prédios que não consigam
						atender às exigências previstas neste artigo devem apresentar
						alternativas para análise junto ao órgão competente, no prazo máximo
						de quinze dias a partir da data de notificação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 153. Os hotéis, motéis e similares que infringirem o disposto
						nesta Subseção ficarão sujeitos às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequarem no prazo de trinta
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - multa de 35 (trinta e cinco) UPF/PR - no caso da não adequação
						no prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de setenta UPF/PR, em caso de reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Dos Shopping Centers e Similares
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 154. Os shopping centers e estabelecimentos similares em todo o
						Estado do Paraná deverão, obrigatoriamente, disponibilizar cadeiras
						de rodas para pessoas com deficiência física, devendo haver ao menos
						cinco unidades disponíveis, em conformidade com as normas de
						acessibilidade em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 155. O fornecimento das cadeiras de rodas, referido no art. 154
						desta Lei, será gratuito, sem qualquer ônus para o usuário, cabendo
						exclusivamente aos estabelecimentos comerciais mencionados o seu
						fornecimento e manutenção em perfeitas condições de uso.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 156. Os estabelecimentos obrigados deverão afixar em suas
						dependências internas, inclusive nas garagens, cartazes ou placas
						indicativas dos locais onde as cadeiras de rodas se encontram
						disponíveis aos usuários.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 157. Os shopping centers e os restaurantes estabelecidos no
						Estado do Paraná deverão destinar 5% (cinco por cento) dos lugares
						acessíveis para refeição nas praças de alimentação para uso
						preferencial das pessoas com deficiência, preferencialmente com
						assentos móveis, em rota acessível.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os lugares reservados para o cumprimento do
						disposto neste artigo deverão conter identificação visível e
						acessível.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 158. Os centros comerciais, shopping centers, hiper e
						supermercados no âmbito do Estado, deverão fornecer, gratuitamente,
						veículos motorizados para facilitar a locomoção de pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os estabelecimentos referidos neste artigo
						afixarão, em local de grande visibilidade, nas dependências externas
						e internas, placas indicativas dos postos de retirada dos veículos
						motorizados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 159. O estabelecimento que violar o previsto nesta Subseção
						ficará sujeito às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequar no prazo de trinta
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<br />
						II - multa no valor de 35 (trina e cinco) UPF/PR no caso da não
						adequação no prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de setenta UPF/PR em caso de reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção VI
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Das Edificações de Uso Privado
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 160. Os edifícios de uso coletivo privado em que seja
						obrigatória a instalação de elevadores deverão ser construídos
						atendendo aos seguintes requisitos mínimos de acessibilidade:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - percurso acessível que una as unidades habitacionais com o
						exterior e com as dependências de uso comum;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - percurso acessível que una a edificação à via pública, às
						edificações e aos serviços anexos de uso comum e aos edifícios
						vizinhos; e
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - cabine do elevador e respectiva porta de entrada acessíveis
						para pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 161. Os edifícios a serem construídos com mais de um pavimento
						além do pavimento de acesso, à exceção das habitações unifamiliares,
						e que não estejam obrigados à instalação de elevador, deverão dispor
						de especificações técnicas e de projeto que facilitem a instalação
						de um elevador adaptado, devendo os demais elementos de uso comum
						destes edifícios atenderem aos requisitos de acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção VII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade aos Empreendimentos de Interesse Turístico
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 162. Os empreendimentos desenvolvidos no Estado do Paraná que
						envolvam interesse turístico, de lazer ou negócios, eventos, feiras,
						convenções e afins deverão adequar seus projetos arquitetônicos e de
						engenharia consoante as normas e especificações de adaptação e
						acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para fins de identificação, considera-se
						empreendimento de interesse turístico qualquer ação que se estruture
						com objetivos de recepção, atendimento, entretenimento e
						hospitalidade destinados ao visitante ou residente tais como:
						eventos gerais e turísticos, campanhas promocionais, programas de
						capacitação e preparação de recursos humanos, atividades
						empresariais com projetos arquitetônicos e de engenharia como meios
						de hospedagem, alimentação e entretenimento, centros de eventos e
						convenções tradicionais ou alternativos e outros que venham a sofrer
						adaptação para este fim, centrais de informação e atendimento ao
						visitante e terminais de transportes modais utilizados para fins
						turísticos e recreacionais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 163. As pessoas com deficiência deverão gozar de 5% (cinco por
						cento) de suas acomodações adaptadas, sendo, no mínimo, uma
						acomodação adaptada nos empreendimentos relativos ao meio de
						hospedagem com possibilidade e condições de utilização com segurança
						e autonomia dos espaços, inclusive nos banheiros e ainda, dispor de
						equipamentos, mobiliário e pessoal capacitados para assegurar a
						recepção e a acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As referidas adaptações deverão contemplar todos os
						tipos de deficiência em conformidade com a presente Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<strong
							>(Artigo acrescentado pela Lei Nº 19062 DE 27/06/2017):</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 163-A. Os eventos organizados em espaços públicos ou privados
						em que haja instalação de banheiros químicos deverão contar com
						unidades acessíveis a pessoas com deficiência ou com mobilidade
						reduzida.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O número mínimo de banheiros adaptados
						corresponderá a 10% (dez por cento) do total, garantindo-se pelo
						menos uma unidade adaptada caso a aplicação do percentual resulte em
						fração inferior a um.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 164. Os municípios deverão regulamentar a presente Seção no que
						concerne à adaptação arquitetônica, assegurando a acessibilidade,
						nos<br />
						empreendimentos de interesse turístico já existentes, observando
						sempre as legislações vigentes.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 165. Para os fins desta Seção entende-se por:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - adaptações arquitetônicas: quaisquer alterações promovidas em
						edificações com objetivo de permitir à pessoa com deficiência
						superar as barreiras da mobilidade, bem como entrave ou obstáculo
						que limite ou impeça o acesso, a liberdade de movimento e a
						circulação com segurança.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - adaptações das áreas comuns: modificações promovidas em locais
						como banheiros, estacionamentos, pistas de dança, quadras, áreas de
						lazer e esportes, arquibancadas e áreas de assentos, decks (saunas,
						piscinas), áreas de hidromassagem, bares, restaurantes e similares,
						ou onde mais aconteça fluxo de visitantes e turistas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 166. Os empreendimentos turísticos novos e aqueles que
						estiverem adaptados e adequados ao conjunto de recomendações
						indicadas em legislação própria e na legislação específica que
						atendam à recepção e à acessibilidade das pessoas com deficiência
						deverão adotar a identificação geral internacional convencionada e a
						especificada pelo Ministério do Turismo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 167. O Estado do Paraná será devidamente dividido em áreas de
						maior concentração turística para realização de empreendimentos de
						caráter público ou privado voltados para o turismo local, visando à
						conscientização das pessoas com deficiência e demais interessadas
						por meio da indicação dos acessos e das possibilidades de utilização
						pelas mesmas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 168. A liberação de apoio, recursos e benefícios
						institucionais, técnicos ou financeiros destinados aos
						empreendimentos de interesse turístico promovidos por empresários,
						prefeituras, entidades ou comunidades, provenientes de órgãos
						voltados para o setor em nível estadual, só ocorrerá após a
						verificação de adequação ao conjunto de recomendações indicadas na
						legislação própria e específica em relação a espaços físicos,
						mobiliários, equipamentos e pessoa capacitada para o atendimento e
						acessibilidade da pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 169. Nos alvarás a serem concedidos para novos empreendimentos
						deverá constar a obrigatoriedade de atendimento às normas técnicas
						de acessibilidade e legislações em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 170. As pessoas jurídicas de direito público ou privado que não
						cumprirem o disposto nesta Seção, serão notificadas em primeira
						avaliação e em seguida, caso não cumpridas as exigências iniciais,
						estarão sujeitas a multas que variam de 35 (trinta e cinco) a
						novecentas UPF/PR, dependendo das especificações do empreendimento e
						do evento ou do local a ser contemplado com a utilização dos
						visitantes e turistas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 171. O Poder Executivo do Estado do Paraná por meio de sua
						designação, contará com órgão competente para a fiscalização e
						controle da aplicabilidade pelo disposto nesta Seção.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção VIII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade aos Bens Culturais Imóveis
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 172. As soluções destinadas à eliminação, redução ou superação
						de barreira na promoção da acessibilidade a todos os bens culturais
						imóveis devem estar de acordo com as exigências dos órgãos federais
						e estaduais responsáveis pelo patrimônio histórico.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Deverá ser garantido o acesso a todos os bens
						culturais imóveis no Estado do Paraná, e quando não seja possível,
						deverá ser garantida a informação em formato acessível, inclusive
						com mapa tátil.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 173. A Secretaria de Estado responsável pela tutela dos bens
						culturais imóveis deverá, no prazo de noventa dias, instituir comitê
						composto por técnicos das áreas de acessibilidade e patrimônio
						histórico, com a finalidade de verificar a viabilidade de adequação
						às normas e legislações de acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 174. As pessoas jurídicas de direito público ou privado que não
						cumprirem o disposto nesta Lei, serão notificadas em primeira
						avaliação e em seguida, caso não cumpridas as exigências iniciais,
						estarão sujeitas a multas que variam 35 (trinta e cinco) a
						novecentas UPF/PR dependendo das especificações do empreendimento e
						do evento ou do local a ser contemplado com a utilização dos
						visitantes e turistas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção IX
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Do Símbolo Internacional de Acesso
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 175. É obrigatória a colocação, de forma visível, do
						&quot;Símbolo Internacional de Acesso&quot;, em todos os locais que
						possibilitem acesso, circulação e utilização por pessoas com
						deficiência, e em todos os serviços que forem postos à sua
						disposição ou que possibilitem o seu uso.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 176. Só é permitida a colocação do símbolo em edificações:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - que ofereçam condições de acesso natural ou por meio de rampas
						construídas com as especificações contidas na legislação pertinente
						em vigor;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - cujas formas de acesso e circulação não estejam impedidas às
						pessoas com deficiência em cadeira de rodas ou aparelhos ortopédicos
						em virtude da existência de degraus, soleiras e demais obstáculos
						que dificultem sua locomoção;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - que tenham porta de entrada com largura mínima de 90 cm
						(noventa centímetros);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - que tenham corredores ou passagens com largura mínima de 120 cm
						(cento e vinte centímetros);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - que tenham elevador cuja largura da porta seja, no mínimo, de
						100 cm (cem centímetros); e;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - que tenham sanitários apropriados ao uso da pessoa com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 177. Só é permitida a colocação do &quot;Símbolo Internacional
						de Acesso&quot; na identificação de serviços cujo uso seja
						comprovadamente adequado às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 178. Observado o disposto nos artigos 176 e 177 desta Lei, é
						obrigatória a colocação do símbolo na identificação dos seguintes
						locais e serviços, dentre outros de interesse comunitário:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - sede dos Poderes Executivo, Legislativo e Judiciário;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - prédios onde funcionam órgãos ou entidades públicas, quer de
						administração ou de prestação de serviços;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - edifícios residenciais, comerciais ou de escritórios;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - estabelecimentos de ensino em todos os níveis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - hospitais, clínicas e demais estabelecimentos do gênero;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - bibliotecas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - supermercados, centros de compras e lojas de departamento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<br />
						VIII - edificações destinadas ao lazer, como estádios, cinemas,
						clubes, teatros e parques recreativos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - auditórios para convenções, congressos e conferências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - estabelecimentos bancários;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - bares e restaurantes;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - hotéis e motéis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - sindicatos e associações profissionais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XlV - terminais aeroviários, rodoviários, ferroviários e metrôs;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XV - igrejas e demais templos religiosos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVI - tribunais federais e estaduais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVII - cartórios;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVIII - todos os veículos de transporte coletivo que possibilitem o
						acesso e que ofereçam vagas adequadas à pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIX - veículos que sejam conduzidos pela pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XX - locais e respectivas vagas para estacionamento, as quais devem
						ter largura mínima de 3,66 m (três metros e sessenta e seis
						centímetros);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXI - banheiros compatíveis ao uso da pessoa com deficiência e à
						mobilidade da sua cadeira de rodas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXII - elevadores cuja abertura da porta tenha, no mínimo, 100 cm
						(cem centímetros) e de dimensões internas mínimas de 120cm x 150cm
						(cento e vinte centímetros por cento e cinquenta centímetros);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXIII - telefones com altura máxima do receptáculo de fichas de 120
						cm (cento e vinte centímetros);
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXIV - bebedouros adequados;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXV - guias de calçada rebaixadas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXVI - vias e logradouros públicos que configurem rota de trajeto
						possível e elaborado para a pessoa com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXVII - rampas de acesso e circulação com piso antiderrapante;
						largura mínima de 120 cm (cento e vinte centímetros); corrimão de
						ambos os lados com altura máxima de 80 cm (oitenta centímetros);
						proteção lateral de segurança; e declive de 5% (cinco por cento) a
						6% (seis por cento), nunca excedendo a 8,33% (oito vírgula trinta e
						três por cento) e 3,50m (três metros e cinquenta centímetros) de
						comprimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXVIII - escadas com largura mínima de 120 cm (cento e vinte
						centímetros); corrimão de ambos os lados coma altura máxima de 80 cm
						(oitenta centímetros) e degraus com altura máxima de 18 cm (dezoito
						centímetros) e largura mínima de 25 cm (vinte e cinco centímetros).
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 179. O &#39;&#39;Símbolo Internacional de Acesso&#39;&#39;
						deverá ser colocado, obrigatoriamente, em local visível ao público,
						não sendo permitida nenhuma modificação ou adição ao desenho
						reproduzido no anexo a da Lei Federal nº 7.405, de 12 de novembro de
						1985.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 180. É vedada a utilização do &quot;Símbolo Internacional de
						Acesso&quot; para finalidade outra que não seja a de identificar,
						assinalar ou indicar local ou serviço habilitado ao uso de pessoas
						com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O disposto no caput deste artigo não se aplica à
						reprodução do símbolo em publicações e outros meios de comunicação
						relevantes para os interesses do deficiente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 181. A utilização do &quot;Símbolo Internacional de
						Acesso&quot; de modo que viole as disposições desta Subseção sujeita
						o infrator às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e notificação para se adequarem no prazo de trinta
						dias úteis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - multa de 35 (trinta e cinco) UPF/PR no caso da não adequação no
						prazo previsto;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - multa de setenta UPF/PR, em caso de reincidência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - após a incidência das penalidades previstas nos incisos I, II e
						III, em caso de nova reincidência, cassação do alvará e interdição
						do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção X
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade aos Serviços de Transportes Coletivos
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade no Transporte Coletivo Rodoviário
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 182. Os veículos de transporte coletivo, metropolitano e
						intermunicipal, público e privado, em trânsito no Estado do Paraná
						deverão cumprir os requisitos de acessibilidade estabelecidos nas
						normas técnicas específicas em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 183. Todos os modelos e marcas de veículos de transporte
						coletivo rodoviário, metropolitano e intermunicipal, público e
						privado, para utilização no Estado do Paraná, serão fabricados
						acessíveis e disponíveis para integrar a frota operante, de forma a
						garantir o seu uso por pessoas com deficiência, observado o disposto
						na legislação federal em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A substituição da frota operante atual por veículos
						acessíveis, a ser feita pelas empresas concessionárias e
						permissionárias de transporte coletivo rodoviário, metropolitano e
						intermunicipal, público e privado, dar-se-á de forma gradativa,
						conforme o prazo previsto nos contratos de concessão e permissão
						desse serviço.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 184. As empresas concessionárias e permissionárias dos serviços
						de transporte coletivo rodoviário, metropolitano e intermunicipal,
						público e privado, deverão garantir a acessibilidade da frota de
						veículos em circulação, inclusive de seus equipamentos, observado o
						disposto na legislação federal em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 185. As empresas permissionárias de transporte coletivo
						metropolitano e intermunicipal ficam obrigadas a permitir o embarque
						e o desembarque, pela mesma porta, dos usuários com qualquer
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Nos casos em que se fizer necessária a permissão
						referida no caput deste artigo, esta será estendida ao acompanhante
						do usuário em questão, conforme disposto nesta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 186. Os ônibus das linhas metropolitanas e intermunicipais de
						transporte coletivo do Estado ficam autorizados a parar fora dos
						pontos obrigatórios de parada, para embarque e desembarque de
						passageiros com deficiência física e visual, podendo este indicar o
						melhor local para desembarque, desde que o itinerário original da
						linha seja respeitado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Subseção II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade no Transporte Coletivo Metroferroviário
						Metropolitano e Ferroviário Intermunicipal
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 187. A frota de veículos de transporte coletivo
						metroferroviário metropolitano e ferroviário intermunicipal, assim
						como a infraestrutura dos serviços deste transporte deverão estar
						totalmente acessíveis no prazo definido na legislação federal em
						vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A acessibilidade nos serviços de transporte coletivo
						metroferroviário metropolitano e ferroviário intermunicipal
						obedecerá ao disposto nas normas técnicas de acessibilidade em
						vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Todos os modelos e marcas de veículos de transporte coletivo
						metroferroviário metropolitano e ferroviário intermunicipal serão
						fabricados de forma acessível e estarão disponíveis para integrar a
						frota operante, de modo a garantir o seu uso por pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 188. Os serviços de transporte coletivo metroferroviário e
						ferroviário existentes deverão estar totalmente acessíveis no prazo
						máximo definido na legislação federal em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. As empresas concessionárias e permissionárias dos
						serviços de transporte coletivo metroferroviário metropolitano e
						ferroviário intermunicipal deverão apresentar plano de adaptação dos
						sistemas existentes, prevendo ações saneadoras com acessibilidade
						total sobre os elementos que compõem o sistema, conforme legislação
						federal em vigor.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção XI
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Da Acessibilidade dos Sistemas de Comunicação, Informação e
						Sinalização
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 189. O Poder Público do Estado do Paraná promoverá a eliminação
						de barreiras na comunicação e estabelecerá mecanismos e alternativas
						técnicas que tornem acessíveis os sistemas de comunicação e
						sinalização às pessoas com deficiência sensorial e com dificuldade
						de comunicação, garantindo-lhes o direito de acesso à informação, à
						comunicação, ao trabalho, à educação, ao transporte, à cultura, ao
						esporte e ao lazer.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 190. O Poder Público do Estado do Paraná formará profissionais
						para o uso do Sistema Braille, intérpretes de Libras e de guias
						intérpretes, para facilitar qualquer tipo de comunicação direta com
						a pessoa com deficiência sensorial e com dificuldade de locomoção.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 191. Os serviços de difusão sonora e imagens adotarão plano de
						medidas técnicas com o objetivo de permitir o uso da Libras ou outra
						sub-titulação e áudio descrição para garantir o direito de acesso à
						informação às pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 192. Ficam reconhecidas oficialmente pelo estado do Paraná a
						LIBRAS e outros recursos de expressão a ela associados, como meios
						de comunicação objetiva e de uso corrente.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Compreende-se como Língua Brasileira de Sinais - Libras - a
						forma de comunicação e expressão, em que o sistema linguístico de
						natureza visual motora, com estrutura gramatical própria, constituem
						um sistema linguístico de transmissão de ideias e fatos, oriundos de
						comunidades de pessoas surdas do Brasil.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Para os propósitos desta Lei e da Língua Brasileira de Sinais -
						Libras, os intérpretes serão preferencialmente ouvintes e os
						instrutores serão preferencialmente surdos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 193. As mensagens de publicidade de atos, programas, serviços e
						campanhas da administração direta, indireta e fundacional do estado
						do Paraná veiculadas na televisão, terão tradução simultânea para a
						Libras, e serão apresentadas em legendas com o objetivo de
						tornarem-se acessíveis às pessoas com deficiência auditiva ou
						surdas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 194. Assegura aos surdos e às pessoas com deficiência auditiva
						o direito à informação e ao atendimento em toda a administração
						pública, direta e indireta, por servidor apto a comunicar-se através
						da Libras.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Para efetivar o disposto no caput deste artigo, o
						Poder Executivo terá o prazo de seis meses, prorrogável por igual
						período, e poderá<br />
						estabelecer convênios com entidades públicas ou privadas que atuem
						no atendimento dos surdos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 195. As editoras instaladas no estado do Paraná e que, no
						território paranaense, comercializem livros, apostilas ou outras
						obras literárias de quaisquer gêneros deverão atender às
						solicitações dos consumidores com deficiência visual para impressão
						em braile ou em fonte ampliada das obras que editam, assim como
						disponibilizar versão em áudio.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 196. O fabricante de produtos industrializados deverá
						disponibilizar, mediante solicitação de usuários ou de revendedores,
						instruções de uso em meio magnético, braile ou em fonte ampliada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os produtos industrializados a que o caput deste artigo se
						refere, compreendem: produtos de beleza, produtos alimentícios,
						eletrodomésticos e medicamentos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As instruções às quais se referem o caput deste artigo são
						informações de uso e características dos produtos, tais como: o
						valor calórico, a natureza do produto, a composição química, o
						funcionamento, as contra indicações, a data de fabricação e a data
						de validade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 197. As empresas de energia elétrica, água e esgoto, telefonia
						fixa e telefonia celular no Estado do Paraná deverão, no modo que
						estabelece o presente diploma legal, fornecer nas faturas e
						documentos de cobrança informações básicas no sistema braile ou em
						fonte ampliada, sempre que requerido.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A impressão em braile ou em fonte ampliada será,
						obrigatoriamente, na parte superior do documento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 198. As empresas concessionárias poderão optar pela impressão
						em todos os documentos ou realizar o cadastramento das pessoas com
						deficiência visual.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Caso a empresa opte pelo cadastramento das pessoas
						com deficiência visual, deverá promover publicidade da forma desse
						cadastramento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 199. A impressão em braile ou em fonte ampliada deverá conter,
						no mínimo, as seguintes informações:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - data de vencimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - valor da fatura,
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - valor dos juros,
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - multa por atraso;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - nome da empresa,
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - nome e endereço do usuário para fins de confirmação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Em caso de reaviso de vencimento a palavra
						&quot;REAVISO&quot; também será impressa em braile ou em fonte
						ampliada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 200. As empresas de que trata a presente Seção deverão
						providenciar a impressão no Sistema Braille, no prazo de 180 (cento
						e oitenta) dias contados da data da promulgação da presente Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção XII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Das Ajudas Técnicas
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 201. O Poder Público do Estado do Paraná promoverá a supressão
						de barreiras urbanísticas, arquitetônicas, de transporte e de
						comunicação mediante ajudas técnicas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 202. O Poder Público do Estado do Paraná, por meio dos
						organismos de apoio à pesquisa e das agências de financiamento,
						fomentará programas destinados:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - à promoção de pesquisas científicas voltadas ao tratamento e
						prevenção de deficiências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - ao desenvolvimento tecnológico orientado à produção de ajudas
						técnicas para as pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - à formação e especialização de recursos humanos em
						acessibilidade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção XIII
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Do Cão-guia
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 203. Assegura à pessoa com deficiência visual usuária de
						cão-guia, bem como treinador ou ao acompanhante habilitado, o
						direito de ingressar e permanecer com o animal em qualquer local
						aberto ao público ou utilizado pelo público, gratuitamente ou
						mediante pagamento de ingresso, no Estado do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A deficiência visual referida no caput deste artigo
						restringe-se à cegueira e à baixa visão.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Para efeito do disposto no caput deste artigo, consideram-se
						locais abertos ao público, ou utilizados pelo público:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - os próprios estaduais de uso comum do povo e de uso especial;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - os edifícios de órgãos públicos em geral;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - os hotéis, pensões, estalagens ou estabelecimentos similares;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - as lojas de qualquer gênero, restaurantes, bares, confeitarias,
						ou locais semelhantes;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - os cinemas, teatros, estádios, ginásios ou qualquer
						estabelecimento público de diversão ou esporte;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - os supermercados, shopping centers, ou qualquer tipo de
						estabelecimento comercial ou de prestação de serviços;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - os estabelecimentos de ensino público ou privado de qualquer
						curso ou grau;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - os clubes sociais abertos ao público;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - os salões de cabeleireiros, barbearias ou estabelecimentos
						similares;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - as entradas sociais em edifícios públicos ou residenciais,
						elevadores e escadas de acesso aos mesmos, bem como as áreas comuns
						de condomínios;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - os meios de transporte públicos ou concedidos, metropolitanos e
						intermunicipais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - os estabelecimentos religiosos de qualquer natureza.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Nos locais onde haja cobrança de ingresso é vedada a cobrança
						de qualquer taxa ou contribuição extra pelo ingresso e permanência
						do cão-guia.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 204. O cão-guia deverá portar a devida identificação e, quando
						solicitado, seu condutor deverá apresentar documento comprobatório
						do registro expedido por escola de cães-guia devidamente vinculada à
						Federação Internacional de Cães-Guia, acompanhado do atestado de
						sanidade do animal fornecido pelo órgão competente ou médico
						veterinário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 205. Os estabelecimentos e pessoas que impedirem o acesso e
						permanência de pessoa com deficiência visual acompanhados de
						cão-guia estão sujeitos às seguintes penalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - advertência e multa no valor de 35 (trina e cinco) UPF/PR -
						Unidade Padrão Fiscal do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - no caso de reincidência, o valor da multa será dobrado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - após a incidência das penalidades previstas nos incisos I e II
						deste artigo, cassação do alvará e interdição do estabelecimento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 206. Viola os direitos humanos aquele que impede ou dificulta o
						acesso da pessoa com deficiência visual ou cego, conduzida por
						cão-guia, aos locais previstos nesta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os estabelecimentos, empresas ou órgãos que derem
						causa à discriminação serão punidos com pena de multa de 45
						(quarenta e cinco) UPF/PR e de interdição enquanto dure a
						discriminação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 207. O direito de ingresso do cão-guia que conduz pessoa com
						deficiência visual permanece mesmo nos condomínios residenciais em
						que, por convenção ou regimento interno, fica restrita a presença ou
						circulação de animais, sejam as pessoas com deficiência moradores ou
						visitantes.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 208. Aos instrutores e treinadores reconhecidos pela Federação
						Internacional de Cães-Guia e às famílias de acolhimento autorizadas
						pelas escolas de treinamento filiadas à Federação Internacional de
						Cães-Guia serão garantidos os mesmos direitos do usuário previstos
						nesta Seção.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Seção XIV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Das Linhas de Crédito Especiais
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 209. As instituições financeiras estaduais manterão linha de
						crédito especial destinada às pessoas com deficiência e às entidades
						que trabalhem na sua promoção e na defesa de seus direitos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Os recursos dos quais tratam o caput deste artigo serão
						exclusivamente destinados para a cobertura de despesas necessárias à
						superação das dificuldades geradas pela deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A liberação do crédito especial fica condicionada à prova
						documental, pelos interessados - pessoas físicas e jurídicas -, de
						que sua aplicação será feita estritamente na área da deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 210. Tanto às pessoas físicas como às jurídicas, a concessão do
						crédito especial se dará dentro dos critérios usuais das
						instituições financeiras, respeitada a capacidade de liquidez dos
						financiados, demonstrada por documentos que lhes forem solicitados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 211. As pessoas físicas comprovarão a deficiência por meio de
						documento de identificação da pessoa com deficiência, devendo as
						entidades fazerem prova, por meio de seus estatutos, de que se
						dedicam à promoção da pessoa com deficiência, comprovando também que
						se encontram em efetivo e regular funcionamento.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO X
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA CIÊNCIA E TECNOLOGIA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 212. O Poder Público do Estado do Paraná promoverá e
						incentivará o desenvolvimento científico, a pesquisa e a capacitação
						tecnológica, voltados para a melhoria da qualidade de vida e
						trabalho das pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º O desenvolvimento e a pesquisa promovidos ou incentivados pela
						administração pública darão prioridade à geração de conhecimentos e
						técnicas que visem à prevenção e ao tratamento das deficiências,
						assim como à produção de ajudas técnicas e tecnologias de apoio.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Será incentivada e apoiada a capacitação tecnológica de
						instituições públicas e privadas que produzam e ofereçam, no Estado,
						medicamentos, próteses, órteses, instrumentos, equipamentos,
						serviços e sistemas voltados para melhorar a funcionalidade de
						pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 213. O Poder Público do Estado do Paraná adotará medidas de
						incentivo à produção e ao desenvolvimento científico e tecnológico
						voltado às ajudas técnicas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 214. Serão estimulados a pesquisa e o desenvolvimento, assim
						como a difusão de tecnologias voltadas para ampliar o acesso de
						pessoas com deficiência às tecnologias da informação e comunicação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Será estimulado, em especial, o emprego das tecnologias da
						informação e comunicação como instrumento de superação de limitações
						funcionais e de barreiras à comunicação e à educação de pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Serão estimuladas a adoção de soluções e a difusão de normas
						que visem a ampliar a acessibilidade de pessoas com deficiência à
						computação, aos sítios da rede mundial de computadores (internet) em
						geral e, em especial, aos serviços eletrônicos do governo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO XI
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA DISCRIMINAÇÃO E MAUS-TRATOS
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 215. Nenhuma pessoa com deficiência será objeto de qualquer
						forma de negligência, discriminação, exploração, violência, tortura,
						crueldade, opressão, tratamento desumano ou degradante, punido na
						forma da lei qualquer atentado, por ação ou omissão, aos seus
						direitos fundamentais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Considera-se discriminação qualquer distinção, restrição ou
						exclusão em razão da deficiência, mediante ação ou omissão, que
						tenha o propósito ou efeito de prejudicar, impedir ou anular o
						reconhecimento, gozo ou exercício de seus direitos e liberdades
						fundamentais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Não constitui discriminação a diferenciação ou preferência
						adotada para promover a inclusão social ou o desenvolvimento
						pessoal, não sendo as pessoas com deficiência obrigadas a aceitar
						tal diferenciação ou preferência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 216. Constitui preconceito e discriminação à pessoa com
						deficiência:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - impedir, dificultar, obstar ou recusar a livre locomoção em
						estabelecimentos da administração direta ou indireta e das
						concessionárias de serviços públicos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - impedir, dificultar, obstar ou restringir o acesso às
						dependências de bares, restaurantes, hotéis, cinemas, teatros,
						clubes, centros comerciais e similares;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - fazer exigências específicas às pessoas com deficiência para
						obtenção e manutenção de emprego;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - induzir ou incitar à prática de atos discriminatórios;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - veicular, por meio de comunicação escrita, sonora, audiovisual
						ou eletrônica, conteúdo discriminatório ou preconceituoso;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - praticar qualquer ato relacionado à pessoa com deficiência que
						cause constrangimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - ofender a honra ou a integridade física em razão da
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º Incide nas discriminações previstas nos incisos I e II deste
						artigo a alegação da existência de barreiras arquitetônicas para
						negar, dificultar ou restringir atendimento ou prestação de serviço
						à pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º A ausência de atendimento preferencial à pessoa com deficiência
						é forma de prática discriminatória prevista nos incisos VI e VII
						deste artigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 217. A administração pública direta e indireta, em todos os
						níveis, adotará medidas imediatas, eficazes e apropriadas para:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - aumentar a consciência da sociedade em relação às pessoas com
						deficiência e promover o respeito por seus direitos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - combater estereótipos, preconceitos e práticas prejudiciais às
						pessoas com deficiência, incluindo aqueles baseados em sexo e idade,
						em todos os aspectos da vida;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - promover a tomada de consciência a respeito das deficiências e
						das capacidades da pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Estas medidas incluem a execução e a manutenção de
						campanhas eficazes de conscientização pública, destinadas a:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - fomentar atitudes receptivas a respeito dos direitos de pessoas
						com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - promover percepções positivas e maior consciência social sobre
						as pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - promover o reconhecimento das competências, méritos,
						habilidades e contribuições de pessoas com deficiência relacionadas
						ao ambiente e ao mercado de trabalho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - promover em todos os níveis do sistema educacional, incluindo
						todas as crianças desde a primeira idade, uma atitude de respeito
						para os direitos de pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - estimular todos os órgãos da mídia a difundir a imagem das
						pessoas com deficiência compatível com o propósito desta Lei;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - promover programas de capacitação e conscientização a respeito
						das pessoas com deficiência e seus direitos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 218. O Poder Público Estadual desenvolverá ações de cunho
						educativo e combativo ao preconceito e à discriminação relativos às
						pessoas com deficiência, nos serviços públicos e demais atividades
						exercidas no Estado, conforme o disposto no inciso I do art. 204, da
						Constituição Federal e demais normas da legislação federal e
						estadual pertinentes.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 219. A notificação compulsória de maus-tratos é obrigatória nos
						casos que envolvam pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A notificação compulsória será emitida por
						profissionais dos órgãos públicos das áreas de saúde, educação,
						assistência social e segurança pública.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 220. A notificação compulsória será encaminhada por intermédio
						dos responsáveis pelas unidades das políticas públicas setoriais ao
						Conselho Tutelar ou, na falta deste, à Vara da Infância e Juventude,
						quando se tratar de criança e adolescente, e ao Ministério Público,
						quando se tratar de pessoa adulta com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 221. É de responsabilidade da autoridade policial e dos órgãos
						de segurança pública, recebida a notícia do desaparecimento de
						pessoa com deficiência, de qualquer idade, proceder a sua imediata
						busca e localização.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						TÍTULO III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA FORMULAÇÃO E COORDENAÇÃO DA POLÍTICA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO I
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO CONSELHO ESTADUAL DOS DIREITOS DA PESSOA COM DEFICIÊNCIA DO
						PARANÁ - COEDE/PR
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 222. Estabelece na estrutura organizacional da Secretaria de
						Estado responsável pelas políticas públicas para as pessoas com
						deficiência, no nível de direção superior, o Conselho Estadual dos
						Direitos da Pessoa com Deficiência do Paraná - COEDE/PR, órgão
						colegiado de caráter consultivo, deliberativo, fiscalizador e
						articulador das políticas voltadas a assegurar o pleno exercício dos
						direitos individuais e sociais da pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 223. O COEDE/PR tem por finalidade possibilitar a participação
						popular nas discussões, proposições, elaborações e auxílio na
						implementação e fiscalização das políticas públicas voltadas a
						assegurar o pleno exercício dos direitos das pessoas com
						deficiência, em todas as esferas da administração pública do Estado
						do Paraná, a fim de garantir a promoção e proteção das pessoas com
						deficiência, assim como exercer a orientação normativa e consultiva
						sobre os direitos das pessoas com deficiência no Estado do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 224. O COEDE/PR será responsável pela deliberação de políticas
						públicas, visando à inclusão das pessoas com deficiência e disporá
						sobre seus direitos básicos, objeto desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 225. São funções do COEDE/PR:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - avaliar, propor, discutir e participar da formulação, execução e
						fiscalização de políticas públicas para inclusão das pessoas com
						deficiência, observada a legislação em vigor, visando à eliminação
						de preconceitos e a plena inserção na vida socioeconômica, política
						e cultural do Estado do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - formular planos, programas e projetos da política estadual de
						integração da pessoa com deficiência e propor as providências
						necessárias à completa implementação e ao adequado desenvolvimento
						destes planos, programas e projetos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - propor a adoção de mecanismos e instrumentos que assegurem a
						participação e o controle popular sobre as políticas públicas para a
						promoção e inclusão das pessoas com deficiência, por meio da
						elaboração do plano diretor de programas, projetos e ações, bem como
						pela obtenção dos recursos públicos necessários para tais fins;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - acompanhar o planejamento e avaliar a execução das políticas
						estaduais de acesso à saúde, à educação, à assistência social, à
						habilitação e à reabilitação profissional, ao trabalho, à cultura,
						ao desporto, ao turismo e ao lazer;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - acompanhar a elaboração e a execução da proposta orçamentária do
						Estado, indicando ao Secretário de Estado responsável pela execução
						da política pública de atendimento às pessoas com deficiência as
						medidas necessárias à consecução da política formulada e do adequado
						funcionamento deste Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - acompanhar a concessão de auxílios e subvenções a pessoas
						jurídicas de direito privado atuantes no atendimento às pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - elaborar e apresentar, anualmente, ao Secretário de Estado
						responsável pela execução da política pública de atendimento às
						pessoas com deficiência, relatório circunstanciado de todas as
						atividades desenvolvidas pelo Conselho Estadual no período;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - acompanhar, mediante relatório de gestão, o desempenho dos
						programas e projetos da política estadual para inclusão das pessoas
						com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - apreciar e avaliar a proposta orçamentária da política pública;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - propor aos poderes constituídos modificações nas estruturas
						governamentais diretamente ligadas à proteção e à promoção dos
						direitos das pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - oferecer subsídios para elaboração de anteprojetos de Lei
						atinentes aos interesses das pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - pronunciar-se, emitir pareceres e prestar informações sobre
						assuntos que digam respeito às pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - incentivar e apoiar a realização de eventos, estudos e
						pesquisas sobre a questão das deficiências;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIV - pronunciar-se sobre matérias que lhe sejam submetidas pela
						Secretaria de Estado responsável pelas políticas públicas para as
						pessoas com deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XV - aprovar, de acordo com critérios estabelecidos em seu Regimento
						Interno, o cadastramento de entidades de proteção ou de atendimento
						às pessoas com deficiência que pretendam integrar o Conselho
						Estadual;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVI - receber petições, denúncias, reclamações, representações ou
						queixas de qualquer pessoa por desrespeito aos direitos assegurados
						às pessoas com deficiência, adotando as medidas cabíveis;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVII - promover canais de diálogo com a sociedade civil;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVIII - propor e incentivar a realização de campanhas que visem à
						prevenção de deficiências e à promoção dos direitos das pessoas com
						deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIX - promover intercâmbio com entidades públicas e particulares,
						organismos nacionais, internacionais e estrangeiros visando atender
						aos seus objetivos;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XX - incentivar a criação e estimular o funcionamento dos Conselhos
						Municipais dos Direitos da Pessoa com Deficiência;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXI - receber de órgãos públicos, entidades privadas ou de
						particulares todas as informações necessárias ao exercício de sua
						atividade.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXII - manifestar-se, dentro dos limites de sua atuação, acerca da
						administração e condução de trabalhos de prevenção, habilitação,
						reabilitação e inclusão social de entidade particular ou pública,
						quando houver notícia de irregularidade, expedindo, quando entender
						cabível, recomendação ao representante legal da entidade;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXIII - avaliar anualmente o desenvolvimento estadual e municipal de
						atendimento especializado à pessoa com deficiência visando à sua
						plena adequação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XXIV - elaborar seu Regimento Interno.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O COEDE/PR poderá estabelecer contato direto com os
						órgãos do Estado, pertencentes à administração direta ou indireta,
						objetivando o fiel cumprimento das suas atribuições.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 226. O COEDE/PR deverá apresentar, no prazo de 120 (cento e
						vinte dias) da data de promulgação desta Lei, o Plano Estadual da
						Pessoa com Deficiência, o qual deverá contemplar programas, projetos
						e ações para sua concretização, que deverão ser contemplados pelo
						Plano Plurianual (PPA), a Lei de Diretrizes Orçamentárias (LDO) e
						Lei Orçamentária Anual (LOA).
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 227. O COEDE/PR deverá convocar a Conferência Estadual dos
						Direitos da Pessoa com Deficiência, a qual deverá realizar-se em
						data anterior à Conferência Nacional dos Direitos da Pessoa com
						Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 228. O COEDE/PR será composto por 24 (vinte e quatro) membros e
						seus respectivos suplentes, nomeados pelo Governador do Estado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 229. A representação do poder público será composta da seguinte
						forma:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - um membro titular e um membro suplente da Secretaria de Estado
						responsável pelas políticas públicas para as pessoas com
						deficiência, que preferencialmente atuem na área, a serem indicados
						pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública da saúde, a serem indicados pelo
						titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública do trabalho, emprego e economia
						solidária, a serem indicados pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública de Assistência Social, a serem
						indicados pelo titular da pasta;
						<strong
							>(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						V - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública da educação, a serem indicados
						pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública da justiça, cidadania e direitos
						humanos, a serem indicados pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública da ciência, tecnologia e ensino
						superior, a serem indicados pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - um membro titular e um membro suplente da Secretaria de
						Estado responsável pela política pública de segurança pública, a
						serem indicados pelo titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública da cultura, a serem indicados pelo
						titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública do turismo, a serem indicados pelo
						titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública do esporte, a serem indicados pelo
						titular da Pasta;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - um membro titular e um membro suplente da Secretaria de Estado
						responsável pela política pública do planejamento e coordenação
						geral, a serem indicados pelo titular da Pasta.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Os representantes governamentais serão
						preferencialmente servidores com deficiência ou pessoas
						comprometidas com a causa da pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 230. A representação da sociedade civil organizada será eleita
						e composta por doze representantes, dentre as entidades eleitas em
						Assembleia, sendo dois representantes das seguintes áreas de
						atuação:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - deficiência física;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - deficiência auditiva e/ou surdez;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - deficiência intelectual;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - deficiência visual e/ou cego;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - transtorno global do desenvolvimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - múltipla deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 231. Na ausência de entidade com representação estadual em
						qualquer das áreas descritas no art. 230 desta Lei, será indicada
						outra mediante eleição entre as demais entidades.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 232. A ampliação da composição do COEDE/PR prevista nesta Lei
						será implementada a partir da próxima eleição, permanecendo válida,
						até então, a composição prevista no Decreto nº 10.315, de 28 de
						fevereiro de 2014, e suas posteriores alterações.
						<strong
							>(Redação do artigo dada pela Lei Nº 18453 DE 14/04/2015).</strong
						>
					</label></b-form-group>

					<b-form-group class="text-label"><label tabindex="0">
						Art. 233. Serão convidados a participar das reuniões do Conselho
						Estadual dos Direitos da Pessoa com Deficiência do Paraná -
						COEDE/PR, com direito a voz, sem direito a voto:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - um representante do Poder Judiciário do Estado do Paraná e seu
						suplente, a serem indicados anualmente pelo Presidente do Tribunal
						de Justiça do Estado do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - um representante do Ministério Público do Estado do Paraná e
						seu suplente, a serem indicados anualmente pelo Procurador-Geral de
						Justiça do Estado do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - um representante da Defensoria Pública do Estado do Paraná e
						seu suplente, a serem indicados anualmente pelo Defensor Público
						Geral do Estado do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - um representante da Ordem dos Advogados do Brasil, seção do
						Paraná, e seu suplente, a serem indicados anualmente pelo Presidente
						da Ordem dos Advogados do Brasil, seção do Paraná;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - um representante da Assembleia Legislativa do Estado do Paraná e
						seu suplente, a serem indicados anualmente pela Presidente da
						Assembleia Legislativa do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O COEDE/PR poderá convidar para participar de suas
						sessões, com direito a voz, sem direito a voto, representantes de
						entidades ou de órgãos, públicos ou privados, cuja participação seja
						considerada importante diante da pauta da sessão, e pessoas que, por
						seus conhecimentos e experiência profissional, possam contribuir
						para a discussão das matérias em exame.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 234. A eleição dos membros representantes da sociedade civil
						organizada do COEDE/PR será realizada em assembleia convocada
						especificamente para este fim.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º A assembleia de eleição será convocada a cada dois anos pelo
						Presidente do COEDE/PR.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º O Presidente do COEDE/PR deverá convocar a assembleia de
						eleição com antecedência de noventa dias do término do mandato dos
						membros representantes da sociedade civil.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º As entidades da sociedade civil com representação estadual
						deverão apresentar documentação comprobatória do exercício de suas
						atividades há pelo menos um ano e indicar um representante titular e
						um representante suplente para participação na Assembleia Estadual
						dos Direitos da Pessoa com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 4º O Ministério Público assistirá e fiscalizará a eleição dos
						membros representantes da sociedade civil organizada durante a
						Assembleia convocada especificamente para este fim.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 235. Caberá aos órgãos públicos e às entidades da sociedade
						civil a indicação de seus membros titulares e suplentes para
						composição do COEDE/PR, no prazo a ser estabelecido pela Secretaria
						de Estado responsável pelas políticas públicas para as pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 236. O não atendimento ao disposto no art. 235 desta Lei,
						quando se tratar de entidade da sociedade civil implicará na
						substituição desta entidade pela entidade mais votada na ordem de
						sucessão, observando-se a representatividade da área da deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 237. Os membros das entidades da sociedade civil e seus
						respectivos suplentes não poderão ser destituídos no período do
						mandato, salvo por<br />
						razões que motivem a deliberação da maioria qualificada por 2/3
						(dois terços) do Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 238. Será necessariamente substituído o membro do COEDE/PR que:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - desvincular-se do órgão de origem de sua representação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - faltar a três reuniões consecutivas ou a cinco reuniões
						intercaladas, sem justificativa;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - apresentar renúncia ao Plenário do Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - apresentar procedimento incompatível com a dignidade das
						funções.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 239. A justificativa de falta prevista no inciso II do art. 238
						desta Lei deverá ser dirigida ao Presidente do COEDE/PR, no prazo de
						cinco dias úteis anteriores ao evento ou reunião, salvo motivo de
						força maior posteriormente justificado.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 240. A substituição involuntária, quando necessária, dar-se-á
						por deliberação da maioria dos membros presentes à sessão do
						Conselho, em procedimento iniciado mediante provocação de integrante
						do COEDE/PR, do Ministério Público ou de qualquer cidadão,
						assegurada a ampla defesa.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 241. O COEDE/PR reunir-se-á ordinariamente a cada mês e,
						extraordinariamente, por convocação de seu Presidente ou a
						requerimento da maioria de seus membros.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 242. O Regimento Interno do COEDE/PR, em vigor, deverá ser
						alterado no prazo de noventa dias a contar da data da publicação
						desta Lei, adequando-se aos seus termos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 243. O mandato dos membros do COEDE/PR será de dois anos,
						permitida uma recondução.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 244. O desempenho da função de membro do COEDE/PR, que não tem
						qualquer remuneração ou percepção de gratificação, será considerado
						serviço relevante prestado ao Estado, sendo seu exercício
						prioritário, justificando as ausências a qualquer outro serviço,
						desde que determinadas pelas atividades próprias do Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 245. As deliberações do COEDE/PR serão tomadas pela maioria
						simples, estando presente a maioria absoluta dos membros do
						Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 246. Todas as reuniões do COEDE/PR serão abertas à participação
						de quaisquer interessados.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 247. Ao Presidente do COEDE/PR compete:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - representar o Conselho junto às autoridades, aos órgãos e às
						entidades;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - dirigir as atividades do Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - convocar e presidir as sessões do Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - proferir o voto de desempate nas decisões do Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 248. O Presidente do COEDE/PR será substituído em suas faltas e
						impedimentos pelo Vice-Presidente do Conselho, e, na ausência
						simultânea de ambos, presidirá o Conselho o seu membro mais antigo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 249. A Presidência do Conselho terá alternância em sua gestão,
						sendo um mandato presidido por um representante do poder público e o
						outro por um representante da sociedade civil organizada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 250. À Secretária Geral do COEDE/PR compete:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - providenciar a convocação, organizar e secretariar as sessões do
						Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - elaborar a pauta de matérias a serem submetidas às sessões do
						Conselho para deliberação;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - manter sistema de informação sobre os processos e assuntos de
						interesse do Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - organizar e manter a guarda de papéis e documentos do Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - exercer outras funções correlatas aos objetivos do Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 251. O Presidente, o Vice-Presidente e o Secretário-Geral do
						COEDE/PR serão eleitos pela maioria qualificada do Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O Regimento Interno disporá sobre as eleições
						gerais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 252. A Secretaria de Estado responsável pelas políticas
						públicas para as pessoas com deficiência prestará o apoio técnico,
						administrativo e de infraestrutura necessários ao pleno
						funcionamento do COEDE/PR.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 253. O COEDE/PR deverá ser instalado em local indicado pelo
						Estado, incumbindo à Secretaria de Estado responsável pelas
						políticas públicas para as pessoas com deficiência adotar as
						providências para tanto.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 254. O Poder Executivo do Estado do Paraná arcará com os custos
						de deslocamento, alimentação e permanência dos Conselheiros e seus
						acompanhantes não residentes em Curitiba e Região Metropolitana,
						quando necessário e justificadamente, para o exercício de suas
						funções.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 255. O Poder Executivo do Estado do Paraná deverá custear as
						despesas dos Delegados eleitos na Conferência Estadual dos Direitos
						da Pessoa com Deficiência e seus acompanhantes, quando necessário e
						justificadamente, para tornar possível sua presença na Conferência
						Nacional dos Direitos da Pessoa com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A previsão do caput deste artigo refere-se tanto
						aos Delegados representantes do poder público quanto aos delegados
						representantes da sociedade civil organizada.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 256. O Poder Executivo deverá arcar com as despesas de
						realização e divulgação das Conferências Estaduais dos Direitos da
						Pessoa com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º As despesas decorrentes desta Lei correrão por conta de
						dotações orçamentárias próprias e ficam condicionadas às
						disponibilidades orçamentárias e financeiras estabelecidas nas leis
						orçamentárias anuais.
						<strong
							>(Parágrafo acrescentado pela Lei Nº 20774 DE 16/11/2021).</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						<strong
							>(Parágrafo acrescentado pela Lei Nº 20774 DE 16/11/2021):</strong
						>
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º Autoriza o Poder Executivo a custear as despesas de locomoção,
						hospedagem e alimentação:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - dos conselheiros governamentais e representantes das entidades
						não governamentais, titulares ou suplentes, integrantes do COEDE,
						decorrente do exercício de sua função, mediante convocação do
						referido Conselho;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - dos delegados governamentais, inclusive municipais, e
						representantes das entidades não governamentais eleitos para
						participarem das Conferências Estaduais, convocadas pelo COEDE,
						decorrente do exercício de sua função, mediante convocação do
						referido Conselho.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - de acompanhante para os conselheiros e delegados mencionados
						nos incisos I e II deste parágrafo, desde que, mediante
						justificativa fundamentada, seja atestada a imprescindibilidade da
						presença do acompanhante para viabilizar o desempenho pleno da
						função do conselheiro ou delegado perante o COEDE ou suas
						Conferências Estaduais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 257. As demais matérias pertinentes ao funcionamento do
						Conselho serão disciplinadas pelo seu Regimento Interno.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO II
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA ASSESSORIA ESPECIAL PARA INTEGRAÇÃO DA PESSOA COM DEFICIÊNCIA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 258. A Assessoria Especial para Integração da Pessoa com
						Deficiência, vinculada à Secretaria de Estado responsável pela
						política pública voltada à pessoa com deficiência será responsável
						pelo monitoramento e implementação dos dispositivos desta Lei,
						mediante a criação de mecanismos específicos para este fim, no prazo
						de sessenta dias da data da publicação desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Deverá ser instituído um Comitê Intersecretarial
						para viabilizar o monitoramento e implementação referida no caput
						deste artigo, a ser regulamentado pela Secretaria de Estado
						responsável pelas políticas públicas voltadas às pessoas com
						deficiência no prazo de sessenta dias da data publicação desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO III
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DA ÁREA DE ATENÇÃO ESPECIAL À PESSOA COM DEFICIÊNCIA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 259. Institui no âmbito de cada órgão e entidade da
						administração pública do estado, nos termos da Lei nº 8.485, de 3 de
						junho de 1987, a Área de Atenção Especial à Pessoa com Deficiência,
						destinada a conferir tratamento prioritário e adequado aos assuntos
						que lhe são relativos.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. A administração pública do Estado compreende as
						secretarias de Estado, os órgãos de regime especial, as autarquias,
						as empresas públicas, as sociedades de economia mista, os serviços
						sociais autônomos e os órgãos de representação do estado do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 260. A Área de Atenção Especial à Pessoa com Deficiência ficará
						sob a coordenação da Secretaria de Estado responsável pelas
						políticas públicas para as pessoas com deficiência, por meio da
						Assessoria Especial para Integração da Pessoa com Deficiência,
						responsável pela execução das Políticas Públicas do Estado do Paraná
						para Promoção e Inclusão da Pessoa com Deficiência e demais
						políticas públicas elaboradas com relação à pessoa com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 261. Para a consecução do disposto no art. 259 desta Lei será
						designado, no âmbito de cada órgão e entidade da Administração
						Pública do Estado, pelos seus titulares, servidor pertencente ao
						respectivo quadro funcional para atuar como &quot;Ponto Focal de
						Atendimento&quot; na Área de Atenção Especial à Pessoa com
						Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 1º O servidor designado como &quot;Ponto Focal de
						Atendimento&quot;, a que se refere o caput deste artigo, atuará sob
						a orientação da Assessoria Especial para integração da Pessoa com
						Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 2º As atribuições dos &quot;Pontos Focais de Atendimento&quot;
						referidos no caput deste artigo serão desenvolvidas sem prejuízo das
						atividades inerentes aos seus respectivos cargos e funções.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						§ 3º Na ausência ou impedimento dos &quot;Pontos Focais de
						Atendimento&quot; designados, os titulares de órgão e entidade da
						Administração Pública do Estado designarão suplentes para o
						exercício temporário da atribuição.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 262. O Conselho Estadual dos Direitos da Pessoa com
						Deficiência, regulamentado por esta Lei, como órgão colegiado
						consultivo, deliberativo, fiscalizador e articulador das políticas
						voltadas a assegurar os direitos da pessoa com deficiência,
						vinculado à Secretaria de Estado responsável pelas políticas
						públicas para as pessoas com deficiência, sem prejuízo de suas
						atribuições, acompanhará os trabalhos desenvolvidos pela Assessoria
						Especial para integração da Pessoa com Deficiência e pela Área de
						Atenção Especial à Pessoa com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 263. Serão afixados, nas sedes dos órgãos e entidades da
						Administração Pública do Estado, informativos que destaquem a
						identificação do &quot;Ponto Focal de Atendimento&quot; a que se
						refere este Capítulo.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 264. Os órgãos e entidades abrangidos por esta Lei terão o
						prazo de trinta dias, contados da data de sua publicação, para a
						indicação do &quot;Ponto Focal de Atendimento&quot; à Assessoria
						Especial para integração da Pessoa com Deficiência da Secretaria de
						Estado responsável pelas políticas públicas para as pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 265. Os procedimentos operacionais da Área de Atenção Especial
						à Pessoa com Deficiência deverão ser definidos em ato normativo
						próprio, elaborado pela Assessoria Especial para integração da
						Pessoa com Deficiência, da Secretaria de Estado responsável pelas
						políticas públicas para as pessoas com deficiência, no prazo máximo
						de noventa dias, contados da data da publicação desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO CENTRO DE REFERÊNCIA E APOIO DA PESSOA COM DEFICIÊNCIA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 266. O Governo do Estado fica autorizado a criar os Centros de
						Referência e Apoio às Pessoas com Deficiência em todo o território
						do Estado do Paraná.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 267. O Centro de Referência e Apoio às Pessoas com Deficiência
						terá como principais finalidades:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - disponibilizar para as pessoas com deficiência e suas famílias
						informações e encaminhamentos necessários sobre recursos para
						atendimento de suas necessidades na área de políticas públicas;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - disponibilizar para a população em geral informações que
						possibilitem a valorização da diversidade humana e fortalecimento da
						aceitação das diferenças individuais;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - fornecer orientação geral aos pais, a partir do período
						pré-natal, na rede pública de saúde, com continuidade nas fases
						seguintes do desenvolvimento da pessoa.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 268. Para viabilizar a criação dos Centros de Referência e
						Apoio às Pessoas com Deficiência, o Poder Executivo poderá celebrar
						convênios com órgãos públicos federais, estaduais e municipais.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						CAPÍTULO V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DO FUNDO ESTADUAL DA PESSOA COM DEFICIÊNCIA
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 269. O Poder Executivo do Estado do Paraná, com a finalidade de
						prover recursos para a realização de políticas públicas, planos,
						projetos, programas, ações, entre outros, visando a assegurar,
						promover e proteger o exercício pleno e em condições de equidade de
						todos os direitos humanos e fundamentais das pessoas com
						deficiência, encaminhará Anteprojeto de Lei à Assembleia Legislativa
						do Estado do Paraná propondo a criação do Fundo Estadual da Pessoa
						com Deficiência - FEPCD, no âmbito da Secretaria de Estado
						responsável pelas políticas públicas das pessoas com deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. O Anteprojeto de Lei propondo a criação do Fundo
						Estadual da Pessoa com Deficiência deverá ser encaminhado no prazo
						de 120 (cento e vinte) dias, contados da data da publicação da
						presente Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						TÍTULO IV
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DAS MEDIDAS DE PROTEÇÃO
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 270. As medidas de proteção à pessoa com deficiência são
						aplicáveis sempre que os seus direitos, reconhecidos nesta Lei ou em
						outra legislação, forem ameaçados ou violados:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - por falta, omissão ou abuso da família, tutor, curador ou
						entidade de atendimento;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - por ação ou omissão da sociedade ou do Estado;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - em razão de sua condição pessoal.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						TÍTULO V
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						DAS DISPOSIÇÕES FINAIS
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 271. O Poder Público do Estado do Paraná tomará as medidas
						necessárias para assegurar o cumprimento do previsto no art. 23
						desta Lei, provendo médicos pediatras e médicos neonatologistas.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 272. As obrigações previstas nesta Lei não excluem as já
						estabelecidas em outras legislações, inclusive em pactos, tratados,
						convenções e declarações internacionais dos quais o Brasil seja
						signatário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 273. O PPA, a LDO e a LOA deverão conter programas, metas e
						recursos orçamentários destinados ao atendimento das pessoas com
						deficiência, observando o disposto no paragrafo único do art. 2º
						desta Lei.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 274. Os valores arrecadados com as multas previstas nesta Lei
						deverão ser destinados ao Fundo Estadual da Pessoa com Deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Parágrafo único. Enquanto o fundo de que trata o caput deste artigo
						não for criado, os valores deverão ser destinados obrigatoriamente à
						criação e execução de políticas públicas para as pessoas com
						deficiência.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 275. As despesas decorrentes na aplicação desta Lei, no
						corrente exercício, correrão por conta das dotações orçamentárias
						próprias e suplementares, caso necessário.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 276. Esta Lei entra em vigor na data de sua publicação.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Art. 277. Ficam revogadas:
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						I - Lei nº 11.911, de 1º de dezembro de 1997;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						II - Lei nº 13.041, de 11 de janeiro de 2001;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						III - Lei nº 13.450, de 11 de janeiro de 2002;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IV - os arts. 2º, 3º, 4º, 5º, 6º, 7º, 8º 9º, 10º, 11, 12, 13, 14,
						15, 16, 17, 18, 19, 20 e 21 da Lei nº 13.456, de 11 de janeiro de
						2002;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						V - Lei nº 13.871, de 25 de novembro de 2002;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VI - Lei nº 15.000, de 26 de janeiro de 2006;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VII - Lei nº 15.139, de 31 de maio de 2006;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						VIII - Lei nº 15.051, de 17 de abril de 2006;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						IX - Lei nº 15.267, de 18 de setembro de 2006;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						X - Lei nº 15.427, de 15 de janeiro de 2007;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XI - Lei nº 15.430, de 15 de janeiro de 2007;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XII - Lei nº 15.432, de 15 de janeiro de 2007;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - Lei nº 15.441, de 15 de janeiro de 2007;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIV - Lei nº 15.539, de 22 de junho de 2007;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XV - Lei nº 16.087, de 23 de abril de 2009;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVI - Lei nº 16.629, de 22 de novembro de 2010;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XVII - Decreto nº 857, de 24 de março de 2011;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIII - Decreto nº 5.417, de 18 de setembro de 2009;
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						XIX - o art. 1º da Lei nº 12.095, de 11 de março de 1998.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Palácio do Governo, em 07 de janeiro de 2015.
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Carlos Alberto Richa
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Governador do Estado
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Letícia Codagnone Ferrera Raymundo
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Secretária de Estado do Trabalho e Desenvolvimento Social, em
						exercício
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Maria Tereza Uille Gomes
					</label></b-form-group>
					<b-form-group class="text-label"><label tabindex="0">
						Secretária de Estado da Justiça, Cidadania e Direitos Humanos
					</label></b-form-group>
				</b-col>
			</b-row>
		</b-card>
		<div align="right">
			<b-col xl="6">
				<b-button
					outline
					size="md"
					@click="voltar()"
					variant="secondary"
					title="Voltar para tela inicial"
				>
					<i class="fas fa-arrow-left" /> Voltar
				</b-button>
			</b-col>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		voltar() {
			this.$router.push({ name: "legislacoes" });
		},
	},
};
</script>
