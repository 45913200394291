import { Municipio } from './../municipio';
import { RecursoAcessibilidade } from './../recurso-acessibilidade';
import { Beneficio } from './../beneficio';

export class EstabelecimentoFilter {
    cnpj: String;
    nome: String;
    razaoSocial: String;
    ramoAtividade?: String;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;
    recursosLogado?: boolean;
    recursosAcessibilidade: RecursoAcessibilidade[];
    municipio?: Municipio;
    dtInicio?: Date;
    dtFim?: Date;
    cpfUsuarioLogado?: String;
    situacao?: String;

    constructor (cnpj: String, razaoSocial: String, trazerTodos: boolean, ramo?: String, situacao?: String) {
        this.cnpj = cnpj;
        this.razaoSocial = razaoSocial;
        this.trazerTodos = trazerTodos;
        this.ramoAtividade = ramo;
        this.campoOrdenacao = "est.razaoSocial";
        this.ascendente = true;
        this.recursosAcessibilidade = Array.of();
        this.municipio = new Municipio(null, "", "PR");
        this.nome = "";
        this.situacao = situacao;
    }
}