

























































































































































































































































import {
    Component,
    Inject,
    Prop,
    Vue
} from 'vue-property-decorator';
import {
    Validator
} from 'vee-validate';
import formatar from '@/mixins/formatarMixins'
import moment from 'moment';
import {
    Option
} from '@/model/option';
import {
    Util
} from '@/util/util';
import {
    Message
} from '@/model/generic/message';
import {
    Pessoa
} from '@/model/pessoa';
import centralseguranca from '@/api/centralseguranca';
import {
    SexoEnum
} from '@/model/enum/sexo';
import {
    PagingList
} from '@/model/generic/paging-list';
import {
    EscolaridadeEnum
} from '@/model/enum/escolaridade';
import {
    FaixaRendaEnum
} from '@/model/enum/faixa-renda';
import enderecoService from '@/api/endereco.service';
import pessoaService from '@/api/pessoa-service';
import grauParentescoService from '@/api/grau-parentesco.service';
import {
    GrauParentesco
} from '@/model/grau-parentesco';

const {
    entries
} = Object;

@Component({
    mixins: [formatar]
})
export default class DadosPessoaComponent extends Vue {

    @Inject() parentValidator!: Validator;
    @Prop() public isExibir!: Boolean;
    @Prop() public isRepresentado!: Boolean;
    @Prop() public isIncluirDependente!: Boolean;
    @Prop() public pessoa!: Pessoa;

    public exibirDadosPessoa: Boolean = false;

    public possuiDeficiencia: Boolean | null;
    public isResponsavel: Boolean | null;
    public cpfRepresentante!: String;
    public nomeRepresentante!: String;
    public grausParentesco: Array < GrauParentesco > ;
    public sexos: Array < Option > ;
    public estados: PagingList < String > | null;
    public escolaridades: Array < Option > ;
    public faixasRenda: Array < Option > ;

    public justificativaDesvincular: String = "";

    public dataNascimentoStr: String = "";
    public dataNascimentoBlur: Boolean = false;
    public dataNascimentoState: Boolean | null = null;

    public disabledFieldsCS: Boolean;
    public cpfUserLogado: String;

    public userInfoCS: any;
    public novoCadastro: Boolean = false;

    constructor() {
        super();
        if (this.parentValidator != null) {
            this.$validator = this.parentValidator;
        }
        this.userInfoCS = {};
        this.grausParentesco = Array.of < GrauParentesco > ();
        this.possuiDeficiencia = null;
        this.isResponsavel = null;
        this.carregarGrauParentesco();
        this.cpfUserLogado = centralseguranca.getUserInfo().cpf;
        this.userInfoCS = centralseguranca.getUserInfo();

        if (!this.isIncluirDependente) {
            if (this.userInfoCS.cpf == this.pessoa.cpf) {
                this.pessoa.nome = this.userInfoCS.nome;
            }
            if (this.pessoa.id == null) {
                this.pessoa.nome = this.userInfoCS.nome;
                this.pessoa.cpf = this.userInfoCS.cpf;
                this.pessoa.rg = this.userInfoCS.rg;
                if (this.pessoa.rg === undefined) {
                    this.pessoa.rg = '';
                }
                this.pessoa.ufRg = this.userInfoCS.ufRg;
                if (this.userInfoCS.dtNascimento) {
                    this.pessoa.dtNascimento = moment(this.userInfoCS.dtNascimento).toDate();
                }
            }
            if (this.pessoa.dtNascimento != null) {
                this.dataNascimentoStr = this.pessoa.dtNascimento.toLocaleDateString();
            }
            this.disabledFieldsCS = true;
            if (this.isRepresentado && this.pessoa.representante != null) {
                this.cpfRepresentante = this.pessoa.representante.cpf;
                this.nomeRepresentante = this.pessoa.representante.nome;
            }
        } else {
            this.disabledFieldsCS = false;
            this.cpfRepresentante = "";
            this.nomeRepresentante = "";
            this.preencherRepresentante();

            this.pessoa.representado = true;
            this.pessoa.grauParentesco = null;
        }

        this.sexos = Util.carregaValoresEnum(entries(SexoEnum));
        this.escolaridades = Util.carregaValoresEnum(entries(EscolaridadeEnum));
        this.faixasRenda = Util.carregaValoresEnum(entries(FaixaRendaEnum));

        this.estados = null;
        this.carregaEstados();

        this.exibirDadosPessoa = this.pessoa.cpf ? true : false;
    }

    async desvincularRepresentante() {
        const result = await this.$validator.validateAll();
        if (result) {
            await pessoaService.desvincular(this.pessoa.cpf, this.justificativaDesvincular);
            this.pessoa.representante = null;
            this.$store.commit('setMessages', new Message('Representante desvinculado com sucesso', 'success'));
            this.$emit('changeIsRepresentado', false);
        } else {
            this.$store.commit('setMessages', new Message('Preencha todos os campos obrigatórios', 'danger'));
            window.scrollTo(0, 0);
        }
    }

    validarDataNascimento(): Boolean | null {
        if (this.dataNascimentoBlur) {
            this.dataNascimentoBlur = false;
            this.dataNascimentoState = true;

            if (this.dataNascimentoStr == null || this.dataNascimentoStr == "") {
                this.dataNascimentoState = null;
            }

            if (this.dataNascimentoStr.length < 10) {
                this.dataNascimentoState = false;
            }

            this.pessoa.dtNascimento = Util.toDateISO(this.dataNascimentoStr);

            if (isNaN(this.pessoa.dtNascimento.getTime())) {
                this.$store.commit('setMessages', new Message('Data de Nascimento inválida', 'danger'));
                this.dataNascimentoState = false;
            }

            if (this.pessoa.dtNascimento > new Date()) {
                this.$store.commit('setMessages', new Message('Data de Nascimento deve ser menor que a data atual', 'danger'));
                this.dataNascimentoState = false;
            }

        }
        return this.dataNascimentoState;
    }

    async buscarDadosPorCpf() {
        this.novoCadastro = false;

        if (this.pessoa.cpf.length == 14) {
            let userInfoCS = await pessoaService.obterDadosCentralPorCpf(this.pessoa.cpf, true);
            let pessoaCompleto = await pessoaService.obterPorCpf(this.pessoa.cpf, true);

            if (pessoaCompleto !== null) {
                pessoaCompleto.representado = true;
                pessoaCompleto.representante = this.pessoa.representante;
                pessoaCompleto.grauParentesco = this.pessoa.grauParentesco;
                pessoaCompleto.dtNascimento = moment(pessoaCompleto.dtNascimento).toDate()
                this.dataNascimentoStr = pessoaCompleto.dtNascimento.toLocaleDateString();
                // FIXME endereco is not beeing loaded on the screen even if pessoaCompleto has it
                this.$emit('carregarPessoaCompleto', pessoaCompleto);
                this.$emit('changeIsRepresentado', true);
            }
            if (userInfoCS != null) {
                this.pessoa.nome = userInfoCS.nome;
                this.pessoa.cpf = userInfoCS.cpf;
                this.pessoa.rg = userInfoCS.rg;
                this.pessoa.ufRg = userInfoCS.ufRg;
                this.pessoa.dtNascimento = moment(userInfoCS.dtNascimento).toDate();
                this.dataNascimentoStr = this.pessoa.dtNascimento.toLocaleDateString();
                this.disabledFieldsCS = true;
            }

            this.novoCadastro = pessoaCompleto == null && userInfoCS == null;
        }
    }

    async carregaEstados() {
        this.estados = await enderecoService.listarUFs();
    }

    async preencherRepresentante() {
        let representante = await pessoaService.obter();
        if (representante != null) {
            this.cpfRepresentante = formatar.methods.mascaraDocumento(representante.cpf);
            this.nomeRepresentante = representante.nome;
        }
    }

    async carregarGrauParentesco() {
        let retorno = await grauParentescoService.listarTodos();
        if (retorno != null) {
            this.grausParentesco = retorno;
        }
    }

    formatCPF(cpf) {
        return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }

    validarCPF(cpf: String | null): Boolean {
        if (cpf) {
            return Util.validarCPF(cpf)
        }
        return true;
    }

}
