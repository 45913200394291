var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "access-bar" } }, [
    _c(
      "h1",
      [
        _c(
          "b-link",
          {
            attrs: {
              "aria-label": "LINK Ir para o Portal",
              href: "http://www.pracessivel.pr.gov.br"
            }
          },
          [_vm._v("\n        Ir para o Portal\n    ")]
        ),
        _c(
          "b-link",
          {
            attrs: {
              "aria-label": "LINK Acessibilidade",
              href: "https://www.pia.pr.gov.br/acessibilidade"
            }
          },
          [_vm._v("\n        Acessibilidade\n    ")]
        ),
        _c(
          "b-link",
          {
            attrs: { "aria-label": "LINK Mudar o contraste" },
            on: {
              click: function($event) {
                return _vm.setContraste()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fas fa-adjust",
              attrs: { "aria-hidden": "true" }
            })
          ]
        ),
        _c(
          "b-link",
          {
            attrs: { "aria-label": "LINK Aumentar fontes" },
            on: {
              click: function($event) {
                return _vm.aumentarZoom()
              }
            }
          },
          [_vm._v("\n      [ + ] Aumentar fontes\n    ")]
        ),
        _c(
          "b-link",
          {
            attrs: { "aria-label": "LINK Reduzir fontes" },
            on: {
              click: function($event) {
                return _vm.diminuirZoom()
              }
            }
          },
          [_vm._v("\n      [ - ] Reduzir fontes\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }