var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-body",
        { staticClass: "cartaoConteudo" },
        [
          _c(
            "b-row",
            {
              staticClass: "justify-content-center mb-2",
              attrs: { fluid: "" }
            },
            [
              _c(
                "div",
                { staticClass: "center-xy pd-button" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-portal",
                      on: {
                        click: function($event) {
                          return _vm.evento()
                        }
                      }
                    },
                    [
                      _c("i", { class: _vm.icone + " icon-button" }),
                      _c("br"),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.textoBotao) }
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }