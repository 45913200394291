import store from '@/store/store';
import { Message } from './../model/generic/message';
import { PagingList } from './../model/generic/paging-list';
import { Representante, RepresentanteDeficiente } from './../model/representante';
import restUtil from './rest.util.service';
import { Pessoa } from '@/model/pessoa';

class RepresentanteService {
    
    async obter (): Promise<Representante | null> {
         let retorno = await restUtil.doGet<Representante>('v1/representante');
         if (retorno != null && retorno.data != null) {
           return retorno.data;
         }
         return null;
    }

    async obterCompleto (idRepresentante: Number): Promise<Representante | null> {
        let retorno = await restUtil.doGet<Representante>('v1/representante/'+idRepresentante);
        if (retorno != null && retorno.data != null) {
          return retorno.data;
        }
        return null;
   }

   async obterLogadoCompleto (): Promise<Representante | null> {
       let retorno = await this.obter();
       if (retorno != null && retorno.id != null) {
          return this.obterCompleto(retorno.id);
       }
       return retorno;
  }

    async listarVinculados (): Promise<PagingList<RepresentanteDeficiente>|null> {
         try{
            let retorno = await restUtil.doGet<PagingList<RepresentanteDeficiente>>('v1/representante/vinculados');
            if (retorno != null && retorno.data != null) {
              return retorno.data;
            }
          } catch (error) {
            store.commit('setMessages', (error as any).response.data);
          }
          return null;
    }

}

export default new RepresentanteService();