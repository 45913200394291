export let application = {
  name: 'Paraná Acessível',
  version: '0.1.04',
  description:
    'Esta aplicação foi desenvolvida com o objetivo de demonstrar o Framework Vue Celepar.',
  brandLogoPath: 'https://www.pr.gov.br/logos/governo/logo-governo-h.svg',
  framework: {
    name: 'Framework Vue.js Celepar',
    version: '1.0',
  },
  developer: {
    name: 'Celepar',
    href: 'http://www.celepar.pr.gov.br',
    logo: 'https://www.pr.gov.br/logos/governo/logo-governo-h.svg',
  },
  customer: {
    name: 'Celepar',
    href: 'http://www.celepar.pr.gov.br',
    showInHeader: false,
  },
  credits: [
    {
      name: 'vue-the-mask',
      href: 'https://vuejs-tips.github.i o/vue-the-mask/',
      description: 'Máscaras de campos',
    },
    {
      name: 'apexcharts',
      href: 'https://apexcharts.com/',
      description: 'Componente para criação de gráficos',
    },
    {
      name: 'axios',
      href: 'https://github.com/axios/axios',
      description: 'Cliente HTTP para browser e Node.js',
    },
    {
      name: 'portal-vue',
      href: 'https://github.com/LinusBorg/portal-vue',
      description:
        'Componente de renderização de DOM em qualquer parte do documento',
    },
    {
      name: 'vue-backtotop',
      href: 'https://github.com/caiofsouza/vue-backtotop',
      description: 'Componente para exibição de botão para ir ao topo',
    },
    {
      name: 'click-outside',
      href: 'https://github.com/vue-bulma/click-outside',
      description: 'Diretiva para tratamento de cliques externos',
    },
    {
      name: 'vue-json-to-csv',
      href: 'https://github.com/angeliquekom/vue-json-to-csv',
      description: 'Componente para converter e baixar JSON em CSV',
    },
    {
      name: 'Vue-multiselect',
      href: 'https://vue-multiselect.js.org/',
      description: 'Componente de múltipla seleção para Vue.js',
    },
    {
      name: 'vue-router',
      href: 'https://router.vuejs.org/',
      description: 'Componente de rotas oficial do Vue.js',
    },
    {
      name: 'vuex',
      href: 'https://vuex.vuejs.org/',
      description: 'Biblioteca de gerenciamento de estados oficial do Vue.js',
    },
    {
      name: 'vue-form-wizard',
      href: 'https://github.com/BinarCode/vue-form-wizard',
      description: 'Componente de formulários passo-a-passo',
    },
  ],
}
