import { Municipio } from './municipio';

export class Endereco {
    id: Number | null;
    bairro: String | null;
    logradouro: String | null;
    numero: String | null;
    complemento: String | null;
    cep: String | null;
    municipio: Municipio | null;

    constructor (id: Number | null, bairro: String | null, logradouro: String | null, numero: String | null,
            complemento: String | null, cep: String | null, municipio: Municipio | null) {
        this.id = id;
        this.bairro = bairro;
        this.logradouro = logradouro;
        this.numero = numero;
        this.complemento = complemento;
        this.cep = cep;
        this.municipio = municipio;
    }
}