





































































































































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router/router';
import { BeneficioFilter } from '../../model/filter/beneficio-filter';
import { Beneficio } from '../../model/beneficio';
import { TipoDeficiencia } from '../../model/tipo-deficiencia';
import { Option } from '@/model/option';
import beneficioService from '@/api/beneficio.service';
import { Municipio } from '@/model/municipio';
import { PagingList } from '@/model/generic/paging-list';
import enderecoService from '@/api/endereco.service';
import tipoDeficienciaService from '@/api/tipo-deficiencia.service';

@Component
export default class ListarRecursoAcessibilidade extends Vue {

    public filtro: BeneficioFilter;
    private beneficios: Array<Beneficio>;
    private tiposDeficienciaGerais: Array<TipoDeficiencia>;
    private beneficioSelecionado: Beneficio = new Beneficio(null, "", null);
    private categoria: Option|null;
    private tipos: Array<String>;
    private estados: PagingList<String>|null = null;
    private municipios: Array<Municipio> = Array.of();

    private campos: Array<Object> = [
      {
        key: 'descricao',
        label: 'Descrição',
        sortable: true
      },
      {
        key: 'tiposDeficiencia',
        label: 'Tipo de Deficiência',
        sortable: true,
        formatter: value => {
            if (value == null) {
                return null;
            }
            
            let retorno = '';
            value.forEach(function(tipoDef,i) {
                if (retorno.length !== 0) {
                    retorno += ", ";
                }
                retorno += tipoDef.descricao;
            });
            return retorno;
        }
      },
      {
        key: 'tipoBeneficio',
        label: 'Tipo Benefício',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
      {
        key: 'acoes',
        label: 'Exibir',
        tdClass: 'acoes-1'
      }];
    private currentPage: Number = 1;
    private perPage: Number = 5;
    private pesquisando: boolean = false;
    private totalRows: Number = 0;
    
    constructor(){
        super();
        this.filtro = new BeneficioFilter("", true);
        this.beneficios = Array<Beneficio>();
        this.tiposDeficienciaGerais = Array.of();
        this.categoria = null;

        this.tipos = Array.of("Municipal", "Estadual", "Federal");
        this.carregaEstados();
        this.listarTiposDeficiencia();
    }

    mounted (): void {
        this.limpar();
        this.pesquisar();
    }

    limpar () {
        this.filtro = new BeneficioFilter("", true);
        this.beneficios = Array<Beneficio>();
    }

    async pesquisar () {
        let retorno = await beneficioService.listarBeneficios(this.filtro, true)
        if (retorno != null) {
            this.beneficios = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
    }

    async carregaEstados() {
        this.estados = await enderecoService.listarUFs();
        this.carregaMunicipios();
    }

    async carregaMunicipios() {
        if (this.filtro.estado != undefined) {
            let retorno = await enderecoService.listarMunicipios(this.filtro.estado);
            if (retorno != null && retorno.registros != null) {
                this.municipios = retorno.registros;
            }
        } else {
            this.municipios = Array.of();
        }
    }

    async listarTiposDeficiencia () {
        let tiposDeficiencia = await tipoDeficienciaService.listarGerais();
        if(tiposDeficiencia != null) {
            this.tiposDeficienciaGerais = tiposDeficiencia.registros;
        }
    }

    iniciarIncluir () {
        router.push({
            name: 'editar-beneficio'
        });
    }

    iniciarExibir (beneficio: Beneficio) {
        router.push({
            name: 'exibir-beneficio',
            params: { beneficioProp: beneficio as any }
        });
    }

    iniciarAlterar (beneficio: Beneficio) {
        this.$router.push({
            name: 'editar-beneficio',
            params: { beneficioProp: beneficio as any }
        });
    }

    async excluir (beneficio: Beneficio) {
        const retorno = await beneficioService.excluir(beneficio);
        if (retorno != null) {
            this.pesquisar();
        }
    }

    limparMunicipio () {
        if (this.filtro.tipoBeneficio != "Municipal") {
            if (this.filtro.tipoBeneficio == '') {
                this.filtro.tipoBeneficio = undefined;
            }
            this.filtro.municipio = undefined;
        }
        if (this.filtro.tipoBeneficio != "Federal") {
            if (this.filtro.estado == undefined) {
                this.filtro.estado = "PR";
                this.carregaMunicipios();
            }
        } else {
            this.filtro.estado = undefined;
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

}
