import restUtil from './rest.util.service';
import router from '@/router/router';

class MonitoramentoService {

    healthCheck (): void {
        restUtil.healthCheck()
        .then(resp => { return resp })
        .catch(err => {
            router.push({
                name: 'falhaComunicacao'
            });
        });
    }

}

export default new MonitoramentoService();