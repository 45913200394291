<template>
  <div id="app" class="portal-layout" :class="getTheme" >
    <AccessBar />
    <AppHeader  />
    <div id="" class="body-size col-sm-12 col-xl-8" style="margin: 0 auto;">
      <AppMain />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/layout/AppHeader.vue'
import AppMain from '@/layout/AppMain.vue'
import AppFooter from '@/layout/AppFooter.vue'
import AccessBar from '../views/component/AccessBar/AccessBar.vue'

export default {
  components: { AppHeader, AppMain, AccessBar, AppFooter },
  computed: {
    sidebar () {
      return this.$store.getters.getSidebar
    },
    getTheme () {
      return this.$store.getters.getTheme
    }
  }
}
</script>

<style>
</style>