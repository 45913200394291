import { render, staticRenderFns } from "./SidebarMenu.vue?vue&type=template&id=34b9d569&"
import script from "./SidebarMenu.vue?vue&type=script&lang=js&"
export * from "./SidebarMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins-hml/workspace/pracessivel-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34b9d569')) {
      api.createRecord('34b9d569', component.options)
    } else {
      api.reload('34b9d569', component.options)
    }
    module.hot.accept("./SidebarMenu.vue?vue&type=template&id=34b9d569&", function () {
      api.rerender('34b9d569', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/sidebar-menu/SidebarMenu.vue"
export default component.exports