






import { Component, Vue } from 'vue-property-decorator';
import ListarRecursoAcessibilidade from './listar-recurso-acessibilidade.vue';
import EditarRecursoAcessibilidade from './editar-recurso-acessibilidade.vue';
import ExibirRecursoAcessibilidade from './exibir-recurso-acessibilidade.vue';

@Component({
    components: {
        ListarRecursoAcessibilidade,
        EditarRecursoAcessibilidade,
        ExibirRecursoAcessibilidade
    }
})
export default class RecursoAcessibilidadeContainer extends Vue {
}
