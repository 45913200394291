



















































































































































































import router from '@/router/router';
import enderecoService from '@/api/endereco.service';
import estabelecimentoService from '@/api/estabelecimento.service';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';
import { Beneficio } from '@/model/beneficio';
import { Deficiente } from '@/model/deficiente';
import { RamoAtividadeEnum } from '@/model/enum/ramo-atividade';
import { Estabelecimento } from '@/model/estabelecimento';
import { EstabelecimentoFilter } from '@/model/filter/estabelecimento-filter';
import { RecursoAcessibilidadeFilter } from '@/model/filter/recurso-acessibilidade-filter';
import { PagingList } from '@/model/generic/paging-list';
import { Municipio } from '@/model/municipio';
import { Option } from '@/model/option';
import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import { Component, Vue, Watch } from 'vue-property-decorator';
import deficienteService from '@/api/deficiente-service';
import { SituacaoEstabelecimentoEnum } from '@/model/enum/situacao-estabelecimento';
import pessoaService from '@/api/pessoa-service';

@Component
export default class ListarAvaliarEstabelecimento extends Vue {

    public filtro: EstabelecimentoFilter;
    public beneficios: Array<Beneficio>;
    public estabelecimentos: Array<Estabelecimento>;
    public recursosAcessibilidade: Array<RecursoAcessibilidade> = Array.of();
    public recursosAcessibilidadeLogado: Array<RecursoAcessibilidade> = Array.of();
    public recursosAcessibilidadeTodos: Array<RecursoAcessibilidade> = Array.of();
    public ramos: Array<Option>;

    public estados: PagingList<String> | null;
    public municipios: Municipio[] | null;
    public ramoAtividade: Option|null;

    public deficiente: Deficiente|null = null;
    public recursosLogado: Boolean = false;

    public tipoRecursoSelecionado: RecursoAcessibilidade | null = null;

    public campos: Array<Object> = [
      {
        key: 'nomeFantasia',
        label: 'Nome Fantasia',
        sortable: true
      },
      {
        key: 'ramoAtividade',
        label: 'Ramo de Atividade',
        sortable: true
      },
      {
        key: 'endereco',
        label: 'Cidade/Estado',
        sortable: true,
        formatter: value => {
            return value != null  && value.municipio != null
                ? value.municipio.nome + '/' + value.municipio.uf
                : null;
        }
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
    ];

    public camposRecurso: Array<Object> = [
        {
            key: 'nome',
            label: 'Recursos de Acessibilidade',
            sortable: false
        },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
    ];

    public currentPage: Number = 1;
    public perPage: Number = 5;
    public pesquisando: boolean = false;
    public totalRows: Number = 0;
    
    constructor(){
        super();
        this.filtro = new EstabelecimentoFilter("", "", true, undefined, SituacaoEstabelecimentoEnum.CERTIFICADO)
        this.ramoAtividade = null;
        this.beneficios = Array.of();
        this.recursosAcessibilidade = Array.of();
        this.recursosAcessibilidadeLogado = Array.of();
        this.recursosAcessibilidadeTodos = Array.of();
        this.ramos =  Object.entries(RamoAtividadeEnum).flatMap(obj => [new Option(obj, obj[1])]);

        this.estados = null;
        this.municipios = null;
        this.estabelecimentos = Array.of();

        this.carregaRecursosAcessibilidade();
        this.carregaEstados();
        this.carregarPCD();
    }

    getRamoValue(key : RamoAtividadeEnum) : String {
        const enumValue = Object.entries(RamoAtividadeEnum)
            .find(([id, value]) => {if(id == key) return value});

        if (enumValue) {
            return enumValue[1];
        }
        return "";
    }

    mounted (): void {
        this.limpar();
    }

    limpar () {
        this.filtro = new EstabelecimentoFilter("", "", true, undefined, SituacaoEstabelecimentoEnum.CERTIFICADO);
        this.ramoAtividade = null;

        if (this.$refs.tituloTela instanceof HTMLElement) {
            this.$refs.tituloTela.focus();
        }
    }

    async pesquisar () {
        let filtroPesq = this.filtro
        
        if (this.ramoAtividade) {
            filtroPesq.ramoAtividade = this.ramoAtividade.id[0];
        } else {
            delete filtroPesq.ramoAtividade
        }

        const retorno = await estabelecimentoService.listarEstabelecimentos(filtroPesq, true)
        if (retorno != null) {
            this.estabelecimentos = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

    async iniciarExibir (estab: Estabelecimento|null) {
        if(estab != null && estab.id != null){
            estab = await estabelecimentoService.obterPorID(estab.id);
        }
        router.push({
            name: 'exibir-estabelecimento',
            params: {
                estabProp: estab as any,
                voltarProp: 'listar-avaliar-estabelecimento'
            }
        });
    }

    async iniciarAvaliar (estab: Estabelecimento|null) {
        if(estab != null && estab.id != null){
            estab = await estabelecimentoService.obterPorID(estab.id);
        }
        this.$router.push({
            name: 'avaliar-estabelecimento',
            params: { estabProp: estab as any }
        });
    }

    async carregaRecursosAcessibilidade() {
        let filter = new RecursoAcessibilidadeFilter("", "", true);
        let retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(filter, false);
        if (retorno != null && retorno.registros != null) {
            this.recursosAcessibilidadeTodos = retorno.registros;
            this.recursosAcessibilidadeTodos = retorno.registros;
        } else {
            this.recursosAcessibilidadeTodos = Array.of();
        }
        
        this.recursosAcessibilidade = this.recursosAcessibilidadeTodos;

        let pessoa = await pessoaService.obterLogadoCompleto();
        if (pessoa != null && pessoa.recursosAcessibilidade != null) {
            this.recursosAcessibilidadeLogado = pessoa.recursosAcessibilidade;
        }
    }

    @Watch('recursosLogado')
    async carregarRescursosLogado(marcado) {
        this.filtro.recursosAcessibilidade = Array.of();
        this.recursosAcessibilidade  = Array.of();
        
        if (marcado && this.recursosAcessibilidadeLogado) {
            this.filtro.recursosAcessibilidade = [...this.recursosAcessibilidadeLogado];
        } else {
            this.recursosAcessibilidade = [...this.recursosAcessibilidadeTodos];
        }
    }

    async carregaEstados() {
        this.estados = await enderecoService.listarUFs();
        this.carregaMunicipios();
    }

    async carregaMunicipios() {
        if (this.filtro.municipio != undefined && this.filtro.municipio.uf != null) {
            let retorno = await enderecoService.listarMunicipios(this.filtro.municipio.uf);
            if (retorno != null && retorno.registros != null) {
                this.municipios = retorno.registros;
            }
            
        } else {
            this.municipios = null
        }
    }

    async carregarPCD () {
        this.deficiente = await deficienteService.obter();
    }

    adicionarRecurso () : void {
        if (this.tipoRecursoSelecionado && this.recursosAcessibilidade) {
            this.filtro.recursosAcessibilidade.push(this.tipoRecursoSelecionado);
            
            const index = this.recursosAcessibilidade.indexOf(this.tipoRecursoSelecionado, 0);
            if (index > -1) {
                this.recursosAcessibilidade.splice(index, 1);
            }
            

            this.tipoRecursoSelecionado = null;

            if (this.$refs.tabRecursos instanceof HTMLElement) {
                this.$refs.tabRecursos.focus();
            }
        }
    }

    async excluirRecurso (ace: RecursoAcessibilidade) {
        if (this.filtro.recursosAcessibilidade != null) {
            const index = this.filtro.recursosAcessibilidade.indexOf(ace, 0);
            if (index > -1 && this.recursosAcessibilidade) {
                this.recursosAcessibilidade.push(ace);

                this.recursosAcessibilidade.sort((a, b) => {
                    const nomeA = a.nome.toUpperCase();
                    const nomeB = b.nome.toUpperCase();

                    if (nomeA < nomeB) {
                        return -1;
                    }
                    if (nomeA > nomeB) {
                        return 1;
                    }
                    return 0;
                });

                this.filtro.recursosAcessibilidade.splice(index, 1);
            }

            if (this.$refs.tabRecursos instanceof HTMLElement) {
                this.$refs.tabRecursos.focus();
            }
        }
    }
}
