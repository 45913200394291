import { RecursoAcessibilidade } from './recurso-acessibilidade';
import { Endereco } from './endereco';
import { Contato } from './contato';
import { HorarioAtendimento } from './horario-atendimento';
import { SituacaoEstabelecimentoEnum } from './enum/situacao-estabelecimento';
import { PessoaEstabelecimento } from './pessoa-estabelecimento';
import { ArquivoEstabelecimento } from './arquivo-estabelecimento';
import { Usuario } from './usuario';

export class Estabelecimento {
    id: Number | null;
    cnpj: String;
    razaoSocial: String;
    nomeFantasia: String;
    ramoAtividade?: String;
    endereco?: Endereco;
    contatos?: Array<Contato>;
    acessibilidades?: Array<RecursoAcessibilidade>;
    horariosAtendimento?: Array<HorarioAtendimento>;
    origem: String;
    situacao: String;
    dtCadastro: Date;
    pessoas: Array<PessoaEstabelecimento>;
    arquivos: Array<ArquivoEstabelecimento>;
    usuarioAlteracao?: Usuario;

    constructor (id: Number | null, cnpj: String, razaoSocial: String, 
        nomeFantasia: String, ramo?: String, endereco?: Endereco, 
        contatos?: Array<Contato>, acessibilidades?: Array<RecursoAcessibilidade>,
        horariosAtendimento?: Array<HorarioAtendimento>, situacao?: String, pessoas?: Array<PessoaEstabelecimento>,
        arquivos?: Array<ArquivoEstabelecimento>) {
        this.id = id;
        this.cnpj = cnpj;
        this.razaoSocial = razaoSocial;
        this.nomeFantasia = nomeFantasia;
        this.endereco = endereco;
        this.ramoAtividade = ramo;
        this.contatos = contatos;
        this.acessibilidades = acessibilidades;
        this.horariosAtendimento = horariosAtendimento;
        this.origem = "PR_ACESSIVEL";
        if (situacao !== undefined) {
            this.situacao = situacao;
        } else {
            this.situacao = SituacaoEstabelecimentoEnum.OUTROS;
        }
        this.dtCadastro = new Date();
        if (pessoas !== undefined) {
            this.pessoas = pessoas;
        } else {
            this.pessoas = Array.of();
        }
        if (arquivos !== undefined) {
            this.arquivos = arquivos;
        } else {
            this.arquivos = Array.of();
        }
    }
}