import { PerfilCentralSegurancaEnum } from '../model/enum/perfil-central-seguranca';
import { JWTDecoded } from './../model/JWT/jwtDecoded';
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import store from '../store/store'

const loginUrl = process.env.VUE_APP_LOGIN_URI;
const clientId = process.env.VUE_APP_CLIENT_ID;
const redirectUri = process.env.VUE_APP_REDIRECT_URI;
const scope = process.env.VUE_APP_SCOPE;
const authorizeUrl = process.env.VUE_APP_AUTHORIZE_URL + clientId + '&redirect_uri=' + redirectUri + '&scope=' + scope;
const tokenInfoUrl = process.env.VUE_APP_TOKEN_INFO_URL;
const userKey = process.env.VUE_APP_CLIENT_ID;
const centralCidadaoURL = process.env.VUE_APP_CENTRAL_CIDADAO_URL;
const autoCadastro = `/centralcidadao/publico/pages/usuario/autoCadastro/autoCadastro.jsf`;
const portal = "http://www.pracessivel.pr.gov.br/";

// interceptor on response
axios.interceptors.response.use(function (response) {
  return response
},
function (error) {
  if (error === 'Error: Network Error') {
    if (error.response === undefined) {
      var networkError = { response: { data: { message: 'Algum recurso desta funcionalidade encontra-se indisponível. Por favor, tente mais tarde ou entre em contato com o analista responsável.' } } }
      error = networkError
    }
  }

  if (error.response !== undefined && error.response.status === 403) {
    var permissionError = { response: { data: { message: 'Sem permissão de acesso para alguns recursos desta funcionalidade (403).' } } }
    error = permissionError
  }

  return Promise.reject(error)
})

export default {
  userKey,
  authorizeUrl,
  tokenInfoUrl,
  loginUrl,
  centralCidadaoURL,
  autoCadastro,
  portal,
  getHashValue (key: string) {
    var matches = location.hash.match(new RegExp(key + '=([^&]*)'));
    return matches ? matches[1] : null;
  },
  decodeJwtToken (jwtToken: string) : JWTDecoded {
    try {
      return new JWTDecoded(jwtDecode(jwtToken));
    } catch (e) {
      console.log(e);
      return null as any;
    }
  },
  getUserInfo () : JWTDecoded {
    return this.decodeJwtToken(store.getters.getJwtToken);
  },
  getGrupos () : Array<String> {
    return this.decodeJwtToken(store.getters.getJwtToken).grupos;
  },
  usuarioLogadoPossuiPerfil (perfil: PerfilCentralSegurancaEnum) : boolean {
    return this.getGrupos().includes(perfil);
  },
  usuarioLogadoCidadao () : boolean {
    return 'Cidadão' === this.getGrupoAtivo(store.getters.getJwtToken);
  },
  getGrupoAtivo (jwtToken : string) : string {
    let grupos = this.decodeJwtToken(jwtToken).grupos
    if(grupos !== undefined && grupos.length) {
      for(let i = 0; i <= grupos.length; i++) {
        switch (grupos[i]) {
          case PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL:
            return "Paraná Acessível - Administrador Geral";
          case PerfilCentralSegurancaEnum.ADMINISTRATIVO:
            return "Paraná Acessível - Administrativo";
          case PerfilCentralSegurancaEnum.GERENCIAL:
            return "Paraná Acessível - Gerencial";
          case PerfilCentralSegurancaEnum.REGIONAL:
            return "Paraná Acessível - Regional";
        }
      }
    }
    return 'Cidadão';
  }
}
