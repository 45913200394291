var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "btn btn-outline-info ml-1",
                      attrs: {
                        "aria-label":
                          "LINK Estatuto da Pessoa com Deficiência do Estado do Paraná Lei 18419/2015"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exibirLei("/lei18419")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Estatuto da Pessoa com Deficiência do Estado do Paraná (Lei nº 18419/2015)\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("br"),
                  _c(
                    "b-link",
                    {
                      staticClass: "btn btn-outline-info ml-1",
                      attrs: {
                        "aria-label":
                          "Link Lei Brasileira de Inclusão Lei Federal nº 13146/2015"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exibirLei("/lei13146")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Lei Brasileira de Inclusão (Lei Federal nº 13146/2015)\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "right" } },
        [
          _c(
            "b-col",
            { attrs: { xl: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    outline: "",
                    size: "md",
                    variant: "secondary",
                    role: "button",
                    "aria-label": "Voltar para tela inicial"
                  },
                  on: {
                    click: function($event) {
                      return _vm.voltar()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-arrow-left" }),
                  _vm._v(" Voltar para tela inicial\n            ")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "fas fa-star" }),
      _vm._v(" Legislações\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }