var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid", attrs: { id: "main-content" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "10" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-body",
                    { staticClass: "cartaoConteudo" },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "justify-content-center mb-2",
                          attrs: { fluid: "" }
                        },
                        [
                          false
                            ? _c("CardButton", {
                                attrs: {
                                  icone: "far fa-comments",
                                  textoBotao: "FALE COM A GENTE"
                                },
                                on: {
                                  evento: function($event) {
                                    return _vm.faleComAGente()
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-users",
                              textoBotao: "JÁ SOU CADASTRADO"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.jaSouCadastrado()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        {
                          staticClass: "justify-content-center mb-2",
                          attrs: { fluid: "" }
                        },
                        [
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-globe",
                              textoBotao: "PORTAL"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.portal()
                              }
                            }
                          }),
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-plus-circle",
                              textoBotao: "NOVO CADASTRO"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.queroMeCadastrar()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2" } },
            [
              _c(
                "b-card-body",
                { staticClass: "cartaoConteudo" },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "justify-content-center mb-2",
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "center-xy pd-button",
                          staticStyle: { height: "110px" }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass:
                                "btn-portal-deficiente btn-secondary",
                              attrs: {
                                switch: "",
                                id: "deficienteVisual",
                                name: "deficienteVisual"
                              },
                              model: {
                                value: _vm.deficienteVisual,
                                callback: function($$v) {
                                  _vm.deficienteVisual = $$v
                                },
                                expression: "deficienteVisual"
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Sou deficiente visual com ausência total de visão\n                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "justify-content-center mb-2",
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "center-xy pd-button",
                          staticStyle: { height: "150px" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-portal-video",
                              on: {
                                click: function($event) {
                                  return _vm.exibirVideo()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-video icon-button"
                              }),
                              _c("br"),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    "Orientações para cadastro e navegação para deficientes visuais com uso do NVDA"
                                  )
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }