



























import { Beneficio } from '@/model/beneficio';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import beneficioService from '@/api/beneficio.service';
import { BeneficioFilter } from '@/model/filter/beneficio-filter';
import { Endereco } from '@/model/endereco';
import { TipoDeficiencia } from '@/model/tipo-deficiencia';

@Component
export default class BeneficioComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public showCollapseProp!: Boolean;
    @Prop() public enderecoProp!: Endereco;
    @Prop() public beneficiosProp!: Array<Beneficio>;
    @Prop() public tiposDeficiencia!: Array<TipoDeficiencia>;

    public isExibir: Boolean = false;
    public showContent: Boolean = false;

    public beneficios: Array<Beneficio> = Array.of();
    public beneficiosSelecionados: Array<Beneficio> = Array.of();

    constructor() {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);
        this.beneficios = Array.of();
        this.beneficiosSelecionados = this.beneficiosProp;
        this.carregaBeneficios();
        if (this.showCollapseProp != undefined) {
            this.showContent = this.showCollapseProp;
        }
        console.log(this.beneficiosSelecionados)
    }
    
    @Watch('tiposDeficiencia')
    async carregarRescursosLogado() {
        this.carregaBeneficios();
    }

    public async carregaBeneficios() {
        console.log("Entrou carrega beneficios")
        let listaDesficienciasGerais: Number[] = [];

        console.log("Tipos deficiência:")
        console.log(this.tiposDeficiencia)
        /*this.tiposDeficiencia.forEach(td => {
            if (td.pai && td.pai.id) {
                listaDesficienciasGerais.push(td.pai.id);
            }
        });

        listaDesficienciasGerais = listaDesficienciasGerais.filter((valor, index, self) => {
            return self.indexOf(valor) === index;
        });*/

        let tiposDefGerais: Array<TipoDeficiencia> = [];
        /*listaDesficienciasGerais.forEach(dgId => {
            tiposDefGerais.push(new TipoDeficiencia(dgId, "", null));
        })
        console.log("Defs gerais:")
        console.log(tiposDefGerais)*/

        let filter = new BeneficioFilter("", true, true, tiposDefGerais);

        /*if (tiposDefGerais.length == 0) {
            if (this.$props.enderecoProp != null && this.enderecoProp.municipio != null && this.enderecoProp.municipio.id != null) {
                filter.municipio = this.enderecoProp.municipio
            }
        }*/
        
        const retorno = await beneficioService.listarBeneficios(filter, false);
        console.log("Retorno:")
        console.log(retorno)
        console.log(this.beneficiosSelecionados)
        if (retorno != null){
            this.beneficios = retorno.registros;
            this.beneficiosSelecionados = this.beneficiosSelecionados.filter(
                selec => this.beneficios.filter(ben => ben.id === selec.id).length !== 0)
            this.$emit('atualizarBeneficio', this.beneficiosSelecionados)
        }
    }

    removerBeneficio(ben: Beneficio) {
        console.log("Entrou remover")
        if (this.beneficiosSelecionados != null) {
            const index = this.beneficiosSelecionados.indexOf(ben, 0);
            if (index > -1) {
                this.beneficiosSelecionados.splice(index, 1);
            }
        }
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }
}
