export class TipoDeficienciaFilter {

    deficiencia?: String;
    gerais: boolean;
    subCategorias: boolean;
    trazerTodos: boolean;
    campoOrdenacao: String;
    ascendente: boolean;

    constructor(descricao: String, trazerTodos: boolean) {
        this.deficiencia = descricao;
        this.trazerTodos = trazerTodos;
        this.campoOrdenacao = "tp.descricao";
        this.ascendente = true;
        this.gerais = false;
        this.subCategorias = false;
    }
}