






import { Component, Vue } from 'vue-property-decorator';
import EditarPessoa from './editar-pessoa.vue';
import ListarVinculados from './listar-vinculados.vue';

@Component({
    components: {
        EditarPessoa,
        ListarVinculados
    }
})
export default class PessoaContainer extends Vue {
}
