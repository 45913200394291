var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { id: "menu", sm: "12", xl: "8" } },
    [
      _c(
        "b-navbar",
        {
          staticClass: "menu-top menu-portal p-0",
          attrs: { id: "portal-menu", toggleable: "lg", type: "dark" }
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c("div", { staticClass: "app-title-sm" }, [
            _vm._v("\n      " + _vm._s(_vm.AppInfo.name) + "\n    ")
          ]),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _vm._l(_vm.itens, function(item) {
                    return [
                      !item.children
                        ? [
                            _c(
                              "b-nav-item",
                              { key: item.key, attrs: { to: item.url } },
                              [
                                item.faleConosco != true
                                  ? _c(
                                      "router-link",
                                      { attrs: { to: item.url } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.faleConosco
                                  ? _c(
                                      "router-link",
                                      { attrs: { to: "#", custom: "" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.faleConosco()
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : [
                            _c(
                              "b-nav-item-dropdown",
                              { key: item.key, attrs: { text: item.name } },
                              _vm._l(item.children, function(submenu) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: submenu.key,
                                    attrs: { to: submenu.url }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: submenu.url } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(submenu.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }