import { render, staticRenderFns } from "./listar-beneficio.vue?vue&type=template&id=64664c6a&"
import script from "./listar-beneficio.vue?vue&type=script&lang=ts&"
export * from "./listar-beneficio.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins-hml/workspace/pracessivel-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64664c6a')) {
      api.createRecord('64664c6a', component.options)
    } else {
      api.reload('64664c6a', component.options)
    }
    module.hot.accept("./listar-beneficio.vue?vue&type=template&id=64664c6a&", function () {
      api.rerender('64664c6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/beneficio/listar-beneficio.vue"
export default component.exports