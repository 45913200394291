export class PagingList <T> {
    registros: Array<T>;
    totalRegistros: Number;
    primeiroRegistro?: Number;
    ultimoRegistro?: Number;
    quantidadePorPagina?: Number;

    constructor(registros: Array<T>, totalRegistros: Number,
            primeiroRegistro?: Number, ultimoRegistro?: Number,
            quantidadePorPagina?: Number){
        this.totalRegistros = totalRegistros;
        this.registros = registros;
        this.primeiroRegistro = primeiroRegistro;
        this.ultimoRegistro = ultimoRegistro;
        this.quantidadePorPagina = quantidadePorPagina;
    }
}