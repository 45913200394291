












































import router from '@/router/router';
import menu from '@/api/menu'
import { application } from '@/application.js'
import representanteService from '@/api/representante-service'
import store from '@/store/store';

export default {
  data () {
    return {
      AppInfo: application
    }
  },
  computed: {
    itens () {
      return store.getters.getMenu
    }
  },
  beforeCreate () {
    store.commit('setMenu', menu.itensAcesso(menu.itens))
    store.commit('setMenuOriginal', menu.itensAcesso(menu.itens))
  },
  methods: {
    async faleConosco () {
      let representante = await representanteService.obterLogadoCompleto();
        router.push({
            name: 'fale-com-a-gente',
            params: {
               pessoaProp: representante as any
            }
        });
    }
  }
}
