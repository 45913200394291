var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c("h2", { staticClass: "app-title" }, [
        _c("i", { staticClass: "fa fa-user-friends" }),
        _c("label", { attrs: { tabindex: "0" } }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.isExibir
                  ? "Exibir"
                  : _vm.pessoa.id == null || _vm.pessoa.id == 0
                  ? "Incluir"
                  : "Alterar"
              ) +
              "\n            Pessoa\n        "
          )
        ])
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  !_vm.isExibir && !_vm.isRepresentado && _vm.pessoa.id !== null
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            size: "md",
                            title: "Pessoas Vinculadas"
                          },
                          on: {
                            click: function($event) {
                              return _vm.pessoasVinculadas()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-users" }),
                          _vm._v(" Pessoas Vinculadas\n                ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" \n                "),
                  !_vm.isExibir && !_vm.isRepresentado && _vm.pessoa.id !== null
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.modalVincularRepresentante",
                              modifiers: { modalVincularRepresentante: true }
                            }
                          ],
                          attrs: {
                            variant: "primary",
                            size: "md",
                            title: "Vincular Representante"
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-user-plus" }),
                          _vm._v(" Vincular Representante\n                ")
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("dados-pessoa-component", {
                ref: "dadosPessoa",
                attrs: {
                  isRepresentado: _vm.isRepresentado,
                  pessoa: _vm.pessoa,
                  isIncluirDependente: _vm.isIncluirDependente,
                  isExibir: _vm.isExibir
                },
                on: {
                  changePossuiDeficiencia: function($event) {
                    _vm.possuiDeficiencia = $event
                  },
                  changeIsResponsavel: function($event) {
                    _vm.isResponsavel = $event
                  },
                  changeIsRepresentado: function($event) {
                    _vm.isRepresentado = $event
                  },
                  carregarPessoaCompleto: function($event) {
                    _vm.pessoa = $event
                  }
                }
              }),
              _c("endereco-component", {
                ref: "enderecoComponent",
                attrs: {
                  enderecoProp: _vm.pessoa.endereco,
                  isExibirProp: _vm.isExibir
                },
                on: {
                  changeMunicipio: function($event) {
                    return _vm.$refs.beneficioComponent.carregaBeneficios()
                  }
                }
              }),
              _c("contato-component", {
                ref: "contatoComponent",
                attrs: {
                  listaContatosProp: _vm.pessoa.contatos,
                  isExibirProp: _vm.isExibir
                }
              }),
              _c("assuntos-interesse-component", {
                ref: "assuntoComponent",
                attrs: {
                  interesseConteudoVinculadoProp:
                    _vm.pessoa.interesseConteudoVinculado,
                  isExibirProp: _vm.isExibir
                },
                on: {
                  atualizarInteresseConteudo: function($event) {
                    _vm.pessoa.interesseConteudoVinculado = $event
                  }
                }
              }),
              _c(
                "fieldset",
                { staticClass: "blocoFieldset" },
                [
                  _c("legend", [
                    _vm._v("\n                    PCD\n                ")
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("b-form-group", {
                            staticClass: "text-label",
                            attrs: {
                              label: "Você possui algum tipo de deficiência?"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var ariaDescribedby = ref.ariaDescribedby
                                  return [
                                    _c(
                                      "b-form-radio",
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        attrs: {
                                          "aria-describedby": ariaDescribedby,
                                          value: true,
                                          disabled: _vm.isExibir,
                                          "data-vv-as":
                                            "Você possui algum tipo de deficiência?",
                                          "data-vv-name": "possuiDeficiencia",
                                          state:
                                            _vm.$validator.errors.has(
                                              "possuiDeficiencia"
                                            ) == false
                                              ? null
                                              : !_vm.$validator.errors.has(
                                                  "possuiDeficiencia"
                                                )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$emit(
                                              "changePossuiDeficiencia",
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.possuiDeficiencia,
                                          callback: function($$v) {
                                            _vm.possuiDeficiencia = $$v
                                          },
                                          expression: "possuiDeficiencia"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Sim\n                            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        attrs: {
                                          "aria-describedby": ariaDescribedby,
                                          value: false,
                                          disabled: _vm.isExibir,
                                          "data-vv-as":
                                            "Você possui algum tipo de deficiência?",
                                          "data-vv-name": "possuiDeficiencia",
                                          state:
                                            _vm.$validator.errors.has(
                                              "possuiDeficiencia"
                                            ) == false
                                              ? null
                                              : !_vm.$validator.errors.has(
                                                  "possuiDeficiencia"
                                                )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$emit(
                                              "changePossuiDeficiencia",
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.possuiDeficiencia,
                                          callback: function($$v) {
                                            _vm.possuiDeficiencia = $$v
                                          },
                                          expression: "possuiDeficiencia"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Não\n                            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.$validator.errors.has(
                                              "possuiDeficiencia"
                                            ),
                                            expression:
                                              "$validator.errors.has('possuiDeficiencia')"
                                          }
                                        ],
                                        staticClass: "help is-danger"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$validator.errors.first(
                                                "possuiDeficiencia"
                                              )
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.possuiDeficiencia
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { xl: "12" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    switch: "",
                                    id: "deficienteVisual",
                                    name: "deficienteVisual"
                                  },
                                  model: {
                                    value: _vm.pessoa.deficienteVisual,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.pessoa,
                                        "deficienteVisual",
                                        $$v
                                      )
                                    },
                                    expression: "pessoa.deficienteVisual"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Sou deficiente visual - Esta opção, se assinalada, adequará a aplicação para ser utilizada com softwares leitores de texto.\n                        "
                                  )
                                ]
                              ),
                              _c("br")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.possuiDeficiencia
                    ? _c("tipo-deficiencia-component", {
                        ref: "tipoDeficienciaComponent",
                        attrs: {
                          tiposDeficienciaVinculadoProp:
                            _vm.pessoa.tiposDeficiencia,
                          isExibirProp: _vm.isExibir
                        },
                        on: {
                          atualizarTiposDeficiencia: function($event) {
                            _vm.pessoa.tiposDeficiencia = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.possuiDeficiencia
                    ? _c("recurso-acessibilidade-component", {
                        ref: "recursoAcessibilidadeComponent",
                        attrs: {
                          recursosAcessibilidadeProp:
                            _vm.pessoa.recursosAcessibilidade,
                          isExibirProp: _vm.isExibir,
                          showCollapseProp: _vm.pessoa.id !== null
                        },
                        on: {
                          atualizarRecursoAcessibilidade: function($event) {
                            _vm.pessoa.recursosAcessibilidade = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.possuiDeficiencia
                    ? _c("beneficio-component", {
                        ref: "beneficioComponent",
                        attrs: {
                          beneficiosProp: _vm.pessoa.beneficios,
                          tiposDeficiencia: _vm.pessoa.tiposDeficiencia,
                          isExibirProp: _vm.isExibir
                        },
                        on: {
                          atualizarBeneficio: function($event) {
                            _vm.pessoa.beneficios = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.possuiDeficiencia
                    ? _c("fieldset", { staticClass: "blocoFieldset" }, [
                        _c("legend", [
                          _vm._v(
                            "\n                        Laudos\n                    "
                          )
                        ]),
                        _c(
                          "label",
                          { ref: "arquivosValidos", attrs: { tabindex: "0" } },
                          [
                            _vm._v(
                              "\n                        Serão válidos apenas arquivos do tipo pdf, jpg, jpeg, bmp, png, doc, docx, xls e xlsx com tamanho individual máximo de 5 mb.\n                    "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-card",
                              [
                                _c(
                                  "label",
                                  {
                                    ref: "tabLaudos",
                                    attrs: { tabindex: "0" }
                                  },
                                  [_vm._v("Tabela de Laudos")]
                                ),
                                _c(
                                  "b-table",
                                  {
                                    ref: "tabelaLaudos",
                                    attrs: {
                                      "show-empty": "",
                                      stacked: "sm",
                                      hover: "hover",
                                      striped: "striped",
                                      items: _vm.pessoa.laudos,
                                      fields: _vm.campos,
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.perPage
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(acoes)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-left"
                                                },
                                                [
                                                  !_vm.isExibir
                                                    ? _c(
                                                        "b-link",
                                                        {
                                                          staticClass:
                                                            "btn btn-outline-info ml-1",
                                                          attrs: {
                                                            title:
                                                              "Excluir Laudo " +
                                                              data.item.arquivo
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeArquivo(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-times"
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3589919339
                                    )
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: {
                                          slot: "empty",
                                          colspan: "2",
                                          align: "center"
                                        },
                                        slot: "empty"
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { tabindex: "0" } },
                                          [
                                            _vm._v(
                                              "Nenhum arquivo de laudo carregado"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "file-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "btn mt-30 btn-primary btn-md",
                                    attrs: {
                                      tabindex: "0",
                                      title: "Adicionar Laudo",
                                      multiple: true
                                    },
                                    on: { input: _vm.inputFile },
                                    model: {
                                      value: _vm.arquivos,
                                      callback: function($$v) {
                                        _vm.arquivos = $$v
                                      },
                                      expression: "arquivos"
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-plus" }),
                                    _vm._v(
                                      " Incluir Arquivo de Laudo\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              !_vm.isIncluirDependente &&
              !_vm.isRepresentado &&
              !_vm.pessoa.possuiVinculados
                ? _c(
                    "fieldset",
                    { staticClass: "blocoFieldset" },
                    [
                      _c("legend", [
                        _vm._v(
                          "\n                    Representante\n                "
                        )
                      ]),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "12",
                                md: "6",
                                lg: "6",
                                xl: "6"
                              }
                            },
                            [
                              _c("b-form-group", {
                                staticClass: "text-label",
                                attrs: {
                                  label:
                                    "Você é responsável por alguém com deficiência?"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var ariaDescribedby =
                                          ref.ariaDescribedby
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              attrs: {
                                                "aria-describedby": ariaDescribedby,
                                                value: true,
                                                disabled: _vm.isExibir,
                                                "data-vv-as":
                                                  "Você é responsável por alguém com deficiência?",
                                                "data-vv-name": "eResponsavel",
                                                state:
                                                  _vm.$validator.errors.has(
                                                    "eResponsavel"
                                                  ) == false
                                                    ? null
                                                    : !_vm.$validator.errors.has(
                                                        "eResponsavel"
                                                      )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$emit(
                                                    "changeIsResponsavel",
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.isResponsavel,
                                                callback: function($$v) {
                                                  _vm.isResponsavel = $$v
                                                },
                                                expression: "isResponsavel"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Sim\n                            "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              attrs: {
                                                "aria-describedby": ariaDescribedby,
                                                value: false,
                                                disabled: _vm.isExibir,
                                                "data-vv-as":
                                                  "Você é responsável por alguém com deficiência?",
                                                "data-vv-name": "eResponsavel",
                                                state:
                                                  _vm.$validator.errors.has(
                                                    "eResponsavel"
                                                  ) == false
                                                    ? null
                                                    : !_vm.$validator.errors.has(
                                                        "eResponsavel"
                                                      )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$emit(
                                                    "changeIsResponsavel",
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.isResponsavel,
                                                callback: function($$v) {
                                                  _vm.isResponsavel = $$v
                                                },
                                                expression: "isResponsavel"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Não\n                            "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.$validator.errors.has(
                                                    "eResponsavel"
                                                  ),
                                                  expression:
                                                    "$validator.errors.has('eResponsavel')"
                                                }
                                              ],
                                              staticClass: "help is-danger"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$validator.errors.first(
                                                      "eResponsavel"
                                                    )
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  526736648
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "md" },
                      on: {
                        click: function($event) {
                          return _vm.salvar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isResponsavel && !_vm.pessoa.possuiVinculados
                              ? "Salvar Dados e Incluir Dependente"
                              : "Salvar"
                          ) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v("\n                 \n\n                "),
                  _c(
                    "b-button",
                    {
                      attrs: { outline: "", size: "md", variant: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Voltar para tela inicial\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalVincularRepresentante",
            title: "Vincular Representante",
            "cancel-title": "Cancelar",
            modal: "",
            size: "xl"
          },
          on: {
            ok: function($event) {
              return _vm.vincularRepresentante()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var cancel = ref.cancel
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.pessoa.representante == null
                      },
                      on: { click: ok }
                    },
                    [_vm._v("\n                Confirmar\n            ")]
                  ),
                  _c("b-button", { on: { click: cancel } }, [
                    _vm._v("\n                Cancelar\n            ")
                  ])
                ]
              }
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { xs: "12" } }, [
                _vm.messages
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              variant: "danger",
                              show: 5,
                              dismissible: "",
                              fade: ""
                            },
                            on: {
                              dismissed: function($event) {
                                _vm.messages = undefined
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-exclamation-circle"
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.messages.message) +
                                "\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "4", sm: "4", md: "2", lg: "2", xl: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        label: "CPF do Representante",
                        "label-for": "cpfVincularRepresentante"
                      }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###.###.###-##",
                            expression: "'###.###.###-##'"
                          }
                        ],
                        attrs: { id: "cpfVincularRepresentante" },
                        model: {
                          value: _vm.cpfVincularRepresentante,
                          callback: function($$v) {
                            _vm.cpfVincularRepresentante = $$v
                          },
                          expression: "cpfVincularRepresentante"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { xs: "4", sm: "4", md: "2", lg: "2", xl: "2" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Buscar Representante"
                      },
                      on: {
                        click: function($event) {
                          return _vm.buscarVincularRepresentante()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-search" }),
                      _vm._v(" Buscar\n                ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { xs: "4", sm: "4", md: "2", lg: "2", xl: "2" }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label required",
                      attrs: {
                        label: "Grau Parentesco",
                        "label-for": "grauParentesco"
                      }
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            id: "grauParentesco",
                            plain: true,
                            required: true,
                            value: "Selecionar Grau de Parentesco",
                            "data-vv-name": "grauParentesco",
                            "data-vv-as": "Grau de Parentesco",
                            "error-messages": _vm.$validator.errors.collect(
                              "grauParentesco"
                            ),
                            state:
                              _vm.$validator.errors.has("grauParentesco") ==
                              false
                                ? null
                                : !_vm.$validator.errors.has("grauParentesco")
                          },
                          model: {
                            value: _vm.pessoa.grauParentesco,
                            callback: function($$v) {
                              _vm.$set(_vm.pessoa, "grauParentesco", $$v)
                            },
                            expression: "pessoa.grauParentesco"
                          }
                        },
                        [
                          _c("template", { slot: "first" }, [
                            _c("option", { domProps: { value: null } })
                          ]),
                          _vm._l(_vm.grausParentesco, function(m, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: m } },
                              [
                                _vm._v(
                                  _vm._s(m.descricao) +
                                    "\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$validator.errors.has(
                                "grauParentesco"
                              ),
                              expression:
                                "$validator.errors.has('grauParentesco')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$validator.errors.first("grauParentesco")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.pessoa.representante != null
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _vm._v(
                        "\n                Deseja se vincular ao Representante " +
                          _vm._s(_vm.pessoa.representante.nome) +
                          " ?\n            "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalTermoAceite",
            title: "Termo de Aceite",
            "cancel-title": "Cancelar",
            "ok-title": "Confirmar",
            modal: "",
            size: "xl"
          },
          on: {
            ok: function($event) {
              return _vm.confirmarAceite()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var cancel = ref.cancel
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", disabled: !_vm.ciente },
                      on: { click: ok }
                    },
                    [_vm._v("\n                Confirmar\n            ")]
                  ),
                  _c("b-button", { on: { click: cancel } }, [
                    _vm._v("\n                Cancelar\n            ")
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("b-row", [_c("b-col", [_c("termo-aceite")], 1)], 1),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "text-label",
                      attrs: {
                        "label-for": "ciente",
                        "invalid-feedback": "Campo obrigatório",
                        state: _vm.ciente
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            value: true,
                            id: "ciente",
                            state: _vm.ciente,
                            required: ""
                          },
                          model: {
                            value: _vm.ciente,
                            callback: function($$v) {
                              _vm.ciente = $$v
                            },
                            expression: "ciente"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Declaro estar ciente e de acordo com os termos dispostos\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }