var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide"
        }
      ],
      staticClass: "dont-print cel-user-account",
      attrs: { id: "user-menu-button" },
      on: { click: _vm.openMenu }
    },
    [
      _c("div", { staticClass: "user-menu-labels" }, [
        _c("div", { staticClass: "label-username text-right" }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Nome do usuário")]),
          _vm._v("\n      " + _vm._s(_vm.nomeUsuario()) + "\n    ")
        ]),
        _c("div", { staticClass: "text-right" }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Grupo do usuário")]),
          _c("span", { staticClass: "label-usergroup" }, [
            _vm._v(_vm._s(_vm.grupoUsuario()))
          ])
        ])
      ]),
      _c(
        "div",
        [
          _c("img", {
            staticClass: "user-photo",
            attrs: {
              src: require("@/assets/images/user-photo.png"),
              alt: "Foto do usuário"
            }
          }),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Menu do usuário")]),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _vm.showMenu
              ? _c("div", { staticClass: "user-menu" }, [
                  _c("div", { staticClass: "header no-gutters" }, [
                    _c("img", {
                      staticClass: "user-photo-menu",
                      attrs: { src: _vm.user.photo, alt: "Foto do usuário" }
                    }),
                    _c("div", { staticClass: "id-container" }, [
                      _c("div", { staticClass: "label-username" }, [
                        _vm._v(_vm._s(_vm.nomeUsuario()))
                      ]),
                      _c("div", { staticClass: "label-usergroup" }, [
                        _vm._v(_vm._s(_vm.grupoUsuario()))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "user-menu-body" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          { attrs: { href: "#" }, on: { click: _vm.perfil } },
                          [
                            _c("div", { staticClass: "i-block" }, [
                              _c("i", { staticClass: "fas fa-id-badge" })
                            ]),
                            _vm._v("\n                Perfil\n              ")
                          ]
                        )
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          { attrs: { href: "#" }, on: { click: _vm.sair } },
                          [
                            _c("div", { staticClass: "i-block" }, [
                              _c("i", { staticClass: "fas fa-sign-out-alt" })
                            ]),
                            _vm._v("\n                Sair\n              ")
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }