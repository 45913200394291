






import { Component, Vue } from 'vue-property-decorator';
import ListarEstabelecimento from './listar-estabelecimento.vue';
import EditarEstabelecimento from './editar-estabelecimento.vue';
import ExibirEstabelecimento from './exibir-estabelecimento.vue';

@Component({
    components: {
        ListarEstabelecimento,
        EditarEstabelecimento,
        ExibirEstabelecimento
    }
})
export default class EstabelecimentoContainer extends Vue {
}
