var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portal-layout", class: _vm.getTheme, attrs: { id: "app" } },
    [
      _c("AccessBar"),
      _c("AppHeader"),
      _c(
        "div",
        {
          staticClass: "body-size col-sm-12 col-xl-8",
          staticStyle: { margin: "0 auto" },
          attrs: { id: "" }
        },
        [_c("AppMain")],
        1
      ),
      _c("AppFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }