<template>
  <div id="access-bar">
    <h1>
      <b-link 
        aria-label="LINK Ir para o Portal" 
        href="http://www.pracessivel.pr.gov.br">
          Ir para o Portal
      </b-link>

      <b-link 
        aria-label="LINK Acessibilidade" 
        href="https://www.pia.pr.gov.br/acessibilidade">
          Acessibilidade
      </b-link>
    
      <b-link 
        aria-label="LINK Mudar o contraste" 
        @click="setContraste()">
        <i class="fas fa-adjust" aria-hidden="true"></i>
      </b-link>

      <b-link 
        aria-label="LINK Aumentar fontes" 
        @click="aumentarZoom()">
        [ + ] Aumentar fontes
      </b-link>

      <b-link 
        aria-label="LINK Reduzir fontes" 
        @click="diminuirZoom()">
        [ - ] Reduzir fontes
      </b-link>
    </h1>
  </div>

</template>

<script>
export default {
  methods: {
    setContraste () {
      let theme = ''
      
      if (this.$store.getters.getTheme === '') {
        theme = 'dark'
      }
      if (this.$store.getters.getTheme === 'dark') {
        theme = 'blue'
      }
      if (this.$store.getters.getTheme === 'blue') {
        theme = 'yellow'
      }

      this.$store.commit('setTheme', theme)
    },
    aumentarZoom() {
      document.body.style.transformOrigin = '0 0';

      const elementoBody = document.body;
      const estiloTransform = window.getComputedStyle(elementoBody).getPropertyValue('transform');
      
      // Analise a matriz de transformação para obter a escala atual
      const matrizTransform = estiloTransform.match(/matrix\(([^\)]+)\)/);
      if (matrizTransform) {
        const matriz = matrizTransform[1].split(', ');
        const escalaAtual = parseFloat(matriz[0]); // O primeiro valor é a escala X

        const novaEscala = escalaAtual + 0.025;
        document.body.style.transform = `scale(${novaEscala})`;
      } else {
        elementoBody.style.transform = 'scale(1.025)';
      }
    },
    diminuirZoom() {
      document.body.style.transformOrigin = '0 0';

      const elementoBody = document.body;
      const estiloTransform = window.getComputedStyle(elementoBody).getPropertyValue('transform');
      
      // Analise a matriz de transformação para obter a escala atual
      const matrizTransform = estiloTransform.match(/matrix\(([^\)]+)\)/);
      if (matrizTransform) {
        const matriz = matrizTransform[1].split(', ');
        const escalaAtual = parseFloat(matriz[0]); // O primeiro valor é a escala X
        const novaEscala = escalaAtual - 0.025;

        elementoBody.style.transform = `scale(${novaEscala})`;
      } else {
        elementoBody.style.transform = 'scale(0.975)';
      }
    },
  }
}
</script>
