




















































import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';
import { Component, Vue } from 'vue-property-decorator';
import { Message } from '../../model/generic/message';
import { RecursoAcessibilidade } from '../../model/recurso-acessibilidade';

@Component({
    props: { recursoProp: Object }
})
export default class EditarRecursoAcessibilidade extends Vue {
    
    public recurso: RecursoAcessibilidade;

    constructor(){
        super();
        if (this.$props.recursoProp != null) {
            this.recurso = this.$props.recursoProp;
        } else {
            this.recurso = new RecursoAcessibilidade(null, "", "");
        }
    }

    async salvar () {
        const result = await this.$validator.validateAll();
        if (result) {
            let retorno = await recursoAcessibilidadeService.salvar(this.recurso);
            if (retorno != null) {
                this.$router.push({
                    name: 'listar-recurso-acessibilidade'
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    voltar () {
        this.$router.push({
            name: 'listar-recurso-acessibilidade'
        });
    }
}
