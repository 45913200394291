




























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins'
import { Message } from '../../model/generic/message';
import { Estabelecimento } from '../../model/estabelecimento';
import { RamoAtividadeEnum } from '@/model/enum/ramo-atividade';
import EnderecoComponent from '../component/endereco-component.vue';
import ContatoComponent from '../component/contato-component.vue';
import PessoaEstabelecimentoComponent from '../component/pessoa-estabelecimento-component.vue';
import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import { HorarioAtendimento } from '@/model/horario-atendimento';
import estabelecimentoService from '@/api/estabelecimento.service';
import { Option } from '@/model/option';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';
import { RecursoAcessibilidadeFilter } from '@/model/filter/recurso-acessibilidade-filter';
import { Endereco } from '@/model/endereco';
import { ArquivoEstabelecimento } from '@/model/arquivo-estabelecimento';
import { saveAs } from 'file-saver';

@Component({
    mixins: [formatar],
    props: { estabProp: Object, voltarProp: String, visaoCidadao: Boolean },
    components: { EnderecoComponent, ContatoComponent, PessoaEstabelecimentoComponent }
})
export default class EditarEstabelecimento extends Vue {

    public regexDoc: String = '/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/';
    public estabelecimento: Estabelecimento;
    public ramos: Array<Option>;
    public recursosAcessibilidade: Array<RecursoAcessibilidade>;
    public ramoAtividade: Option|null;
    public files: Array<File>;
    private arquivosEnviando: Array<String>;
    public camposFotos: Array<Object> = [
      {
        key: 'nome',
        label: 'Nome do Arquivo',
        sortable: true
      },
      {
        key: 'miniatura',
        label: "Pré-Visualização",
        sortable: false
      },
      {
        key: 'descricao',
        label: "Descrição da foto para deficientes visuais",
        sortable: false
      },
      {
        key: 'fonte',
        label: "Fonte da Foto",
        sortable: false
      },
      {
        key: 'autor',
        label: "Autor da Foto",
        sortable: false
      },
      {
        key: 'acoes',
        label: 'Ações',
        sortable: false
      }];

    constructor () {
        super();
        this.ramoAtividade = null;
        this.ramos =  Object.entries(RamoAtividadeEnum).flatMap(obj => [new Option(obj, obj[1])]);
        this.recursosAcessibilidade = Array.of();
        this.carregaRecursosAcessibilidade();
        this.files = Array.of();

        if (this.$props.estabProp != null) {
            console.log(this.$props.estabProp);
            this.estabelecimento = this.$props.estabProp;
            if (this.estabelecimento.ramoAtividade) {
                this.ramoAtividade = this.getRamoOption(this.estabelecimento.ramoAtividade);
            }
            if(this.estabelecimento.horariosAtendimento != undefined){
                this.estabelecimento.horariosAtendimento.forEach(horarioAtendimento => {
                    horarioAtendimento.horaInicio = new Date(horarioAtendimento.horaInicio);
                    horarioAtendimento.horaFim = new Date(horarioAtendimento.horaFim);
                })
            }
        } else {
            this.estabelecimento = new Estabelecimento(null, "", "", "");
            this.estabelecimento.endereco = new Endereco(null, "", "", "", null, "", null);
            this.estabelecimento.contatos = Array.of();
            let emptyDate = new Date(2021, 1, 1, 0, 0, 0, 0);
            this.estabelecimento.horariosAtendimento = Array.of(new HorarioAtendimento(1, 1, emptyDate, emptyDate),
                new HorarioAtendimento(2, 2, emptyDate, emptyDate),
                new HorarioAtendimento(3, 3, emptyDate, emptyDate),
                new HorarioAtendimento(4, 4, emptyDate, emptyDate),
                new HorarioAtendimento(5, 5, emptyDate, emptyDate),
                new HorarioAtendimento(6, 6, emptyDate, emptyDate),
                new HorarioAtendimento(7, 7, emptyDate, emptyDate));
        }
        this.estabelecimento.arquivos.forEach( arq => {
            arq.miniatura = '../../assets/loading.gif'
            this.geraMiniatura(arq);
        })
        this.arquivosEnviando = Array.of();
    }

    getRamoOption(key : String) : Option {
        const enumValue = Object.entries(RamoAtividadeEnum)
            .find(([id, value]) => {if(id == key) return value});

        if (enumValue) {
            return new Option(enumValue, enumValue[1]);
        }
        return new Option(0, "");
    }

    async carregaRecursosAcessibilidade() {
        const retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(new RecursoAcessibilidadeFilter("", "", true), false);
        if(retorno != null) {
            this.recursosAcessibilidade = retorno.registros;
        }
    }

    async salvar () {
        await this.aguardaEnvioArquivos();
        console.log('this.estabelecimento', this.estabelecimento);

        const result = await this.$validator.validateAll();
        if (result) {
            if (this.ramoAtividade !== null) {
                this.estabelecimento.ramoAtividade = this.ramoAtividade.id[0];
            }
            let retorno = await estabelecimentoService.salvar(this.estabelecimento);
            if (retorno != null) {
                this.$router.push({
                    name: this.$props.voltarProp,
                    params: { visaoCidadao: this.$props.visaoCidadao }
                });
                this.$store.commit('setMessages', new Message('Registro salvo com sucesso', 'success'));
            }
        }
    }

    async aguardaEnvioArquivos () {
        if (this.arquivosEnviando.length > 0) {
            await this.delay(1000);
            await this.aguardaEnvioArquivos();
        }
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    voltar () {
        this.$router.push({
            name: 'listar-estabelecimento',
            params: { visaoCidadao: this.$props.visaoCidadao }
        });
    }

    removerRecurso (rec: RecursoAcessibilidade) {
        if (this.estabelecimento.acessibilidades != null) {
            const index = this.estabelecimento.acessibilidades.indexOf(rec, 0);
            if (index > -1) {
                this.estabelecimento.acessibilidades.splice(index, 1);
            }
        }
    }

    inputFile (newFile: Array<Object>) {
        if (this.estabelecimento.arquivos === undefined) {
            this.estabelecimento.arquivos = Array.of();
        }
        // @ts-ignore
        let lastFile = newFile[newFile.length-1].file;

        let arquivoEstabelecimento = new ArquivoEstabelecimento(null, lastFile.name, lastFile, '', '', '');
        let arquivos =  this.estabelecimento.arquivos
        var reader = new FileReader();
        // @ts-ignore
        reader.readAsDataURL(lastFile);
        reader.onloadend = function() {
            var base64data = reader.result;
            // @ts-ignore
            arquivoEstabelecimento.miniatura = base64data;
            arquivos.push(arquivoEstabelecimento)
        }
        this.arquivosEnviando.push(lastFile.name);
        estabelecimentoService.enviarArquivo(lastFile).then(response => {
            const index = this.arquivosEnviando.indexOf(lastFile.name, 0);
            if (index > -1) {
                this.arquivosEnviando.splice(index, 1);
            }
        });
    }

    alterarArquivo (arquivo: ArquivoEstabelecimento, campo: string) {
        if (this.estabelecimento.arquivos != null) {
            const index = this.estabelecimento.arquivos.indexOf(arquivo, 0);
            if (index > -1) {
                if (campo == 'descricao') {
                    this.estabelecimento.arquivos[index].descricao = arquivo.descricao;
                } else if (campo == 'fonte') {
                    this.estabelecimento.arquivos[index].fonte = arquivo.fonte;
                } else if (campo == 'autor') {
                    this.estabelecimento.arquivos[index].autor = arquivo.autor;
                }
            }
        }
    }

    removeArquivo (arquivo: ArquivoEstabelecimento) {
        if (this.estabelecimento.arquivos != null) {
            const index = this.estabelecimento.arquivos.indexOf(arquivo, 0);
            if (index > -1) {
                this.estabelecimento.arquivos.splice(index, 1);
            }
        }
    }

    async geraMiniatura (arquivo: ArquivoEstabelecimento) {
        // @ts-ignore
        let conteudo = await estabelecimentoService.downloadArquivo(arquivo);
        if (conteudo != null) {
            var reader = new FileReader();
            // @ts-ignore
            reader.readAsDataURL(conteudo.data); 
            reader.onloadend = function() {
                var base64data = reader.result;
                // @ts-ignore
                arquivo.miniatura = base64data;
            }
        }
    }

    salvarArquivo (arquivo: ArquivoEstabelecimento) {
        if (arquivo.miniatura !== undefined) {
            // @ts-ignore
            saveAs(arquivo.miniatura, arquivo.nome);
        }
    }

}
