var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset",
        { staticClass: "blocoFieldset" },
        [
          _c(
            "legend",
            [
              _vm._v("\n            Gestores\n            "),
              !_vm.isExibir
                ? _c(
                    "b-link",
                    {
                      staticClass: "btn btnLegend btn-outline-info ml-1 mr-2",
                      attrs: { title: "Incluir Gestor" },
                      on: {
                        click: function($event) {
                          return _vm.iniciarIncluir()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-plus" })]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.pessoa != null && !_vm.isExibir
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "4", xl: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "CPF", "label-for": "cpf" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'"
                              }
                            ],
                            attrs: {
                              id: "cpf",
                              "data-vv-name": "cpf",
                              "error-messages": "CPF Inválido",
                              state: _vm.validarCPF(_vm.pessoa.cpf)
                            },
                            model: {
                              value: _vm.pessoa.cpf,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "cpf", $$v)
                              },
                              expression: "pessoa.cpf"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.validarCPF(_vm.pessoa.cpf),
                                  expression: "!validarCPF(pessoa.cpf)"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("CPF Inválido")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "6", md: "6", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Nome", "label-for": "nome" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "nome",
                              trim: "",
                              "data-vv-name": "nome",
                              "error-messages": _vm.errors.collect("nome"),
                              state:
                                _vm.errors.has("nome") == false
                                  ? null
                                  : !_vm.errors.has("nome")
                            },
                            model: {
                              value: _vm.pessoa.nome,
                              callback: function($$v) {
                                _vm.$set(_vm.pessoa, "nome", $$v)
                              },
                              expression: "pessoa.nome"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("nome"),
                                  expression: "errors.has('nome')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errors.first("nome")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "12", lg: "2", xl: "2" }
                    },
                    [
                      _vm.pessoaAlterar == null
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-30",
                              attrs: {
                                variant: "primary",
                                size: "md",
                                title: "Incluir Pessoa"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.incluir()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-plus" }),
                              _vm._v(" Incluir Pessoa\n                ")
                            ]
                          )
                        : _vm._e(),
                      _vm.pessoaAlterar != null
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-30",
                              attrs: {
                                variant: "primary",
                                size: "md",
                                title: "Alterar Pessoa"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.alterar()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-pencil-alt" }),
                              _vm._v(" Alterar Pessoa\n                ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-card",
            [
              _c(
                "b-table",
                {
                  attrs: {
                    "show-empty": "",
                    stacked: "sm",
                    hover: "hover",
                    striped: "striped",
                    items: _vm.pessoas,
                    fields: _vm.campos,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage,
                    "caption-top": "",
                    caption: "Tabela de Pessoas"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(cpf)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("mascaraDocumento")(data.item.cpf)
                              ) +
                              "\n                "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(acoes)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-left" },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "btn btn-outline-info ml-1",
                                  attrs: { title: "Alterar Pessoa" },
                                  on: {
                                    click: function($event) {
                                      return _vm.iniciarAlterar(data.item)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-pencil-alt" })]
                              ),
                              _c(
                                "b-link",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modalExcluirPessoa",
                                      modifiers: { modalExcluirPessoa: true }
                                    }
                                  ],
                                  staticClass: "btn btn-outline-info ml-1",
                                  attrs: { title: "Excluir Pessoa" },
                                  on: {
                                    click: function($event) {
                                      _vm.pessoaAlterar = data.item
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-times" })]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "empty", colspan: "2", align: "center" },
                      slot: "empty"
                    },
                    [_vm._v("Sem dados para exibir")]
                  )
                ]
              ),
              _vm.pessoas.length > 0
                ? _c(
                    "b-row",
                    { staticClass: "my-1 center-xy" },
                    [
                      _c("b-pagination", {
                        staticClass: "text-label",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          length: _vm.currentPage
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      }),
                      _c(
                        "p",
                        {
                          staticClass:
                            "VuePagination__count text-center col-md-12"
                        },
                        [
                          _vm._v(
                            "\n                    Mostrando " +
                              _vm._s(
                                _vm.currentPage * _vm.perPage - _vm.perPage + 1
                              ) +
                              " a\n                    " +
                              _vm._s(
                                _vm.currentPage * _vm.perPage > _vm.totalRows
                                  ? _vm.totalRows
                                  : _vm.currentPage * _vm.perPage
                              ) +
                              "\n                    de " +
                              _vm._s(_vm.totalRows) +
                              " registros\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalExcluirPessoa",
            title: "Confirmação de Remoção",
            "cancel-title": "Cancelar",
            "ok-title": "Excluir",
            modal: ""
          },
          on: {
            ok: function($event) {
              return _vm.excluir(_vm.pessoaAlterar)
            }
          }
        },
        [_c("b-row", [_c("b-col", [_vm._v("Deseja excluir a Pessoa?")])], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }