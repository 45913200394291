import { PagingList } from './../model/generic/paging-list';
import store from '@/store/store';
import { TipoDeficienciaFilter } from './../model/filter/tipo-deficiencia-filter';
import { TipoDeficiencia } from './../model/tipo-deficiencia';
import restUtil from './rest.util.service';
import { Message } from './../model/generic/message';

class TipoDeficienciaService {

  async listarTiposDeficiencia (filter: TipoDeficienciaFilter, exibirMensagem: Boolean): Promise<PagingList<TipoDeficiencia>|null> {
    try{
      let retorno = await restUtil.doPost<PagingList<TipoDeficiencia>>('v1/tipodeficiencia/search', filter);
      if (retorno != null && retorno.data != null) {
        if (retorno.data.totalRegistros == 0 && exibirMensagem) {
          store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
      }
        return retorno.data;
      }
    } catch (error) {
      store.commit('setMessages', (error as any).response.data);
    }
    return null;
  }

  async listarGerais (): Promise<PagingList<TipoDeficiencia>|null> {
    let filtro = new TipoDeficienciaFilter("", true)
    filtro.gerais = true;
    return this.listarTiposDeficiencia(filtro, true);
  }

  async obter (id: Number): Promise<TipoDeficiencia | null> {
    let retorno = await restUtil.doGet<TipoDeficiencia>('v1/tipodeficiencia/'+id);
    if (retorno != null && retorno.data != null) {
      return retorno.data;
    }
    return null;
  }

  async salvar (tpDef: TipoDeficiencia) {
    let retorno;
    if (tpDef.id == null) {
      retorno = await restUtil.doPost<TipoDeficiencia>('v1/tipodeficiencia', tpDef);
    } else {
      retorno = await restUtil.doPut<TipoDeficiencia>('v1/tipodeficiencia/'+tpDef.id, tpDef);
    }
    return retorno;
  }

  async excluir (id: Number) {
    const retorno = await restUtil.doDelete('v1/tipodeficiencia/'+id);
    if (retorno != null) {
      store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
    }
    return retorno;
  }

}

export default new TipoDeficienciaService();