import { Message } from './../model/generic/message';
import store from '@/store/store';
import restUtil from './rest.util.service';
import { Pessoa } from '@/model/pessoa';
import { Deficiente } from '@/model/deficiente';

class DeficienteService {

    async obter (): Promise<Deficiente | null> {
        let retorno = await restUtil.doGetWithoutMessage<Deficiente>('v1/pessoa');
        if (retorno != null && retorno.data != null) {
          return retorno.data;
        }
        return null;
   }

   async obterCompleto (idDeficiente: Number): Promise<Deficiente | null> {
       let retorno = await restUtil.doGet<Deficiente>('v1/pessoa/'+idDeficiente);
       if (retorno != null && retorno.data != null) {
         return retorno.data;
       }
       return null;
  }

  async obterLogadoCompleto (): Promise<Deficiente | null> {
      let retorno = await this.obter();
      if (retorno == null || retorno.id == null) {
         return null;
      }
      return await this.obterCompleto(retorno.id);
 }
    
    async salvar (deficiente: Deficiente) {
         let retorno;
         if (deficiente.id == null){
            retorno = await restUtil.doPost<Pessoa>('v1/pessoa', deficiente);
         } else {
            deficiente.origem = "PR_ACESSIVEL";
            retorno = await restUtil.doPut<Pessoa>('v1/pessoa/'+deficiente.id, deficiente);
         }
         return retorno;
    }

    async excluir (id: Number) {
         const retorno = await restUtil.doDelete('v1/pessoa/'+id);
         if (retorno != null) {
           store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
         }
         return retorno;
    }

}

export default new DeficienteService();