import { Municipio } from './../model/municipio';
import { PagingList } from './../model/generic/paging-list';
import restUtil from './rest.util.service';
import restUtilCep from './rest.util.cep.service';
import { Endereco } from '@/model/endereco';
import axios, { AxiosResponse } from 'axios'

class EnderecoService {

    async obterPorCep (cep: String | null): Promise<Endereco|null> {
      cep = cep ? cep.replace(/[^0-9]/g, '') : '00000000'
      // tipo_codificacao_localidade_retorno
      // 0-CORREIOS, 1-SERPRO e 2-IBGE
      let retorno = await restUtilCep.doGetCEP(`/endereco/${cep}/0`);

      return retorno != null ? 
        new Endereco(null, retorno[0].bairro, 
          retorno[0].nome ? retorno[0].tipo + ' ' + retorno[0].nome : null, 
          null, null, retorno[0].cep, new Municipio(null, retorno[0].localidade, retorno[0].uf))
        : null;
    }

    async listarUFs (): Promise<PagingList<String>|null> {
      let retorno = await restUtil.doGet<PagingList<String>>('v1/endereco/ufs');
      if (retorno != null && retorno.data != null) {
        return retorno.data;
      }
      return null;
  }

  async listarMunicipios (uf: String): Promise<PagingList<Municipio>|null> {
      let retorno = await restUtil.doGet<PagingList<Municipio>>(`v1/endereco/municipios/${uf}`);
      if (retorno != null && retorno.data != null) {
        return retorno.data;
      }
      return null;
  }

  async listarMunicipiosNeoCep (uf: String): Promise<PagingList<Object> | null> {
    // tipo_codificacao_localidade_retorno
    // 0-CORREIOS, 1-SERPRO e 2-IBGE
    // tipo_localidade
    // tipo de localidade (M-Município, P-Povoado e D-Distrito)
    let retorno = await restUtilCep.doGetCEP(`/localidades/${uf}/M/0`);
    let retArray = retorno != null ? [retorno] : [];
    console.log(654654, retArray);
    return retorno != null ? 
      new PagingList(retArray, retArray.length, 1, retArray.length, retArray.length)
      : null;
  }
}

export default new EnderecoService();