var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Nome", "label-for": "nome" }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'"
                              }
                            ],
                            attrs: {
                              id: "nome",
                              "data-vv-name": "nome",
                              disabled: _vm.pessoaProp != null,
                              "data-vv-as": "Nome",
                              "error-messages": _vm.$validator.errors.collect(
                                "nome"
                              ),
                              state:
                                _vm.$validator.errors.has("nome") == false
                                  ? null
                                  : !_vm.$validator.errors.has("nome"),
                              autofocus: true,
                              trim: ""
                            },
                            model: {
                              value: _vm.sac.nome,
                              callback: function($$v) {
                                _vm.$set(_vm.sac, "nome", $$v)
                              },
                              expression: "sac.nome"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has("nome"),
                                  expression: "$validator.errors.has('nome')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$validator.errors.first("nome")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "12", md: "6", lg: "6", xl: "6" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Assunto", "label-for": "assunto" }
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                id: "assunto",
                                plain: true,
                                value: "Selecionar Assunto",
                                "data-vv-name": "assunto",
                                "data-vv-as": "Assunto",
                                "error-messages": _vm.$validator.errors.collect(
                                  "assunto"
                                ),
                                state:
                                  _vm.$validator.errors.has("assunto") == false
                                    ? null
                                    : !_vm.$validator.errors.has("assunto")
                              },
                              model: {
                                value: _vm.sac.assunto,
                                callback: function($$v) {
                                  _vm.$set(_vm.sac, "assunto", $$v)
                                },
                                expression: "sac.assunto"
                              }
                            },
                            [
                              _c("template", { slot: "first" }, [
                                _c("option", { attrs: { value: "" } })
                              ]),
                              _vm._l(_vm.assuntos, function(m, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: m } },
                                  [_vm._v(_vm._s(m.descricao))]
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has("assunto"),
                                  expression: "$validator.errors.has('assunto')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$validator.errors.first("assunto")
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-label required",
                          attrs: { label: "Mensagem:", "label-for": "mensagem" }
                        },
                        [
                          _c("b-form-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:4095",
                                expression: "'required|max:4095'"
                              }
                            ],
                            attrs: {
                              id: "mensagem",
                              size: "lg",
                              "data-vv-name": "mensagem",
                              "data-vv-as": "Mensagem",
                              trim: "",
                              "error-messages": _vm.$validator.errors.collect(
                                "mensagem"
                              ),
                              state:
                                _vm.$validator.errors.has("mensagem") == false
                                  ? null
                                  : !_vm.$validator.errors.has("mensagem")
                            },
                            model: {
                              value: _vm.sac.mensagem,
                              callback: function($$v) {
                                _vm.$set(_vm.sac, "mensagem", $$v)
                              },
                              expression: "sac.mensagem"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$validator.errors.has("mensagem"),
                                  expression:
                                    "$validator.errors.has('mensagem')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$validator.errors.first("mensagem")
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12"
                      }
                    },
                    [
                      _c("contato-component", {
                        attrs: { listaContatosProp: _vm.sac.contatos }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Salvar"
                      },
                      on: {
                        click: function($event) {
                          return _vm.salvar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Salvar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para tela anterior"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Cancelar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "far fa-comments" }),
      _vm._v(" Fale com a gente\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }