import { Usuario } from "./usuario";

export class LaudoPessoa {
    id: Number|null;
    situacao: String; // ENUM?
    arquivo: string;
    origemAvaliacao?: String;
    usuarioAvaliacao?: Usuario;
    dataAvaliacao?: Date;
    justificativa?: String;
    content?: File;

    constructor (id: Number|null, situacao: String, arquivo: string, origemAvaliacao?: String, usuarioAvaliacao?: Usuario, dataAvaliacao?: Date, justificativa?: String, content?: File) {
        this.id = id;
        this.situacao = situacao; 
        this.arquivo = arquivo;
        this.origemAvaliacao = origemAvaliacao;
        this.usuarioAvaliacao = usuarioAvaliacao;
        this.dataAvaliacao = dataAvaliacao;
        this.justificativa = justificativa;
        this.content = content;
    }
}