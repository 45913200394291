var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c(
                "fieldset",
                { staticClass: "blocoFieldset" },
                [
                  _c("legend", [
                    _vm._v("\n                    SAC\n                ")
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: { label: "Nome", "label-for": "nome" }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "nome", disabled: "" },
                                model: {
                                  value: _vm.sac.nome,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sac, "nome", $$v)
                                  },
                                  expression: "sac.nome"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Assunto",
                                "label-for": "assunto"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "assunto", disabled: "" },
                                model: {
                                  value: _vm.sac.assunto.descricao,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sac.assunto, "descricao", $$v)
                                  },
                                  expression: "sac.assunto.descricao"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Situação",
                                "label-for": "situacao"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "situacao", disabled: "" },
                                model: {
                                  value: _vm.sacProp.situacao,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sacProp, "situacao", $$v)
                                  },
                                  expression: "sacProp.situacao"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.sacProp.usuarioResponsavel !== undefined
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                                xl: "12"
                              }
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "text-label",
                                  attrs: {
                                    label: "Responsável",
                                    "label-for": "responsavel"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "responsavel", disabled: "" },
                                    model: {
                                      value:
                                        _vm.sacProp.usuarioResponsavel.nome,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sacProp.usuarioResponsavel,
                                          "nome",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sacProp.usuarioResponsavel.nome"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label",
                              attrs: {
                                label: "Mensagem:",
                                "label-for": "mensagem"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "mensagem",
                                  size: "lg",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.sac.mensagem,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sac, "mensagem", $$v)
                                  },
                                  expression: "sac.mensagem"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c("contato-component", {
                            attrs: {
                              listaContatosProp: _vm.sac.contatos,
                              isExibirProp: true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.sacProp.anotacoes != null
                ? _c("historico-sac-component", {
                    attrs: { anotacoesProp: _vm.sacProp.anotacoes }
                  })
                : _vm._e(),
              _c(
                "fieldset",
                { staticClass: "blocoFieldset" },
                [
                  _c("legend", [
                    _vm._v("\n                    Anotação\n                ")
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "12",
                            lg: "12",
                            xl: "12"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-label required",
                              attrs: { label: "", "label-for": "anotacao" }
                            },
                            [
                              _c("b-form-textarea", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:4095",
                                    expression: "'required|max:4095'"
                                  }
                                ],
                                attrs: {
                                  id: "anotacao",
                                  size: "lg",
                                  "data-vv-name": "anotacao",
                                  "data-vv-as": "Anotação",
                                  trim: "",
                                  "error-messages": _vm.$validator.errors.collect(
                                    "anotacao"
                                  ),
                                  state:
                                    _vm.$validator.errors.has("anotacao") ==
                                    false
                                      ? null
                                      : !_vm.$validator.errors.has("anotacao")
                                },
                                model: {
                                  value: _vm.sac.anotacao,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sac, "anotacao", $$v)
                                  },
                                  expression: "sac.anotacao"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.$validator.errors.has(
                                        "anotacao"
                                      ),
                                      expression:
                                        "$validator.errors.has('anotacao')"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$validator.errors.first("anotacao")
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Finalizar Atendimento SAC"
                      },
                      on: {
                        click: function($event) {
                          return _vm.finalizar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-times-circle" }),
                      _vm._v(" Finalizar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "md",
                        title: "Salvar Resposta SAC"
                      },
                      on: {
                        click: function($event) {
                          return _vm.salvar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Salvar\n                ")
                    ]
                  ),
                  _vm._v(" \n                "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        outline: "",
                        size: "md",
                        variant: "secondary",
                        title: "Voltar para tela de listagem de SAC"
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-arrow-left" }),
                      _vm._v(" Cancelar\n                ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "far fa-comments" }),
      _vm._v(" Responder SAC\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }