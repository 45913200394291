<template>
  <div id="app" class="portal-layout" :class="getTheme">
    <AccessBar />
    <AppHeader  />
    <div id="content-wrapper" :class="sidebar">
      <AppNav />
      <AppMain />
      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppHeader from '@/layout/AppHeader.vue'
import AppNav from '@/layout/AppNav.vue'
import AppMain from '@/layout/AppMain.vue'
import AppFooter from '@/layout/AppFooter.vue'
import AccessBar from '../views/component/AccessBar/AccessBar.vue'

export default {
  components: { AppHeader, AppNav, AppMain, AppFooter, AccessBar },
  computed: {
    sidebar () {
      return this.$store.getters.getSidebar
    },
    getTheme () {
      return this.$store.getters.getTheme
    }
  }
}
</script>
