var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid", attrs: { id: "main-content" } },
    [
      _c("br"),
      _c("h1", { staticClass: "app-title" }, [_vm._v("Início")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "10" } },
            [
              _c(
                "b-card",
                { staticClass: "cardHome" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-user-friends",
                              textoBotao: "CADASTRO DE PESSOAS"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.cadastroPessoaNew()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.pessoa !== null
                        ? _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "12",
                                md: "6",
                                lg: "6",
                                xl: "6"
                              }
                            },
                            [
                              _c("CardButton", {
                                attrs: {
                                  icone: "fas fa-tasks",
                                  textoBotao:
                                    "BUSCA E AVALIAÇÃO DE ESTABELECIMENTOS"
                                },
                                on: {
                                  evento: function($event) {
                                    return _vm.avaliarEstabelecimento()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "12",
                                md: "6",
                                lg: "6",
                                xl: "6"
                              }
                            },
                            [
                              _c("CardButton", {
                                attrs: {
                                  icone: "far fa-comments",
                                  textoBotao: "FALE COM A GENTE"
                                },
                                on: {
                                  evento: function($event) {
                                    return _vm.faleComAGente()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-building",
                              textoBotao:
                                "CONSULTA E CERTIFICAÇÃO DE ESTABELECIMENTOS"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.meusEstabelecimentos()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("CardButton", {
                            attrs: {
                              icone: "fas fa-globe",
                              textoBotao: "PORTAL"
                            },
                            on: {
                              evento: function($event) {
                                return _vm.portal()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2" } },
            [
              _c(
                "b-card-body",
                { staticClass: "cartaoConteudo" },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "justify-content-center mb-2",
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "center-xy pd-button",
                          staticStyle: { height: "110px" }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass:
                                "btn-portal-deficiente btn-secondary",
                              attrs: {
                                switch: "",
                                id: "deficienteVisual",
                                name: "deficienteVisual"
                              },
                              model: {
                                value: _vm.deficienteVisual,
                                callback: function($$v) {
                                  _vm.deficienteVisual = $$v
                                },
                                expression: "deficienteVisual"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Sou deficiente visual com ausência total de visão"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "justify-content-center mb-2",
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "center-xy pd-button",
                          staticStyle: { height: "150px" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-portal-video",
                              on: {
                                click: function($event) {
                                  return _vm.exibirVideo()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-video icon-button"
                              }),
                              _c("br"),
                              _c("p", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      "Orientações para cadastro e navegação para deficientes visuais com uso do NVDA"
                                    )
                                  }
                                })
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }