export class JWTDecoded {
    acr: String;
    amr: String;
    aud: String;
    dataCadastroUsuario: String;// "2016-06-24 10:07:26"
    dtNascimento: string; //"1992-07-15 00:00:00"
    email: String;
    exp: Number;
    grant_type: String;
    grupos: Array<String>;
    iat: Number;
    iss: String;
    jti: String;
    login: String;
    nome: String;
    nivelConfiabilidadeCadastro: Number;
    telefone: String;
    resouceowner_id: String;
    rg: String;
    session: String;
    sub: String;
    ufRg: String;
    cpf: String;

    constructor (obj: any) {
        this.acr = obj.acr;
        this.amr = obj.amr;
        this.aud = obj.aud;
        this.dataCadastroUsuario = obj.dataCadastroUsuario;
        this.dtNascimento = obj.dtNascimento;
        this.email = obj.email;
        this.exp = obj.exp;
        this.grant_type = obj.grant_type;
        this.iat = obj.iat;
        this.iss = obj.iss;
        this.jti = obj.jti;
        this.login = obj.login;
        this.nome = obj.name;
        this.nivelConfiabilidadeCadastro = obj.nivelConfiabilidadeCadastro;
        this.telefone = obj.phone_number;
        this.resouceowner_id = obj.resouceowner_id;
        this.rg = obj.rg;
        this.session = obj.session;
        this.sub = obj.sub;
        this.ufRg = obj.ufRg;
        this.cpf = obj.upn;

        this.grupos = new Array<String>();
        if(obj.groups !== undefined) {
            for(let i = 0; i <= obj.groups.length; i++) {
                if (obj.groups[i] != null && obj.groups[i] != "null") {
                    this.grupos.push(obj.groups[i])
                }
            }
        }
    }
}