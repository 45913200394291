









































































































































































































































import { AvaliacaoEstabelecimento } from '@/model/avaliacao-estabelecimento';
import { Estabelecimento } from '@/model/estabelecimento';
import { Component, Vue } from 'vue-property-decorator';
import { RamoAtividadeEnum } from '@/model/enum/ramo-atividade';
import EnderecoComponent from '../component/endereco-component.vue';
import ContatoComponent from '../component/contato-component.vue';
import { Message } from '@/model/generic/message';
import estabelecimentoService from '@/api/estabelecimento.service';
import {TheMask} from 'vue-the-mask'
import { Util } from '@/util/util';
import { AvaliacaoRecurso } from '@/model/avaliacao-recurso';
import { Avaliacao } from '@/model/avaliacao';

@Component({
    props: { estabProp: Object },
    components: { EnderecoComponent, ContatoComponent, TheMask }
})
export default class AvaliarEstabelecimento extends Vue {

    public estabelecimento: Estabelecimento;
    public avaliacaoEstabelecimento: AvaliacaoEstabelecimento;
    public ramoAtividade!: String;
    public dataComparecimentoStr: String = "";

    constructor(){
        super();
        this.estabelecimento = this.$props.estabProp;
        this.avaliacaoEstabelecimento = new AvaliacaoEstabelecimento(this.estabelecimento);
        if (this.estabelecimento.ramoAtividade) {
            this.ramoAtividade = this.getRamoValue(this.estabelecimento.ramoAtividade);
        }
    }

    formatCnpj(cnpj) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }

    getRamoValue(keyStr : String) : String {
        const enumValue = Object.entries(RamoAtividadeEnum)
            .find(([id, value]) => {if(id == keyStr) return value});

        if (enumValue) {
            return enumValue[1];
        }
        return "";
    }

    voltar () {
        this.$router.push({
            name: 'listar-avaliar-estabelecimento'
        });
    }
    
    async salvar () {
        this.avaliacaoEstabelecimento.dataComparecimento = Util.toDateISO(this.dataComparecimentoStr);

        const result = await this.$validator.validateAll();
        if (result) {
            if (this.avaliacaoEstabelecimento.dataComparecimento > new Date()) {
                this.$store.commit('setMessages', new Message('Data de comparecimento deve ser menor que a data atual', 'danger'));
                return;
            }

            let retorno = await estabelecimentoService.avaliar(this.avaliacaoEstabelecimento);
            if (retorno != null) {
                this.$router.push({
                    name: 'listar-avaliar-estabelecimento'
                });
                this.$store.commit('setMessages', new Message('Avaliação salva com sucesso', 'success'));
            }
        }
    }

    changeNotaRecurso(i) {
        if(this.avaliacaoEstabelecimento.avaliacoesRecursos[i].avaliacao.nota != 5) {
            console.log(this.$validator.validate('observacao_'+i))
        }
    }

    verificaEstadoAvaliacao(avaliacao: Avaliacao) {
        if (avaliacao.nota == undefined) {
            return null;
        }
        if (avaliacao.nota == 5) {
            return avaliacao.observacao == undefined || avaliacao.observacao.length < 255
        }

        return avaliacao.observacao != undefined && avaliacao.observacao.length > 0 && avaliacao.observacao.length < 255
    }

}
