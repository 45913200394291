import { AvaliacaoEstabelecimento } from './../model/avaliacao-estabelecimento';
import { PagingList } from '../model/generic/paging-list';
import restUtil from './rest.util.service';
import store from '@/store/store';
import { Message } from './../model/generic/message';
import { EstabelecimentoFilter } from './../model/filter/estabelecimento-filter';
import { Estabelecimento } from './../model/estabelecimento';
import { ArquivoEstabelecimento } from '@/model/arquivo-estabelecimento';
import centralseguranca from './centralseguranca';

class EstabelecimentoService {

    async obterPorID (id: Number): Promise<Estabelecimento|null> {
        let retorno = await restUtil.doGet<Estabelecimento>('v1/estabelecimento/'+id);

        return retorno != null && retorno.data != null ? retorno.data : null;
    }

    async listarEstabelecimentos (filter: EstabelecimentoFilter, exibeMensagem: Boolean): Promise<PagingList<Estabelecimento>|null> {
        try{
            let retorno = await restUtil.doPost<PagingList<Estabelecimento>>('v1/estabelecimento/search', filter);
            if (retorno != null && retorno.data != null) {
                if (retorno.data.totalRegistros == 0 && exibeMensagem) {
                    store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
                }
                return retorno.data;
            }
        } catch (error) {
            store.commit('setMessages', (error as any).response.data);
        }
        return null;
    }

    async salvar (estabelecimento: Estabelecimento): Promise<Estabelecimento|null> {
        let retorno;
        if (estabelecimento.id == null) {
            retorno = await restUtil.doPost<Estabelecimento>('v1/estabelecimento', estabelecimento);
        } else {
            retorno = await restUtil.doPut<Estabelecimento>('v1/estabelecimento/'+estabelecimento.id, estabelecimento);
        }
        return retorno;
    }

    async alterarSituacao (estabelecimento: Estabelecimento): Promise<Object|null>{
        return restUtil.doPut<Estabelecimento>('v1/estabelecimento/situacao/'+estabelecimento.id, estabelecimento);
    }

    async excluir (estabelecimento: Estabelecimento) {
        const retorno = await restUtil.doDelete('v1/estabelecimento/'+estabelecimento.id);
        if (retorno != null) {
            store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
        }
        return retorno;
    }

    async avaliar (avaliacao: AvaliacaoEstabelecimento) {
        return await restUtil.doPost<Estabelecimento>('v1/estabelecimento/avaliar', avaliacao);
    }

    enviarArquivo (arquivo: File): Promise<Object|null> {
        let body = new FormData;
        body.append("file", arquivo)
        body.append("name", arquivo.name)

        let cpf = centralseguranca.getUserInfo().cpf;

        return restUtil.doPost<Object>(`v1/estabelecimento/files/${cpf}`, body);
    }

    async downloadArquivo (arquivo: ArquivoEstabelecimento): Promise<Object|null> {
        return restUtil.download<Object>('v1/estabelecimento/files/'+arquivo.id);
    }

    async excluirArquivo (arquivo: ArquivoEstabelecimento): Promise<Object|null> {
        const retorno = await restUtil.doDelete('v1/estabelecimento/files/'+arquivo.id);
        if (retorno != null) {
            store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
        }
        return retorno;
    }

}

export default new EstabelecimentoService();