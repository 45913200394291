






import { Component, Vue } from 'vue-property-decorator';
import NovoFaleComAGente from './novo-fale-com-a-gente.vue';
import ListarFaleComAGente from './listar-fale-com-a-gente.vue';
import ResponderFaleComAGente from './responder-fale-com-a-gente.vue';
import ExibirFaleComAGente from './exibir-fale-com-a-gente.vue';

@Component({
    components: {
        NovoFaleComAGente,
        ListarFaleComAGente,
        ResponderFaleComAGente,
        ExibirFaleComAGente
    }
})
export default class FaleComAGenteContainer extends Vue {

}
