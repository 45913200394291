/* eslint-disable no-array-constructor */
import store from '../store/store'
import apiCentralSeguranca from './centralseguranca'

// Perfis
import { PerfilCentralSegurancaEnum } from '../model/enum/perfil-central-seguranca';

export default {
  itens: [
    {
      name: 'Início',
      url: '/home',
      icon: 'fas fa-chart-line'
    },
    {
      name: 'SEDEF',
      url: '#',
      icon: 'fas fa-user-graduate',
      groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO,
        PerfilCentralSegurancaEnum.GERENCIAL, PerfilCentralSegurancaEnum.REGIONAL],
      children: [
        {
          name: 'Cadastro de Benefícios por Tipo de Deficiência',
          url: '/beneficio/listar'
        },
        {
          name: 'Cadastro de Recursos de Acessibilidade',
          url: '/recurso-acessibilidade/listar'
        },
        {
          name: 'Consulta e Certificação de Estabelecimentos',
          url: '/estabelecimento/listar'
        },
        {
          name: 'Cadastro de Tipos de Deficiência',
          url: '/tipo-deficiencia/listar'
        },
        {
          name: 'Consulta de Pessoas',
          url: '/pessoa/listar'
        }
      ]
    },
    {
      name: 'Administrador',
      url: '#',
      icon: 'fas fa-user-graduate',
      groups: [PerfilCentralSegurancaEnum.ADMINISTRADOR_GERAL, PerfilCentralSegurancaEnum.ADMINISTRATIVO],

      children: [
        {
          name: 'Cadastro de Usuário Administrativo',
          url: '/usuario-administrativo/listar'
        },
        {
          name: 'Cadastro de Regional',
          url: '/#'
        }
      ]
    }
  ],

  itensAcesso (itens) {
    var permitidos = new Array()

    var grupos = apiCentralSeguranca.decodeJwtToken(store.getters.getJwtToken).grupos

    for (let i = 0; i < itens.length; i++) {
      if (itens[i].groups === undefined || itens[i].groups.length === 0) {
        if (itens[i].children !== undefined) {
          itens[i].children = this.itensAcesso(itens[i].children)
        }
        permitidos.push(itens[i])
      } else {
        for (let y = 0; y < grupos.length; y++) {
          if (itens[i].groups.includes(grupos[y])) {
            if (itens[i].children !== undefined) {
              itens[i].children = this.itensAcesso(itens[i].children)
            }
            permitidos.push(itens[i])
            break
          }
        }
      }
    }

    return permitidos;
  }

}
