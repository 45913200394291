










































import { application } from '@/application'
import { Component, Vue, Watch } from 'vue-property-decorator'
import centralSeguranca from '../../api/centralseguranca'
import CardButton from '../component/card-button.vue'
import docsService from '@/api/docs-service';

@Component({
  components: {
    CardButton
  }
})
export default class Login extends Vue {
  public deficienteVisual = false;

  @Watch('deficienteVisual')
  async setDeficienteVisual() {
    localStorage.setItem('deficienteVisual', this.deficienteVisual ? 'S' : "N")
  }

  mounted (): void {
    localStorage.setItem('deficienteVisual', this.deficienteVisual ? 'S' : "N")
    console.log(`O sistema ${application.name} está na versão ${application.version}`)
  }

  faleComAGente () {
    this.$router.push({
        name: 'fale-com-a-gente'
    });
  }

  jaSouCadastrado () {
    window.location.href = "" + centralSeguranca.authorizeUrl;
  }
  exibirVideo() {
        if (this.deficienteVisual) {
            docsService.downloadArquivo(4)
                .then(response => {
                    if (response != null) {
                        // @ts-ignore
                        const blob = new Blob([response.data], { type: 'video/mp4' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'pra-video-tutorial-deficientes-visuais.mp4'
                        link.click()

                        URL.revokeObjectURL(link.href)
                    }
                })
        } else {
            docsService.downloadArquivo(3)
                .then(response => {
                    if (response != null) {
                        // @ts-ignore
                        const blob = new Blob([response.data], { type: 'video/mp4' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'pra-video-tutorial.mp4'
                        link.click()

                        URL.revokeObjectURL(link.href)
                    }
                })
        }
    }

  portal () {
    window.location.href = 'http://www.pracessivel.pr.gov.br'
  }

  queroMeCadastrar () {
    window.location.href = "" + centralSeguranca.centralCidadaoURL + centralSeguranca.autoCadastro;
  }
}
