






































import { Component, Vue } from 'vue-property-decorator';
import { RecursoAcessibilidade } from '../../model/recurso-acessibilidade';

@Component({
    props: { recursoProp: Object }
})
export default class ExibirRecursoAcessibilidade extends Vue {
    
    private recurso: RecursoAcessibilidade;

    constructor(){
        super();
        this.recurso = this.$props.recursoProp;
    }

    voltar () {
        this.$router.push({
            name: 'listar-recurso-acessibilidade'
        });
    }
}
