var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "portal-header" },
    [
      _c(
        "b-row",
        {
          staticClass: "hide-sm",
          attrs: { "align-v": "center", "align-h": "center" }
        },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", xl: "8" } },
            [
              _c(
                "b-row",
                { attrs: { id: "main-header", "align-v": "center" } },
                [
                  _c("div", { attrs: { id: "logo-sistema" } }),
                  _c("b-col", [_c("div", { staticClass: "portal-title" })]),
                  _vm.showUser() ? _c("UserAccount") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showUser()
        ? _c(
            "b-row",
            { staticClass: "menu-bg", attrs: { "align-h": "center" } },
            [_c("PortalMenu")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }