


































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router/router';
import formatar from '@/mixins/formatarMixins';
import { EstabelecimentoFilter } from '../../model/filter/estabelecimento-filter';
import { Estabelecimento } from '../../model/estabelecimento';
import { RamoAtividadeEnum } from '@/model/enum/ramo-atividade';
import { SituacaoEstabelecimentoEnum } from '@/model/enum/situacao-estabelecimento';
import { Option } from '@/model/option';
import estabelecimentoService from '@/api/estabelecimento.service';
import centralseguranca from '@/api/centralseguranca';
import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';
import { RecursoAcessibilidadeFilter } from '@/model/filter/recurso-acessibilidade-filter';
import { Message } from '@/model/generic/message';
import { Util } from '@/util/util';

@Component({
    props: { visaoCidadao: Boolean },
    mixins: [formatar]
})
export default class ListarEstabelecimento extends Vue {

    public filtro: EstabelecimentoFilter;
    public recursosAcessibilidade: Array<RecursoAcessibilidade> | null;
    public estabelecimentos: Array<Estabelecimento>;
    public estabelecimentoSelecionado: Estabelecimento;
    public ramos: Array<Option>;
    public situacoes: Array<Option>;
    public ramoAtividade: Option|null;
    public situacao: Option|null;
    public situacaoSelecionada: Option|null;

    //campos da grid
    public campos: Array<Object> = [
      {
        key: 'cnpj',
        label: 'CNPJ',
        sortable: true
      },
      {
        key: 'razaoSocial',
        label: 'Razão Social',
        sortable: true
      },
      {
        key: 'ramoAtividade',
        label: 'Ramo Atividade',
        sortable: true
      },
      {
        key: 'situacao',
        label: 'Situação',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      },
      {
        key: 'acoes',
        label: 'Exibir',
        tdClass: 'acoes-1'
      }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public pesquisando: boolean = false;
    public totalRows: Number = 0;

    public visaoCidadao: boolean;

    public dataInicioStr: String = "";
    public dataInicioState: boolean | null = true;
    public dataInicioBlur: boolean = false;

    public dataFimStr: String = "";
    public dataFimState: boolean | null = null;
    public dataFimBlur: boolean = false;

    constructor () {
        super();
        this.situacaoSelecionada = null;
        this.ramoAtividade = null;
        this.situacao = null;
        this.recursosAcessibilidade = Array.of();
        this.estabelecimentoSelecionado = new Estabelecimento(null, "", "", "");
        this.filtro = new EstabelecimentoFilter("", "", true);
        this.estabelecimentos = Array<Estabelecimento>();
        this.ramos = Object.entries(RamoAtividadeEnum).flatMap(obj => [new Option(obj, obj[1])]);
        this.situacoes = Object.entries(SituacaoEstabelecimentoEnum).flatMap(obj => [new Option(obj, obj[1])]);
        if (this.$props.visaoCidadao != null) {
            this.visaoCidadao = this.$props.visaoCidadao;
        } else {
            this.visaoCidadao = false;
        }
        this.carregaRecursosAcessibilidade();
    }

    async carregaRecursosAcessibilidade() {
        var filter = new RecursoAcessibilidadeFilter("", "", true);
        let retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(filter, false);
        if (retorno != null && retorno.registros != null) {
            this.recursosAcessibilidade = retorno.registros;
        } else {
            this.recursosAcessibilidade = Array.of();
        }
    }

    getRamoValue(key : RamoAtividadeEnum) : String {
        const enumValue = Object.entries(RamoAtividadeEnum)
            .find(([id, value]) => {if(id == key) return value});

        if (enumValue) {
            return enumValue[1];
        }
        return "";
    }

    mounted (): void {
        this.limpar();
        this.pesquisar();
    }

    limpar () {
        this.filtro = new EstabelecimentoFilter("", "", true);
        this.filtro.municipio = undefined
        this.ramoAtividade = null;
        this.situacao = null;
        this.dataInicioBlur = false;
        this.dataInicioStr = "";
        this.dataInicioState = true;
        this.dataFimBlur = false;
        this.dataFimStr = "";
        this.dataFimState = true;
        this.estabelecimentos = Array<Estabelecimento>();
        if (this.visaoCidadao) {
            this.filtro.cpfUsuarioLogado = centralseguranca.getUserInfo().cpf;
        }
    }

    async pesquisar () {
        if (this.ramoAtividade != null && this.ramoAtividade.id !== undefined) {
            this.filtro.ramoAtividade = this.ramoAtividade.id[0];
        } else {
            this.filtro.ramoAtividade = undefined
        }
        if (this.situacao != null && this.situacao.id !== undefined) {
            this.filtro.situacao = this.situacao.id[1];
        } else {
            this.filtro.situacao = undefined
        }
        const retorno = await estabelecimentoService.listarEstabelecimentos(this.filtro, !this.visaoCidadao)
        if (retorno != null) {
            this.estabelecimentos = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        }
    }

    iniciarIncluir () {
        router.push({
            name: 'editar-estabelecimento',
            params: {
                voltarProp: this.visaoCidadao ? 'listar-meus-estabelecimentos' : 'listar-estabelecimento',
                visaoCidadao: this.visaoCidadao as any
            }
        });
    }

    async iniciarExibir (estab: Estabelecimento|null) {
        if(estab != null && estab.id != null){
            estab = await estabelecimentoService.obterPorID(estab.id);
        }
        router.push({
            name: 'exibir-estabelecimento',
            params: {
                estabProp: estab as any,
                voltarProp: this.visaoCidadao ? 'listar-meus-estabelecimentos' : 'listar-estabelecimento',
                visaoCidadao: this.visaoCidadao as any
            }
        });
    }

    async iniciarAlterar (estab: Estabelecimento|null) {
        if(estab != null && estab.id != null){
            estab = await estabelecimentoService.obterPorID(estab.id);
        }

        this.$router.push({
            name: 'editar-estabelecimento',
            params: {
                estabProp: estab as any,
                voltarProp: this.visaoCidadao ? 'listar-meus-estabelecimentos' : 'listar-estabelecimento',
                visaoCidadao: this.visaoCidadao as any
            }
        });
    }

    async excluir (estabelecimento: Estabelecimento) {
        const retorno = await estabelecimentoService.excluir(estabelecimento);
        if (retorno != null) {
            this.pesquisar();
        }
    }

    async alterarSituacao (estabelecimento: Estabelecimento) {
        if (this.situacaoSelecionada !== null) {
            estabelecimento.situacao = this.situacaoSelecionada.name;
            await estabelecimentoService.alterarSituacao(estabelecimento);
            this.pesquisar();
        }
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }
    
    validarDataInicio(): Boolean | null {
        if (this.dataInicioBlur) {
            this.dataInicioState = true;
            this.dataInicioBlur = false;

            if (this.dataInicioStr == null || this.dataInicioStr === "") {
                this.dataInicioState = null;
            }

            if (this.dataInicioStr.length < 10) {
                this.dataInicioState = false;
            }

            this.filtro.dtInicio = Util.toDateISO(this.dataInicioStr);
            if (this.filtro.dtInicio > new Date()) {
                this.$store.commit('setMessages', new Message('Data Inicial de Cadastro deve ser menor que a data atual', 'danger'));
                this.dataInicioState = false;
            }

            if (this.filtro.dtFim != null && this.filtro.dtInicio > this.filtro.dtFim) {
                this.$store.commit('setMessages', new Message('Data Inicial de Cadastro deve ser menor que a Data Final de Cadastro', 'danger'));
                this.dataInicioState = false;
            }

        }
        return this.dataInicioState;
    }
    
    validarDataFim(): Boolean | null {
        if (this.dataFimBlur) {
            this.dataFimState = true;
            this.dataFimBlur = false;

            if (this.dataFimStr == null || this.dataFimStr === "") {
                this.dataFimState = null;
            }

            if (this.dataFimStr.length < 10) {
                this.dataFimState = false;
            }

            this.filtro.dtFim = Util.toDateISO(this.dataFimStr);
            if (this.filtro.dtFim > new Date()) {
                this.$store.commit('setMessages', new Message('Data Final de Cadastro deve ser menor que a data atual', 'danger'));
                this.dataFimState = false;
            }

            if (this.filtro.dtInicio != null && this.filtro.dtFim < this.filtro.dtInicio) {
                this.$store.commit('setMessages', new Message('Data Final de Cadastro deve ser maior que a Data Inicial de Cadastro', 'danger'));
                this.dataFimState = false;
            }

        }
        return this.dataFimState;
    }
}

