






















































































































import { Component, Vue} from 'vue-property-decorator';
import formatar from '@/mixins/formatarMixins';
import { SAC } from '@/model/sac';
import SACService from '@/api/sac.service';
import { Option } from '@/model/option';
import { SituacaoSACEnum } from '@/model/enum/situacao-sac';
import { SACFilter } from '@/model/filter/sac-filter';
import usuarioService from '@/api/usuario.service';
import assuntoService from '@/api/assunto.service';
import centralseguranca from '@/api/centralseguranca';
import { Usuario } from '@/model/usuario';
import { Assunto } from '@/model/assunto';

@Component({
    mixins: [formatar]
})
export default class ListarFaleComAGente extends Vue {

    public filtro: SACFilter;
    public situacaoFilter: Option|null;
    public usuario: Usuario|null;
    public sacs: Array<SAC>;
    public situacoes: Array<Option>;
    public assuntos: Array<Assunto>;

    //campos da grid
    public campos: Array<Object> = [
      {
        key: 'icon',
        label: '',
        sortable: false
      },
      {
        key: 'nome',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'assunto.descricao',
        label: 'Assunto',
        sortable: true
      },
      {
        key: 'dataHora',
        label: 'Data Cadastro',
        sortable: true
      },
      {
        key: 'situacao',
        label: 'Situação',
        sortable: true
      },
      {
        key: 'responsavel.nome',
        label: 'Responsável',
        sortable: true
      },
      {
        key: 'acoes',
        label: 'Ações',
        tdClass: 'acoes-1',
        thClass: 'acoes-1'
      }];
    public currentPage: Number = 1;
    public perPage: Number = 5;
    public pesquisando: boolean = false;
    public totalRows: Number = 0;

    constructor () {
        super();
        this.situacaoFilter = null;
        this.sacs = Array.of();
        this.filtro = new SACFilter("", null, true);

        this.assuntos = Array.of();
        this.usuario = null;
        this.situacoes =  Object.entries(SituacaoSACEnum).flatMap(obj => [new Option(obj, obj[1])]);
        this.carregaUsuario();
        this.carregaAssuntos();
    }

    mounted (): void {
        this.limpar();
        this.pesquisar();
    }

    async carregaUsuario() {
        this.usuario = await usuarioService.obterPorCPF(centralseguranca.getUserInfo().cpf);
    }

    async carregaAssuntos() {
        let retorno = await assuntoService.listarAtivos();
        if (retorno != null && retorno.registros != null) {
            this.assuntos = retorno.registros;
        }
    }

    async pesquisar () {
        if (this.situacaoFilter != null) {
            this.filtro.situacao = this.situacaoFilter.name;
        } else {
            this.filtro.situacao = undefined;
        }
        let retorno = await SACService.listarSAC(this.filtro);
        if (retorno != null && retorno.registros != null) {
            this.sacs = retorno.registros;
            this.totalRows = retorno.totalRegistros;
        } else {
            this.sacs = Array.of();
            this.totalRows = 0;
        }
    }

    limpar () {
        this.sacs = Array.of();
        this.filtro = new SACFilter("", null, true);
        this.situacaoFilter = null;
    }

    async iniciarResponder (item: SAC|null) {
        let usuario = await usuarioService.obterPorCPF(centralseguranca.getUserInfo().cpf);
        if(item != null && item.id != null){
            item = await SACService.obterPorID(item.id);
        }
        this.$router.push({
            name: 'fale-com-a-gente-responder',
            params: {
                sacProp: item as any,
                responsavelProp: usuario as any
            }
        });
    }

    async iniciarExibir (item: SAC|null) {
        if(item != null && item.id != null){
            item = await SACService.obterPorID(item.id);
        }

        this.$router.push({
            name: 'fale-com-a-gente-exibir',
            params: {
                sacProp: item as any
            }
        });
    }

    voltar () {
        this.$router.push({
            name: 'inicial'
        });
    }

    rowClass(item, type) {
        if (!item || type !== 'row'){
            return
        }
        return this.isNovo(item) ? 'negrito' : '';
    }

    isNovo(sac: SAC) : boolean {
        if (sac.situacao == SituacaoSACEnum.NOVO) {
            return true;
        }

        return false;
    }
}
