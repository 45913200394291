import { PagingList } from '../model/generic/paging-list';
import store from '@/store/store';
import { RecursoAcessibilidadeFilter } from './../model/filter/recurso-acessibilidade-filter';
import { RecursoAcessibilidade } from './../model/recurso-acessibilidade';
import restUtil from './rest.util.service';
import { Message } from './../model/generic/message';

class RecursoAcessibilidadeService {

  async listarRecursosAcessibilidade (filter: RecursoAcessibilidadeFilter, exibirMensagem: Boolean): Promise<PagingList<RecursoAcessibilidade>|null> {
    try{
      let retorno = await restUtil.doPost<PagingList<RecursoAcessibilidade>>('v1/acessibilidade/search', filter);
      if (retorno != null && retorno.data != null) {
        if (retorno.data.totalRegistros == 0 && exibirMensagem) {
          store.commit('setMessages', new Message('Nenhum registro encontrado', 'info'));
        }
        return retorno.data;
      }
    } catch (error) {
      store.commit('setMessages', (error as any).response.data);
    }
    return null;
  }

  async salvar (rec: RecursoAcessibilidade): Promise<RecursoAcessibilidade|null> {
     let retorno;
     if (rec.id == null) {
       retorno = await restUtil.doPost<RecursoAcessibilidade>('v1/acessibilidade', rec);
     } else {
       retorno = await restUtil.doPut<RecursoAcessibilidade>('v1/acessibilidade/'+rec.id, rec);
     }
     return retorno;
  }

  async excluir (id: Number) {
     const retorno = await restUtil.doDelete('v1/acessibilidade/'+id);
     if (retorno != null) {
       store.commit('setMessages', new Message('Registro removido com sucesso', 'success'));
     }
     return retorno;
  }

}

export default new RecursoAcessibilidadeService();