var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _vm._m(0),
      _c("b-card", [
        _c(
          "div",
          { attrs: { align: "right" } },
          [
            _c(
              "b-col",
              { attrs: { xs: "12", sm: "6", md: "6", lg: "6", xl: "6" } },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      size: "md",
                      title: "Inserir PCD"
                    },
                    on: {
                      click: function($event) {
                        return _vm.iniciarIncluir()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus" }),
                    _vm._v(" Incluir\n                ")
                  ]
                ),
                _vm._v(" \n                "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      outline: "",
                      size: "md",
                      variant: "secondary",
                      title: "Voltar para tela inicial"
                    },
                    on: {
                      click: function($event) {
                        return _vm.voltar()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-arrow-left" }),
                    _vm._v(" Voltar\n                ")
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.vinculados !== undefined
        ? _c(
            "b-card",
            [
              _c(
                "b-table",
                {
                  attrs: {
                    "show-empty": "",
                    stacked: "sm",
                    hover: "hover",
                    striped: "striped",
                    items: _vm.vinculados,
                    fields: _vm.campos,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage,
                    busy: _vm.pesquisando,
                    "caption-top": "",
                    caption: "Tabela de Pessoas Vinculadas"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(cpf)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("mascaraDocumento")(
                                    data.item.deficiente.cpf
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(acoes)",
                        fn: function(data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-left" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    staticClass: "btn btn-outline-info ml-1",
                                    attrs: { title: "Alterar" },
                                    on: {
                                      click: function($event) {
                                        return _vm.iniciarAlterar(data.item)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pencil-alt" })]
                                ),
                                _c(
                                  "b-link",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modalDesvincular",
                                        modifiers: { modalDesvincular: true }
                                      }
                                    ],
                                    staticClass: "btn btn-outline-info ml-1",
                                    attrs: { title: "Desvincular" },
                                    on: {
                                      click: function($event) {
                                        _vm.pessoaSelecionada = data.item
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fas fa-times" })]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    426836007
                  )
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "empty", colspan: "2", align: "center" },
                      slot: "empty"
                    },
                    [_vm._v("Sem dados para exibir")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "text-center text-danger my-2",
                      attrs: { slot: "table-busy" },
                      slot: "table-busy"
                    },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _c("strong", [_vm._v(" Pesquisando ...")])
                    ],
                    1
                  )
                ]
              ),
              _vm.vinculados.length > 0
                ? _c(
                    "b-row",
                    { staticClass: "my-1 center-xy" },
                    [
                      _c("b-pagination", {
                        staticClass: "text-label",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          length: _vm.currentPage
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      }),
                      _c(
                        "p",
                        {
                          staticClass:
                            "VuePagination__count text-center col-md-12"
                        },
                        [
                          _vm._v(
                            "\n                Mostrando " +
                              _vm._s(
                                _vm.currentPage * _vm.perPage - _vm.perPage + 1
                              ) +
                              " a\n                " +
                              _vm._s(
                                _vm.currentPage * _vm.perPage > _vm.totalRows
                                  ? _vm.totalRows
                                  : _vm.currentPage * _vm.perPage
                              ) +
                              "\n                de " +
                              _vm._s(_vm.totalRows) +
                              " registros\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalDesvincular",
            title: "Confirmação de Remoção",
            "cancel-title": "Cancelar",
            "ok-title": "Desvincular",
            modal: ""
          },
          on: {
            ok: function($event) {
              return _vm.desvincular(_vm.pessoaSelecionada)
            }
          }
        },
        [
          _c(
            "b-row",
            [_c("b-col", [_vm._v("Deseja desvincular o Dependente?")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "app-title" }, [
      _c("i", { staticClass: "fas fa-user-friends" }),
      _vm._v(" Pessoas Vinculadas\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }