var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fluid" },
    [
      _c("br"),
      _c(
        "h2",
        { staticClass: "app-title" },
        [
          _c("b-form-group", { staticClass: "text-label" }, [
            _c("label", { ref: "cpf", attrs: { tabindex: "0" } }, [
              _c("legend", [
                _vm._v(
                  "Estatuto da Pessoa com Deficiência do Estado do Paraná (Lei nº 18419/2015)"
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tA Assembleia Legislativa do Estado do Paraná decretou e eu sanciono a seguinte Lei:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tTÍTULO I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDISPOSIÇÕES PRELIMINARES\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 1º Institui o Estatuto da Pessoa com Deficiência do Estado do\n\t\t\t\t\tParaná, destinado a estabelecer orientações normativas que objetivam\n\t\t\t\t\tassegurar, promover e proteger o exercício pleno e em condições de\n\t\t\t\t\tequidade de todos os direitos humanos e fundamentais das pessoas com\n\t\t\t\t\tdeficiência, visando à sua inclusão social e cidadania plena,\n\t\t\t\t\tefetiva e participativa.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do artigo dada pela Lei Nº 20059 DE 18/12/2019)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("strong", [
                        _vm._v(
                          "(Redação do caput dada pela Lei Nº 21214 DE 29/08/2022):"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 2º É dever dos órgãos e entidades do Poder Público do Estado do\n\t\t\t\t\tParaná, da sociedade, da comunidade e da família assegurar com\n\t\t\t\t\tprioridade, às pessoas com deficiência, o pleno exercício dos\n\t\t\t\t\tdireitos, dentre outros decorrentes da Constituição da República\n\t\t\t\t\tFederativa do Brasil, da Constituição do Estado do Paraná e demais\n\t\t\t\t\tleis esparsas, que propiciam o bem-estar pessoal, social e\n\t\t\t\t\teconômico, referentes:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tI - à vida;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tII - à saúde;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tIII - à sexualidade;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - à paternidade e maternidade;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tV - à alimentação;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVI - à educação;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - à profissionalização;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVIII - ao trabalho;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - à habilitação e reabilitação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tX - à segurança;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXI - à previdência social;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - à assistência social;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXIII - ao transporte;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXIV - à cultura;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXV - ao desporto;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXVI - ao turismo;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXVII - ao lazer;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVIII - à informação e comunicação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXIX - à acessibilidade;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXX - aos avanços científicos e tecnológicos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXXI - à dignidade;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXXII - ao respeito;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXIII - à liberdade e à convivência familiar e comunitária\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O Poder Executivo do Estado do Paraná compromete-se\n\t\t\t\t\ta tomar as medidas necessárias, tanto quanto permitir os recursos\n\t\t\t\t\tdisponíveis, e quando necessário no âmbito da cooperação\n\t\t\t\t\tinternacional, nacional, estadual e municipal, a fim de assegurar\n\t\t\t\t\tprogressivamente o pleno exercício desses direitos, sem prejuízo das\n\t\t\t\t\tobrigações contidas na presente Lei que forem imediatamente\n\t\t\t\t\taplicáveis, de acordo com a Convenção sobre os Direitos das Pessoas\n\t\t\t\t\tcom Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 3º São consideradas pessoas com deficiência aquelas que têm\n\t\t\t\t\timpedimentos de longo prazo de natureza física, mental, intelectual\n\t\t\t\t\tou sensorial, os quais, em interação com diversas barreiras, podem\n\t\t\t\t\tobstruir sua participação plena e efetiva na sociedade em equidade\n\t\t\t\t\tde condições com as demais pessoas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para os fins deste Estatuto as pessoas com\n\t\t\t\t\tneurofibromatose e com fissura palatina e labiopalatina, estas\n\t\t\t\t\tquando não totalmente recuperadas, têm os mesmos direitos das\n\t\t\t\t\tpessoas com deficiência.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Parágrafo acrescentado pela Lei Nº 20059 DE 18/12/2019)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 4º Para fins de aplicação da presente Lei, considera-se:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - apoio especial: a orientação, a supervisão, as ajudas técnicas,\n\t\t\t\t\tentre outros elementos que auxiliem ou permitam compensar uma ou\n\t\t\t\t\tmais limitações motoras, sensoriais, mentais ou intelectuais da\n\t\t\t\t\tpessoa com deficiência, favorecendo a sua autonomia e sua\n\t\t\t\t\tindependência, de forma a contribuir com sua inclusão social, bem\n\t\t\t\t\tcomo beneficiar processo de habilitação e reabilitação ou qualidade\n\t\t\t\t\tde vida;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - ajuda técnica: qualquer elemento que facilite a autonomia\n\t\t\t\t\tpessoal ou possibilite o acesso e o uso de meio físico, visando à\n\t\t\t\t\tmelhoria da funcionalidade e qualidade de vida da pessoa com\n\t\t\t\t\tdeficiência, como produtos, instrumentos, equipamentos ou tecnologia\n\t\t\t\t\tadaptados ou especialmente projetados, incluindo-se órteses e\n\t\t\t\t\tpróteses, equipamentos e elementos necessários à terapia e à\n\t\t\t\t\treabilitação da pessoa com deficiência, elementos de cuidado e de\n\t\t\t\t\thigiene pessoal de uso diário necessários para facilitar a autonomia\n\t\t\t\t\te a segurança da pessoa com deficiência, bolsas coletoras para\n\t\t\t\t\tpessoas ostomizadas, material para cateterismo vesical,"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tbloqueadores, protetores, filtros e demais preparados antissolares\n\t\t\t\t\tpara terapias, cão-guia, leitores, ledores, entre outros;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - procedimentos especiais: meios utilizados para auxiliar à\n\t\t\t\t\tpessoa que, devido ao seu grau de deficiência, exige condições\n\t\t\t\t\tpeculiares para o desenvolvimento de atividades, como jornada de\n\t\t\t\t\ttrabalho variável, horário flexível, entre outros.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 5º São princípios fundamentais da Política Pública Estadual\n\t\t\t\t\tpara Promoção dos Direitos e Inclusão da Pessoa com Deficiência:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - o respeito à dignidade inerente, à autonomia individual,\n\t\t\t\t\tincluindo a liberdade de fazer suas próprias escolhas, e à\n\t\t\t\t\tindependência das pessoas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tII - a não discriminação;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - a inclusão e participação plena e efetiva na sociedade;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - o respeito pela diferença e aceitação da deficiência como parte\n\t\t\t\t\tda diversidade e da condição humana;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - a igualdade de oportunidades;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVI - a acessibilidade;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - a igualdade entre homens e mulheres;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - o respeito pela capacidade em desenvolvimento das crianças e\n\t\t\t\t\tadolescentes com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 6º A Política Pública Estadual para Promoção dos Direitos e\n\t\t\t\t\tInclusão da Pessoa com Deficiência, em consonância com a Política\n\t\t\t\t\tNacional para Integração da Pessoa com Deficiência, nortear-se-á\n\t\t\t\t\tpelos seguintes objetivos:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - desenvolvimento de ação conjunta entre o Estado e a Sociedade\n\t\t\t\t\tCivil de modo a assegurar a plena inclusão da pessoa com deficiência\n\t\t\t\t\tno contexto socioeconômico e cultural;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - estabelecimento de mecanismos e instrumentos legais e\n\t\t\t\t\toperacionais que assegurem às pessoas com deficiência o pleno\n\t\t\t\t\texercício de seus direitos básicos que decorrem da Constituição\n\t\t\t\t\tFederal, Constituição do Estado Paraná e demais leis esparsas,\n\t\t\t\t\tpropiciando seu bem-estar pessoal, social e econômico;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - respeito às pessoas com deficiência, que devem receber\n\t\t\t\t\tequidade de oportunidades na sociedade por reconhecimento dos\n\t\t\t\t\tdireitos que lhes são assegurados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 7º A Política Pública Estadual para Promoção dos Direitos e\n\t\t\t\t\tInclusão da Pessoa com Deficiência, em consonância com a Política\n\t\t\t\t\tNacional para Integração da Pessoa com Deficiência e com o Programa\n\t\t\t\t\tNacional de Saúde da Pessoa com Deficiência, obedecerá às seguintes\n\t\t\t\t\tdiretrizes:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - promoção da qualidade de vida das pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - assistência integral à saúde da pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - prevenção de deficiências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - ampliação e fortalecimento dos mecanismos de informação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - organização e funcionamento dos serviços de atenção às pessoas\n\t\t\t\t\tcom deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - capacitação de recursos humanos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - estabelecimento de mecanismos que acelerem e favoreçam a\n\t\t\t\t\tinclusão social da pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - adoção de estratégias de articulação com órgãos e entidades\n\t\t\t\t\tpúblicos e privados e com organismos nacionais e estrangeiros para a\n\t\t\t\t\timplantação destas políticas públicas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - inclusão da pessoa com deficiência, respeitando-se as suas\n\t\t\t\t\tpeculiaridades, em todas as iniciativas governamentais relacionadas\n\t\t\t\t\tà educação, à saúde, ao trabalho, à edificação pública, à seguridade\n\t\t\t\t\tsocial, ao transporte, à habitação, à cultura, ao esporte e ao\n\t\t\t\t\tlazer;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - viabilização da participação das pessoas com deficiência em\n\t\t\t\t\ttodas as fases de elaboração e implementação dessas políticas, por\n\t\t\t\t\tintermédio de suas entidades representativas e outros fóruns;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - ampliação das alternativas de inserção econômica das pessoas\n\t\t\t\t\tcom deficiência, proporcionando qualificação profissional e\n\t\t\t\t\tincorporação no mercado de trabalho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - garantia do efetivo atendimento das necessidades das pessoas\n\t\t\t\t\tcom deficiência, inclusive nos atendimentos realizados em serviços\n\t\t\t\t\tpúblicos com a disponibilização de suporte em Língua Brasileira de\n\t\t\t\t\tSinais (Libras), quando possível presencial, ou por meio telemático,\n\t\t\t\t\tconforme § 2º do art. 111 desta Lei;\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do inciso dada pela Lei Nº 21214 DE 29/08/2022)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - articulação com entidades governamentais e não governamentais\n\t\t\t\t\tem nível federal, estadual e municipal, visando garantir a\n\t\t\t\t\tefetividade dos programas de prevenção, de atendimento especializado\n\t\t\t\t\te de inclusão social das pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tTÍTULO II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDIREITOS FUNDAMENTAIS\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDO ATENDIMENTO PRIORITÁRIO\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 8º Todos os órgãos públicos da administração direta, indireta e\n\t\t\t\t\tautarquias, agências e postos bancários, estabelecimentos de crédito\n\t\t\t\t\tfinanceiro, e instituições similares, estabelecimentos ou espaços\n\t\t\t\t\tculturais, estabelecimentos comerciais, bem como estabelecimentos ou\n\t\t\t\t\tespaços esportivos, devidamente instalados no Estado do Paraná,\n\t\t\t\t\tficam obrigados a dar atendimento prioritário e especial às pessoas\n\t\t\t\t\tcom deficiência, clientes ou não clientes, que, por sua vez, ficam\n\t\t\t\t\tdesobrigadas, a qualquer tempo, de aguardar a vez em filas, mesmo\n\t\t\t\t\taquelas externas de aguardo ao horário de abertura e início de\n\t\t\t\t\texpediente, quando também terão preferência, sempre e em todas as\n\t\t\t\t\tcircunstâncias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Entende-se por precedência de atendimento aquele prestado à\n\t\t\t\t\tpessoa com deficiência antes de qualquer outra depois de concluído o\n\t\t\t\t\tatendimento que estiver em andamento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Nos serviços de emergência dos estabelecimentos públicos,\n\t\t\t\t\tconveniados e privados de atendimento à saúde, a primazia conferida\n\t\t\t\t\tpor esta Lei fica condicionada à avaliação médica em face da\n\t\t\t\t\tgravidade dos casos a serem atendidos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Cabe ao Estado do Paraná e aos municípios, no âmbito de suas\n\t\t\t\t\tcompetências, criar os mecanismos necessários para a efetiva\n\t\t\t\t\timplantação e controle do atendimento prioritário referido nesta\n\t\t\t\t\tLei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 9º As entidades previstas no art. 8º desta Lei deverão afixar,\n\t\t\t\t\tem locais visíveis ao público, placas e cartazes informativos\n\t\t\t\t\tcontendo as previsões legais que conferem a prioridade de\n\t\t\t\t\tatendimento às pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 10. A garantia de prioridade estabelecida nesta Lei compreende,\n\t\t\t\t\tdentre outras medidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - a primazia de receber proteção e socorro em quaisquer\n\t\t\t\t\tcircunstâncias;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - a precedência de atendimento nos serviços públicos ou de\n\t\t\t\t\trelevância pública, junto aos órgãos públicos e privados prestadores\n\t\t\t\t\tde serviços à população;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - a prioridade no embarque no sistema de transporte coletivo;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - a preferência na formulação e na execução das políticas\n\t\t\t\t\tpúblicas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - a destinação privilegiada de recursos públicos nas áreas\n\t\t\t\t\trelacionadas às pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - a priorização do atendimento da pessoa com deficiência por sua\n\t\t\t\t\tprópria família, em detrimento de abrigo ou entidade de longa\n\t\t\t\t\tpermanência, exceto quando não possuam ou careçam de condições de\n\t\t\t\t\tmanutenção da própria sobrevivência ou estejam em situação de risco\n\t\t\t\t\tou violação de direitos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - a capacitação e formação continuada de recursos humanos para o\n\t\t\t\t\tatendimento das pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - o estabelecimento de mecanismos que favoreçam a divulgação de\n\t\t\t\t\tinformações de caráter educativo sobre aspectos ligados às\n\t\t\t\t\tdeficiências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - a garantia de acesso à rede de serviços de políticas públicas\n\t\t\t\t\tsetoriais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 11. O descumprimento do disposto neste Capítulo sujeitará os\n\t\t\t\t\tresponsáveis pela infração ao pagamento de multa correspondente a 35\n\t\t\t\t\t(trinta e cinco) UPF/PR - Unidades Padrão Fiscal do Paraná, não os\n\t\t\t\t\tdesobrigando de seu posterior cumprimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Em caso de reincidência, depois de decorrido o\n\t\t\t\t\tprazo de trinta dias, contados a partir da aplicação da primeira\n\t\t\t\t\tmulta, o valor da multa a que se refere o caput deste artigo será\n\t\t\t\t\tdobrado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDA SAÚDE\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDisposições Gerais\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 12. O direito aos serviços de saúde compreende:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - atenção integral à saúde, universal e gratuita, por meio do\n\t\t\t\t\tSistema Único de Saúde - SUS, assegurado atendimento personalizado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - transporte, sempre que indispensável à viabilização da atenção\n\t\t\t\t\tintegral à saúde;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - atenção integral à saúde respeitada a classificação de risco,\n\t\t\t\t\tviabilizando acomodações acessíveis de acordo com a legislação em\n\t\t\t\t\tvigor;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - fornecimento de medicamentos e materiais, inclusive os de uso\n\t\t\t\t\tcontínuo, necessários para o tratamento e realização de\n\t\t\t\t\tprocedimentos específicos para cada tipo de deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Fica garantida a gratuidade de todos os serviços de saúde\n\t\t\t\t\treferidos nesta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º É assegurado o direito à presença de acompanhante junto à\n\t\t\t\t\tpessoa com deficiência, durante os períodos de atendimento e de\n\t\t\t\t\tinternação, devendo o órgão de saúde proporcionar as condições\n\t\t\t\t\tadequadas para a sua permanência em tempo integral, salvo\n\t\t\t\t\tentendimento contrário, devidamente justificado, de profissional da\n\t\t\t\t\tsaúde.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 13. Os órgãos e entidades da administração pública estadual e\n\t\t\t\t\tmunicipal direta e indireta deverão assegurar às pessoas com\n\t\t\t\t\tdeficiência o pleno exercício de seu direito à saúde e à\n\t\t\t\t\tmaternidade, e de outros que, decorrentes da Constituição da\n\t\t\t\t\tRepublica Federativa do Brasil, da Constituição do Estado do Paraná\n\t\t\t\t\te das demais leis esparsas, propiciem seu bem-estar pessoal, social\n\t\t\t\t\te econômico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 14. Para o fim estabelecido no art. 13 desta Lei, os órgãos e\n\t\t\t\t\tentidades da Administração Estadual Direta e Indireta dispensarão,\n\t\t\t\t\tno âmbito de sua competência e finalidade, bem como respeitando a\n\t\t\t\t\tclassificação de risco,"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\ttratamento prioritário e adequado, tendente a viabilizar, sem\n\t\t\t\t\tprejuízo de outras, as seguintes medidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - promoção de ações preventivas, como às referentes ao\n\t\t\t\t\tplanejamento familiar, ao aconselhamento genético, ao acompanhamento\n\t\t\t\t\tda gravidez, do parto e do puerpério, à nutrição da mulher e da\n\t\t\t\t\tcriança, à identificação e ao controle da gestante e do feto de alto\n\t\t\t\t\trisco ou com deficiência, à imunização, às doenças do metabolismo e\n\t\t\t\t\tseu diagnóstico, ao encaminhamento de outras doenças causadoras de\n\t\t\t\t\tdeficiência, bem como de outras doenças crônico degenerativas e de\n\t\t\t\t\toutras potencialmente incapacitantes, para o serviço de saúde\n\t\t\t\t\tespecializado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - desenvolvimento de programas especiais de prevenção de trauma\n\t\t\t\t\tde qualquer natureza e o desenvolvimento de programa para tratamento\n\t\t\t\t\tadequado às suas vítimas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - criação e estruturação de rede de serviços regionalizados,\n\t\t\t\t\tdescentralizados e hierarquizados em crescentes níveis de\n\t\t\t\t\tcomplexidade, voltados ao atendimento à saúde e à reabilitação da\n\t\t\t\t\tpessoa com deficiência, articulada com outras políticas setoriais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - garantia de acessibilidade da pessoa com deficiência aos\n\t\t\t\t\testabelecimentos de saúde públicos e privados e de seu adequado\n\t\t\t\t\ttratamento sob normas técnicas e padrões de conduta apropriados;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - garantia de atenção domiciliar de saúde à pessoa com\n\t\t\t\t\tdeficiência, quando indicado por profissional da saúde;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - desenvolvimento de programas de saúde voltados às pessoas com\n\t\t\t\t\tdeficiência, os quais deverão contar com a participação de pessoas\n\t\t\t\t\tcom deficiência na análise das propostas formuladas pelo Poder\n\t\t\t\t\tExecutivo;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - reconhecimento do papel estratégico da atuação das Unidades\n\t\t\t\t\tBásicas de Saúde e das equipes de saúde da família na disseminação\n\t\t\t\t\tdas práticas e estratégias de promoção, prevenção e reabilitação\n\t\t\t\t\tbaseada na comunidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 15. Para os efeitos deste Capítulo, prevenção compreende as\n\t\t\t\t\tações e medidas orientadas a evitar as causas das deficiências que\n\t\t\t\t\tpossam ocasionar incapacidade e as destinadas a evitar sua\n\t\t\t\t\tprogressão ou derivação em outras incapacidades.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 16. É assegurado, no âmbito público e privado, o acesso\n\t\t\t\t\tigualitário às ações e aos serviços de promoção, prevenção e\n\t\t\t\t\tassistência à saúde das pessoas com deficiência, bem como sua\n\t\t\t\t\thabilitação e reabilitação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Toda pessoa que apresente deficiência devidamente\n\t\t\t\t\tdiagnosticada, qualquer que seja sua natureza, agente causal, grau\n\t\t\t\t\tde severidade ou prejuízo de sua saúde, terá direito aos\n\t\t\t\t\tprocedimentos necessários de habilitação e reabilitação realizados\n\t\t\t\t\tpor profissional de saúde, durante o período que for pertinente\n\t\t\t\t\tassegurar estes cuidados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º É parte integrante dos processos de habilitação e reabilitação\n\t\t\t\t\to tratamento e o apoio das equipes de saúde nos diversos níveis de\n\t\t\t\t\tatenção, inclusive psicológica, e durante todas as fases do processo\n\t\t\t\t\thabilitador e reabilitador, bem como o suprimento dos medicamentos e\n\t\t\t\t\tdas ajudas técnicas e tecnologias assistivas necessárias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Quando esgotados os meios de atenção à saúde da pessoa com\n\t\t\t\t\tdeficiência em sua localidade de residência, será prestado\n\t\t\t\t\tatendimento fora de domicílio, para fins de diagnóstico e\n\t\t\t\t\tatendimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 17. O Poder Público fomentará ações, programas e projetos para\n\t\t\t\t\tavaliação, pesquisa e diagnósticos com a finalidade de assegurar\n\t\t\t\t\tatendimento adequado para as pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 18. Incluem-se na assistência integral à saúde, reabilitação e\n\t\t\t\t\thabilitação da pessoa com deficiência, a concessão de órteses,\n\t\t\t\t\tpróteses, bolsas coletoras, materiais auxiliares, inclusive os de\n\t\t\t\t\tuso contínuo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 19. O Poder Público poderá oferecer no contraturno escolar\n\t\t\t\t\tatendimento terapêutico de manutenção e multiprofissional aos alunos\n\t\t\t\t\tcom deficiência, por intermédio da oferta de serviços especializados\n\t\t\t\t\tpúblicos ou conveniados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 20. Os programas e as linhas de pesquisa a serem desenvolvidos\n\t\t\t\t\tcom o apoio de organismos públicos e privados de auxílio à pesquisa\n\t\t\t\t\te de agências de financiamento deverão contemplar temas voltados\n\t\t\t\t\tpara ajudas técnicas, cura, tratamento e prevenção de deficiências\n\t\t\t\t\tou que contribuam para impedir ou minimizar o seu agravamento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 21. O desenvolvimento científico e tecnológico voltado para a\n\t\t\t\t\tprodução de ajudas técnicas dar-se-á a partir da instituição de\n\t\t\t\t\tparcerias com universidades e centros de pesquisa para a produção\n\t\t\t\t\tnacional de componentes e equipamentos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 22. Deverá o Poder Público instituir campanhas destinadas à\n\t\t\t\t\tprevenção de doenças, deficiências e fatores de risco causadores de\n\t\t\t\t\tdeficiências, inclusive acidentes, veiculadas por todos os meios de\n\t\t\t\t\tcomunicação, assegurada sua acessibilidade, por intermédio de janela\n\t\t\t\t\tcom tradução simultânea para a Língua Brasileira de Sinais - Libras,\n\t\t\t\t\táudio descrição, legenda, dentre outros.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Prevenção e do Tratamento\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 23. O atendimento ao recém-nascido na sala de parto e nas\n\t\t\t\t\tUnidades Intermediárias consiste em sua assistência por profissional\n\t\t\t\t\tcapacitado, médico, preferencialmente neonatologista ou pediatra, ou\n\t\t\t\t\tprofissional de enfermagem, preferencialmente enfermeiro obstetra ou\n\t\t\t\t\tneonatal, desde o período imediatamente anterior ao parto, até que o\n\t\t\t\t\trecém-nascido seja avaliado e entregue aos cuidados da mãe, da\n\t\t\t\t\tunidade intermediária ou, se necessário, da Unidade de Tratamento\n\t\t\t\t\tIntensivo - UTI neonatal.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 24. É obrigatória a realização gratuita do Exame de Triagem\n\t\t\t\t\tNeonatal - "Teste do Pezinho" em todos os recém-nascidos\n\t\t\t\t\tem hospitais da rede pública e privada, nos termos da Lei nº 8.627,\n\t\t\t\t\tde 9 de dezembro de 1987.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 25. É obrigatória a realização gratuita do Exame de Emissões\n\t\t\t\t\tOtoacústicas Evocadas - "Teste da Orelhinha" em todos os\n\t\t\t\t\trecém-nascidos em hospitais da rede pública e privada, nos termos da\n\t\t\t\t\tLei nº 14.588, de 22 de dezembro de 2004.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 26. É obrigatória a realização gratuita do Exame de Diagnóstico\n\t\t\t\t\tClínico de Catarata Congênita - "Teste do Olhinho" em\n\t\t\t\t\ttodos os recém-nascidos em hospitais da rede pública e privada, nos\n\t\t\t\t\ttermos Lei nº 14.601, de 28 de dezembro de 2004.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 27. É obrigatória a realização gratuita de testes de acuidade\n\t\t\t\t\tvisual e auditiva em todos os estudantes da rede pública estadual de\n\t\t\t\t\tensino.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os alunos que apresentarem distúrbios de acuidade visual ou\n\t\t\t\t\tauditiva serão obrigatoriamente encaminhados para exames\n\t\t\t\t\toftalmológicos ou otorrinolaringológicos, respectivamente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O Poder Executivo regulamentará a realização dos testes de\n\t\t\t\t\tacuidade visual e auditiva, inclusive sua periodicidade, no prazo de\n\t\t\t\t\tnoventa dias, contados a partir da data da publicação desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 28. Caberá ao Poder Executivo do Estado do Paraná o\n\t\t\t\t\tdesenvolvimento e a implantação de sistema próprio para o registro\n\t\t\t\t\tdos casos de nascimento e diagnóstico de pessoa com deficiência,\n\t\t\t\t\tassim como os casos de deficiência adquirida por acidente ou\n\t\t\t\t\tmoléstia detectada, em estabelecimento hospitalar ou ambulatorial,\n\t\t\t\t\tpúblico ou privado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º As informações para o registro dos nascimentos com detecção de\n\t\t\t\t\tanomalia congênita poderão ser obtidas a partir dos dados do Sistema\n\t\t\t\t\tde Informações sobre Nascidos Vivos - Sinasc.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As informações para o registro dos casos de deficiências\n\t\t\t\t\tcausadas por moléstia ou acidente, identificadas posteriormente ao\n\t\t\t\t\tpreenchimento da Declaração de Nascido Vivo, serão obtidas a partir\n\t\t\t\t\tdos dados do Sistema de Informações de Atenção Básica.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Quando necessário, o Poder Executivo do Estado do Paraná criará\n\t\t\t\t\tos meios para captação de dados e alimentação no sistema previsto no\n\t\t\t\t\tcaput deste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º As informações integrantes do sistema previsto no caput deste\n\t\t\t\t\tartigo serão de caráter sigiloso e utilizadas com a finalidade de\n\t\t\t\t\testabelecer índices estatísticos e mapeamento do Estado do Paraná a\n\t\t\t\t\tfim de subsidiar as políticas públicas voltadas às pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 5º O Poder Executivo regulamentará a criação e a forma de\n\t\t\t\t\tnotificação do sistema previsto no caput deste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 29. Os hospitais e as maternidades situados no Estado do Paraná\n\t\t\t\t\tprestarão assistência integral às parturientes e aos bebês em\n\t\t\t\t\tsituação de risco que necessitem de tratamento continuado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os hospitais e as maternidades situados no Estado do Paraná,\n\t\t\t\t\talém da assistência integral prevista no caput deste artigo,\n\t\t\t\t\tprestarão informações por escrito à parturiente, ou a quem a\n\t\t\t\t\trepresente, sobre os cuidados a serem tomados com o recém-nascido\n\t\t\t\t\tpor conta de sua deficiência ou patologia, bem como no fornecimento\n\t\t\t\t\tde listagem das instituições, públicas e privadas, especializadas na\n\t\t\t\t\tassistência às pessoas com deficiência ou patologia específica.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Os hospitais e maternidades públicos e conveniados situados no\n\t\t\t\t\tEstado do Paraná prestarão assistência psicológica aos pais, aos\n\t\t\t\t\tresponsáveis legais e às crianças, quando constatadas deficiências\n\t\t\t\t\tou patologias nos atendimentos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDO DIREITO À HABITAÇÃO\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 30. As habitações de interesse social ofertadas pelo Estado do\n\t\t\t\t\tParaná deverão respeitar os padrões do desenho universal,\n\t\t\t\t\tpossibilitando o pleno uso por parte de pessoas com e sem\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os órgãos da administração direta ou indireta do estado, as\n\t\t\t\t\tfundações ou instituições financeiras instituídas e mantidas pelo\n\t\t\t\t\tEstado do Paraná, ou da qual ele faça parte como acionista\n\t\t\t\t\tmajoritário, quando efetuarem venda de casa própria, farão constar\n\t\t\t\t\tse o interessado na aquisição ou qualquer de seus moradores é pessoa\n\t\t\t\t\tcom deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A entrega dos imóveis objetos da inscrição dar-se-á, sempre que\n\t\t\t\t\tpossível, de forma adaptada e preferencial aos inscritos, na forma\n\t\t\t\t\tdo § 1º"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tdeste artigo, permitindo-se a escolha das unidades que melhor se\n\t\t\t\t\tprestem à moradia destes em cada lote ofertado, respeitada a ordem\n\t\t\t\t\tprévia da inscrição geral.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Deverão ser destinadas no mínimo 5% (cinco por cento) das\n\t\t\t\t\tunidades habitacionais para pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDA EDUCAÇÃO\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDisposições Gerais\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 31. Assegura o sistema educacional inclusivo em todos os\n\t\t\t\t\tníveis, bem como o aprendizado ao longo de toda a vida, como meio de\n\t\t\t\t\tefetivar o direito das pessoas com deficiência à educação sem\n\t\t\t\t\tdiscriminação e com base na igualdade de oportunidades.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("strong", [
                        _vm._v(
                          "(Redação do artigo dada pela Lei Nº 19603 DE 19/07/2018):"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 32. É dever do Estado, da família, da comunidade escolar e da\n\t\t\t\t\tsociedade assegurar educação de qualidade às pessoas com\n\t\t\t\t\tdeficiência, colocando-as a salvo de toda a forma de negligência,\n\t\t\t\t\tdiscriminação, violência, crueldade e opressão escolar.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Assegura ao aluno com deficiência, à sua família ou ao seu\n\t\t\t\t\trepresentante legal, o direito de opção pela frequência nas escolas\n\t\t\t\t\tda rede comum de ensino ou nas escolas de educação básica na\n\t\t\t\t\tmodalidade de educação especial, observadas as especificidades\n\t\t\t\t\tdevidamente detectadas por avaliação multiprofissional, devendo\n\t\t\t\t\thaver o serviço de apoio educacional complementar.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Assegura aos alunos com síndrome de down o direito de matrícula\n\t\t\t\t\tsimultânea nas escolas da rede regular de ensino e nas escolas que\n\t\t\t\t\tprestem atendimento educacional especial.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 33. O Governo do Estado do Paraná deverá promover cursos,\n\t\t\t\t\tgrupos de estudos e capacitação dos professores da rede pública de\n\t\t\t\t\tensino, a fim de que estejam aptos a lecionar para alunos com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 34. Os órgãos e as entidades da administração pública estadual\n\t\t\t\t\tdireta e indireta responsáveis pelo sistema de educação do Estado do\n\t\t\t\t\tParaná dispensarão tratamento prioritário e adequado aos assuntos\n\t\t\t\t\tobjeto desta Seção, viabilizando, sem prejuízo de outras, as\n\t\t\t\t\tseguintes medidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - inclusão, no sistema educacional, da educação especial como\n\t\t\t\t\tmodalidade de educação escolar que permeia transversalmente todos os\n\t\t\t\t\tníveis e as modalidades de ensino;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - inserção, no sistema educacional, das escolas ou instituições\n\t\t\t\t\tespecializadas, públicas ou privadas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - oferta, obrigatória e gratuita, da educação especial em\n\t\t\t\t\testabelecimentos públicos ou conveniados de ensino;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - oferta obrigatória dos serviços de educação especial ao aluno\n\t\t\t\t\tcom deficiência que esteja internado por prazo igual ou superior a\n\t\t\t\t\tum mês em unidades hospitalares e congêneres;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - acesso de aluno com deficiência aos benefícios conferidos aos\n\t\t\t\t\tdemais alunos, inclusive material escolar, transporte adaptado e\n\t\t\t\t\tadequado e merenda escolar.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Entende-se por educação especial, para os efeitos desta Seção,\n\t\t\t\t\ta modalidade de educação escolar oferecida preferencialmente na rede\n\t\t\t\t\tcomum de ensino para educandos com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A educação especial deve constituir processos flexíveis,\n\t\t\t\t\tdinâmicos e individualizados, contando com equipe multidisciplinar\n\t\t\t\t\tespecializada que deverá adotar orientações adequadas a cada caso.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º A educação do aluno com deficiência iniciar-se-á na educação\n\t\t\t\t\tinfantil, a partir de zero de idade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º Quando da construção e reforma de estabelecimentos de ensino\n\t\t\t\t\tdeverão ser atendidas as normas técnicas de acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 35. Os serviços de educação especial serão ofertados nas\n\t\t\t\t\tinstituições de ensino públicas e privadas do sistema de educação\n\t\t\t\t\tgeral, de forma transitória ou permanente, mediante programas de\n\t\t\t\t\tapoio ao aluno que esteja incluído no sistema comum de ensino, ou\n\t\t\t\t\tnas escolas de educação básica na modalidade especial,\n\t\t\t\t\texclusivamente quando a educação das escolas da rede comum de ensino\n\t\t\t\t\tnão puder satisfazer as necessidades educativas do aluno ou quando\n\t\t\t\t\tnecessário ao seu bem-estar.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 36. Assegura à pessoa com deficiência a prioridade de vaga em\n\t\t\t\t\tescola pública, inclusive nos centros de educação infantil,\n\t\t\t\t\tpreferencialmente naquela com localização mais próxima à sua\n\t\t\t\t\tresidência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Considera-se estabelecimento mais próximo da residência da\n\t\t\t\t\tpessoa com deficiência aquele cuja distância da residência seja\n\t\t\t\t\tmenor ou que seja mais fácil seu acesso por meio de transporte\n\t\t\t\t\tcoletivo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Havendo mais de um estabelecimento de ensino considerado\n\t\t\t\t\tpróximo à residência do aluno com deficiência, este terá o direito\n\t\t\t\t\tde optar por qualquer das instituições de ensino.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Para a obtenção da prioridade de que trata o caput deste\n\t\t\t\t\tartigo, as pessoas com deficiência deverão apresentar junto à\n\t\t\t\t\tinstituição de ensino comprovante de residência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º No caso de preferência por instituição de ensino que não seja a\n\t\t\t\t\tconsiderada mais próxima de sua residência, o aluno com deficiência\n\t\t\t\t\tdeverá apresentar justificativa circunstanciada, que será apreciada\n\t\t\t\t\tpela instituição de ensino escolhida, sendo a decisão da escola\n\t\t\t\t\tpassível de recurso administrativo ao órgão responsável pela\n\t\t\t\t\tadministração da escola.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 37. A escola deverá incluir, regularmente, o aluno com\n\t\t\t\t\tdeficiência matriculado em atividades esportivas proporcionando sua\n\t\t\t\t\tparticipação em atividades físicas, jogos e competições desportivas\n\t\t\t\t\tou paradesportivas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 38. Todas as instituições que ofertam educação básica e\n\t\t\t\t\tsuperior deverão implementar medidas para assegurar aos alunos\n\t\t\t\t\tsurdos ou com deficiência auditiva o acesso à comunicação, à\n\t\t\t\t\tinformação e à educação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParagrafo único. Os professores deverão ter acesso à literatura e às\n\t\t\t\t\tinformações sobre a especificidade linguística do aluno surdo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 39. A programação visual dos cursos de nível médio e superior,\n\t\t\t\t\tpreferencialmente os de formação de professores, na modalidade de\n\t\t\t\t\teducação à distância, deve dispor de sistemas de acesso à\n\t\t\t\t\tinformação, nos termos do Decreto Federal nº 5.296, de 02 de\n\t\t\t\t\tdezembro de 2004.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 40. A Língua Brasileira de Sinais - Libras deverá ser inserida\n\t\t\t\t\tcomo disciplina curricular obrigatória nos cursos de formação de\n\t\t\t\t\tprofessores para o exercício do magistério, em nível médio e\n\t\t\t\t\tsuperior, e nos cursos de Fonoaudiologia, Pedagogia e Educação\n\t\t\t\t\tEspecial.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Nos demais cursos de nível superior e de ensino\n\t\t\t\t\tprofissionalizante, a Libras será incluída como disciplina\n\t\t\t\t\tcurricular optativa nos termos do § 2º do art. 3º do Decreto Federal\n\t\t\t\t\tnº 5.626, de 22 de dezembro de 2005.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 41. A Secretaria de Estado responsável pela política pública da\n\t\t\t\t\teducação atenderá às necessidades dos alunos com deficiência\n\t\t\t\t\tvisual"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tmatriculados nas escolas estaduais para a impressão em braile ou em\n\t\t\t\t\tfonte ampliada dos livros, apostilas e outros materiais pedagógicos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os autores estão autorizados a fornecer à\n\t\t\t\t\tSecretaria de Estado responsável pela política pública da educação\n\t\t\t\t\tcópia do texto integral das obras mencionadas no caput deste artigo\n\t\t\t\t\tem meio digital.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 42. O Poder Executivo do Estado do Paraná assegurará a\n\t\t\t\t\tdisponibilização de ledor capacitado para aplicação de prova a fim\n\t\t\t\t\tde não prejudicar a avaliação do desempenho da pessoa com\n\t\t\t\t\tdeficiência visual, mediante criação e manutenção de cadastro de\n\t\t\t\t\tledores.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDa Educação Básica\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 43. As instituições de ensino de educação básica, em qualquer\n\t\t\t\t\tnível ou modalidade de ensino, devem assegurar o atendimento\n\t\t\t\t\teducacional aos alunos com deficiência, prevendo e provendo a oferta\n\t\t\t\t\tde serviço e apoio especializados para o processo ensino\n\t\t\t\t\taprendizagem desses alunos, tais como:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - escolas e classes de educação bilíngue, abertas a alunos surdos\n\t\t\t\t\te ouvintes, com professores bilíngues e professores surdos, na\n\t\t\t\t\teducação infantil e nos anos iniciais do ensino fundamental, bem\n\t\t\t\t\tcomo com a presença de tradutores e intérpretes da Língua Brasileira\n\t\t\t\t\tde Sinais - LIBRAS;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - escolas bilíngues ou escolas comuns da rede comum de ensino,\n\t\t\t\t\tabertas a alunos surdos e ouvintes, para os anos finais do ensino\n\t\t\t\t\tfundamental, ensino médio ou educação profissional, com docentes das\n\t\t\t\t\tdiferentes áreas do conhecimento, cientes da singularidade\n\t\t\t\t\tlinguística dos alunos surdos e de suas especificidades, bem como\n\t\t\t\t\tcom a presença de tradutores e intérpretes da Língua Brasileira de\n\t\t\t\t\tSinais - Libras.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os alunos com deficiência têm direito à escolarização em um\n\t\t\t\t\tturno diferenciado ao do atendimento educacional especializado para\n\t\t\t\t\to desenvolvimento de complementação curricular, com utilização de\n\t\t\t\t\tequipamentos e tecnologias de informação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º São denominadas escolas ou classes de educação bilíngue aquelas\n\t\t\t\t\tem que a Língua Brasileira de Sinais - Libras L1 e a Língua\n\t\t\t\t\tPortuguesa L2 sejam, respectivamente, a primeira e a segunda língua\n\t\t\t\t\tde instrução, e que ambas sejam utilizadas no desenvolvimento de\n\t\t\t\t\ttodo o processo educativo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º As mudanças decorrentes da implementação dos incisos I e II\n\t\t\t\t\tdeste artigo implicam na formalização da opção ou preferência pela\n\t\t\t\t\teducação bilíngue, a critério dos pais e dos próprios alunos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDo Ensino Superior\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 44. As instituições de ensino superior deverão oferecer\n\t\t\t\t\tadaptação das provas e o apoio necessário ao aluno com deficiência,\n\t\t\t\t\tinclusive tempo adicional para realização das provas e critérios\n\t\t\t\t\tdiferenciados de avaliação, conforme as características da\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º As disposições deste artigo aplicam-se, também, ao sistema\n\t\t\t\t\tgeral do processo seletivo para o ingresso em cursos universitários\n\t\t\t\t\tde instituições de ensino superior, conforme legislação vigente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A Secretaria de Estado responsável pela política pública da\n\t\t\t\t\tciência e tecnologia e ensino superior, no âmbito da sua competência\n\t\t\t\t\te em conformidade com a legislação vigente, expedirá instruções para\n\t\t\t\t\tque os"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tprogramas de educação superior incluam nos seus currículos\n\t\t\t\t\tconteúdos, itens, ou disciplinas relacionados às pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDa Educação Profissional\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 45. Todo o aluno com deficiência matriculado ou egresso do\n\t\t\t\t\tensino fundamental ou médio terá acesso à educação profissional, a\n\t\t\t\t\tfim de obter habilitação profissional que lhe proporcione\n\t\t\t\t\toportunidade de acesso ao mercado de trabalho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A educação profissional para as pessoas com deficiência será\n\t\t\t\t\toferecida nos níveis básico, médio, técnico e tecnológico em escolas\n\t\t\t\t\tda rede comum de ensino, em instituições especializadas e nos\n\t\t\t\t\tambientes de trabalho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As instituições especializadas que ministram educação\n\t\t\t\t\tprofissional deverão, obrigatoriamente, oferecer cursos\n\t\t\t\t\tprofissionalizantes de nível básico à pessoa com deficiência,\n\t\t\t\t\tcondicionando a matrícula à sua capacidade de aproveitamento e não\n\t\t\t\t\tao seu nível de escolaridade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Entende-se por habilitação profissional o processo destinado a\n\t\t\t\t\tpropiciar às pessoas com deficiência, em nível formal e\n\t\t\t\t\tsistematizado, a aquisição de conhecimentos e habilidades\n\t\t\t\t\tespecificamente associados à determinada profissão.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 46. As escolas e instituições de educação profissional\n\t\t\t\t\toferecerão serviços de apoio especializado para atender às\n\t\t\t\t\tespecificidades das pessoas com deficiência, tais como:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - adaptação dos recursos instrucionais: material pedagógico,\n\t\t\t\t\tequipamento e currículo;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - capacitação dos recursos humanos: professores, instrutores e\n\t\t\t\t\tprofissionais especializados;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - adequação dos recursos físicos: eliminação de barreiras\n\t\t\t\t\tarquitetônicas, ambientais e de comunicação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 47. O Governo do Estado do Paraná, por meio da Secretaria de\n\t\t\t\t\tEstado responsável pela política pública da educação, da Secretaria\n\t\t\t\t\tde Estado responsável pela política pública da administração e da\n\t\t\t\t\tprevidência, da Secretaria de Estado responsável pela política\n\t\t\t\t\tpública da ciência, tecnologia e ensino superior, pela Secretaria de\n\t\t\t\t\tEstado responsável pela política pública do trabalho, emprego e\n\t\t\t\t\teconomia solidária e demais órgãos da administração direta e\n\t\t\t\t\tindireta do Estado do Paraná desenvolverão projetos recomendando a\n\t\t\t\t\tcriação e implantação de programas de cursos técnicos e\n\t\t\t\t\tprofissionalizantes direcionados às pessoas com deficiência,\n\t\t\t\t\tpossibilitando sua inclusão no mercado de trabalho de modo a atender\n\t\t\t\t\tas suas especificidades.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDo Estágio e do Aprendiz\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 48. É permitida a admissão de pessoa com deficiência na\n\t\t\t\t\tcondição de aprendiz ou estagiário por órgãos da administração\n\t\t\t\t\tdireta e indireta, sob forma de contrato de aprendizagem ou de\n\t\t\t\t\testágio.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As condições de aprendizagem ou de estágio serão\n\t\t\t\t\tdefinidas em regulamento próprio a ser definido pela Secretaria de\n\t\t\t\t\tEstado responsável pela política pública da administração e da\n\t\t\t\t\tprevidência, observada a legislação federal específica.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 49. A duração do estágio, exercido na mesma parte concedente,\n\t\t\t\t\tpoderá exceder dois anos quando se tratar de estagiário com\n\t\t\t\t\tdeficiência, desde que em áreas de atuação diversas, assegurando,\n\t\t\t\t\tdesta forma, o aprendizado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDO TRABALHO\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDisposições Gerais\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 50. Consideram-se pessoas com deficiência, para os fins deste\n\t\t\t\t\tCapítulo, aquelas que se enquadram nos critérios especificados no\n\t\t\t\t\tDecreto Federal nº 3.298, de 20 de dezembro de 1999, na Lei Estadual\n\t\t\t\t\tnº 16.945, de 18 de novembro de 2011, na Lei Federal nº 12.764, de\n\t\t\t\t\t27 de dezembro de 2012 e demais normas que venham a ampliar o rol de\n\t\t\t\t\tcritérios para pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 51. Os órgãos e entidades da administração pública estadual\n\t\t\t\t\tdireta e indireta deverão assegurar às pessoas com deficiência o\n\t\t\t\t\tpleno exercício de seu direito ao trabalho e de outros que,\n\t\t\t\t\tdecorrentes da Constituição Federal, da Constituição do Estado do\n\t\t\t\t\tParaná e das demais leis esparsas, propiciem seu bem-estar pessoal,\n\t\t\t\t\tsocial e econômico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 52. Para o fim estabelecido no art. 51 desta Lei, os órgãos e\n\t\t\t\t\tentidades da administração estadual direta e indireta dispensarão,\n\t\t\t\t\tno âmbito de sua competência e finalidade, atendimento prioritário e\n\t\t\t\t\tadequado, tendente a viabilizar, sem prejuízo de outras, as\n\t\t\t\t\tseguintes medidas na área da formação profissional e do trabalho:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - apoio governamental à formação profissional e à garantia de\n\t\t\t\t\tacesso aos serviços oferecidos, inclusive aos cursos regulares\n\t\t\t\t\tvoltados à formação profissional;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - empenho do poder público quanto ao incentivo para a criação,\n\t\t\t\t\tmanutenção e ampliação de empregos destinados às pessoas com\n\t\t\t\t\tdeficiência, conforme previsto no art. 35 do Decreto Federal nº\n\t\t\t\t\t3.298, de 20 de dezembro de 1999;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - promoção de ações eficazes que propiciem a inserção, nos\n\t\t\t\t\tsetores públicos e privados, de pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 53. O Poder Executivo do Estado do Paraná deverá criar no prazo\n\t\t\t\t\tde 24 (vinte e quatro) meses programa de incentivo para a\n\t\t\t\t\tcontratação de pessoas com deficiência nas empresas não abrangidas\n\t\t\t\t\tpelo art. 93 da Lei Federal nº 8.213, de 24 de julho de 1991.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Reserva de Cargos e Empregos\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 54. Assegura à pessoa com deficiência o direito de se inscrever\n\t\t\t\t\tem concurso público, processos seletivos ou quaisquer outros\n\t\t\t\t\tprocedimentos de recrutamento de mão de obra para provimento em\n\t\t\t\t\tigualdade de condições com os demais candidatos de cargo ou emprego\n\t\t\t\t\tpúblico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º O candidato com deficiência, em razão da necessária igualdade\n\t\t\t\t\tde condições, concorrerá a todas as vagas, sendo reservado, no\n\t\t\t\t\tmínimo, o percentual de 5% (cinco por cento) em face da\n\t\t\t\t\tclassificação obtida.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Caso a aplicação do percentual de que trata o § 1º deste artigo\n\t\t\t\t\tresultar em número fracionado, este deverá ser elevado até o\n\t\t\t\t\tprimeiro número inteiro subsequente, respeitando o percentual máximo\n\t\t\t\t\tde 20% (vinte por cento) das vagas oferecidas no certame.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º É assegurada a gratuidade de inscrição em concurso público à\n\t\t\t\t\tpessoa com deficiência comprovadamente carente, desde que apresente\n\t\t\t\t\tcomprovante atualizado de inscrição no Cadastro Único - CadÚnico\n\t\t\t\t\tpara programas sociais do Governo Federal, nos termos do Decreto\n\t\t\t\t\tFederal nº 6.135, de 26 de junho de 2007, emitido pelo Gestor do\n\t\t\t\t\tPrograma do seu município de residência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º A reserva do percentual adotado será distribuída\n\t\t\t\t\tproporcionalmente pelas vagas em disputa.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 55. Não se aplica o disposto no art. 54 desta Lei aos casos de\n\t\t\t\t\tprovimento de cargo ou emprego público integrante de carreira que\n\t\t\t\t\texija aptidão plena do candidato, auferida em parecer emitido por\n\t\t\t\t\tequipe multiprofissional.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O exame de higidez física ou avaliação médica não\n\t\t\t\t\tpoderá excluir o candidato em razão de sua deficiência, exceto nos\n\t\t\t\t\tcasos em que se exija aptidão plena do candidato em razão da função\n\t\t\t\t\ta ser desempenhada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 56. Os editais de concursos públicos e testes seletivos deverão\n\t\t\t\t\tser disponibilizados em formato acessível às pessoas com deficiência\n\t\t\t\t\tvisual nos portais e sítios eletrônicos da administração pública na\n\t\t\t\t\trede mundial de computadores.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O Poder Público terá o prazo de até doze meses,\n\t\t\t\t\tcontados da data de publicação da presente Lei, para atender ao\n\t\t\t\t\tdisposto no caput deste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 57. Os editais de concursos públicos deverão conter:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - o número de vagas existentes, bem como o total correspondente à\n\t\t\t\t\treserva destinada à pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - as atribuições e tarefas essenciais dos cargos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - previsão de adaptação das provas do curso de formação e do\n\t\t\t\t\testágio probatório, conforme a deficiência do candidato;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - previsão do conteúdo das provas para aferir as habilidades do\n\t\t\t\t\tcandidato, quando se tratarem de funções que dispensam conhecimentos\n\t\t\t\t\ttécnicos e comprovação de escolaridade;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - exigência de apresentação, pelo candidato com deficiência, de\n\t\t\t\t\tlaudo médico atestando a espécie e o grau ou o nível de deficiência,\n\t\t\t\t\tcom expressa referência ao código correspondente da Classificação\n\t\t\t\t\tInternacional de Funcionalidade, Incapacidade e Saúde - CIF, bem\n\t\t\t\t\tcomo a provável causa da deficiência, após a realização da prova de\n\t\t\t\t\tconhecimentos, mediante convocação específica para este fim, sendo\n\t\t\t\t\tassegurada a alteração de sua inscrição para as vagas de livre\n\t\t\t\t\tconcorrência nos casos em que o laudo médico não se enquadrar nos\n\t\t\t\t\tcritérios legais para definição de pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 58. É vedado à autoridade competente obstar a inscrição de\n\t\t\t\t\tpessoa com deficiência em concurso público para ingresso em carreira\n\t\t\t\t\tda administração pública estadual direta e indireta.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º No ato da inscrição, o candidato com deficiência, que necessite\n\t\t\t\t\tde atendimento diferenciado nos dias do concurso, deverá requerê-lo,\n\t\t\t\t\tno prazo determinado em edital, indicando as condições diferenciadas\n\t\t\t\t\tde que necessita para a realização das provas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O candidato com deficiência que necessitar de tempo adicional\n\t\t\t\t\tpara a realização das provas deverá requerê-lo no prazo estabelecido\n\t\t\t\t\tno edital do concurso.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 59. A pessoa com deficiência, resguardadas as condições\n\t\t\t\t\tespeciais previstas nesta Lei, participará de concurso em equidade\n\t\t\t\t\tde condições com os demais candidatos no que concerne:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - ao conteúdo das provas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - à avaliação e aos critérios de aprovação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - ao horário e local de aplicação das provas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - à nota mínima exigida para todos os demais candidatos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A igualdade de condições a que se refere o caput deste artigo\n\t\t\t\t\ttambém compreende:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tI - adaptação de provas;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - apoio necessário, previamente solicitado pelo candidato com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - avaliação de provas discursivas ou de redação por uma comissão\n\t\t\t\t\tcomposta por ao menos um profissional com formação específica na\n\t\t\t\t\tárea da deficiência que acarreta especificidades na escrita da\n\t\t\t\t\tlíngua.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Considera-se adaptação de provas todos os meios utilizados para\n\t\t\t\t\tpermitir a realização da prova pelo candidato com deficiência, assim\n\t\t\t\t\tcompreendendo, entre outros:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - a disponibilidade da prova em braile e, quando solicitado, o\n\t\t\t\t\tserviço do ledor apto, ou outros meios existentes, nos casos de\n\t\t\t\t\tcandidato com deficiência visual;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - a disponibilidade de intérprete, quando solicitado, nos casos\n\t\t\t\t\tde candidato surdo ou com deficiência auditiva;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - tempo adicional para a realização das provas, inclusive para\n\t\t\t\t\tpreenchimento do cartão-resposta, quando for o caso, e se\n\t\t\t\t\tnecessário, conforme as características da deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 60. A publicação do resultado final do concurso será feita em\n\t\t\t\t\tduas listas, contendo a primeira a pontuação de todos os candidatos,\n\t\t\t\t\tinclusive daqueles com deficiência, e a segunda somente a pontuação\n\t\t\t\t\tdestes últimos, de acordo com a ordem classificatória.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A nomeação dos candidatos com deficiência aprovados\n\t\t\t\t\tfar-se-á concomitantemente com os dos demais candidatos aprovados,\n\t\t\t\t\tobservada a ordem de classificação das listas de que trata o caput\n\t\t\t\t\tdeste artigo, convocando-se as pessoas com deficiência para\n\t\t\t\t\tnomeação, atendendo-se ao percentual estabelecido em edital, de modo\n\t\t\t\t\ta entrecruzarem-se as listas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 61. O órgão responsável pela realização do concurso terá a\n\t\t\t\t\tassistência de equipe multiprofissional composta por três\n\t\t\t\t\tprofissionais capacitados e atuantes nas áreas de deficiência em\n\t\t\t\t\tquestão, sendo um deles médico e dois profissionais integrantes da\n\t\t\t\t\tcarreira almejada pelo candidato.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A equipe multiprofissional emitirá parecer observando:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - as informações prestadas pelo candidato no ato da inscrição,\n\t\t\t\t\tinclusive as constantes do laudo médico;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - a natureza das atribuições e tarefas essenciais do cargo ou da\n\t\t\t\t\tfunção a desempenhar;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - a viabilidade das condições de acessibilidade e as adequações\n\t\t\t\t\tdo ambiente de trabalho na execução das tarefas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - a possibilidade de uso, pelo candidato, de equipamentos ou\n\t\t\t\t\tmeios que habitualmente utilize;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - a CIF e outros padrões reconhecidos nacional e\n\t\t\t\t\tinternacionalmente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A equipe multiprofissional avaliará a compatibilidade entre as\n\t\t\t\t\tatribuições dos cargos e a deficiência do candidato apenas durante o\n\t\t\t\t\testágio probatório.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 62. A avaliação do servidor ou empregado com deficiência,\n\t\t\t\t\tdurante ou após o período de estágio probatório, deverá considerar\n\t\t\t\t\tas condições oferecidas pelo órgão para o efetivo desempenho de suas\n\t\t\t\t\tatribuições.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Redução da Jornada de Trabalho\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 63. Assegura ao funcionário ocupante de cargo público ou\n\t\t\t\t\tmilitar, que seja pai ou mãe, filho ou filha, cônjuge, companheiro\n\t\t\t\t\tou companheira, tutor ou tutora, curador ou curadora ou que detenha\n\t\t\t\t\ta guarda judicial da pessoa com deficiência congênita ou adquirida,\n\t\t\t\t\tde qualquer idade, a redução da carga horária semanal de seu cargo,\n\t\t\t\t\tsem prejuízo de remuneração, nos termos desta Seção.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A redução de carga horária, de que trata o caput deste artigo,\n\t\t\t\t\tdestina-se ao acompanhamento do dependente no seu processo de\n\t\t\t\t\thabilitação ou reabilitação ou às suas necessidades básicas diárias,\n\t\t\t\t\tpodendo ser consecutivo, intercalado, alternado ou escalonado,\n\t\t\t\t\tconforme necessidade ou programa do atendimento pertinente, mediante\n\t\t\t\t\trequerimento formulado à Secretaria de Estado responsável pela\n\t\t\t\t\tpolítica pública da administração e da previdência instruído com a\n\t\t\t\t\tindicação da necessidade da jornada a ser reduzida.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A dispensa ocorrerá para cargo de quarenta horas semanais e\n\t\t\t\t\tjornada de oito horas diárias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Havendo acumulação legal de dois cargos na esfera do Poder\n\t\t\t\t\tExecutivo Estadual, de vinte horas semanais cada um e jornada de\n\t\t\t\t\tquatro horas diárias cada um, a dispensa será no cargo de menor\n\t\t\t\t\tvalor ou daquele que for mais conveniente para o atendimento à\n\t\t\t\t\tpessoa deficiente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º A dispensa deverá observar o acúmulo máximo de quarenta horas\n\t\t\t\t\tsemanais e jornada de oito horas diárias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 5º Ao servidor alcançado pela dispensa concedida por esta Lei é\n\t\t\t\t\tvedada a ocu pação de qualquer atividade de natureza trabalhista,\n\t\t\t\t\tremunerada ou não, enquanto perdurar a dispensa, seja em qualquer\n\t\t\t\t\thorário ou qualquer região geográfica.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 6º Caberá à Secretaria de Estado responsável pela política pública\n\t\t\t\t\tda administração e da previdência manifestar-se sobre o requerimento\n\t\t\t\t\tde redução da carga horária em até trinta dias contados da data do\n\t\t\t\t\tprotocolo do requerimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 7º A redução prevista no caput deste artigo será regulamentada\n\t\t\t\t\tpela Secretaria de Estado responsável pela política pública da\n\t\t\t\t\tadministração e da previdência em até noventa dias contados da data\n\t\t\t\t\tda publicação da presente Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Habilitação e Reabilitação Profissional\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 64. A pessoa com deficiência, beneficiária ou não do Regime de\n\t\t\t\t\tPrevidência Social, tem direito às prestações de habilitação e\n\t\t\t\t\treabilitação profissional para capacitar-se a obter trabalho,\n\t\t\t\t\tconservá-lo e progredir profissionalmente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 65. O direito à habilitação e reabilitação compreende:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - o provimento de ações terapêuticas em favor da pessoa com\n\t\t\t\t\tdeficiência, visando habilitá-la ou reabilitá-la, sempre que\n\t\t\t\t\tpossível, eliminando ou minorando os efeitos da deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - a concessão de financiamento para a aquisição de equipamentos\n\t\t\t\t\tde uso pessoal que permitam a correção, diminuição e eliminação de\n\t\t\t\t\tbarreiras, por meio de programas próprios do Estado e Municípios.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O financiamento de que trata o inciso II deste\n\t\t\t\t\tartigo será concedido pelo Poder Executivo, por meio de instituição\n\t\t\t\t\tfinanceira, mediante as seguintes condições:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - comprovação do uso exclusivamente pessoal dos equipamentos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - caráter clínico médico para fisioterapia ou terapêutico\n\t\t\t\t\tocupacional dos equipamentos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - comprometimento inferior a 10% (dez por cento) da renda mensal\n\t\t\t\t\tfamiliar no pagamento das parcelas e taxa de juros não superior a\n\t\t\t\t\t12% (doze por cento) ao ano.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 66. Entende-se por habilitação e reabilitação profissional o\n\t\t\t\t\tprocesso orientado a possibilitar que a pessoa com deficiência, a\n\t\t\t\t\tpartir da identificação de suas potencialidades laborativas, adquira\n\t\t\t\t\to nível suficiente de desenvolvimento profissional para ingresso e\n\t\t\t\t\treingresso no mercado de trabalho e participe da vida comunitária.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os serviços de habilitação e reabilitação profissional deverão\n\t\t\t\t\testar dotados dos recursos necessários para atender todas as pessoas\n\t\t\t\t\tcom deficiência, independentemente da causa de sua deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As pessoas com deficiência atendidas pelos serviços de\n\t\t\t\t\thabilitação e reabilitação deverão ser preparadas para o mercado de\n\t\t\t\t\ttrabalho, a fim de exercer uma função adequada às suas\n\t\t\t\t\tespecificidades, assegurando a possibilidade de obter, conservar e\n\t\t\t\t\tprogredir na carreira.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 67. A orientação profissional será prestada pelos\n\t\t\t\t\tcorrespondentes serviços de habilitação e reabilitação profissional,\n\t\t\t\t\ttendo em conta as potencialidades das pessoas com deficiência,\n\t\t\t\t\tidentificadas com base em relatório de equipe multiprofissional, que\n\t\t\t\t\tdeverá considerar:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - educação escolar efetivamente recebida e por receber;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - expectativas de promoção social;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - possibilidades de emprego existentes em cada caso;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - motivações, atitudes e preferências profissionais; e\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - necessidades do mercado de trabalho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 68. Os órgãos e as entidades da administração pública estadual\n\t\t\t\t\tdireta e indireta, responsáveis pela formação de recursos humanos,\n\t\t\t\t\tdevem dispensar ao assunto objeto desta Seção tratamento prioritário\n\t\t\t\t\te adequado, viabilizando, sem prejuízo de outras, as seguintes\n\t\t\t\t\tmedidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - formação e qualificação de profissionais da educação para a\n\t\t\t\t\teducação especial e inclusiva especializados na habilitação e\n\t\t\t\t\treabilitação, bem como de instrutores e professores para a formação\n\t\t\t\t\tprofissional;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - formação e qualificação profissional, nas diversas áreas de\n\t\t\t\t\tconhecimento e de recursos humanos que atendam às demandas da pessoa\n\t\t\t\t\tcom deficiência; e\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - incentivo e apoio à pesquisa e ao desenvolvimento tecnológico\n\t\t\t\t\tem todas as áreas do conhecimento relacionadas à pessoa com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO VI\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tASSISTÊNCIA SOCIAL\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 69. A garantia do atendimento para a pessoa com deficiência\n\t\t\t\t\testará de acordo com o estabelecido na Lei Federal nº 8.742, de 7 de\n\t\t\t\t\tdezembro de 1993, em especial em seu art. 2º, que estabelece os\n\t\t\t\t\tobjetivos da Política Nacional da Assistência Social.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 70. O poder público garantirá os direitos socioassistenciais\n\t\t\t\t\tpara as pessoas com deficiência, previstos na Política Nacional da\n\t\t\t\t\tAssistência Social - PNAS em cumprimento às normativas do Sistema\n\t\t\t\t\tÚnico de Assistência Social - SUAS, na execução dos programas,\n\t\t\t\t\tprojetos, serviços e benefícios assistenciais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. É garantido à pessoa com deficiência o Benefício de\n\t\t\t\t\tPrestação Continuada - BPC/LOAS, nos termos da legislação federal\n\t\t\t\t\tvigente, assegurado que as equipes da Assistência Social deverão\n\t\t\t\t\tprestar o atendimento prioritário às pessoas com deficiência e suas\n\t\t\t\t\tfamílias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 71. Compete ao Estado o monitoramento e a avaliação da Política\n\t\t\t\t\tde Assistência Social, bem como assessorar os municípios para o\n\t\t\t\t\tdesenvolvimento dos programas, projetos, serviços e benefícios\n\t\t\t\t\tassistenciais à pessoa com deficiência, cabendo a manifestação do\n\t\t\t\t\tConselho Estadual de Assistência Social - CEAS no que tange às suas\n\t\t\t\t\tatribuições legais, inclusive no que diz respeito ao controle\n\t\t\t\t\tsocial.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 72. Para o cumprimento no disposto neste Capítulo, o Governo do\n\t\t\t\t\tEstado poderá manter parcerias com entidades civis de direito\n\t\t\t\t\tprivado sem fins lucrativos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 73. A execução dos atendimentos voltados para a pessoa com\n\t\t\t\t\tdeficiência atenderá aos princípios previstos na Tipificação de\n\t\t\t\t\tServiços do Sistema Único de Assistência Social e demais legislações\n\t\t\t\t\te normativas vigentes, pertinentes à organização destes\n\t\t\t\t\tatendimentos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tProteção Social Básica\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 74. Assegura às pessoas com deficiência o acesso à Proteção\n\t\t\t\t\tSocial Básica - PSB, que tem como objetivo prevenir situações de\n\t\t\t\t\trisco, por meio da execução de programas, projetos, benefícios e\n\t\t\t\t\tserviços que promovam o desenvolvimento de potencialidades,\n\t\t\t\t\taquisições e fortalecimento de vínculos familiares e comunitários.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 75. Caberá ao poder público por meio da PSB viabilizar o\n\t\t\t\t\tatendimento na unidade pública municipal - Centro de Referência de\n\t\t\t\t\tAssistência Social - CRAS, para a execução dos programas, projetos,\n\t\t\t\t\tbenefícios e serviços de assistência social, dentre eles o Serviço\n\t\t\t\t\tde Proteção e Atendimento Integral à família - PAIF e demais\n\t\t\t\t\tserviços voltados à pessoa com deficiência, monitorando e avaliando\n\t\t\t\t\tos serviços prestados na rede socioassistencial do município.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tProteção Social Especial\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 76. Assegura à pessoa com deficiência o acesso à Proteção\n\t\t\t\t\tSocial Especial - PSE, que tem por objetivo desenvolver programas,\n\t\t\t\t\tprojetos e serviços especializados às famílias e aos indivíduos que\n\t\t\t\t\tse encontram em situação de risco pessoal e social, por violação de\n\t\t\t\t\tdireito a exemplo das situações de abandono, negligência e maus\n\t\t\t\t\ttratos físicos, psíquicos, abuso sexual, deficiência, situação de\n\t\t\t\t\tdependência, entre outras.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tMédia Complexidade\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 77. Caberá ao poder público, por meio da Proteção Social\n\t\t\t\t\tEspecial de Média Complexidade, viabilizar o atendimento na unidade\n\t\t\t\t\tpública - Centro Especializado de Referência de Assistência Social -\n\t\t\t\t\tCREAS, para a execução dos programas, projetos, benefícios e\n\t\t\t\t\tserviços de assistência social de média complexidade, dentre eles, o\n\t\t\t\t\tServiço de Proteção e Atendimento Especializado às Famílias e aos\n\t\t\t\t\tIndivíduos - PAEFI, e demais serviços voltados às pessoas com\n\t\t\t\t\tdeficiência e suas famílias com grau de dependência, e que tiverem\n\t\t\t\t\tsuas limitações agravadas por violações de direitos, que elevam a\n\t\t\t\t\tdesvalorização da potencialidade e da capacidade da pessoa, dentre\n\t\t\t\t\toutras que agravam a dependência e comprometem o desenvolvimento da\n\t\t\t\t\tautonomia.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tAlta Complexidade\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 78. O Poder Público fica incumbido de assegurar às pessoas com\n\t\t\t\t\tdeficiência com vínculos fragilizados ou rompidos, o acolhimento de\n\t\t\t\t\tacordo com as especificidades, a fim de garantir a proteção\n\t\t\t\t\tintegral, por meio das modalidades previstas no SUAS.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO VII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tDO DIREITO AO TRANSPORTE - "PASSE LIVRE"\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 79. Assegura o transporte gratuito às pessoas com deficiência\n\t\t\t\t\tem linhas de transporte intermunicipal e em linhas de ônibus que\n\t\t\t\t\tcompõem as redes integradas de transporte coletivo das regiões\n\t\t\t\t\tmetropolitanas, mediante apresentação de comprovação do Passe Livre.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 80. A concessão de transporte gratuito previsto no art. 79\n\t\t\t\t\tdesta Lei estende-se às pessoas com as seguintes patologias\n\t\t\t\t\tcrônicas, desde que em tratamento continuado, fora do município de\n\t\t\t\t\tsua residência:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - insuficiência renal crônica;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tII - doença de Crohn;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tIII - câncer;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - transtornos mentais graves;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - HIV, mesmo que com carga viral indetectável por adesão efetiva\n\t\t\t\t\tao tratamento;\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do inciso dada pela Lei Nº 21259 DE 07/11/2022)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVI - mucoviscosidade;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVII - hemofilia;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVIII - esclerose múltipla.\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - transtorno do espectro autista."
                      ),
                      _c("strong", [
                        _vm._v(
                          "\n\t\t\t\t\t\t(Inciso acrescentado pela Lei Nº 20021 DE 13/11/2019)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 81. As empresas que exploram, através de concessão ou permissão\n\t\t\t\t\tdo Estado, o transporte coletivo intermunicipal no estado do Paraná,\n\t\t\t\t\tficam obrigadas a adaptar os veículos de suas respectivas frotas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Entende-se por adaptação todas as alterações\n\t\t\t\t\tprevistas na legislação federal ou estadual vigentes.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 82. Os beneficiários da isenção tarifária de que trata este\n\t\t\t\t\tCapítulo deverão promover a reserva da passagem com antecedência\n\t\t\t\t\tmínima de três horas do embarque, nos casos de linhas de transporte\n\t\t\t\t\tcoletivo intermunicipal.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 83. A empresa transportadora que recusar ou dificultar a\n\t\t\t\t\tutilização do passe livre, a qualquer pretexto, sofrerá as sanções\n\t\t\t\t\tprevistas na legislação estadual vigente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 84. As empresas que exploram o transporte coletivo\n\t\t\t\t\tintermunicipal deverão comunicar aos estabelecimentos comerciais\n\t\t\t\t\tonde são efetuadas as paradas para as refeições que passarão a\n\t\t\t\t\toperar com ônibus adaptados para o transporte de pessoas com\n\t\t\t\t\tdeficiência, a fim de que esses"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\testabelecimentos contem com banheiros e demais instalações adaptadas\n\t\t\t\t\tpara receber esses usuários nos termos desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os estabelecimentos comerciais de que trata este\n\t\t\t\t\tartigo que não atenderem ao pedido de adaptação deverão ser\n\t\t\t\t\tsubstituídos por outros que apresentem condições de receber usuários\n\t\t\t\t\tcom deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 85. Somente poderão se beneficiar desta isenção usuários do\n\t\t\t\t\ttransporte coletivo cuja renda bruta familiar per capita não seja\n\t\t\t\t\tsuperior a dois salários mínimos estadual do Grupo I.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 86. A isenção de tarifa à pessoa com deficiência, mediante\n\t\t\t\t\texpedição de carteira específica, será concedida pelo setor\n\t\t\t\t\tdesignado pela Secretaria de Estado responsável pelas políticas\n\t\t\t\t\tpúblicas para as pessoas com deficiência, após análise e conferência\n\t\t\t\t\tdos seguintes documentos comprobatórios:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - requerimento de concessão do passe livre em formulário\n\t\t\t\t\tespecífico, contendo declaração de carência de recursos financeiros\n\t\t\t\t\tpelo interessado, procurador ou representante legal, juntando\n\t\t\t\t\tcomprovante de rendimentos do requerente e das pessoas com as quais\n\t\t\t\t\treside, dirigido à Secretaria de Estado responsável pelas políticas\n\t\t\t\t\tpúblicas para as pessoas com deficiência, conforme modelo a ser\n\t\t\t\t\tdisponibilizado pela mesma Secretaria;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - laudo médico de avaliação fornecido por profissional habilitado\n\t\t\t\t\tno Sistema Único de Saúde - SUS, da Secretaria de Estado responsável\n\t\t\t\t\tpela política pública da saúde, ou da Secretaria de Saúde do\n\t\t\t\t\tmunicípio de domicílio com identificação do paciente, o qual deverá\n\t\t\t\t\tconter informações sobre a deficiência, sobre necessidade de\n\t\t\t\t\tacompanhante, se a deficiência é permanente ou necessita de nova\n\t\t\t\t\tavaliação, bem como a data da reavaliação, entre outras informações\n\t\t\t\t\tconforme modelo definido pela Resolução nº 246, de 7 de abril de\n\t\t\t\t\t2010 da Secretaria de Estado responsável pela política pública da\n\t\t\t\t\tsaúde ou pelo modelo que venha a substituí-la;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - ficha cadastral do requerente conforme modelo emitido pelo\n\t\t\t\t\tGoverno do Estado do Paraná, por intermédio da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da pessoa com deficiência, a ser\n\t\t\t\t\tpreenchida junto ao Conselho Estadual dos Direitos da Pessoa com\n\t\t\t\t\tDeficiência do Paraná - COEDE/PR;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - uma foto 3X4 recente, sem rasuras ou danificações, viabilizando\n\t\t\t\t\ta identificação imediata do requerente;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - uma fotocópia legível da Carteira de Identidade (RG);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - uma fotocópia legível do Cadastro de Pessoa Física (CPF);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - uma fotocópia do comprovante de residência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Nos casos em que houver a prescrição médica da necessidade de\n\t\t\t\t\tacompanhante, deverá ser indicado no requerimento de concessão do\n\t\t\t\t\tbenefício o nome de até três pessoas maiores de dezoito anos,\n\t\t\t\t\tanexando a este, fotocópia do RG legível destas pessoas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O Governo do Estado do Paraná, por intermédio da Secretária de\n\t\t\t\t\tEstado da Saúde, emitirá nota técnica disponibilizando novo modelo\n\t\t\t\t\tde laudo médico atendendo às disposições desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Na hipótese do interessado não ser alfabetizado ou estar\n\t\t\t\t\timpossibilitado de assinar, será admitida a impressão digital na\n\t\t\t\t\tpresença do funcionário do órgão autorizador que fará a\n\t\t\t\t\tidentificação, ou a assinatura a rogo, na presença de duas\n\t\t\t\t\ttestemunhas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º A falsa declaração ou comprovação de renda mensal sujeitará o\n\t\t\t\t\tinfrator às penas da lei, bem como à perda do benefício.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 87. Nos casos de deficiência permanente, fica dispensada a\n\t\t\t\t\tapresentação de laudo médico na renovação da concessão do passe\n\t\t\t\t\tlivre, devendo apresentar novamente os demais documentos exigidos no\n\t\t\t\t\tart. 86 desta Lei.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do artigo dada pela Lei Nº 18453 DE 14/04/2015)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 88. Os procedimentos administrativos para requerimento e\n\t\t\t\t\tconcessão do benefício do passe livre serão regulamentados pelo\n\t\t\t\t\tPoder Executivo do Estado do Paraná, no prazo de noventa dias pela\n\t\t\t\t\tSecretaria de Estado responsável pela política pública voltada à\n\t\t\t\t\tpessoa com deficiência e pela Secretaria de Estado responsável pelo\n\t\t\t\t\ttransporte.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Enquanto não estiver em vigor a regulamentação do\n\t\t\t\t\tprocedimento administrativo necessário à concessão do passe livre,\n\t\t\t\t\tprevisto no caput deste artigo, deverá ser utilizado o procedimento\n\t\t\t\t\tvigente até a publicação desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 89. O requerimento do passe livre será indeferido nos casos de:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - documentação incorreta ou incompleta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - renda bruta per capta superior a dois salários mínimos estadual\n\t\t\t\t\tdo Grupo I;\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - tratamento realizado no município de sua residência, quando se\n\t\t\t\t\ttratar de pessoa com doença crônica.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os requerimentos indeferidos serão restituídos ao requerente,\n\t\t\t\t\tvia correio, mediante ofício especificando o motivo do\n\t\t\t\t\tindeferimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Sanado o motivo do indeferimento, este poderá ser reenviado ao\n\t\t\t\t\tsetor responsável da Secretaria de Estado responsável pelas\n\t\t\t\t\tpolíticas públicas para as pessoas com deficiência para nova\n\t\t\t\t\tanálise.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 90. O requerente que tiver o benefício do passe livre\n\t\t\t\t\tindeferido poderá requerer a revisão da decisão pelo Conselho\n\t\t\t\t\tEstadual dos Direitos da Pessoa com Deficiência - COEDE/PR.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 91. A carteira do passe livre concedida às pessoas\n\t\t\t\t\tbeneficiárias desta Lei terá validade de quatro anos, exceto nos\n\t\t\t\t\tcasos em que houver indicação em laudo médico sobre necessidade de\n\t\t\t\t\tnova avaliação em prazo inferior.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do artigo dada pela Lei Nº 21259 DE 07/11/2022)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 92. A carteira do passe livre concedida à pessoa com doença\n\t\t\t\t\tcrônica terá validade de dois anos, exceto nos casos em que houver\n\t\t\t\t\tindicação em laudo médico sobre a necessidade de nova avaliação em\n\t\t\t\t\tprazo inferior a dois anos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 93. Na carteira concedida ao beneficiário deverão constar os\n\t\t\t\t\tseguintes dados:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - nome e dados de identificação do beneficiário;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tII - foto do beneficiário;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("span", { staticClass: "revogado" }, [
                        _c("strong", [
                          _vm._v("(Revogado pela Lei Nº 21259 DE 07/11/2022):")
                        ])
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("span", { staticClass: "revogado" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tIII - indicação da deficiência ou doença crônica apontada no laudo\n\t\t\t\t\t\tmédico;"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - a necessidade ou não de acompanhante;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - os dados de identificação dos acompanhantes indicados;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - data de expedição da carteira;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - data de validade da carteira.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 94. Somente terá direito à isenção tarifária de que trata este\n\t\t\t\t\tCapítulo o acompanhante que possuir nome e dados pessoais descritos\n\t\t\t\t\tna carteira do passe livre do beneficiário, restringindo-se a um\n\t\t\t\t\tacompanhante por viagem.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 95. A verificação pelas empresas concessionárias ou\n\t\t\t\t\tpermissionárias da necessidade de acompanhante para o beneficiário\n\t\t\t\t\tserá constatada mediante a conferência da inscrição na carteira\n\t\t\t\t\tconcedida ao beneficiário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Quando solicitado pelas empresas concessionárias ou\n\t\t\t\t\tpermissionárias de transporte, o acompanhante deverá apresentar\n\t\t\t\t\tdocumento de identificação com foto e as indicações de acompanhantes\n\t\t\t\t\tconstantes na carteira concedida ao beneficiário, podendo esta\n\t\t\t\t\tsolicitação ser realizada tanto no momento da aquisição da passagem\n\t\t\t\t\tquanto no embarque do ônibus.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 96. O beneficiário perderá o direito ao passe livre nos casos\n\t\t\t\t\tde:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - emissão de falsa declaração ou falsa comprovação de renda mensal\n\t\t\t\t\tno momento do pedido do benefício;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - uso do benefício para fins diversos dos estabelecidos nesta\n\t\t\t\t\tLei;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - existência de membros da família com renda superior a dois\n\t\t\t\t\tsalários mínimos estadual do Grupo I no momento da renovação do\n\t\t\t\t\tpasse livre concedido (aumento da renda familiar posterior à\n\t\t\t\t\tconcessão do benefício).\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 97. As Secretarias de Saúde do Estado e dos municípios deverão\n\t\t\t\t\tdar ampla divulgação dos locais para avaliação, e os Conselhos\n\t\t\t\t\tMunicipais e entidades, a que se refere este Capítulo, deverão\n\t\t\t\t\ttambém divulgar os locais para expedição das carteiras e\n\t\t\t\t\tprocedimentos adotados para tal fim.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 98. As empresas concessionárias ou permissionárias deverão\n\t\t\t\t\temitir o bilhete de passagem no ato da apresentação da carteira de\n\t\t\t\t\tpasse livre e documento de identificação com foto.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Na emissão do bilhete de passagem para o transporte não poderão\n\t\t\t\t\tser cobradas taxas referentes ao uso de balsas ou ferry-boats, taxas\n\t\t\t\t\tde embarque ou de pedágio.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As empresas prestadoras dos serviços deverão reservar, até três\n\t\t\t\t\thoras antes do embarque, no mínimo dois assentos em cada viagem,\n\t\t\t\t\tpreferencialmente na primeira fila de poltronas para conferir\n\t\t\t\t\tacessibilidade às pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Na hipótese de nenhum beneficiário demonstrar interesse em\n\t\t\t\t\tviajar dentro do prazo previsto no § 2º deste artigo, as empresas\n\t\t\t\t\tprestadoras dos serviços poderão colocar à venda os bilhetes de\n\t\t\t\t\treferidos assentos reservados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º Os funcionários das empresas transportadoras deverão auxiliar\n\t\t\t\t\tno embarque e desembarque dos beneficiários, tanto nos terminais das\n\t\t\t\t\tlinhas como nos pontos de parada e apoio ao longo do itinerário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 5º As empresas transportadoras providenciarão a capacitação\n\t\t\t\t\tpermanente de seu quadro funcional para prestar o atendimento\n\t\t\t\t\tadequado aos beneficiários.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 6º Os equipamentos indispensáveis à locomoção e à vida da pessoa\n\t\t\t\t\tcom deficiência serão transportados de forma adequada, acessível e\n\t\t\t\t\tgratuitamente pela empresa, além de sua bagagem.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 7º No embarque deverá o beneficiário apresentar a carteira de\n\t\t\t\t\tisenção acompanhada de documento de identificação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 99. Compete ao Departamento de Estradas de Rodagem do Estado do\n\t\t\t\t\tParaná - DER/PR e à Coordenação da Região Metropolitana de Curitiba\n\t\t\t\t\t- COMEC a fiscalização da utilização do benefício.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 100. As adaptações a serem feitas nos veículos das frotas das\n\t\t\t\t\tempresas concessionárias ou permissionárias do transporte rodoviário\n\t\t\t\t\tcoletivo intermunicipal de passageiros serão definidas pela\n\t\t\t\t\tSecretaria de Estado responsável pela política pública de\n\t\t\t\t\tinfraestrutura e logística, de acordo com as normas técnicas de\n\t\t\t\t\tacessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO VIII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDA CULTURA, DO DESPORTO, DO TURISMO, DO LAZER E DA COMUNICAÇÃO\n\t\t\t\t\tSOCIAL\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 101. Os órgãos e as entidades da administração pública estadual\n\t\t\t\t\tdireta e indireta responsáveis pela cultura, pelo desporto, pelo\n\t\t\t\t\tturismo, pelo lazer e pela comunicação social, dispensarão\n\t\t\t\t\ttratamento prioritário e adequado aos assuntos objeto deste\n\t\t\t\t\tCapítulo, com vista a viabilizar, sem prejuízo de outras, as\n\t\t\t\t\tseguintes medidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - promoção do acesso da pessoa com deficiência aos meios de\n\t\t\t\t\tcomunicação social:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\ta) acesso às informações através das legendas, áudio descrição e\n\t\t\t\t\tinterpretação em Libras, em conformidade com a Lei nº 12.095, de 11\n\t\t\t\t\tde março de 1998;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tb) desenvolvimento de programas e trabalhos nos meios de\n\t\t\t\t\tcomunicação, visando ao esclarecimento das necessidades das pessoas\n\t\t\t\t\tcom deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tc) implantação de programas de impressão em braile ou fonte ampliada\n\t\t\t\t\tnos meios de comunicação escrita;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\td) criação de programa de informação pública pautando temáticas\n\t\t\t\t\trelacionadas às áreas das deficiências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - acesso das pessoas com deficiência a museus, arquivos,\n\t\t\t\t\tbibliotecas e afins;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - incentivos para o exercício de atividades culturais, mediante:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\ta) participação da pessoa com deficiência em concursos de prêmios no\n\t\t\t\t\tcampo das artes e das letras;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tb) promoção de concursos culturais no campo das artes e das letras\n\t\t\t\t\tque estimulem o potencial da pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tc) exposições, publicações e representações artísticas de pessoa com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\td) incentivo à produção cultural para as pessoas com deficiência nas\n\t\t\t\t\táreas de música, artes cênicas, audiovisual, literatura, artes\n\t\t\t\t\tvisuais, folclore, artesanato, dentre outras manifestações\n\t\t\t\t\tculturais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\te) cursos e oficinas culturais acessíveis às pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - prática desportiva e paradesportiva nos seguintes moldes:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\ta) prática desportiva e paradesportiva formal e não formal como\n\t\t\t\t\tdireito de cada um;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tb) meios que facilitem o exercício de atividades desportivas e\n\t\t\t\t\tparadesportivas entre as pessoas com deficiência e suas entidades\n\t\t\t\t\trepresentativas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tc) acessibilidade às instalações desportivas dos estabelecimentos de\n\t\t\t\t\tensino, desde a educação infantil até o nível superior;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\td) inclusão de atividades desportivas e paradesportivas nos\n\t\t\t\t\testabelecimentos de ensino, desde a educação infantil até o nível\n\t\t\t\t\tsuperior;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - incentivo ao turismo para as pessoas com deficiência, através\n\t\t\t\t\tdas ações:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\ta) publicação e o uso de guias de turismo com informações acessíveis\n\t\t\t\t\tàs pessoas com deficiência e às características próprias de cada\n\t\t\t\t\tárea específica de deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tb) ampliação do turismo às pessoas com deficiência, mediante a\n\t\t\t\t\toferta de instalações hoteleiras acessíveis e de serviços adaptados\n\t\t\t\t\tde transporte.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - incentivo e criação de ações e iniciativas de lazer inclusivas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tArt. 102. Os órgãos e as entidades da administração pública estadual\n\t\t\t\t\tdireta e indireta, promotores ou financiadores de atividades\n\t\t\t\t\tdesportivas e de lazer devem concorrer técnica e financeiramente\n\t\t\t\t\tpara obtenção dos objetivos deste Capítulo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Serão prioritariamente apoiadas as manifestações\n\t\t\t\t\tdesportivas e paradesportivas de rendimento e a educacional,\n\t\t\t\t\tcompreendendo as atividades de:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - desenvolvimento de recursos humanos especializados em cada uma\n\t\t\t\t\tdas áreas de deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - promoção de competições desportivas internacionais, nacionais,\n\t\t\t\t\testaduais e municipais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - pesquisa científica, desenvolvimento tecnológico, documentação\n\t\t\t\t\te informação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - construção, ampliação, recuperação e adaptação de instalações\n\t\t\t\t\tdesportivas, paradesportivas e de lazer.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 103. Os próprios eventos esportivos estaduais terão, em seu\n\t\t\t\t\tcalendário, datas reservadas para a realização de eventos para as\n\t\t\t\t\tpessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 104. O Estado promoverá a realização dos eventos de que trata o\n\t\t\t\t\tart. 103 desta Lei, admitida a participação de entidades não\n\t\t\t\t\tgovernamentais na sua promoção.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para a elaboração da programação dos eventos serão\n\t\t\t\t\touvidas as pessoas com deficiência e as instituições que desenvolvem\n\t\t\t\t\tações para as pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 105. Os programas de cultura, de desporto, de paradesporto, de\n\t\t\t\t\tturismo e de lazer do estado deverão atender às pessoas com\n\t\t\t\t\tdeficiência, prevendo ações inclusivas, assegurada a acessibilidade\n\t\t\t\t\tdos programas e a busca da igualdade de oportunidades.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º O Poder Público instituirá programas de incentivo fiscal às\n\t\t\t\t\tpessoas físicas e jurídicas que apoiarem financeiramente os eventos\n\t\t\t\t\te as práticas desportiva, cultural, de turismo e de lazer das\n\t\t\t\t\tpessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As pessoas físicas e jurídicas que recebem recursos públicos ou\n\t\t\t\t\tincentivos para programas, projetos e ações nas áreas de cultura,\n\t\t\t\t\tdesporto, turismo e lazer deverão garantir a inclusão de pessoas com\n\t\t\t\t\tdeficiência, proporcionando local reservado e transporte específico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Em caso de evento de calendário oficial do Estado do Paraná, as\n\t\t\t\t\tempresas de transporte intermunicipal deverão assegurar a\n\t\t\t\t\tdisponibilidade de transporte acessível à pessoa com deficiência,\n\t\t\t\t\tdesde que comunicadas com antecedência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 106. Informações essenciais sobre produtos e serviços nas áreas\n\t\t\t\t\tde cultura, desporto, paradesporto, turismo e lazer deverão ter\n\t\t\t\t\tversões acessíveis às pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 107. O Poder Público colocará à disposição pela rede mundial de\n\t\t\t\t\tcomputadores, arquivos com o conteúdo de livros:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - de domínio público, conforme disposto na legislação em vigor;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - autorizados pelos detentores dos respectivos direitos autorais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - adquiridos pelo Poder Público para distribuição gratuita no\n\t\t\t\t\tâmbito de programas criados com este propósito.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os arquivos digitais aos quais se referem o caput deste artigo\n\t\t\t\t\tdeverão ser conversíveis em áudio, em sistema braile ou outro\n\t\t\t\t\tsistema de leitura digital.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Os arquivos serão colocados à disposição de bibliotecas\n\t\t\t\t\tpúblicas, de entidades de educação de pessoas com deficiência, de\n\t\t\t\t\tusuários com deficiência e das instituições de ensino públicas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 108. O Poder Executivo do Estado do Paraná garantirá a inclusão\n\t\t\t\t\tdas pessoas com deficiência, inclusive crianças, mediante instalação\n\t\t\t\t\tde equipamentos especialmente desenvolvidos para o lazer e a\n\t\t\t\t\trecreação, nas praças e parques estaduais assegurando o acesso até\n\t\t\t\t\tesses equipamentos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O Poder Executivo do Estado do Paraná priorizará as\n\t\t\t\t\tpraças e parques que possibilitem acesso e atendimento do maior\n\t\t\t\t\tnúmero de pessoas com deficiência na instalação dos equipamentos\n\t\t\t\t\treferidos no caput deste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 109. O Poder Público apoiará preferencialmente os congressos,\n\t\t\t\t\tseminários, oficinas e demais eventos científicos culturais que\n\t\t\t\t\tofereçam, mediante solicitação, apoio às pessoas com deficiência\n\t\t\t\t\tauditiva, visual e surdez, tais como tradutores e intérpretes de\n\t\t\t\t\tLibras, ledores, guias intérpretes, ou tecnologias de informação e\n\t\t\t\t\tcomunicação, tais como a transcrição eletrônica simultânea e a\n\t\t\t\t\taudiodescrição.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 110. Ficam os hotéis, restaurantes e similares, que possuam\n\t\t\t\t\tcardápios como meios informativos de seus produtos aos clientes,\n\t\t\t\t\tobrigados a produzir e dispor de exemplares em braile e fonte\n\t\t\t\t\tampliada para atendimento às pessoas com deficiência visual.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para efeitos desta Lei, considera-se cardápio como\n\t\t\t\t\tsendo o encarte portfólio informativo do rol de produtos e serviços\n\t\t\t\t\toferecidos habitualmente aos consumidores clientes dos\n\t\t\t\t\testabelecimentos comerciais referidos no caput deste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO IX\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDA ACESSIBILIDADE\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDisposições Preliminares\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 111. A acessibilidade é condição de alcance para a utilização,\n\t\t\t\t\tcom segurança e autonomia, total ou assistida, dos espaços,\n\t\t\t\t\tmobiliários e equipamentos urbanos, das edificações de uso público,\n\t\t\t\t\tcoletivo e uso privado, dos transportes e dos dispositivos, dos\n\t\t\t\t\tsistemas e dos meios de comunicação e informação, por pessoa com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A acessibilidade para as pessoas com deficiência será garantida\n\t\t\t\t\tmediante supressão de barreiras e de obstáculos nas vias e espaços\n\t\t\t\t\tpúblicos, no mobiliário urbano, na construção e reforma de edifícios\n\t\t\t\t\te nos meios de transporte e de comunicação, através das seguintes\n\t\t\t\t\tmedidas:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - elaboração de planos de acessibilidade como parte integrante dos\n\t\t\t\t\tplanos diretores e dos planos de transporte urbano integrado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - planejamento e urbanização de espaços de uso público, inclusive\n\t\t\t\t\tvias, parques e praças, de forma a torná-los acessíveis para as\n\t\t\t\t\tpessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - atendimento prioritário e diferenciado às pessoas com\n\t\t\t\t\tdeficiência, prestado pelos órgãos da administração pública, bem\n\t\t\t\t\tcomo pelas empresas e instituições privadas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - construção, ampliação, reforma e adequação das edificações de\n\t\t\t\t\tuso público, uso coletivo e uso privado, inclusive os equipamentos\n\t\t\t\t\tesportivos e de lazer, na forma desta Lei e demais normas em vigor,\n\t\t\t\t\tde forma a que se tornem acessíveis para as pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - atendimento aos princípios do desenho universal na concepção e\n\t\t\t\t\timplantação de projetos arquitetônicos, urbanísticos e de\n\t\t\t\t\tcomunicação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - reserva de espaços e lugares específicos para pessoas com\n\t\t\t\t\tdeficiência, considerando suas especificidades, em teatros, cinemas,\n\t\t\t\t\tauditórios, salas de conferência, museus, bibliotecas e ambientes de\n\t\t\t\t\tnatureza similar;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - reserva de vagas específicas, devidamente sinalizadas, para\n\t\t\t\t\tveículos que transportem pessoas com deficiência, em garagens e\n\t\t\t\t\testacionamentos nas edificações e demais espaços urbanos de uso\n\t\t\t\t\tpúblico e coletivo;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - concepção, organização, implantação e adequação dos veículos\n\t\t\t\t\te da infraestrutura de todos os sistemas de transporte coletivo,\n\t\t\t\t\tpúblico ou privado, aos requisitos de acessibilidade estabelecidos\n\t\t\t\t\tna legislação e nas demais normas de acessibilidade em vigor;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - implantação de sinalização ambiental, visual e tátil para\n\t\t\t\t\torientação de pessoas com deficiência nas edificações de uso\n\t\t\t\t\tpúblico, uso coletivo e uso privado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - adoção de medidas, nas políticas e programas habitacionais de\n\t\t\t\t\tinteresse social, que assegurem a acessibilidade das pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - utilização de instrumentos e técnicas adequadas que tornem\n\t\t\t\t\tacessíveis os sistemas de comunicação e sinalização às pessoas com\n\t\t\t\t\tdeficiência no sentido de lhes assegurar o acesso à informação, à\n\t\t\t\t\tcomunicação e aos demais direitos fundamentais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - pessoal capacitado para prestar atendimento às pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - disponibilidade de área especial para embarque e desembarque\n\t\t\t\t\tde pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIV - divulgação, em lugar visível, do direito de atendimento\n\t\t\t\t\tprioritário das pessoas com deficiência e existência de local de\n\t\t\t\t\tatendimento específico e acessível.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O direito ao tratamento diferenciado que deverá ser prestado à\n\t\t\t\t\tpessoa com deficiência, dentre outras medidas, compreende:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - mobiliário de recepção e atendimento obrigatoriamente adaptados\n\t\t\t\t\tà altura e à condição física de pessoas em cadeira de rodas,\n\t\t\t\t\tconforme estabelecido nas normas técnicas de acessibilidade em\n\t\t\t\t\tvigor;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - serviços de atendimento para pessoas com deficiência auditiva e\n\t\t\t\t\tsurdos prestados por intérpretes ou pessoas capacitadas em Libras e\n\t\t\t\t\tno trato com aquelas que assim não se comuniquem, bem como para\n\t\t\t\t\tpessoas surdo-cegas, prestados por guias intérpretes ou pessoas\n\t\t\t\t\tcapacitadas neste tipo de atendimento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - implementação de mecanismos que assegurem a acessibilidade das\n\t\t\t\t\tpessoas com deficiência visual nos portais e sítios eletrônicos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - admissão de entrada e permanência de cão-guia junto de pessoa\n\t\t\t\t\tcom deficiência ou de treinador nas edificações de uso público, uso\n\t\t\t\t\tcoletivo, mesmo que de propriedade privada, ou de uso privado,\n\t\t\t\t\tmediante apresentação da carteira de vacina atualizada do animal;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - a existência de pelo menos um telefone de atendimento adaptado\n\t\t\t\t\tpara comunicação de pessoas com deficiência auditiva e surdos pelos\n\t\t\t\t\tórgãos da"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tadministração pública direta indireta e fundacional, empresas\n\t\t\t\t\tprestadoras de serviços públicos, instituições financeiras, bem como\n\t\t\t\t\tnas demais edificações de uso público e de uso coletivo, mesmo que\n\t\t\t\t\tde propriedade privada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Consideram-se edificações de uso público aquelas administradas\n\t\t\t\t\tpor entidades da Administração Pública, Direta e Indireta, ou por\n\t\t\t\t\tempresas prestadoras de serviços públicos e destinadas ao público em\n\t\t\t\t\tgeral.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º Consideram-se edificações de uso coletivo aquelas destinadas às\n\t\t\t\t\tatividades de natureza habitacional, comercial, hoteleira, cultural,\n\t\t\t\t\tesportiva, financeira, turística, recreativa, social, religiosa,\n\t\t\t\t\teducacional, industrial e de saúde, inclusive as edificações de\n\t\t\t\t\tprestação de serviços de atividades da mesma natureza, mesmo que de\n\t\t\t\t\tpropriedade privada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 5º Consideram-se edificações de uso privado aquelas destinadas à\n\t\t\t\t\thabitação, que podem ser classificadas como unifamiliar ou\n\t\t\t\t\tmultifamiliar.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 6º Considera-se desenho universal a concepção de espaços,\n\t\t\t\t\tartefatos e produtos que visam a atender simultaneamente todas as\n\t\t\t\t\tpessoas, com diferentes características antropométricas e\n\t\t\t\t\tsensoriais, de forma autônoma, segura e confortável, constituindo-se\n\t\t\t\t\tnos elementos ou soluções que compõem a acessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 112. Para os fins deste Capítulo são estabelecidas as seguintes\n\t\t\t\t\tdefinições:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - barreiras: qualquer entrave ou obstáculo que limite, dificulte\n\t\t\t\t\tou impeça o acesso, a liberdade de movimento, a circulação segura\n\t\t\t\t\tdas pessoas, a comunicação ou o acesso à informação, classificadas\n\t\t\t\t\tem:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\ta) barreiras urbanísticas: aquelas existentes nas vias públicas e\n\t\t\t\t\tnos espaços de uso público;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tb) barreiras nas edificações: as existentes no entorno e interior\n\t\t\t\t\tdas edificações de uso público e coletivo e no entorno e áreas\n\t\t\t\t\tinternas de uso comum nas edificações de uso privado multifamiliar;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tc) barreiras nos transportes: aquelas existentes nos meios de\n\t\t\t\t\ttransportes;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\td) barreiras nas comunicações e informações: qualquer entrave ou\n\t\t\t\t\tobstáculo que dificulte ou impossibilite a expressão ou o\n\t\t\t\t\trecebimento de mensagens por intermédio dos dispositivos, dos meios\n\t\t\t\t\tou dos sistemas de comunicação, sejam ou não de massa; bem como\n\t\t\t\t\taqueles que dificultem ou impossibilitem o acesso à informação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - elemento da urbanização: qualquer componente das obras de\n\t\t\t\t\turbanização, tais como os referentes à pavimentação, ao calçamento,\n\t\t\t\t\tao saneamento, aos encanamentos para esgotos, à distribuição de\n\t\t\t\t\tenergia elétrica, à iluminação pública, ao abastecimento e à\n\t\t\t\t\tdistribuição de água, ao paisagismo e os que materializam as\n\t\t\t\t\tindicações do planejamento urbanístico;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - mobiliário urbano: o conjunto de objetos existentes nas vias e\n\t\t\t\t\tespaços públicos, superpostos ou adicionados aos elementos da\n\t\t\t\t\turbanização ou da edificação, de forma que sua modificação ou\n\t\t\t\t\ttraslado não provoque alterações substanciais nestes elementos, tais\n\t\t\t\t\tcomo semáforos, postes de sinalização e similares, cabines\n\t\t\t\t\ttelefônicas, fontes públicas, lixeiras, toldos, marquises, quiosques\n\t\t\t\t\te quaisquer outros de natureza análoga.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Elementos de Urbanização\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 113. O planejamento e a urbanização das vias públicas, dos\n\t\t\t\t\tparques e dos demais espaços de uso público deverão ser concebidos e\n\t\t\t\t\texecutados de forma a torná-los acessíveis para as pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 114. As vias públicas, os parques e os demais espaços de uso\n\t\t\t\t\tpúblico existentes, assim como as respectivas instalações de\n\t\t\t\t\tserviços e mobiliários urbanos deverão ser adaptados, obedecendo-se\n\t\t\t\t\tordem de prioridade que vise à maior eficiência das modificações, no\n\t\t\t\t\tsentido de promover a plena acessibilidade às pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 115. Os parques de diversões, públicos e privados, devem\n\t\t\t\t\tadaptar, no mínimo, 5% (cinco por cento) de cada brinquedo e de seus\n\t\t\t\t\tequipamentos e identificá-los para possibilitar sua utilização por\n\t\t\t\t\tpessoas com deficiência, tanto quanto tecnicamente possível.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 116. O projeto e o traçado dos elementos de urbanização,\n\t\t\t\t\tpúblicos e privados, de uso comunitário, nestes compreendidos os\n\t\t\t\t\titinerários e as passagens de pedestres, os percursos de entrada e\n\t\t\t\t\tde saída de veículos, as escadas e rampas, deverão observar os\n\t\t\t\t\tparâmetros estabelecidos pelas normas técnicas de acessibilidade em\n\t\t\t\t\tvigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 117. Os parques, praças, jardins e espaços livres públicos\n\t\t\t\t\tdeverão dispor, pelo menos, de duas instalações sanitárias\n\t\t\t\t\tacessíveis, sendo uma masculina e uma feminina, em conformidade com\n\t\t\t\t\tas normas técnicas de acessibilidade em vigor, incluindo\n\t\t\t\t\tidentificação e rota acessível.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 118. Em todas as áreas de estacionamento de veículos,\n\t\t\t\t\tlocalizadas em vias ou em espaços públicos, deverão ser reservadas\n\t\t\t\t\tvagas próximas dos acessos de circulação de pedestres, devidamente\n\t\t\t\t\tsinalizadas, para veículos que transportem pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As vagas às quais se refere o caput deste artigo\n\t\t\t\t\tdeverão ser em número equivalente a 2% (dois por cento) do total,\n\t\t\t\t\tgarantida, no mínimo, uma vaga, devidamente sinalizada e com as\n\t\t\t\t\tespecificações técnicas de desenho e traçado de acordo com as normas\n\t\t\t\t\ttécnicas em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 119. Os estacionamentos, públicos e privados, e os fornecedores\n\t\t\t\t\tde serviços de manobra e guarda de veículos em geral estão obrigados\n\t\t\t\t\ta conceder aos veículos automotores utilizados por pessoas com\n\t\t\t\t\tdeficiência, período mínimo de gratuidade do pagamento de tarifa\n\t\t\t\t\tequivalente ao dobro daquele concedido aos demais veículos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os estabelecimentos que não dispuserem de período mínimo de\n\t\t\t\t\tgratuidade do pagamento de tarifa deverão conceder o período de\n\t\t\t\t\ttrinta minutos para a pessoa com deficiência que usufruiu do serviço\n\t\t\t\t\tde estacionamento, assegurando seu deslocamento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O detalhamento técnico do disposto no caput deste artigo é\n\t\t\t\t\tdefinido em regulamento, nos termos da Lei Federal nº 10.098, de 19\n\t\t\t\t\tde dezembro de 2000.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 120. Os sinais de tráfego, semáforos, postes de iluminação ou\n\t\t\t\t\tquaisquer outros elementos verticais de sinalização que devam ser\n\t\t\t\t\tinstalados em itinerário ou espaço de acesso para pedestres deverão\n\t\t\t\t\tser dispostos de forma a não dificultar ou impedir a circulação, e\n\t\t\t\t\tde modo que possam ser utilizados com a máxima comodidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 121. Os semáforos para pedestres instalados nas vias públicas\n\t\t\t\t\tdeverão estar equipados com mecanismo que emita sinal sonoro suave,\n\t\t\t\t\tintermitente e sem estridência ou com mecanismo alternativo, que\n\t\t\t\t\tsirva de guia ou orientação para a travessia de pessoas com\n\t\t\t\t\tdeficiência visual.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A adequação dos semáforos conforme previsto neste\n\t\t\t\t\tartigo será efetuada conforme disponibilidade orçamentária, de forma\n\t\t\t\t\tgradativa, sendo, para tanto, considerados prioritários os locais\n\t\t\t\t\tpróximos às instituições"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tvoltadas às pessoas com deficiência, periculosidade dos cruzamentos\n\t\t\t\t\te a intensidade de tráfego de veículos automotores.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 122. Os elementos do mobiliário urbano deverão ser projetados e\n\t\t\t\t\tinstalados em locais que permitam serem utilizados pelas pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 123. A infração às disposições desta Seção acarretará ao\n\t\t\t\t\tresponsável as seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequar no prazo de quinze\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - multa de 680 (seiscentos e oitenta) UPF/PR no caso da não\n\t\t\t\t\tadequação no prazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de 1360 (mil trezentos e sessenta) UPF/PR, em caso de\n\t\t\t\t\treincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade a Estabelecimentos\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDa Adequação dos Guichês\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 124. Os terminais rodoviários, estações de transporte, cinemas,\n\t\t\t\t\tteatros, casa de shows, agências bancárias, correios, lotéricas ou\n\t\t\t\t\tórgãos públicos e estabelecimentos de acesso coletivo ou todo e\n\t\t\t\t\tqualquer outro estabelecimento que utilize guichês de atendimento no\n\t\t\t\t\tEstado do Paraná deverão manter ao menos um de seus guichês adequado\n\t\t\t\t\tà altura e condizentes às necessidades das pessoas com deficiência\n\t\t\t\t\tque utilizam cadeiras de rodas, para que tenham um melhor contato\n\t\t\t\t\tvisual e de comunicação com o funcionário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 125. O descumprimento do disposto nesta Subseção sujeitará os\n\t\t\t\t\tresponsáveis pela infração ao pagamento de multa, correspondente a\n\t\t\t\t\t35 (trinta e cinco) UPF/PR, não os desobrigando de seu posterior\n\t\t\t\t\tcumprimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Em caso de reincidência, após decorrido o prazo de\n\t\t\t\t\ttrinta dias, contados a partir da aplicação da primeira multa, o\n\t\t\t\t\tvalor da multa a que se refere o caput deste artigo será dobrado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDo Acesso aos Elevadores\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 126. Veda qualquer forma de discriminação à pessoa com\n\t\t\t\t\tdeficiência no acesso aos elevadores de todos os edifícios públicos\n\t\t\t\t\tou particulares, comerciais, industriais e residenciais\n\t\t\t\t\tmultifamiliares existentes no Estado do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os responsáveis legais pela administração dos\n\t\t\t\t\tedifícios citados no caput deste artigo ficam autorizados a\n\t\t\t\t\tregulamentar o acesso a esses imóveis, assim como a circulação\n\t\t\t\t\tdentro deles e a utilização de suas áreas de uso comum e abertas ao\n\t\t\t\t\tuso público, por meio de regras gerais e impessoais não\n\t\t\t\t\tdiscriminatórias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 127. Todos os elevadores deverão possuir botoeiras internas e\n\t\t\t\t\texternas com informações em braile, sistema de áudio informando o\n\t\t\t\t\tandar e o sentido de deslocamento e piso tátil de alerta, de acordo\n\t\t\t\t\tcom as normas técnicas em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 128. Estabelece que, para maior conforto, segurança e igualdade\n\t\t\t\t\tentre os usuários, o elevador social é o meio usual de transporte de\n\t\t\t\t\tpessoas com deficiência que utilizem as dependências dos edifícios,\n\t\t\t\t\tindependentemente"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tdo motivo pelo qual o fazem, desde que não estejam deslocando\n\t\t\t\t\tcargas, para as quais podem ser utilizados os elevadores especiais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 129. Para conferir efetividade e o conhecimento das disposições\n\t\t\t\t\tda presente Subseção, fica determinada a obrigatoriedade da\n\t\t\t\t\tcolocação de avisos no interior dos edifícios.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\t§ 1º Os avisos de que tratam o caput deste artigo devem\n\t\t\t\t\tconfigurar-se em forma de cartaz, placa ou plaqueta com os seguintes\n\t\t\t\t\tdizeres: "É vedada qualquer forma de discriminação em virtude\n\t\t\t\t\tde raça, sexo, cor, origem, condição social, idade, deficiência ou\n\t\t\t\t\tdoença não contagiosa por contato social no acesso aos elevadores\n\t\t\t\t\tdeste edifício".\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Fica o responsável pelo edifício, administrador ou síndico,\n\t\t\t\t\tconforme for o caso, obrigado a colocar na entrada do edifício e de\n\t\t\t\t\tforma bem visível o aviso de que trata este artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 130. Os responsáveis pelo descumprimento do disposto nesta\n\t\t\t\t\tSubseção ficarão sujeitos às seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequar no prazo de trinta\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - multa de 35 (trinta e cinco) UPF/PR no caso da não adequação no\n\t\t\t\t\tprazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de setenta UPF/PR, em caso de reincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Disponibilidade de Cadeiras de Rodas\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 131. É obrigatória, no âmbito do Estado do Paraná, a\n\t\t\t\t\tdisponibilização de cadeiras de ao menos duas cadeiras de rodas,\n\t\t\t\t\tdentro das normas técnicas e de segurança, em todos os\n\t\t\t\t\testabelecimentos públicos ou privados de uso coletivo, inclusive nas\n\t\t\t\t\tagências bancárias.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 132. Todos os estabelecimentos públicos ou privados de uso\n\t\t\t\t\tcoletivo, inclusive as agências bancárias, deverão assegurar o\n\t\t\t\t\tatendimento das pessoas com deficiência física, em locais de fácil\n\t\t\t\t\tacesso à utilização das cadeiras de rodas, bem como dar publicidade\n\t\t\t\t\tà existência dessa facilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 133. O descumprimento do disposto nesta Subseção sujeitará os\n\t\t\t\t\tresponsáveis pela infração ao pagamento de multa, correspondente a\n\t\t\t\t\t35 (trinta e cinco) UPF/PR - Unidade Padrão Fiscal do Paraná, não os\n\t\t\t\t\tdesobrigando de seu posterior cumprimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Em caso de reincidência, após decorrido o prazo de\n\t\t\t\t\ttrinta dias contados a partir da aplicação da primeira multa, o\n\t\t\t\t\tvalor da multa a que se refere o caput deste artigo será dobrado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Assentos nas Áreas de Embarque e Desembarque\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 134. Será assegurada às pessoas com deficiência a participação\n\t\t\t\t\tno percentual de 10% (dez por cento) dos assentos reservados nas\n\t\t\t\t\táreas de embarque e desembarque dos terminais rodoviários e\n\t\t\t\t\trodoferroviários localizados no Estado, nos termos da Lei nº 16.397,\n\t\t\t\t\tde 10 de fevereiro de 2010.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 135. Os assentos de que trata o art. 134 desta Lei terão\n\t\t\t\t\tidentificação específica que informe a sua destinação preferencial.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Estabelecimentos Públicos ou de Uso Coletivo\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 136. A construção, ampliação ou reforma de edifícios públicos\n\t\t\t\t\tou privados destinados ao uso coletivo deverão ser executadas de\n\t\t\t\t\tmodo que sejam ou se tornem acessíveis às pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para os fins do disposto neste artigo, na\n\t\t\t\t\tconstrução, ampliação ou reforma de edifícios públicos ou privados\n\t\t\t\t\tdestinados ao uso coletivo deverão ser observados, pelo menos, os\n\t\t\t\t\tseguintes requisitos de acessibilidade:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - nas áreas externas ou internas da edificação, destinadas à\n\t\t\t\t\tgaragem e ao estacionamento de uso público, deverão ser reservadas\n\t\t\t\t\t5% (cinco por cento) das vagas próximas dos acessos de circulação de\n\t\t\t\t\tpedestres, devidamente sinalizadas e demarcadas, conforme legislação\n\t\t\t\t\te normas técnicas em vigor, para veículos que transportem pessoas\n\t\t\t\t\tcom deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - um dos acessos ao interior da edificação deverá estar livre de\n\t\t\t\t\tbarreiras arquitetônicas e de obstáculos que impeçam ou dificultem a\n\t\t\t\t\tacessibilidade de pessoa com deficiência, sendo preferencialmente o\n\t\t\t\t\tprincipal nos casos de ampliação ou reforma e obrigatoriamente o\n\t\t\t\t\tprincipal nos casos de nova construção;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - deverá ser elencada rota acessível em percursos que comuniquem\n\t\t\t\t\thorizontal e verticalmente todas as dependências e serviços do\n\t\t\t\t\tedifício, entre si e com o exterior, onde se devem cumprir os\n\t\t\t\t\trequisitos de acessibilidade de que trata esta Seção;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - os edifícios deverão dispor, pelo menos, de duas instalações\n\t\t\t\t\tsanitárias acessíveis por pavimento, sendo uma masculina e uma\n\t\t\t\t\tfeminina;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 137. Os órgãos da administração direta, indireta, autarquias,\n\t\t\t\t\tempresas de economia mista e entidades privadas que prestem\n\t\t\t\t\tatendimento diretamente ao público ficam obrigados a implementar\n\t\t\t\t\tmodificações físicas inclusive nas áreas destinadas ao atendimento\n\t\t\t\t\tpúblico, assim como soluções técnicas nos equipamentos de\n\t\t\t\t\tautoatendimento, com vistas à acessibilidade e uso por pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para o efetivo cumprimento do disposto no caput\n\t\t\t\t\tdeste artigo, entende-se como:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - modificações físicas: as adequações necessárias nas áreas\n\t\t\t\t\tdestinadas ao atendimento ao público para a eliminação de qualquer\n\t\t\t\t\tentrave ou obstáculo que limite e impeça o acesso de pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - soluções técnicas: as alterações necessárias nos equipamentos e\n\t\t\t\t\tprogramas para o uso, sem restrição, das pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 138. No caso das edificações públicas já existentes, deverá ser\n\t\t\t\t\tobservado o prazo previsto na legislação vigente para o órgão\n\t\t\t\t\tresponsável apresentar a relação de todas as edificações existentes\n\t\t\t\t\tsob sua responsabilidade, indicando as que atendem e as que não\n\t\t\t\t\tatendem as especificações de acessibilidade e um plano de obras para\n\t\t\t\t\ta execução das adequações necessárias, contendo estimativa de\n\t\t\t\t\tcustos, indicação de previsão no Plano Plurianual - PPA e na Lei\n\t\t\t\t\tOrçamentária Anual - LOA e programa de execução de obras.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDas Edificações de Uso Público\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 139. A construção, ampliação, reforma ou adequação de edifícios\n\t\t\t\t\tpúblicos do Estado do Paraná, incluindo os de administração\n\t\t\t\t\tindireta, deve atender aos preceitos da acessibilidade na\n\t\t\t\t\tinterligação de todas as partes de"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tuso comum ou abertas ao público, conforme os padrões das normas\n\t\t\t\t\ttécnicas de acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 140. Os desníveis das áreas de circulação interna ou externa\n\t\t\t\t\tserão transpostos por meio de rampa em conformidade com as normas\n\t\t\t\t\ttécnicas vigentes ou equipamento eletromecânico de deslocamento\n\t\t\t\t\tvertical, quando não for possível outro acesso mais cômodo para\n\t\t\t\t\tpessoas com deficiência físico motora.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. No caso das edificações já existentes e que ainda\n\t\t\t\t\tnão atendam às especificações de acessibilidade, deverá ser\n\t\t\t\t\tobservado o prazo definido na legislação vigente para garantir a\n\t\t\t\t\tacessibilidade de pessoas com deficiência, especialmente com\n\t\t\t\t\tdeficiência físico motora.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 141. A instalação de novos elevadores ou sua adaptação, quando\n\t\t\t\t\thaja obrigatoriedade da presença de elevadores, deve atender ao\n\t\t\t\t\tdisposto no art. 126 desta Lei, bem como aos padrões das normas\n\t\t\t\t\ttécnicas de acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º No caso da instalação de elevadores novos ou de troca dos já\n\t\t\t\t\texistentes, pelo menos um deles terá cabine que permita acesso e\n\t\t\t\t\tmovimentação cômoda de pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Os edifícios a serem construídos com mais de um pavimento, além\n\t\t\t\t\tdo pavimento de acesso, deverão dispor de especificações técnicas e\n\t\t\t\t\tde projeto que facilitem a instalação de equipamentos\n\t\t\t\t\teletromecânicos de deslocamento vertical para uso das pessoas com\n\t\t\t\t\tdeficiência, especialmente com deficiência físico motora.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º As especificações técnicas, sob responsabilidade do autor do\n\t\t\t\t\tprojeto e do responsável técnico, as quais se referem o § 2º deste\n\t\t\t\t\tartigo devem atender:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - à indicação em planta aprovada pelo poder municipal do local\n\t\t\t\t\treservado para a instalação do equipamento eletromecânico,\n\t\t\t\t\tdevidamente assinada pelo autor do projeto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - à indicação da opção pelo tipo de equipamento, como elevador,\n\t\t\t\t\testeira, plataforma ou similar;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - à indicação das dimensões internas e demais aspectos da cabine\n\t\t\t\t\tdo equipamento a ser instalado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - às demais especificações em nota na própria planta, tais como a\n\t\t\t\t\texistência e as medidas de botoeira, espelho, informação de voz, bem\n\t\t\t\t\tcomo a garantia de responsabilidade técnica de que a estrutura da\n\t\t\t\t\tedificação suporta a implantação do equipamento escolhido.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 142. A construção, ampliação, reforma ou adequação deve dispor\n\t\t\t\t\tde sanitários acessíveis destinados ao uso por pessoa com\n\t\t\t\t\tdeficiência, sendo distribuídos na razão de, no mínimo, uma cabine\n\t\t\t\t\tpara cada sexo em cada pavimento da edificação, com entrada\n\t\t\t\t\tindependente dos sanitários coletivos, obedecendo às normas técnicas\n\t\t\t\t\tde acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As edificações já existentes deverão garantir pelo\n\t\t\t\t\tmenos um banheiro acessível por pavimento, com entrada independente,\n\t\t\t\t\tdistribuindo-se seus equipamentos e acessórios de modo que possam\n\t\t\t\t\tser utilizados pelas pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 143. O Sistema Penal do Estado do Paraná deverá possuir\n\t\t\t\t\tinstalações e celas aptas a receber e abrigar as pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDas Edificações de Uso Coletivo\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Estabelecimentos Financeiros\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 144. As instituições financeiras e bancárias que prestem\n\t\t\t\t\tatendimento diretamente ao público ficam obrigadas a implementar\n\t\t\t\t\tmodificações físicas nas áreas destinadas ao atendimento público,\n\t\t\t\t\tassim como soluções técnicas nos equipamentos de autoatendimento,\n\t\t\t\t\tcom vistas à acessibilidade e ao uso de pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para o efetivo cumprimento do disposto no caput\n\t\t\t\t\tdeste artigo, entende-se como:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - modificações físicas: as adequações necessárias nas áreas\n\t\t\t\t\tdestinadas ao atendimento ao público para a eliminação de qualquer\n\t\t\t\t\tentrave ou obstáculo que limite e impeça o acesso de pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - soluções técnicas: as alterações necessárias nos equipamentos e\n\t\t\t\t\tprogramas para o uso sem restrição das pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 145. Os estabelecimentos financeiros com agências no Estado do\n\t\t\t\t\tParaná ficam obrigados a possuir instalações sanitárias separadas\n\t\t\t\t\tpor sexo e compatíveis com a pessoa com deficiência, para uso de\n\t\t\t\t\tseus clientes, conforme normas técnicas de acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os estabelecimentos financeiros referidos no caput\n\t\t\t\t\tdeste artigo compreendem os bancos, caixas econômicas, sociedades de\n\t\t\t\t\tcrédito, associações de poupança e suas agências, subagências e\n\t\t\t\t\tseções, bem como as conveniadas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 146. Os sanitários devidamente compatíveis com a pessoa com\n\t\t\t\t\tdeficiência física deverão estar disponíveis nos mesmos horários de\n\t\t\t\t\tfuncionamento dos estabelecimentos financeiros.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 147. Todos os estabelecimentos financeiros, nas dependências\n\t\t\t\t\tdestinadas para atendimento ao público, deverão possuir bebedouros,\n\t\t\t\t\tobservando-se as normas de acessibilidade para a pessoa com\n\t\t\t\t\tdeficiência física, sendo disponibilizados copos descartáveis aos\n\t\t\t\t\tclientes.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 148. É obrigatória a instalação de caixas de autoatendimento e\n\t\t\t\t\tguichês de atendimento acessíveis, assegurando sua vinculação às\n\t\t\t\t\trotas acessíveis.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 149. É obrigatória a disponibilização de caixas de\n\t\t\t\t\tautoatendimento em sistema braile e áudio para pessoa com\n\t\t\t\t\tdeficiência visual ou cega em todas as agências bancárias do Estado\n\t\t\t\t\tdo Paraná, bem como em todo e qualquer tipo de rede bancária.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As instruções para usuário com deficiência visual\n\t\t\t\t\tdeverão ser feitas por meio de dispositivo de áudio, mediante\n\t\t\t\t\tutilização de fones de ouvido e teclado mecânico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 150. O acesso do deficiente visual ao caixa eletrônico de que\n\t\t\t\t\ttrata o art. 148 desta Lei deverá ser através de piso tátil, de\n\t\t\t\t\tacordo com as normas técnicas de acessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 151. Os estabelecimentos bancários que infringirem o disposto\n\t\t\t\t\tnesta subseção, ficarão sujeitos às seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequar no prazo de quinze\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - multa de 865 (oitocentas e sessenta e cinco) UPF/PR no caso da\n\t\t\t\t\tnão adequação no prazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de 1730 (mil setecentos e trinta) UPF/PR, em caso de\n\t\t\t\t\treincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Hotéis, Motéis e Similares\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 152. Os hotéis, motéis e estabelecimentos similares\n\t\t\t\t\testabelecidos no Estado do Paraná ficam obrigados a adaptar suas\n\t\t\t\t\tinstalações a fim de garantir o acesso da pessoa com deficiência,\n\t\t\t\t\treservando-lhes 5% (cinco por cento) de seus quartos ou\n\t\t\t\t\tapartamentos, em qualquer número de unidades, sendo no mínimo uma\n\t\t\t\t\tunidade adaptada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º As adaptações de que tratam o caput deste artigo serão\n\t\t\t\t\tdefinidas em conformidade com o disposto nas normas técnicas de\n\t\t\t\t\tacessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Os estabelecimentos localizados em prédios que não consigam\n\t\t\t\t\tatender às exigências previstas neste artigo devem apresentar\n\t\t\t\t\talternativas para análise junto ao órgão competente, no prazo máximo\n\t\t\t\t\tde quinze dias a partir da data de notificação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 153. Os hotéis, motéis e similares que infringirem o disposto\n\t\t\t\t\tnesta Subseção ficarão sujeitos às seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequarem no prazo de trinta\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - multa de 35 (trinta e cinco) UPF/PR - no caso da não adequação\n\t\t\t\t\tno prazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de setenta UPF/PR, em caso de reincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDos Shopping Centers e Similares\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 154. Os shopping centers e estabelecimentos similares em todo o\n\t\t\t\t\tEstado do Paraná deverão, obrigatoriamente, disponibilizar cadeiras\n\t\t\t\t\tde rodas para pessoas com deficiência física, devendo haver ao menos\n\t\t\t\t\tcinco unidades disponíveis, em conformidade com as normas de\n\t\t\t\t\tacessibilidade em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 155. O fornecimento das cadeiras de rodas, referido no art. 154\n\t\t\t\t\tdesta Lei, será gratuito, sem qualquer ônus para o usuário, cabendo\n\t\t\t\t\texclusivamente aos estabelecimentos comerciais mencionados o seu\n\t\t\t\t\tfornecimento e manutenção em perfeitas condições de uso.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 156. Os estabelecimentos obrigados deverão afixar em suas\n\t\t\t\t\tdependências internas, inclusive nas garagens, cartazes ou placas\n\t\t\t\t\tindicativas dos locais onde as cadeiras de rodas se encontram\n\t\t\t\t\tdisponíveis aos usuários.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 157. Os shopping centers e os restaurantes estabelecidos no\n\t\t\t\t\tEstado do Paraná deverão destinar 5% (cinco por cento) dos lugares\n\t\t\t\t\tacessíveis para refeição nas praças de alimentação para uso\n\t\t\t\t\tpreferencial das pessoas com deficiência, preferencialmente com\n\t\t\t\t\tassentos móveis, em rota acessível.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os lugares reservados para o cumprimento do\n\t\t\t\t\tdisposto neste artigo deverão conter identificação visível e\n\t\t\t\t\tacessível.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 158. Os centros comerciais, shopping centers, hiper e\n\t\t\t\t\tsupermercados no âmbito do Estado, deverão fornecer, gratuitamente,\n\t\t\t\t\tveículos motorizados para facilitar a locomoção de pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os estabelecimentos referidos neste artigo\n\t\t\t\t\tafixarão, em local de grande visibilidade, nas dependências externas\n\t\t\t\t\te internas, placas indicativas dos postos de retirada dos veículos\n\t\t\t\t\tmotorizados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 159. O estabelecimento que violar o previsto nesta Subseção\n\t\t\t\t\tficará sujeito às seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequar no prazo de trinta\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tII - multa no valor de 35 (trina e cinco) UPF/PR no caso da não\n\t\t\t\t\tadequação no prazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de setenta UPF/PR em caso de reincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção VI\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDas Edificações de Uso Privado\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 160. Os edifícios de uso coletivo privado em que seja\n\t\t\t\t\tobrigatória a instalação de elevadores deverão ser construídos\n\t\t\t\t\tatendendo aos seguintes requisitos mínimos de acessibilidade:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - percurso acessível que una as unidades habitacionais com o\n\t\t\t\t\texterior e com as dependências de uso comum;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - percurso acessível que una a edificação à via pública, às\n\t\t\t\t\tedificações e aos serviços anexos de uso comum e aos edifícios\n\t\t\t\t\tvizinhos; e\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - cabine do elevador e respectiva porta de entrada acessíveis\n\t\t\t\t\tpara pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 161. Os edifícios a serem construídos com mais de um pavimento\n\t\t\t\t\talém do pavimento de acesso, à exceção das habitações unifamiliares,\n\t\t\t\t\te que não estejam obrigados à instalação de elevador, deverão dispor\n\t\t\t\t\tde especificações técnicas e de projeto que facilitem a instalação\n\t\t\t\t\tde um elevador adaptado, devendo os demais elementos de uso comum\n\t\t\t\t\tdestes edifícios atenderem aos requisitos de acessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção VII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade aos Empreendimentos de Interesse Turístico\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 162. Os empreendimentos desenvolvidos no Estado do Paraná que\n\t\t\t\t\tenvolvam interesse turístico, de lazer ou negócios, eventos, feiras,\n\t\t\t\t\tconvenções e afins deverão adequar seus projetos arquitetônicos e de\n\t\t\t\t\tengenharia consoante as normas e especificações de adaptação e\n\t\t\t\t\tacessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para fins de identificação, considera-se\n\t\t\t\t\tempreendimento de interesse turístico qualquer ação que se estruture\n\t\t\t\t\tcom objetivos de recepção, atendimento, entretenimento e\n\t\t\t\t\thospitalidade destinados ao visitante ou residente tais como:\n\t\t\t\t\teventos gerais e turísticos, campanhas promocionais, programas de\n\t\t\t\t\tcapacitação e preparação de recursos humanos, atividades\n\t\t\t\t\tempresariais com projetos arquitetônicos e de engenharia como meios\n\t\t\t\t\tde hospedagem, alimentação e entretenimento, centros de eventos e\n\t\t\t\t\tconvenções tradicionais ou alternativos e outros que venham a sofrer\n\t\t\t\t\tadaptação para este fim, centrais de informação e atendimento ao\n\t\t\t\t\tvisitante e terminais de transportes modais utilizados para fins\n\t\t\t\t\tturísticos e recreacionais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 163. As pessoas com deficiência deverão gozar de 5% (cinco por\n\t\t\t\t\tcento) de suas acomodações adaptadas, sendo, no mínimo, uma\n\t\t\t\t\tacomodação adaptada nos empreendimentos relativos ao meio de\n\t\t\t\t\thospedagem com possibilidade e condições de utilização com segurança\n\t\t\t\t\te autonomia dos espaços, inclusive nos banheiros e ainda, dispor de\n\t\t\t\t\tequipamentos, mobiliário e pessoal capacitados para assegurar a\n\t\t\t\t\trecepção e a acessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As referidas adaptações deverão contemplar todos os\n\t\t\t\t\ttipos de deficiência em conformidade com a presente Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("strong", [
                        _vm._v(
                          "(Artigo acrescentado pela Lei Nº 19062 DE 27/06/2017):"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 163-A. Os eventos organizados em espaços públicos ou privados\n\t\t\t\t\tem que haja instalação de banheiros químicos deverão contar com\n\t\t\t\t\tunidades acessíveis a pessoas com deficiência ou com mobilidade\n\t\t\t\t\treduzida.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O número mínimo de banheiros adaptados\n\t\t\t\t\tcorresponderá a 10% (dez por cento) do total, garantindo-se pelo\n\t\t\t\t\tmenos uma unidade adaptada caso a aplicação do percentual resulte em\n\t\t\t\t\tfração inferior a um.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 164. Os municípios deverão regulamentar a presente Seção no que\n\t\t\t\t\tconcerne à adaptação arquitetônica, assegurando a acessibilidade,\n\t\t\t\t\tnos"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tempreendimentos de interesse turístico já existentes, observando\n\t\t\t\t\tsempre as legislações vigentes.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 165. Para os fins desta Seção entende-se por:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - adaptações arquitetônicas: quaisquer alterações promovidas em\n\t\t\t\t\tedificações com objetivo de permitir à pessoa com deficiência\n\t\t\t\t\tsuperar as barreiras da mobilidade, bem como entrave ou obstáculo\n\t\t\t\t\tque limite ou impeça o acesso, a liberdade de movimento e a\n\t\t\t\t\tcirculação com segurança.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - adaptações das áreas comuns: modificações promovidas em locais\n\t\t\t\t\tcomo banheiros, estacionamentos, pistas de dança, quadras, áreas de\n\t\t\t\t\tlazer e esportes, arquibancadas e áreas de assentos, decks (saunas,\n\t\t\t\t\tpiscinas), áreas de hidromassagem, bares, restaurantes e similares,\n\t\t\t\t\tou onde mais aconteça fluxo de visitantes e turistas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 166. Os empreendimentos turísticos novos e aqueles que\n\t\t\t\t\testiverem adaptados e adequados ao conjunto de recomendações\n\t\t\t\t\tindicadas em legislação própria e na legislação específica que\n\t\t\t\t\tatendam à recepção e à acessibilidade das pessoas com deficiência\n\t\t\t\t\tdeverão adotar a identificação geral internacional convencionada e a\n\t\t\t\t\tespecificada pelo Ministério do Turismo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 167. O Estado do Paraná será devidamente dividido em áreas de\n\t\t\t\t\tmaior concentração turística para realização de empreendimentos de\n\t\t\t\t\tcaráter público ou privado voltados para o turismo local, visando à\n\t\t\t\t\tconscientização das pessoas com deficiência e demais interessadas\n\t\t\t\t\tpor meio da indicação dos acessos e das possibilidades de utilização\n\t\t\t\t\tpelas mesmas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 168. A liberação de apoio, recursos e benefícios\n\t\t\t\t\tinstitucionais, técnicos ou financeiros destinados aos\n\t\t\t\t\tempreendimentos de interesse turístico promovidos por empresários,\n\t\t\t\t\tprefeituras, entidades ou comunidades, provenientes de órgãos\n\t\t\t\t\tvoltados para o setor em nível estadual, só ocorrerá após a\n\t\t\t\t\tverificação de adequação ao conjunto de recomendações indicadas na\n\t\t\t\t\tlegislação própria e específica em relação a espaços físicos,\n\t\t\t\t\tmobiliários, equipamentos e pessoa capacitada para o atendimento e\n\t\t\t\t\tacessibilidade da pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 169. Nos alvarás a serem concedidos para novos empreendimentos\n\t\t\t\t\tdeverá constar a obrigatoriedade de atendimento às normas técnicas\n\t\t\t\t\tde acessibilidade e legislações em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 170. As pessoas jurídicas de direito público ou privado que não\n\t\t\t\t\tcumprirem o disposto nesta Seção, serão notificadas em primeira\n\t\t\t\t\tavaliação e em seguida, caso não cumpridas as exigências iniciais,\n\t\t\t\t\testarão sujeitas a multas que variam de 35 (trinta e cinco) a\n\t\t\t\t\tnovecentas UPF/PR, dependendo das especificações do empreendimento e\n\t\t\t\t\tdo evento ou do local a ser contemplado com a utilização dos\n\t\t\t\t\tvisitantes e turistas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 171. O Poder Executivo do Estado do Paraná por meio de sua\n\t\t\t\t\tdesignação, contará com órgão competente para a fiscalização e\n\t\t\t\t\tcontrole da aplicabilidade pelo disposto nesta Seção.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção VIII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade aos Bens Culturais Imóveis\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 172. As soluções destinadas à eliminação, redução ou superação\n\t\t\t\t\tde barreira na promoção da acessibilidade a todos os bens culturais\n\t\t\t\t\timóveis devem estar de acordo com as exigências dos órgãos federais\n\t\t\t\t\te estaduais responsáveis pelo patrimônio histórico.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Deverá ser garantido o acesso a todos os bens\n\t\t\t\t\tculturais imóveis no Estado do Paraná, e quando não seja possível,\n\t\t\t\t\tdeverá ser garantida a informação em formato acessível, inclusive\n\t\t\t\t\tcom mapa tátil.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 173. A Secretaria de Estado responsável pela tutela dos bens\n\t\t\t\t\tculturais imóveis deverá, no prazo de noventa dias, instituir comitê\n\t\t\t\t\tcomposto por técnicos das áreas de acessibilidade e patrimônio\n\t\t\t\t\thistórico, com a finalidade de verificar a viabilidade de adequação\n\t\t\t\t\tàs normas e legislações de acessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 174. As pessoas jurídicas de direito público ou privado que não\n\t\t\t\t\tcumprirem o disposto nesta Lei, serão notificadas em primeira\n\t\t\t\t\tavaliação e em seguida, caso não cumpridas as exigências iniciais,\n\t\t\t\t\testarão sujeitas a multas que variam 35 (trinta e cinco) a\n\t\t\t\t\tnovecentas UPF/PR dependendo das especificações do empreendimento e\n\t\t\t\t\tdo evento ou do local a ser contemplado com a utilização dos\n\t\t\t\t\tvisitantes e turistas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção IX\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDo Símbolo Internacional de Acesso\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 175. É obrigatória a colocação, de forma visível, do\n\t\t\t\t\t"Símbolo Internacional de Acesso", em todos os locais que\n\t\t\t\t\tpossibilitem acesso, circulação e utilização por pessoas com\n\t\t\t\t\tdeficiência, e em todos os serviços que forem postos à sua\n\t\t\t\t\tdisposição ou que possibilitem o seu uso.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 176. Só é permitida a colocação do símbolo em edificações:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - que ofereçam condições de acesso natural ou por meio de rampas\n\t\t\t\t\tconstruídas com as especificações contidas na legislação pertinente\n\t\t\t\t\tem vigor;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - cujas formas de acesso e circulação não estejam impedidas às\n\t\t\t\t\tpessoas com deficiência em cadeira de rodas ou aparelhos ortopédicos\n\t\t\t\t\tem virtude da existência de degraus, soleiras e demais obstáculos\n\t\t\t\t\tque dificultem sua locomoção;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - que tenham porta de entrada com largura mínima de 90 cm\n\t\t\t\t\t(noventa centímetros);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - que tenham corredores ou passagens com largura mínima de 120 cm\n\t\t\t\t\t(cento e vinte centímetros);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - que tenham elevador cuja largura da porta seja, no mínimo, de\n\t\t\t\t\t100 cm (cem centímetros); e;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - que tenham sanitários apropriados ao uso da pessoa com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 177. Só é permitida a colocação do "Símbolo Internacional\n\t\t\t\t\tde Acesso" na identificação de serviços cujo uso seja\n\t\t\t\t\tcomprovadamente adequado às pessoas com deficiência.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 178. Observado o disposto nos artigos 176 e 177 desta Lei, é\n\t\t\t\t\tobrigatória a colocação do símbolo na identificação dos seguintes\n\t\t\t\t\tlocais e serviços, dentre outros de interesse comunitário:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - sede dos Poderes Executivo, Legislativo e Judiciário;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - prédios onde funcionam órgãos ou entidades públicas, quer de\n\t\t\t\t\tadministração ou de prestação de serviços;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - edifícios residenciais, comerciais ou de escritórios;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - estabelecimentos de ensino em todos os níveis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - hospitais, clínicas e demais estabelecimentos do gênero;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVI - bibliotecas;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - supermercados, centros de compras e lojas de departamento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\tVIII - edificações destinadas ao lazer, como estádios, cinemas,\n\t\t\t\t\tclubes, teatros e parques recreativos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - auditórios para convenções, congressos e conferências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - estabelecimentos bancários;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXI - bares e restaurantes;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXII - hotéis e motéis;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - sindicatos e associações profissionais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXlV - terminais aeroviários, rodoviários, ferroviários e metrôs;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXV - igrejas e demais templos religiosos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVI - tribunais federais e estaduais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tXVII - cartórios;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVIII - todos os veículos de transporte coletivo que possibilitem o\n\t\t\t\t\tacesso e que ofereçam vagas adequadas à pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIX - veículos que sejam conduzidos pela pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXX - locais e respectivas vagas para estacionamento, as quais devem\n\t\t\t\t\tter largura mínima de 3,66 m (três metros e sessenta e seis\n\t\t\t\t\tcentímetros);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXI - banheiros compatíveis ao uso da pessoa com deficiência e à\n\t\t\t\t\tmobilidade da sua cadeira de rodas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXII - elevadores cuja abertura da porta tenha, no mínimo, 100 cm\n\t\t\t\t\t(cem centímetros) e de dimensões internas mínimas de 120cm x 150cm\n\t\t\t\t\t(cento e vinte centímetros por cento e cinquenta centímetros);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXIII - telefones com altura máxima do receptáculo de fichas de 120\n\t\t\t\t\tcm (cento e vinte centímetros);\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXIV - bebedouros adequados;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXV - guias de calçada rebaixadas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXVI - vias e logradouros públicos que configurem rota de trajeto\n\t\t\t\t\tpossível e elaborado para a pessoa com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXVII - rampas de acesso e circulação com piso antiderrapante;\n\t\t\t\t\tlargura mínima de 120 cm (cento e vinte centímetros); corrimão de\n\t\t\t\t\tambos os lados com altura máxima de 80 cm (oitenta centímetros);\n\t\t\t\t\tproteção lateral de segurança; e declive de 5% (cinco por cento) a\n\t\t\t\t\t6% (seis por cento), nunca excedendo a 8,33% (oito vírgula trinta e\n\t\t\t\t\ttrês por cento) e 3,50m (três metros e cinquenta centímetros) de\n\t\t\t\t\tcomprimento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXVIII - escadas com largura mínima de 120 cm (cento e vinte\n\t\t\t\t\tcentímetros); corrimão de ambos os lados coma altura máxima de 80 cm\n\t\t\t\t\t(oitenta centímetros) e degraus com altura máxima de 18 cm (dezoito\n\t\t\t\t\tcentímetros) e largura mínima de 25 cm (vinte e cinco centímetros).\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 179. O ''Símbolo Internacional de Acesso''\n\t\t\t\t\tdeverá ser colocado, obrigatoriamente, em local visível ao público,\n\t\t\t\t\tnão sendo permitida nenhuma modificação ou adição ao desenho\n\t\t\t\t\treproduzido no anexo a da Lei Federal nº 7.405, de 12 de novembro de\n\t\t\t\t\t1985.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 180. É vedada a utilização do "Símbolo Internacional de\n\t\t\t\t\tAcesso" para finalidade outra que não seja a de identificar,\n\t\t\t\t\tassinalar ou indicar local ou serviço habilitado ao uso de pessoas\n\t\t\t\t\tcom deficiência.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O disposto no caput deste artigo não se aplica à\n\t\t\t\t\treprodução do símbolo em publicações e outros meios de comunicação\n\t\t\t\t\trelevantes para os interesses do deficiente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 181. A utilização do "Símbolo Internacional de\n\t\t\t\t\tAcesso" de modo que viole as disposições desta Subseção sujeita\n\t\t\t\t\to infrator às seguintes penalidades:\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e notificação para se adequarem no prazo de trinta\n\t\t\t\t\tdias úteis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - multa de 35 (trinta e cinco) UPF/PR no caso da não adequação no\n\t\t\t\t\tprazo previsto;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - multa de setenta UPF/PR, em caso de reincidência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - após a incidência das penalidades previstas nos incisos I, II e\n\t\t\t\t\tIII, em caso de nova reincidência, cassação do alvará e interdição\n\t\t\t\t\tdo estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção X\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade aos Serviços de Transportes Coletivos\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade no Transporte Coletivo Rodoviário\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 182. Os veículos de transporte coletivo, metropolitano e\n\t\t\t\t\tintermunicipal, público e privado, em trânsito no Estado do Paraná\n\t\t\t\t\tdeverão cumprir os requisitos de acessibilidade estabelecidos nas\n\t\t\t\t\tnormas técnicas específicas em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 183. Todos os modelos e marcas de veículos de transporte\n\t\t\t\t\tcoletivo rodoviário, metropolitano e intermunicipal, público e\n\t\t\t\t\tprivado, para utilização no Estado do Paraná, serão fabricados\n\t\t\t\t\tacessíveis e disponíveis para integrar a frota operante, de forma a\n\t\t\t\t\tgarantir o seu uso por pessoas com deficiência, observado o disposto\n\t\t\t\t\tna legislação federal em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A substituição da frota operante atual por veículos\n\t\t\t\t\tacessíveis, a ser feita pelas empresas concessionárias e\n\t\t\t\t\tpermissionárias de transporte coletivo rodoviário, metropolitano e\n\t\t\t\t\tintermunicipal, público e privado, dar-se-á de forma gradativa,\n\t\t\t\t\tconforme o prazo previsto nos contratos de concessão e permissão\n\t\t\t\t\tdesse serviço.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 184. As empresas concessionárias e permissionárias dos serviços\n\t\t\t\t\tde transporte coletivo rodoviário, metropolitano e intermunicipal,\n\t\t\t\t\tpúblico e privado, deverão garantir a acessibilidade da frota de\n\t\t\t\t\tveículos em circulação, inclusive de seus equipamentos, observado o\n\t\t\t\t\tdisposto na legislação federal em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 185. As empresas permissionárias de transporte coletivo\n\t\t\t\t\tmetropolitano e intermunicipal ficam obrigadas a permitir o embarque\n\t\t\t\t\te o desembarque, pela mesma porta, dos usuários com qualquer\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Nos casos em que se fizer necessária a permissão\n\t\t\t\t\treferida no caput deste artigo, esta será estendida ao acompanhante\n\t\t\t\t\tdo usuário em questão, conforme disposto nesta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 186. Os ônibus das linhas metropolitanas e intermunicipais de\n\t\t\t\t\ttransporte coletivo do Estado ficam autorizados a parar fora dos\n\t\t\t\t\tpontos obrigatórios de parada, para embarque e desembarque de\n\t\t\t\t\tpassageiros com deficiência física e visual, podendo este indicar o\n\t\t\t\t\tmelhor local para desembarque, desde que o itinerário original da\n\t\t\t\t\tlinha seja respeitado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSubseção II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade no Transporte Coletivo Metroferroviário\n\t\t\t\t\tMetropolitano e Ferroviário Intermunicipal\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 187. A frota de veículos de transporte coletivo\n\t\t\t\t\tmetroferroviário metropolitano e ferroviário intermunicipal, assim\n\t\t\t\t\tcomo a infraestrutura dos serviços deste transporte deverão estar\n\t\t\t\t\ttotalmente acessíveis no prazo definido na legislação federal em\n\t\t\t\t\tvigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A acessibilidade nos serviços de transporte coletivo\n\t\t\t\t\tmetroferroviário metropolitano e ferroviário intermunicipal\n\t\t\t\t\tobedecerá ao disposto nas normas técnicas de acessibilidade em\n\t\t\t\t\tvigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Todos os modelos e marcas de veículos de transporte coletivo\n\t\t\t\t\tmetroferroviário metropolitano e ferroviário intermunicipal serão\n\t\t\t\t\tfabricados de forma acessível e estarão disponíveis para integrar a\n\t\t\t\t\tfrota operante, de modo a garantir o seu uso por pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 188. Os serviços de transporte coletivo metroferroviário e\n\t\t\t\t\tferroviário existentes deverão estar totalmente acessíveis no prazo\n\t\t\t\t\tmáximo definido na legislação federal em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. As empresas concessionárias e permissionárias dos\n\t\t\t\t\tserviços de transporte coletivo metroferroviário metropolitano e\n\t\t\t\t\tferroviário intermunicipal deverão apresentar plano de adaptação dos\n\t\t\t\t\tsistemas existentes, prevendo ações saneadoras com acessibilidade\n\t\t\t\t\ttotal sobre os elementos que compõem o sistema, conforme legislação\n\t\t\t\t\tfederal em vigor.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção XI\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDa Acessibilidade dos Sistemas de Comunicação, Informação e\n\t\t\t\t\tSinalização\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 189. O Poder Público do Estado do Paraná promoverá a eliminação\n\t\t\t\t\tde barreiras na comunicação e estabelecerá mecanismos e alternativas\n\t\t\t\t\ttécnicas que tornem acessíveis os sistemas de comunicação e\n\t\t\t\t\tsinalização às pessoas com deficiência sensorial e com dificuldade\n\t\t\t\t\tde comunicação, garantindo-lhes o direito de acesso à informação, à\n\t\t\t\t\tcomunicação, ao trabalho, à educação, ao transporte, à cultura, ao\n\t\t\t\t\tesporte e ao lazer.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 190. O Poder Público do Estado do Paraná formará profissionais\n\t\t\t\t\tpara o uso do Sistema Braille, intérpretes de Libras e de guias\n\t\t\t\t\tintérpretes, para facilitar qualquer tipo de comunicação direta com\n\t\t\t\t\ta pessoa com deficiência sensorial e com dificuldade de locomoção.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 191. Os serviços de difusão sonora e imagens adotarão plano de\n\t\t\t\t\tmedidas técnicas com o objetivo de permitir o uso da Libras ou outra\n\t\t\t\t\tsub-titulação e áudio descrição para garantir o direito de acesso à\n\t\t\t\t\tinformação às pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 192. Ficam reconhecidas oficialmente pelo estado do Paraná a\n\t\t\t\t\tLIBRAS e outros recursos de expressão a ela associados, como meios\n\t\t\t\t\tde comunicação objetiva e de uso corrente.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Compreende-se como Língua Brasileira de Sinais - Libras - a\n\t\t\t\t\tforma de comunicação e expressão, em que o sistema linguístico de\n\t\t\t\t\tnatureza visual motora, com estrutura gramatical própria, constituem\n\t\t\t\t\tum sistema linguístico de transmissão de ideias e fatos, oriundos de\n\t\t\t\t\tcomunidades de pessoas surdas do Brasil.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Para os propósitos desta Lei e da Língua Brasileira de Sinais -\n\t\t\t\t\tLibras, os intérpretes serão preferencialmente ouvintes e os\n\t\t\t\t\tinstrutores serão preferencialmente surdos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 193. As mensagens de publicidade de atos, programas, serviços e\n\t\t\t\t\tcampanhas da administração direta, indireta e fundacional do estado\n\t\t\t\t\tdo Paraná veiculadas na televisão, terão tradução simultânea para a\n\t\t\t\t\tLibras, e serão apresentadas em legendas com o objetivo de\n\t\t\t\t\ttornarem-se acessíveis às pessoas com deficiência auditiva ou\n\t\t\t\t\tsurdas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 194. Assegura aos surdos e às pessoas com deficiência auditiva\n\t\t\t\t\to direito à informação e ao atendimento em toda a administração\n\t\t\t\t\tpública, direta e indireta, por servidor apto a comunicar-se através\n\t\t\t\t\tda Libras.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Para efetivar o disposto no caput deste artigo, o\n\t\t\t\t\tPoder Executivo terá o prazo de seis meses, prorrogável por igual\n\t\t\t\t\tperíodo, e poderá"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\testabelecer convênios com entidades públicas ou privadas que atuem\n\t\t\t\t\tno atendimento dos surdos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 195. As editoras instaladas no estado do Paraná e que, no\n\t\t\t\t\tterritório paranaense, comercializem livros, apostilas ou outras\n\t\t\t\t\tobras literárias de quaisquer gêneros deverão atender às\n\t\t\t\t\tsolicitações dos consumidores com deficiência visual para impressão\n\t\t\t\t\tem braile ou em fonte ampliada das obras que editam, assim como\n\t\t\t\t\tdisponibilizar versão em áudio.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 196. O fabricante de produtos industrializados deverá\n\t\t\t\t\tdisponibilizar, mediante solicitação de usuários ou de revendedores,\n\t\t\t\t\tinstruções de uso em meio magnético, braile ou em fonte ampliada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os produtos industrializados a que o caput deste artigo se\n\t\t\t\t\trefere, compreendem: produtos de beleza, produtos alimentícios,\n\t\t\t\t\teletrodomésticos e medicamentos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º As instruções às quais se referem o caput deste artigo são\n\t\t\t\t\tinformações de uso e características dos produtos, tais como: o\n\t\t\t\t\tvalor calórico, a natureza do produto, a composição química, o\n\t\t\t\t\tfuncionamento, as contra indicações, a data de fabricação e a data\n\t\t\t\t\tde validade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 197. As empresas de energia elétrica, água e esgoto, telefonia\n\t\t\t\t\tfixa e telefonia celular no Estado do Paraná deverão, no modo que\n\t\t\t\t\testabelece o presente diploma legal, fornecer nas faturas e\n\t\t\t\t\tdocumentos de cobrança informações básicas no sistema braile ou em\n\t\t\t\t\tfonte ampliada, sempre que requerido.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A impressão em braile ou em fonte ampliada será,\n\t\t\t\t\tobrigatoriamente, na parte superior do documento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 198. As empresas concessionárias poderão optar pela impressão\n\t\t\t\t\tem todos os documentos ou realizar o cadastramento das pessoas com\n\t\t\t\t\tdeficiência visual.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Caso a empresa opte pelo cadastramento das pessoas\n\t\t\t\t\tcom deficiência visual, deverá promover publicidade da forma desse\n\t\t\t\t\tcadastramento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 199. A impressão em braile ou em fonte ampliada deverá conter,\n\t\t\t\t\tno mínimo, as seguintes informações:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tI - data de vencimento;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tII - valor da fatura,\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tIII - valor dos juros,\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tIV - multa por atraso;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tV - nome da empresa,\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - nome e endereço do usuário para fins de confirmação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tParágrafo único. Em caso de reaviso de vencimento a palavra\n\t\t\t\t\t"REAVISO" também será impressa em braile ou em fonte\n\t\t\t\t\tampliada.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 200. As empresas de que trata a presente Seção deverão\n\t\t\t\t\tprovidenciar a impressão no Sistema Braille, no prazo de 180 (cento\n\t\t\t\t\te oitenta) dias contados da data da promulgação da presente Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção XII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDas Ajudas Técnicas\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 201. O Poder Público do Estado do Paraná promoverá a supressão\n\t\t\t\t\tde barreiras urbanísticas, arquitetônicas, de transporte e de\n\t\t\t\t\tcomunicação mediante ajudas técnicas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 202. O Poder Público do Estado do Paraná, por meio dos\n\t\t\t\t\torganismos de apoio à pesquisa e das agências de financiamento,\n\t\t\t\t\tfomentará programas destinados:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - à promoção de pesquisas científicas voltadas ao tratamento e\n\t\t\t\t\tprevenção de deficiências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - ao desenvolvimento tecnológico orientado à produção de ajudas\n\t\t\t\t\ttécnicas para as pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - à formação e especialização de recursos humanos em\n\t\t\t\t\tacessibilidade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção XIII\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDo Cão-guia\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 203. Assegura à pessoa com deficiência visual usuária de\n\t\t\t\t\tcão-guia, bem como treinador ou ao acompanhante habilitado, o\n\t\t\t\t\tdireito de ingressar e permanecer com o animal em qualquer local\n\t\t\t\t\taberto ao público ou utilizado pelo público, gratuitamente ou\n\t\t\t\t\tmediante pagamento de ingresso, no Estado do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A deficiência visual referida no caput deste artigo\n\t\t\t\t\trestringe-se à cegueira e à baixa visão.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Para efeito do disposto no caput deste artigo, consideram-se\n\t\t\t\t\tlocais abertos ao público, ou utilizados pelo público:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - os próprios estaduais de uso comum do povo e de uso especial;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - os edifícios de órgãos públicos em geral;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - os hotéis, pensões, estalagens ou estabelecimentos similares;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - as lojas de qualquer gênero, restaurantes, bares, confeitarias,\n\t\t\t\t\tou locais semelhantes;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - os cinemas, teatros, estádios, ginásios ou qualquer\n\t\t\t\t\testabelecimento público de diversão ou esporte;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - os supermercados, shopping centers, ou qualquer tipo de\n\t\t\t\t\testabelecimento comercial ou de prestação de serviços;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - os estabelecimentos de ensino público ou privado de qualquer\n\t\t\t\t\tcurso ou grau;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - os clubes sociais abertos ao público;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - os salões de cabeleireiros, barbearias ou estabelecimentos\n\t\t\t\t\tsimilares;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - as entradas sociais em edifícios públicos ou residenciais,\n\t\t\t\t\televadores e escadas de acesso aos mesmos, bem como as áreas comuns\n\t\t\t\t\tde condomínios;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - os meios de transporte públicos ou concedidos, metropolitanos e\n\t\t\t\t\tintermunicipais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - os estabelecimentos religiosos de qualquer natureza.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º Nos locais onde haja cobrança de ingresso é vedada a cobrança\n\t\t\t\t\tde qualquer taxa ou contribuição extra pelo ingresso e permanência\n\t\t\t\t\tdo cão-guia.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 204. O cão-guia deverá portar a devida identificação e, quando\n\t\t\t\t\tsolicitado, seu condutor deverá apresentar documento comprobatório\n\t\t\t\t\tdo registro expedido por escola de cães-guia devidamente vinculada à\n\t\t\t\t\tFederação Internacional de Cães-Guia, acompanhado do atestado de\n\t\t\t\t\tsanidade do animal fornecido pelo órgão competente ou médico\n\t\t\t\t\tveterinário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 205. Os estabelecimentos e pessoas que impedirem o acesso e\n\t\t\t\t\tpermanência de pessoa com deficiência visual acompanhados de\n\t\t\t\t\tcão-guia estão sujeitos às seguintes penalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - advertência e multa no valor de 35 (trina e cinco) UPF/PR -\n\t\t\t\t\tUnidade Padrão Fiscal do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - no caso de reincidência, o valor da multa será dobrado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - após a incidência das penalidades previstas nos incisos I e II\n\t\t\t\t\tdeste artigo, cassação do alvará e interdição do estabelecimento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 206. Viola os direitos humanos aquele que impede ou dificulta o\n\t\t\t\t\tacesso da pessoa com deficiência visual ou cego, conduzida por\n\t\t\t\t\tcão-guia, aos locais previstos nesta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os estabelecimentos, empresas ou órgãos que derem\n\t\t\t\t\tcausa à discriminação serão punidos com pena de multa de 45\n\t\t\t\t\t(quarenta e cinco) UPF/PR e de interdição enquanto dure a\n\t\t\t\t\tdiscriminação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 207. O direito de ingresso do cão-guia que conduz pessoa com\n\t\t\t\t\tdeficiência visual permanece mesmo nos condomínios residenciais em\n\t\t\t\t\tque, por convenção ou regimento interno, fica restrita a presença ou\n\t\t\t\t\tcirculação de animais, sejam as pessoas com deficiência moradores ou\n\t\t\t\t\tvisitantes.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 208. Aos instrutores e treinadores reconhecidos pela Federação\n\t\t\t\t\tInternacional de Cães-Guia e às famílias de acolhimento autorizadas\n\t\t\t\t\tpelas escolas de treinamento filiadas à Federação Internacional de\n\t\t\t\t\tCães-Guia serão garantidos os mesmos direitos do usuário previstos\n\t\t\t\t\tnesta Seção.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tSeção XIV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDas Linhas de Crédito Especiais\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 209. As instituições financeiras estaduais manterão linha de\n\t\t\t\t\tcrédito especial destinada às pessoas com deficiência e às entidades\n\t\t\t\t\tque trabalhem na sua promoção e na defesa de seus direitos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Os recursos dos quais tratam o caput deste artigo serão\n\t\t\t\t\texclusivamente destinados para a cobertura de despesas necessárias à\n\t\t\t\t\tsuperação das dificuldades geradas pela deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A liberação do crédito especial fica condicionada à prova\n\t\t\t\t\tdocumental, pelos interessados - pessoas físicas e jurídicas -, de\n\t\t\t\t\tque sua aplicação será feita estritamente na área da deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 210. Tanto às pessoas físicas como às jurídicas, a concessão do\n\t\t\t\t\tcrédito especial se dará dentro dos critérios usuais das\n\t\t\t\t\tinstituições financeiras, respeitada a capacidade de liquidez dos\n\t\t\t\t\tfinanciados, demonstrada por documentos que lhes forem solicitados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 211. As pessoas físicas comprovarão a deficiência por meio de\n\t\t\t\t\tdocumento de identificação da pessoa com deficiência, devendo as\n\t\t\t\t\tentidades fazerem prova, por meio de seus estatutos, de que se\n\t\t\t\t\tdedicam à promoção da pessoa com deficiência, comprovando também que\n\t\t\t\t\tse encontram em efetivo e regular funcionamento.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO X\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDA CIÊNCIA E TECNOLOGIA\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 212. O Poder Público do Estado do Paraná promoverá e\n\t\t\t\t\tincentivará o desenvolvimento científico, a pesquisa e a capacitação\n\t\t\t\t\ttecnológica, voltados para a melhoria da qualidade de vida e\n\t\t\t\t\ttrabalho das pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º O desenvolvimento e a pesquisa promovidos ou incentivados pela\n\t\t\t\t\tadministração pública darão prioridade à geração de conhecimentos e\n\t\t\t\t\ttécnicas que visem à prevenção e ao tratamento das deficiências,\n\t\t\t\t\tassim como à produção de ajudas técnicas e tecnologias de apoio.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Será incentivada e apoiada a capacitação tecnológica de\n\t\t\t\t\tinstituições públicas e privadas que produzam e ofereçam, no Estado,\n\t\t\t\t\tmedicamentos, próteses, órteses, instrumentos, equipamentos,\n\t\t\t\t\tserviços e sistemas voltados para melhorar a funcionalidade de\n\t\t\t\t\tpessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 213. O Poder Público do Estado do Paraná adotará medidas de\n\t\t\t\t\tincentivo à produção e ao desenvolvimento científico e tecnológico\n\t\t\t\t\tvoltado às ajudas técnicas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 214. Serão estimulados a pesquisa e o desenvolvimento, assim\n\t\t\t\t\tcomo a difusão de tecnologias voltadas para ampliar o acesso de\n\t\t\t\t\tpessoas com deficiência às tecnologias da informação e comunicação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Será estimulado, em especial, o emprego das tecnologias da\n\t\t\t\t\tinformação e comunicação como instrumento de superação de limitações\n\t\t\t\t\tfuncionais e de barreiras à comunicação e à educação de pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Serão estimuladas a adoção de soluções e a difusão de normas\n\t\t\t\t\tque visem a ampliar a acessibilidade de pessoas com deficiência à\n\t\t\t\t\tcomputação, aos sítios da rede mundial de computadores (internet) em\n\t\t\t\t\tgeral e, em especial, aos serviços eletrônicos do governo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO XI\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDA DISCRIMINAÇÃO E MAUS-TRATOS\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 215. Nenhuma pessoa com deficiência será objeto de qualquer\n\t\t\t\t\tforma de negligência, discriminação, exploração, violência, tortura,\n\t\t\t\t\tcrueldade, opressão, tratamento desumano ou degradante, punido na\n\t\t\t\t\tforma da lei qualquer atentado, por ação ou omissão, aos seus\n\t\t\t\t\tdireitos fundamentais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Considera-se discriminação qualquer distinção, restrição ou\n\t\t\t\t\texclusão em razão da deficiência, mediante ação ou omissão, que\n\t\t\t\t\ttenha o propósito ou efeito de prejudicar, impedir ou anular o\n\t\t\t\t\treconhecimento, gozo ou exercício de seus direitos e liberdades\n\t\t\t\t\tfundamentais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Não constitui discriminação a diferenciação ou preferência\n\t\t\t\t\tadotada para promover a inclusão social ou o desenvolvimento\n\t\t\t\t\tpessoal, não sendo as pessoas com deficiência obrigadas a aceitar\n\t\t\t\t\ttal diferenciação ou preferência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 216. Constitui preconceito e discriminação à pessoa com\n\t\t\t\t\tdeficiência:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - impedir, dificultar, obstar ou recusar a livre locomoção em\n\t\t\t\t\testabelecimentos da administração direta ou indireta e das\n\t\t\t\t\tconcessionárias de serviços públicos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - impedir, dificultar, obstar ou restringir o acesso às\n\t\t\t\t\tdependências de bares, restaurantes, hotéis, cinemas, teatros,\n\t\t\t\t\tclubes, centros comerciais e similares;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - fazer exigências específicas às pessoas com deficiência para\n\t\t\t\t\tobtenção e manutenção de emprego;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - induzir ou incitar à prática de atos discriminatórios;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - veicular, por meio de comunicação escrita, sonora, audiovisual\n\t\t\t\t\tou eletrônica, conteúdo discriminatório ou preconceituoso;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - praticar qualquer ato relacionado à pessoa com deficiência que\n\t\t\t\t\tcause constrangimento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - ofender a honra ou a integridade física em razão da\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º Incide nas discriminações previstas nos incisos I e II deste\n\t\t\t\t\tartigo a alegação da existência de barreiras arquitetônicas para\n\t\t\t\t\tnegar, dificultar ou restringir atendimento ou prestação de serviço\n\t\t\t\t\tà pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º A ausência de atendimento preferencial à pessoa com deficiência\n\t\t\t\t\té forma de prática discriminatória prevista nos incisos VI e VII\n\t\t\t\t\tdeste artigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 217. A administração pública direta e indireta, em todos os\n\t\t\t\t\tníveis, adotará medidas imediatas, eficazes e apropriadas para:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - aumentar a consciência da sociedade em relação às pessoas com\n\t\t\t\t\tdeficiência e promover o respeito por seus direitos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - combater estereótipos, preconceitos e práticas prejudiciais às\n\t\t\t\t\tpessoas com deficiência, incluindo aqueles baseados em sexo e idade,\n\t\t\t\t\tem todos os aspectos da vida;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - promover a tomada de consciência a respeito das deficiências e\n\t\t\t\t\tdas capacidades da pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Estas medidas incluem a execução e a manutenção de\n\t\t\t\t\tcampanhas eficazes de conscientização pública, destinadas a:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - fomentar atitudes receptivas a respeito dos direitos de pessoas\n\t\t\t\t\tcom deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - promover percepções positivas e maior consciência social sobre\n\t\t\t\t\tas pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - promover o reconhecimento das competências, méritos,\n\t\t\t\t\thabilidades e contribuições de pessoas com deficiência relacionadas\n\t\t\t\t\tao ambiente e ao mercado de trabalho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - promover em todos os níveis do sistema educacional, incluindo\n\t\t\t\t\ttodas as crianças desde a primeira idade, uma atitude de respeito\n\t\t\t\t\tpara os direitos de pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - estimular todos os órgãos da mídia a difundir a imagem das\n\t\t\t\t\tpessoas com deficiência compatível com o propósito desta Lei;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - promover programas de capacitação e conscientização a respeito\n\t\t\t\t\tdas pessoas com deficiência e seus direitos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 218. O Poder Público Estadual desenvolverá ações de cunho\n\t\t\t\t\teducativo e combativo ao preconceito e à discriminação relativos às\n\t\t\t\t\tpessoas com deficiência, nos serviços públicos e demais atividades\n\t\t\t\t\texercidas no Estado, conforme o disposto no inciso I do art. 204, da\n\t\t\t\t\tConstituição Federal e demais normas da legislação federal e\n\t\t\t\t\testadual pertinentes.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 219. A notificação compulsória de maus-tratos é obrigatória nos\n\t\t\t\t\tcasos que envolvam pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A notificação compulsória será emitida por\n\t\t\t\t\tprofissionais dos órgãos públicos das áreas de saúde, educação,\n\t\t\t\t\tassistência social e segurança pública.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 220. A notificação compulsória será encaminhada por intermédio\n\t\t\t\t\tdos responsáveis pelas unidades das políticas públicas setoriais ao\n\t\t\t\t\tConselho Tutelar ou, na falta deste, à Vara da Infância e Juventude,\n\t\t\t\t\tquando se tratar de criança e adolescente, e ao Ministério Público,\n\t\t\t\t\tquando se tratar de pessoa adulta com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 221. É de responsabilidade da autoridade policial e dos órgãos\n\t\t\t\t\tde segurança pública, recebida a notícia do desaparecimento de\n\t\t\t\t\tpessoa com deficiência, de qualquer idade, proceder a sua imediata\n\t\t\t\t\tbusca e localização.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tTÍTULO III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDA FORMULAÇÃO E COORDENAÇÃO DA POLÍTICA\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO I\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDO CONSELHO ESTADUAL DOS DIREITOS DA PESSOA COM DEFICIÊNCIA DO\n\t\t\t\t\tPARANÁ - COEDE/PR\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 222. Estabelece na estrutura organizacional da Secretaria de\n\t\t\t\t\tEstado responsável pelas políticas públicas para as pessoas com\n\t\t\t\t\tdeficiência, no nível de direção superior, o Conselho Estadual dos\n\t\t\t\t\tDireitos da Pessoa com Deficiência do Paraná - COEDE/PR, órgão\n\t\t\t\t\tcolegiado de caráter consultivo, deliberativo, fiscalizador e\n\t\t\t\t\tarticulador das políticas voltadas a assegurar o pleno exercício dos\n\t\t\t\t\tdireitos individuais e sociais da pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 223. O COEDE/PR tem por finalidade possibilitar a participação\n\t\t\t\t\tpopular nas discussões, proposições, elaborações e auxílio na\n\t\t\t\t\timplementação e fiscalização das políticas públicas voltadas a\n\t\t\t\t\tassegurar o pleno exercício dos direitos das pessoas com\n\t\t\t\t\tdeficiência, em todas as esferas da administração pública do Estado\n\t\t\t\t\tdo Paraná, a fim de garantir a promoção e proteção das pessoas com\n\t\t\t\t\tdeficiência, assim como exercer a orientação normativa e consultiva\n\t\t\t\t\tsobre os direitos das pessoas com deficiência no Estado do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 224. O COEDE/PR será responsável pela deliberação de políticas\n\t\t\t\t\tpúblicas, visando à inclusão das pessoas com deficiência e disporá\n\t\t\t\t\tsobre seus direitos básicos, objeto desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 225. São funções do COEDE/PR:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - avaliar, propor, discutir e participar da formulação, execução e\n\t\t\t\t\tfiscalização de políticas públicas para inclusão das pessoas com\n\t\t\t\t\tdeficiência, observada a legislação em vigor, visando à eliminação\n\t\t\t\t\tde preconceitos e a plena inserção na vida socioeconômica, política\n\t\t\t\t\te cultural do Estado do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - formular planos, programas e projetos da política estadual de\n\t\t\t\t\tintegração da pessoa com deficiência e propor as providências\n\t\t\t\t\tnecessárias à completa implementação e ao adequado desenvolvimento\n\t\t\t\t\tdestes planos, programas e projetos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - propor a adoção de mecanismos e instrumentos que assegurem a\n\t\t\t\t\tparticipação e o controle popular sobre as políticas públicas para a\n\t\t\t\t\tpromoção e inclusão das pessoas com deficiência, por meio da\n\t\t\t\t\telaboração do plano diretor de programas, projetos e ações, bem como\n\t\t\t\t\tpela obtenção dos recursos públicos necessários para tais fins;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - acompanhar o planejamento e avaliar a execução das políticas\n\t\t\t\t\testaduais de acesso à saúde, à educação, à assistência social, à\n\t\t\t\t\thabilitação e à reabilitação profissional, ao trabalho, à cultura,\n\t\t\t\t\tao desporto, ao turismo e ao lazer;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - acompanhar a elaboração e a execução da proposta orçamentária do\n\t\t\t\t\tEstado, indicando ao Secretário de Estado responsável pela execução\n\t\t\t\t\tda política pública de atendimento às pessoas com deficiência as\n\t\t\t\t\tmedidas necessárias à consecução da política formulada e do adequado\n\t\t\t\t\tfuncionamento deste Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - acompanhar a concessão de auxílios e subvenções a pessoas\n\t\t\t\t\tjurídicas de direito privado atuantes no atendimento às pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - elaborar e apresentar, anualmente, ao Secretário de Estado\n\t\t\t\t\tresponsável pela execução da política pública de atendimento às\n\t\t\t\t\tpessoas com deficiência, relatório circunstanciado de todas as\n\t\t\t\t\tatividades desenvolvidas pelo Conselho Estadual no período;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - acompanhar, mediante relatório de gestão, o desempenho dos\n\t\t\t\t\tprogramas e projetos da política estadual para inclusão das pessoas\n\t\t\t\t\tcom deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - apreciar e avaliar a proposta orçamentária da política pública;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - propor aos poderes constituídos modificações nas estruturas\n\t\t\t\t\tgovernamentais diretamente ligadas à proteção e à promoção dos\n\t\t\t\t\tdireitos das pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - oferecer subsídios para elaboração de anteprojetos de Lei\n\t\t\t\t\tatinentes aos interesses das pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - pronunciar-se, emitir pareceres e prestar informações sobre\n\t\t\t\t\tassuntos que digam respeito às pessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - incentivar e apoiar a realização de eventos, estudos e\n\t\t\t\t\tpesquisas sobre a questão das deficiências;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIV - pronunciar-se sobre matérias que lhe sejam submetidas pela\n\t\t\t\t\tSecretaria de Estado responsável pelas políticas públicas para as\n\t\t\t\t\tpessoas com deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXV - aprovar, de acordo com critérios estabelecidos em seu Regimento\n\t\t\t\t\tInterno, o cadastramento de entidades de proteção ou de atendimento\n\t\t\t\t\tàs pessoas com deficiência que pretendam integrar o Conselho\n\t\t\t\t\tEstadual;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVI - receber petições, denúncias, reclamações, representações ou\n\t\t\t\t\tqueixas de qualquer pessoa por desrespeito aos direitos assegurados\n\t\t\t\t\tàs pessoas com deficiência, adotando as medidas cabíveis;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVII - promover canais de diálogo com a sociedade civil;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVIII - propor e incentivar a realização de campanhas que visem à\n\t\t\t\t\tprevenção de deficiências e à promoção dos direitos das pessoas com\n\t\t\t\t\tdeficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIX - promover intercâmbio com entidades públicas e particulares,\n\t\t\t\t\torganismos nacionais, internacionais e estrangeiros visando atender\n\t\t\t\t\taos seus objetivos;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXX - incentivar a criação e estimular o funcionamento dos Conselhos\n\t\t\t\t\tMunicipais dos Direitos da Pessoa com Deficiência;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXI - receber de órgãos públicos, entidades privadas ou de\n\t\t\t\t\tparticulares todas as informações necessárias ao exercício de sua\n\t\t\t\t\tatividade.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXII - manifestar-se, dentro dos limites de sua atuação, acerca da\n\t\t\t\t\tadministração e condução de trabalhos de prevenção, habilitação,\n\t\t\t\t\treabilitação e inclusão social de entidade particular ou pública,\n\t\t\t\t\tquando houver notícia de irregularidade, expedindo, quando entender\n\t\t\t\t\tcabível, recomendação ao representante legal da entidade;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXIII - avaliar anualmente o desenvolvimento estadual e municipal de\n\t\t\t\t\tatendimento especializado à pessoa com deficiência visando à sua\n\t\t\t\t\tplena adequação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXXIV - elaborar seu Regimento Interno.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O COEDE/PR poderá estabelecer contato direto com os\n\t\t\t\t\tórgãos do Estado, pertencentes à administração direta ou indireta,\n\t\t\t\t\tobjetivando o fiel cumprimento das suas atribuições.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 226. O COEDE/PR deverá apresentar, no prazo de 120 (cento e\n\t\t\t\t\tvinte dias) da data de promulgação desta Lei, o Plano Estadual da\n\t\t\t\t\tPessoa com Deficiência, o qual deverá contemplar programas, projetos\n\t\t\t\t\te ações para sua concretização, que deverão ser contemplados pelo\n\t\t\t\t\tPlano Plurianual (PPA), a Lei de Diretrizes Orçamentárias (LDO) e\n\t\t\t\t\tLei Orçamentária Anual (LOA).\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 227. O COEDE/PR deverá convocar a Conferência Estadual dos\n\t\t\t\t\tDireitos da Pessoa com Deficiência, a qual deverá realizar-se em\n\t\t\t\t\tdata anterior à Conferência Nacional dos Direitos da Pessoa com\n\t\t\t\t\tDeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 228. O COEDE/PR será composto por 24 (vinte e quatro) membros e\n\t\t\t\t\tseus respectivos suplentes, nomeados pelo Governador do Estado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 229. A representação do poder público será composta da seguinte\n\t\t\t\t\tforma:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pelas políticas públicas para as pessoas com\n\t\t\t\t\tdeficiência, que preferencialmente atuem na área, a serem indicados\n\t\t\t\t\tpelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da saúde, a serem indicados pelo\n\t\t\t\t\ttitular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública do trabalho, emprego e economia\n\t\t\t\t\tsolidária, a serem indicados pelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública de Assistência Social, a serem\n\t\t\t\t\tindicados pelo titular da pasta;\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do inciso dada pela Lei Nº 18453 DE 14/04/2015)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da educação, a serem indicados\n\t\t\t\t\tpelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da justiça, cidadania e direitos\n\t\t\t\t\thumanos, a serem indicados pelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da ciência, tecnologia e ensino\n\t\t\t\t\tsuperior, a serem indicados pelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - um membro titular e um membro suplente da Secretaria de\n\t\t\t\t\tEstado responsável pela política pública de segurança pública, a\n\t\t\t\t\tserem indicados pelo titular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública da cultura, a serem indicados pelo\n\t\t\t\t\ttitular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública do turismo, a serem indicados pelo\n\t\t\t\t\ttitular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública do esporte, a serem indicados pelo\n\t\t\t\t\ttitular da Pasta;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - um membro titular e um membro suplente da Secretaria de Estado\n\t\t\t\t\tresponsável pela política pública do planejamento e coordenação\n\t\t\t\t\tgeral, a serem indicados pelo titular da Pasta.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Os representantes governamentais serão\n\t\t\t\t\tpreferencialmente servidores com deficiência ou pessoas\n\t\t\t\t\tcomprometidas com a causa da pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 230. A representação da sociedade civil organizada será eleita\n\t\t\t\t\te composta por doze representantes, dentre as entidades eleitas em\n\t\t\t\t\tAssembleia, sendo dois representantes das seguintes áreas de\n\t\t\t\t\tatuação:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tI - deficiência física;\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - deficiência auditiva e/ou surdez;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - deficiência intelectual;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - deficiência visual e/ou cego;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - transtorno global do desenvolvimento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tVI - múltipla deficiência.\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 231. Na ausência de entidade com representação estadual em\n\t\t\t\t\tqualquer das áreas descritas no art. 230 desta Lei, será indicada\n\t\t\t\t\toutra mediante eleição entre as demais entidades.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 232. A ampliação da composição do COEDE/PR prevista nesta Lei\n\t\t\t\t\tserá implementada a partir da próxima eleição, permanecendo válida,\n\t\t\t\t\taté então, a composição prevista no Decreto nº 10.315, de 28 de\n\t\t\t\t\tfevereiro de 2014, e suas posteriores alterações.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Redação do artigo dada pela Lei Nº 18453 DE 14/04/2015)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 233. Serão convidados a participar das reuniões do Conselho\n\t\t\t\t\tEstadual dos Direitos da Pessoa com Deficiência do Paraná -\n\t\t\t\t\tCOEDE/PR, com direito a voz, sem direito a voto:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - um representante do Poder Judiciário do Estado do Paraná e seu\n\t\t\t\t\tsuplente, a serem indicados anualmente pelo Presidente do Tribunal\n\t\t\t\t\tde Justiça do Estado do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - um representante do Ministério Público do Estado do Paraná e\n\t\t\t\t\tseu suplente, a serem indicados anualmente pelo Procurador-Geral de\n\t\t\t\t\tJustiça do Estado do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - um representante da Defensoria Pública do Estado do Paraná e\n\t\t\t\t\tseu suplente, a serem indicados anualmente pelo Defensor Público\n\t\t\t\t\tGeral do Estado do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - um representante da Ordem dos Advogados do Brasil, seção do\n\t\t\t\t\tParaná, e seu suplente, a serem indicados anualmente pelo Presidente\n\t\t\t\t\tda Ordem dos Advogados do Brasil, seção do Paraná;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - um representante da Assembleia Legislativa do Estado do Paraná e\n\t\t\t\t\tseu suplente, a serem indicados anualmente pela Presidente da\n\t\t\t\t\tAssembleia Legislativa do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O COEDE/PR poderá convidar para participar de suas\n\t\t\t\t\tsessões, com direito a voz, sem direito a voto, representantes de\n\t\t\t\t\tentidades ou de órgãos, públicos ou privados, cuja participação seja\n\t\t\t\t\tconsiderada importante diante da pauta da sessão, e pessoas que, por\n\t\t\t\t\tseus conhecimentos e experiência profissional, possam contribuir\n\t\t\t\t\tpara a discussão das matérias em exame.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 234. A eleição dos membros representantes da sociedade civil\n\t\t\t\t\torganizada do COEDE/PR será realizada em assembleia convocada\n\t\t\t\t\tespecificamente para este fim.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º A assembleia de eleição será convocada a cada dois anos pelo\n\t\t\t\t\tPresidente do COEDE/PR.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º O Presidente do COEDE/PR deverá convocar a assembleia de\n\t\t\t\t\teleição com antecedência de noventa dias do término do mandato dos\n\t\t\t\t\tmembros representantes da sociedade civil.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 3º As entidades da sociedade civil com representação estadual\n\t\t\t\t\tdeverão apresentar documentação comprobatória do exercício de suas\n\t\t\t\t\tatividades há pelo menos um ano e indicar um representante titular e\n\t\t\t\t\tum representante suplente para participação na Assembleia Estadual\n\t\t\t\t\tdos Direitos da Pessoa com Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 4º O Ministério Público assistirá e fiscalizará a eleição dos\n\t\t\t\t\tmembros representantes da sociedade civil organizada durante a\n\t\t\t\t\tAssembleia convocada especificamente para este fim.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 235. Caberá aos órgãos públicos e às entidades da sociedade\n\t\t\t\t\tcivil a indicação de seus membros titulares e suplentes para\n\t\t\t\t\tcomposição do COEDE/PR, no prazo a ser estabelecido pela Secretaria\n\t\t\t\t\tde Estado responsável pelas políticas públicas para as pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 236. O não atendimento ao disposto no art. 235 desta Lei,\n\t\t\t\t\tquando se tratar de entidade da sociedade civil implicará na\n\t\t\t\t\tsubstituição desta entidade pela entidade mais votada na ordem de\n\t\t\t\t\tsucessão, observando-se a representatividade da área da deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 237. Os membros das entidades da sociedade civil e seus\n\t\t\t\t\trespectivos suplentes não poderão ser destituídos no período do\n\t\t\t\t\tmandato, salvo por"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\trazões que motivem a deliberação da maioria qualificada por 2/3\n\t\t\t\t\t(dois terços) do Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 238. Será necessariamente substituído o membro do COEDE/PR que:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - desvincular-se do órgão de origem de sua representação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - faltar a três reuniões consecutivas ou a cinco reuniões\n\t\t\t\t\tintercaladas, sem justificativa;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - apresentar renúncia ao Plenário do Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - apresentar procedimento incompatível com a dignidade das\n\t\t\t\t\tfunções.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 239. A justificativa de falta prevista no inciso II do art. 238\n\t\t\t\t\tdesta Lei deverá ser dirigida ao Presidente do COEDE/PR, no prazo de\n\t\t\t\t\tcinco dias úteis anteriores ao evento ou reunião, salvo motivo de\n\t\t\t\t\tforça maior posteriormente justificado.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 240. A substituição involuntária, quando necessária, dar-se-á\n\t\t\t\t\tpor deliberação da maioria dos membros presentes à sessão do\n\t\t\t\t\tConselho, em procedimento iniciado mediante provocação de integrante\n\t\t\t\t\tdo COEDE/PR, do Ministério Público ou de qualquer cidadão,\n\t\t\t\t\tassegurada a ampla defesa.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 241. O COEDE/PR reunir-se-á ordinariamente a cada mês e,\n\t\t\t\t\textraordinariamente, por convocação de seu Presidente ou a\n\t\t\t\t\trequerimento da maioria de seus membros.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 242. O Regimento Interno do COEDE/PR, em vigor, deverá ser\n\t\t\t\t\talterado no prazo de noventa dias a contar da data da publicação\n\t\t\t\t\tdesta Lei, adequando-se aos seus termos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 243. O mandato dos membros do COEDE/PR será de dois anos,\n\t\t\t\t\tpermitida uma recondução.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 244. O desempenho da função de membro do COEDE/PR, que não tem\n\t\t\t\t\tqualquer remuneração ou percepção de gratificação, será considerado\n\t\t\t\t\tserviço relevante prestado ao Estado, sendo seu exercício\n\t\t\t\t\tprioritário, justificando as ausências a qualquer outro serviço,\n\t\t\t\t\tdesde que determinadas pelas atividades próprias do Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 245. As deliberações do COEDE/PR serão tomadas pela maioria\n\t\t\t\t\tsimples, estando presente a maioria absoluta dos membros do\n\t\t\t\t\tConselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 246. Todas as reuniões do COEDE/PR serão abertas à participação\n\t\t\t\t\tde quaisquer interessados.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 247. Ao Presidente do COEDE/PR compete:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - representar o Conselho junto às autoridades, aos órgãos e às\n\t\t\t\t\tentidades;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - dirigir as atividades do Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - convocar e presidir as sessões do Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - proferir o voto de desempate nas decisões do Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 248. O Presidente do COEDE/PR será substituído em suas faltas e\n\t\t\t\t\timpedimentos pelo Vice-Presidente do Conselho, e, na ausência\n\t\t\t\t\tsimultânea de ambos, presidirá o Conselho o seu membro mais antigo.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 249. A Presidência do Conselho terá alternância em sua gestão,\n\t\t\t\t\tsendo um mandato presidido por um representante do poder público e o\n\t\t\t\t\toutro por um representante da sociedade civil organizada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 250. À Secretária Geral do COEDE/PR compete:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - providenciar a convocação, organizar e secretariar as sessões do\n\t\t\t\t\tConselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - elaborar a pauta de matérias a serem submetidas às sessões do\n\t\t\t\t\tConselho para deliberação;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - manter sistema de informação sobre os processos e assuntos de\n\t\t\t\t\tinteresse do Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - organizar e manter a guarda de papéis e documentos do Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - exercer outras funções correlatas aos objetivos do Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 251. O Presidente, o Vice-Presidente e o Secretário-Geral do\n\t\t\t\t\tCOEDE/PR serão eleitos pela maioria qualificada do Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O Regimento Interno disporá sobre as eleições\n\t\t\t\t\tgerais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 252. A Secretaria de Estado responsável pelas políticas\n\t\t\t\t\tpúblicas para as pessoas com deficiência prestará o apoio técnico,\n\t\t\t\t\tadministrativo e de infraestrutura necessários ao pleno\n\t\t\t\t\tfuncionamento do COEDE/PR.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 253. O COEDE/PR deverá ser instalado em local indicado pelo\n\t\t\t\t\tEstado, incumbindo à Secretaria de Estado responsável pelas\n\t\t\t\t\tpolíticas públicas para as pessoas com deficiência adotar as\n\t\t\t\t\tprovidências para tanto.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 254. O Poder Executivo do Estado do Paraná arcará com os custos\n\t\t\t\t\tde deslocamento, alimentação e permanência dos Conselheiros e seus\n\t\t\t\t\tacompanhantes não residentes em Curitiba e Região Metropolitana,\n\t\t\t\t\tquando necessário e justificadamente, para o exercício de suas\n\t\t\t\t\tfunções.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 255. O Poder Executivo do Estado do Paraná deverá custear as\n\t\t\t\t\tdespesas dos Delegados eleitos na Conferência Estadual dos Direitos\n\t\t\t\t\tda Pessoa com Deficiência e seus acompanhantes, quando necessário e\n\t\t\t\t\tjustificadamente, para tornar possível sua presença na Conferência\n\t\t\t\t\tNacional dos Direitos da Pessoa com Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A previsão do caput deste artigo refere-se tanto\n\t\t\t\t\taos Delegados representantes do poder público quanto aos delegados\n\t\t\t\t\trepresentantes da sociedade civil organizada.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 256. O Poder Executivo deverá arcar com as despesas de\n\t\t\t\t\trealização e divulgação das Conferências Estaduais dos Direitos da\n\t\t\t\t\tPessoa com Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 1º As despesas decorrentes desta Lei correrão por conta de\n\t\t\t\t\tdotações orçamentárias próprias e ficam condicionadas às\n\t\t\t\t\tdisponibilidades orçamentárias e financeiras estabelecidas nas leis\n\t\t\t\t\torçamentárias anuais.\n\t\t\t\t\t"
                      ),
                      _c("strong", [
                        _vm._v(
                          "(Parágrafo acrescentado pela Lei Nº 20774 DE 16/11/2021)."
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _c("strong", [
                        _vm._v(
                          "(Parágrafo acrescentado pela Lei Nº 20774 DE 16/11/2021):"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t§ 2º Autoriza o Poder Executivo a custear as despesas de locomoção,\n\t\t\t\t\thospedagem e alimentação:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - dos conselheiros governamentais e representantes das entidades\n\t\t\t\t\tnão governamentais, titulares ou suplentes, integrantes do COEDE,\n\t\t\t\t\tdecorrente do exercício de sua função, mediante convocação do\n\t\t\t\t\treferido Conselho;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - dos delegados governamentais, inclusive municipais, e\n\t\t\t\t\trepresentantes das entidades não governamentais eleitos para\n\t\t\t\t\tparticiparem das Conferências Estaduais, convocadas pelo COEDE,\n\t\t\t\t\tdecorrente do exercício de sua função, mediante convocação do\n\t\t\t\t\treferido Conselho.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - de acompanhante para os conselheiros e delegados mencionados\n\t\t\t\t\tnos incisos I e II deste parágrafo, desde que, mediante\n\t\t\t\t\tjustificativa fundamentada, seja atestada a imprescindibilidade da\n\t\t\t\t\tpresença do acompanhante para viabilizar o desempenho pleno da\n\t\t\t\t\tfunção do conselheiro ou delegado perante o COEDE ou suas\n\t\t\t\t\tConferências Estaduais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 257. As demais matérias pertinentes ao funcionamento do\n\t\t\t\t\tConselho serão disciplinadas pelo seu Regimento Interno.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO II\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDA ASSESSORIA ESPECIAL PARA INTEGRAÇÃO DA PESSOA COM DEFICIÊNCIA\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 258. A Assessoria Especial para Integração da Pessoa com\n\t\t\t\t\tDeficiência, vinculada à Secretaria de Estado responsável pela\n\t\t\t\t\tpolítica pública voltada à pessoa com deficiência será responsável\n\t\t\t\t\tpelo monitoramento e implementação dos dispositivos desta Lei,\n\t\t\t\t\tmediante a criação de mecanismos específicos para este fim, no prazo\n\t\t\t\t\tde sessenta dias da data da publicação desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Deverá ser instituído um Comitê Intersecretarial\n\t\t\t\t\tpara viabilizar o monitoramento e implementação referida no caput\n\t\t\t\t\tdeste artigo, a ser regulamentado pela Secretaria de Estado\n\t\t\t\t\tresponsável pelas políticas públicas voltadas às pessoas com\n\t\t\t\t\tdeficiência no prazo de sessenta dias da data publicação desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO III\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDA ÁREA DE ATENÇÃO ESPECIAL À PESSOA COM DEFICIÊNCIA\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 259. Institui no âmbito de cada órgão e entidade da\n\t\t\t\t\tadministração pública do estado, nos termos da Lei nº 8.485, de 3 de\n\t\t\t\t\tjunho de 1987, a Área de Atenção Especial à Pessoa com Deficiência,\n\t\t\t\t\tdestinada a conferir tratamento prioritário e adequado aos assuntos\n\t\t\t\t\tque lhe são relativos.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. A administração pública do Estado compreende as\n\t\t\t\t\tsecretarias de Estado, os órgãos de regime especial, as autarquias,\n\t\t\t\t\tas empresas públicas, as sociedades de economia mista, os serviços\n\t\t\t\t\tsociais autônomos e os órgãos de representação do estado do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 260. A Área de Atenção Especial à Pessoa com Deficiência ficará\n\t\t\t\t\tsob a coordenação da Secretaria de Estado responsável pelas\n\t\t\t\t\tpolíticas públicas para as pessoas com deficiência, por meio da\n\t\t\t\t\tAssessoria Especial para Integração da Pessoa com Deficiência,\n\t\t\t\t\tresponsável pela execução das Políticas Públicas do Estado do Paraná\n\t\t\t\t\tpara Promoção e Inclusão da Pessoa com Deficiência e demais\n\t\t\t\t\tpolíticas públicas elaboradas com relação à pessoa com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 261. Para a consecução do disposto no art. 259 desta Lei será\n\t\t\t\t\tdesignado, no âmbito de cada órgão e entidade da Administração\n\t\t\t\t\tPública do Estado, pelos seus titulares, servidor pertencente ao\n\t\t\t\t\trespectivo quadro funcional para atuar como "Ponto Focal de\n\t\t\t\t\tAtendimento" na Área de Atenção Especial à Pessoa com\n\t\t\t\t\tDeficiência.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\t§ 1º O servidor designado como "Ponto Focal de\n\t\t\t\t\tAtendimento", a que se refere o caput deste artigo, atuará sob\n\t\t\t\t\ta orientação da Assessoria Especial para integração da Pessoa com\n\t\t\t\t\tDeficiência.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\t§ 2º As atribuições dos "Pontos Focais de Atendimento"\n\t\t\t\t\treferidos no caput deste artigo serão desenvolvidas sem prejuízo das\n\t\t\t\t\tatividades inerentes aos seus respectivos cargos e funções.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\t§ 3º Na ausência ou impedimento dos "Pontos Focais de\n\t\t\t\t\tAtendimento" designados, os titulares de órgão e entidade da\n\t\t\t\t\tAdministração Pública do Estado designarão suplentes para o\n\t\t\t\t\texercício temporário da atribuição.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 262. O Conselho Estadual dos Direitos da Pessoa com\n\t\t\t\t\tDeficiência, regulamentado por esta Lei, como órgão colegiado\n\t\t\t\t\tconsultivo, deliberativo, fiscalizador e articulador das políticas\n\t\t\t\t\tvoltadas a assegurar os direitos da pessoa com deficiência,\n\t\t\t\t\tvinculado à Secretaria de Estado responsável pelas políticas\n\t\t\t\t\tpúblicas para as pessoas com deficiência, sem prejuízo de suas\n\t\t\t\t\tatribuições, acompanhará os trabalhos desenvolvidos pela Assessoria\n\t\t\t\t\tEspecial para integração da Pessoa com Deficiência e pela Área de\n\t\t\t\t\tAtenção Especial à Pessoa com Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 263. Serão afixados, nas sedes dos órgãos e entidades da\n\t\t\t\t\tAdministração Pública do Estado, informativos que destaquem a\n\t\t\t\t\tidentificação do "Ponto Focal de Atendimento" a que se\n\t\t\t\t\trefere este Capítulo.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        '\n\t\t\t\t\tArt. 264. Os órgãos e entidades abrangidos por esta Lei terão o\n\t\t\t\t\tprazo de trinta dias, contados da data de sua publicação, para a\n\t\t\t\t\tindicação do "Ponto Focal de Atendimento" à Assessoria\n\t\t\t\t\tEspecial para integração da Pessoa com Deficiência da Secretaria de\n\t\t\t\t\tEstado responsável pelas políticas públicas para as pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t'
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 265. Os procedimentos operacionais da Área de Atenção Especial\n\t\t\t\t\tà Pessoa com Deficiência deverão ser definidos em ato normativo\n\t\t\t\t\tpróprio, elaborado pela Assessoria Especial para integração da\n\t\t\t\t\tPessoa com Deficiência, da Secretaria de Estado responsável pelas\n\t\t\t\t\tpolíticas públicas para as pessoas com deficiência, no prazo máximo\n\t\t\t\t\tde noventa dias, contados da data da publicação desta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDO CENTRO DE REFERÊNCIA E APOIO DA PESSOA COM DEFICIÊNCIA\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 266. O Governo do Estado fica autorizado a criar os Centros de\n\t\t\t\t\tReferência e Apoio às Pessoas com Deficiência em todo o território\n\t\t\t\t\tdo Estado do Paraná.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 267. O Centro de Referência e Apoio às Pessoas com Deficiência\n\t\t\t\t\tterá como principais finalidades:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - disponibilizar para as pessoas com deficiência e suas famílias\n\t\t\t\t\tinformações e encaminhamentos necessários sobre recursos para\n\t\t\t\t\tatendimento de suas necessidades na área de políticas públicas;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - disponibilizar para a população em geral informações que\n\t\t\t\t\tpossibilitem a valorização da diversidade humana e fortalecimento da\n\t\t\t\t\taceitação das diferenças individuais;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - fornecer orientação geral aos pais, a partir do período\n\t\t\t\t\tpré-natal, na rede pública de saúde, com continuidade nas fases\n\t\t\t\t\tseguintes do desenvolvimento da pessoa.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 268. Para viabilizar a criação dos Centros de Referência e\n\t\t\t\t\tApoio às Pessoas com Deficiência, o Poder Executivo poderá celebrar\n\t\t\t\t\tconvênios com órgãos públicos federais, estaduais e municipais.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCAPÍTULO V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tDO FUNDO ESTADUAL DA PESSOA COM DEFICIÊNCIA\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 269. O Poder Executivo do Estado do Paraná, com a finalidade de\n\t\t\t\t\tprover recursos para a realização de políticas públicas, planos,\n\t\t\t\t\tprojetos, programas, ações, entre outros, visando a assegurar,\n\t\t\t\t\tpromover e proteger o exercício pleno e em condições de equidade de\n\t\t\t\t\ttodos os direitos humanos e fundamentais das pessoas com\n\t\t\t\t\tdeficiência, encaminhará Anteprojeto de Lei à Assembleia Legislativa\n\t\t\t\t\tdo Estado do Paraná propondo a criação do Fundo Estadual da Pessoa\n\t\t\t\t\tcom Deficiência - FEPCD, no âmbito da Secretaria de Estado\n\t\t\t\t\tresponsável pelas políticas públicas das pessoas com deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. O Anteprojeto de Lei propondo a criação do Fundo\n\t\t\t\t\tEstadual da Pessoa com Deficiência deverá ser encaminhado no prazo\n\t\t\t\t\tde 120 (cento e vinte) dias, contados da data da publicação da\n\t\t\t\t\tpresente Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tTÍTULO IV\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDAS MEDIDAS DE PROTEÇÃO\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 270. As medidas de proteção à pessoa com deficiência são\n\t\t\t\t\taplicáveis sempre que os seus direitos, reconhecidos nesta Lei ou em\n\t\t\t\t\toutra legislação, forem ameaçados ou violados:\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - por falta, omissão ou abuso da família, tutor, curador ou\n\t\t\t\t\tentidade de atendimento;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - por ação ou omissão da sociedade ou do Estado;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - em razão de sua condição pessoal.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tTÍTULO V\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tDAS DISPOSIÇÕES FINAIS\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 271. O Poder Público do Estado do Paraná tomará as medidas\n\t\t\t\t\tnecessárias para assegurar o cumprimento do previsto no art. 23\n\t\t\t\t\tdesta Lei, provendo médicos pediatras e médicos neonatologistas.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 272. As obrigações previstas nesta Lei não excluem as já\n\t\t\t\t\testabelecidas em outras legislações, inclusive em pactos, tratados,\n\t\t\t\t\tconvenções e declarações internacionais dos quais o Brasil seja\n\t\t\t\t\tsignatário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 273. O PPA, a LDO e a LOA deverão conter programas, metas e\n\t\t\t\t\trecursos orçamentários destinados ao atendimento das pessoas com\n\t\t\t\t\tdeficiência, observando o disposto no paragrafo único do art. 2º\n\t\t\t\t\tdesta Lei.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 274. Os valores arrecadados com as multas previstas nesta Lei\n\t\t\t\t\tdeverão ser destinados ao Fundo Estadual da Pessoa com Deficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tParágrafo único. Enquanto o fundo de que trata o caput deste artigo\n\t\t\t\t\tnão for criado, os valores deverão ser destinados obrigatoriamente à\n\t\t\t\t\tcriação e execução de políticas públicas para as pessoas com\n\t\t\t\t\tdeficiência.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 275. As despesas decorrentes na aplicação desta Lei, no\n\t\t\t\t\tcorrente exercício, correrão por conta das dotações orçamentárias\n\t\t\t\t\tpróprias e suplementares, caso necessário.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tArt. 276. Esta Lei entra em vigor na data de sua publicação.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tArt. 277. Ficam revogadas:\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tI - Lei nº 11.911, de 1º de dezembro de 1997;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tII - Lei nº 13.041, de 11 de janeiro de 2001;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIII - Lei nº 13.450, de 11 de janeiro de 2002;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIV - os arts. 2º, 3º, 4º, 5º, 6º, 7º, 8º 9º, 10º, 11, 12, 13, 14,\n\t\t\t\t\t15, 16, 17, 18, 19, 20 e 21 da Lei nº 13.456, de 11 de janeiro de\n\t\t\t\t\t2002;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tV - Lei nº 13.871, de 25 de novembro de 2002;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVI - Lei nº 15.000, de 26 de janeiro de 2006;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVII - Lei nº 15.139, de 31 de maio de 2006;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tVIII - Lei nº 15.051, de 17 de abril de 2006;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tIX - Lei nº 15.267, de 18 de setembro de 2006;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tX - Lei nº 15.427, de 15 de janeiro de 2007;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXI - Lei nº 15.430, de 15 de janeiro de 2007;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXII - Lei nº 15.432, de 15 de janeiro de 2007;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - Lei nº 15.441, de 15 de janeiro de 2007;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIV - Lei nº 15.539, de 22 de junho de 2007;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXV - Lei nº 16.087, de 23 de abril de 2009;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVI - Lei nº 16.629, de 22 de novembro de 2010;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXVII - Decreto nº 857, de 24 de março de 2011;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIII - Decreto nº 5.417, de 18 de setembro de 2009;\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tXIX - o art. 1º da Lei nº 12.095, de 11 de março de 1998.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tPalácio do Governo, em 07 de janeiro de 2015.\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tCarlos Alberto Richa\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tGovernador do Estado\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tLetícia Codagnone Ferrera Raymundo\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tSecretária de Estado do Trabalho e Desenvolvimento Social, em\n\t\t\t\t\texercício\n\t\t\t\t"
                      )
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v("\n\t\t\t\t\tMaria Tereza Uille Gomes\n\t\t\t\t")
                    ])
                  ]),
                  _c("b-form-group", { staticClass: "text-label" }, [
                    _c("label", { attrs: { tabindex: "0" } }, [
                      _vm._v(
                        "\n\t\t\t\t\tSecretária de Estado da Justiça, Cidadania e Direitos Humanos\n\t\t\t\t"
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "right" } },
        [
          _c(
            "b-col",
            { attrs: { xl: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    outline: "",
                    size: "md",
                    variant: "secondary",
                    title: "Voltar para tela inicial"
                  },
                  on: {
                    click: function($event) {
                      return _vm.voltar()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-arrow-left" }),
                  _vm._v(" Voltar\n\t\t\t")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }