














































import { RecursoAcessibilidade } from '@/model/recurso-acessibilidade';
import { Component, Prop, Vue } from 'vue-property-decorator';
import recursoAcessibilidadeService from '@/api/recurso-acessibilidade.service';
import { RecursoAcessibilidadeFilter } from '@/model/filter/recurso-acessibilidade-filter';

@Component
export default class RecursoAcessibilidadeComponent extends Vue {

    @Prop() public isExibirProp!: Boolean;
    @Prop() public showCollapseProp!: Boolean;
    @Prop() public recursosAcessibilidadeProp!: Array<RecursoAcessibilidade>;

    public showContent: Boolean = false;

    public recursosSelecionados: Array<RecursoAcessibilidade> = Array.of();
    public recursos: Array<RecursoAcessibilidade> = Array.of();

    constructor() {
        super();
        this.$parent.$on('openFieldset', this.openFieldset);
        this.recursos = Array.of();
        this.recursosSelecionados = this.recursosAcessibilidadeProp;
        this.carregaRecursosAcessibilidade();
        if (this.showCollapseProp != undefined) {
            this.showContent = this.showCollapseProp;
        }
    }

    async carregaRecursosAcessibilidade() {
        const retorno = await recursoAcessibilidadeService.listarRecursosAcessibilidade(new RecursoAcessibilidadeFilter("", "", true), false);
        if (retorno != null){
            this.recursos = retorno.registros; 
        }
    }

    removerAcessibilidade(ace: RecursoAcessibilidade) {
        if (this.recursosSelecionados != null) {
            const index = this.recursosSelecionados.indexOf(ace, 0);
            if (index > -1) {
                this.recursosSelecionados.splice(index, 1);
            }
        }
    }

    public openFieldset(value) {
        if (this.$validator.errors.count() > 0) {
            this.showContent = value;
        }
    }
}   

